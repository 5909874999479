import React from "react";
import "./AdminForgotPassword.css";
import CustomInput from "components/widgets/custom-input/CustomInput.js";
import CustomButton from "components/widgets/custom-button/CustomButton.js";
import MainLayout from "components/layout/main-layout/MainLayout.js";
import { titles } from "constants/titles";

const AdminForgotPassword = () => {
  return (
    <MainLayout
      text={titles?.forgot_password}
      isBackHide={false}
      isNavbar={false}
    >
      <div className="afp_container">
        <h1 className="afp_header_text body_h1">{titles?.forgot_password}</h1>
        <h1 className="afp_header_text body_h1 afp_reset_link">
          {titles?.send_you_reset_link}
        </h1>
        <CustomInput label={titles?.email} type="email" className="mb22" />
        <CustomButton
          height={"50px"}
          text={titles?.send_instruction}
          className={"blue_button text-white-color"}
        />
      </div>
    </MainLayout>
  );
};

export default AdminForgotPassword;
