export const ADMIN_LIST_REQUEST = "ADMIN_LIST_REQUEST";
export const ADMIN_LIST_SUCCESS = "ADMIN_LIST_SUCCESS";
export const ADMIN_LIST_FAILURE = "ADMIN_LIST_FAILURE";

export const ADMIN_ADD_REQUEST = "ADMIN_ADD_REQUEST";
export const ADMIN_ADD_SUCCESS = "ADMIN_ADD_SUCCESS";
export const ADMIN_ADD_FAILURE = "ADMIN_ADD_FAILURE";

export const ADMIN_EDIT_REQUEST = "ADMIN_EDIT_REQUEST";
export const ADMIN_EDIT_SUCCESS = "ADMIN_EDIT_SUCCESS";
export const ADMIN_EDIT_FAILURE = "ADMIN_EDIT_FAILURE";

export const ADMIN_PROFILE_UPDATE_REQUEST = "ADMIN_PROFILE_UPDATE_REQUEST";
export const ADMIN_PROFILE_UPDATE_SUCCESS = "ADMIN_PROFILE_UPDATE_SUCCESS";
export const ADMIN_PROFILE_UPDATE_FAILURE = "ADMIN_PROFILE_UPDATE_FAILURE";

export const ADMIN_DELETE_REQUEST = "ADMIN_DELETE_REQUEST";
export const ADMIN_DELETE_SUCCESS = "ADMIN_DELETE_SUCCESS";
export const ADMIN_DELETE_FAILURE = "ADMIN_DELETE_FAILURE";

export const ADMIN_SHOW_LOADER = "ADMIN_SHOW_LOADER";
export const ADMIN_HIDE_LOADER = "ADMIN_HIDE_LOADER";

export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";

export const ADMIN_MENU_LIST_REQUEST='ADMIN_MENU_LIST_REQUEST'
export const ADMIN_MENU_LIST_SUCESS='ADMIN_MENU_LIST_SUCESS'
export const ADMIN_MENU_LIST_FAILURE='ADMIN_MENU_LIST_FAILURE'

export const ADMIN_CHANGE_PASSWORD_REQUEST='ADMIN_CHNAGE_PASSWORD_REQUEST';
export const ADMIN_CHANGE_PASSWORD_SUCESS='ADMIN_CHNAGE_PASSWORD_SUCESS';
export const ADMIN_CHANGE_PASSWORD_FAILURE='ADMIN_CHNAGE_PASSWORD_FAILURE';

export const ADMIN_GENRATE_OTP_REQUEST='ADMIN_GENRATE_OTP_REQUEST';
export const ADMIN_GENRATE_OTP_SUCESS='ADMIN_GENRATE_OTP_SUCESS';
export const ADMIN_GENRATE_OTP_FAILURE='ADMIN_GENRATE_OTP_FAILURE';

export const ADMIN_VERIFY_OTP_REQUEST='ADMIN_VERIFY_OTP_REQUEST';
export const ADMIN_VERIFY_OTP_SUCESS='ADMIN_VERIFY_OTP_SUCESS';
export const ADMIN_VERIFY_OTP_FAILURE='ADMIN_VERIFY_OTP_FAILURE';