import React, { useRef, useState } from "react";
import "./CustomInput.css";
import { IconHide, IconReveal } from "assets/SVGs/SVGs";

const CustomInput = (props) => {
  const [showPasswordIcon, setShowPasswordIcon] = useState(false);
  const inputRef = useRef();
  const {
    type,
    value,
    onChange,
    required,
    label,
    className,
    placeholder = "",
    disabled,
    labelClassName,
    placeholderStyle,
    maxLength,
    hideEyeIcon = false,
    isOnlyNumber,
    characters,
    multiline = false,
  } = props;
  const placeholderText = (
    <p
      onClick={() => {
        inputRef?.current?.focus();
      }}
      className={`ci_placeholder_text  ${placeholderStyle}`}
    >
      {placeholder}
      {required && <span className="error_text">*</span>}
    </p>
  );

  const handleKeyDown = (event) => {
    // Allow only digits (0-9), backspace, delete, arrow keys, and tab
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Home",
      "End",
    ];
    const isDigit = event.key >= "0" && event.key <= "9";

    if (!isDigit && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData("text");
    if (!/^\d+$/.test(paste)) {
      event.preventDefault();
    }
  };

  const sharedProps = {
    ref: inputRef,
    disabled: disabled,
    className: `${className} ci_input ${multiline ? 'ci_input_textarea' : ''}`,
    value: value,
    onChange: onChange,
    onKeyDown: type === "number" || isOnlyNumber ? handleKeyDown : null,
    onPaste: type === "number" || isOnlyNumber ? handlePaste : null,
    required: required,
    maxLength: maxLength || characters,
    autoComplete:
      type === "email" ? "email" : type === "password" ? "new-password" : "off",
  };

  return (
    <div className="ci_container">
      {label ? (
        <p
          className={`ci_label display_h4 ${
            characters ? "" : "mb5"
          } ${labelClassName}`}
        >
          {label}
        </p>
      ) : null}
      {characters ? (
        <p className="body_h1 edm_input_note">
          {characters - value?.length} characters left
        </p>
      ) : null}
      {value ? null : placeholderText}
      {multiline ? (
        <textarea {...sharedProps}
        row={8} />
      ) : (
        <input
          {...sharedProps}
          type={
            type === "password"
              ? showPasswordIcon
                ? "text"
                : "password"
              : type
          }
        />
      )}
      {type === "password" && !hideEyeIcon ? (
        showPasswordIcon ? (
          <IconReveal
            className={"ci_iconHide"}
            onClick={() => setShowPasswordIcon(!showPasswordIcon)}
          />
        ) : (
          <IconHide
            className={"ci_iconHide"}
            onClick={() => setShowPasswordIcon(!showPasswordIcon)}
          />
        )
      ) : null}
    </div>
  );
};

export default CustomInput;
