import React, { useEffect, useState } from "react";
import "./Home.css";
import MainLayout from "components/layout/main-layout/MainLayout.js";
import CustomButton from "components/widgets/custom-button/CustomButton.js";
import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper.js";
import { titles } from "constants/titles";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { useHistory } from "react-router-dom";
import AccountHolderHome from "./account-holder-home/AccountHolderHome";
import { useDispatch, useSelector } from "react-redux";
import { getAccountOwnerDetails, getEsignHistoryList } from "redux/UserDetails/actions";
import {
  getMedicalAlertUsers,
  setSelectedMedicalUser,
} from "redux/MedicalAlertUsers/actions";
import { getStaticUrls } from "redux/Common/action";

const Home = () => {
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const userData = useSelector((store) => store?.auth?.userData);
  const medicalAlertUserList = useSelector(
    (store) => store?.medicalAlertUsers?.medicalAlertUserList
  );
  const isMedicalUserLoading = useSelector(
    (store) => store?.medicalAlertUsers?.loading
  );
  const { medicalUserTimezone } = useSelector(
    (store) => store?.common
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!userData?.isGuestUser) {
      getAccountOwnerDetails(dispatch, history);
      getEsignHistoryList(dispatch, history);
    }
    getMedicalUsers();
  }, []);

  const getMedicalUsers = () => {
    setTimeout(() => {
      const params = {
        dispatch,
        setSnackBarMessage,
        userType: userData?.isGuestUser ? "guestuser" : "accountowner",
        history,
      };
      getMedicalAlertUsers(params);
      getStaticUrls(params);
    }, 500);
  };

  const onSubscriberDetail = async(item) => {
    setSelectedMedicalUser(dispatch, item);
    const timezone =
    medicalUserTimezone?.length > 0
      ? await medicalUserTimezone?.find(
          (data) => data?.medicalAlertUserId === item?.medical_alert_user_id
        )
      : null;

  setSelectedMedicalUser(dispatch, { ...item, ...timezone });
    history.push(ROUTES_CONSTANTS?.subscriberProfile, {
      item: item,
    });
  };

  return (
    <MainLayout
      text={""}
      isBackHide={false}
      isSemiHeader={false}
      fullWidthScreen={true}
      isHomeHeader={false}
      isLoading={isMedicalUserLoading}
      snackBarMessage={snackBarMessage}
      white={true}
    >
      <AccountHolderHome />
      <div className="home_container">
        {medicalAlertUserList?.map((item, index) => {
          return (
            <CardLayoutWrapper key={`medicalusers${index}`}>
              <p className="display_h3 mb10">
                {item?.firstname + " " + item?.lastname}
              </p>
              <CustomButton
                onClick={() => {
                  onSubscriberDetail(item);
                }}
                text={
                  userData?.isGuestUser
                    ? titles?.view_subscriber_profile
                    : titles?.see_subscriber_hub
                }
                width={"250px"}
              />
            </CardLayoutWrapper>
          );
        })}
      </div>
    </MainLayout>
  );
};

export default Home;
