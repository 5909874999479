import {
  BatteryLevel1,
  BatteryLevel2,
  BatteryLevel3,
  BatteryLevel4,
  BatteryLevel5,
  SignalLevel1,
  SignalLevel2,
  SignalLevel3,
  SignalLevel4,
} from "assets/SVGs/DeviceSignalSVG";
import keys from "constants/keys";
import moment from "moment-timezone";
import { getData } from "utility";

export const ringDevicePayload = {
  functionId: "hexFunctionCommand",
  value:
    "04211F2e4920616d20686572652e204565652e204565652e204920616d20686572652e",
  functionKey: "hex",
};

export const deviceLocationPayload = {
  functionId: "singleLocating",
  value: "",
  functionKey: "",
};

export const getBatteryIcon = (batterPercentage) => {
  const batteryLevel = parseInt(batterPercentage ? batterPercentage : 0);
  if (!batteryLevel || batteryLevel <= 0) {
    return <BatteryLevel1 />;
  } else if (batteryLevel > 0 && batteryLevel <= 25) {
    return <BatteryLevel2 />;
  } else if (batteryLevel > 25 && batteryLevel <= 75) {
    return <BatteryLevel3 />;
  } else if (batteryLevel > 75 && batteryLevel <= 99) {
    return <BatteryLevel4 />;
  } else if (batteryLevel === 100) {
    return <BatteryLevel5 />;
  }
  return <BatteryLevel1 />;
};

export const getSignalType = (signalValue) => {
  const signalLevel = parseInt(signalValue ? signalValue : 0);
  if (!signalLevel) {
    return "Unknown";
  } else if (signalLevel === 1) {
    return "None";
  } else if (signalLevel === 2) {
    return "Bad";
  } else if (signalLevel === 3) {
    return "Fair";
  } else if (signalLevel === 4) {
    return "Good";
  } else if (signalLevel === 5) {
    return "Excellent";
  }
  return "";
};

export const getSignalIcon = (signalValue) => {
  const signalLevel = parseInt(signalValue ? signalValue : 0);
  if (!signalLevel || signalLevel <= 1) {
    return <SignalLevel1 />;
  } else if (signalLevel === 2) {
    return <SignalLevel2 />;
  } else if (signalLevel === 3 || signalLevel === 4) {
    return <SignalLevel3 />;
  } else if (signalLevel === 5) {
    return <SignalLevel4 />;
  }
  return <SignalLevel1 />;
};


export const getSignalTextColor = (signalValue) => {
  const signalLevel = parseInt(signalValue ? signalValue : 0);

  if (!signalLevel || signalLevel <= 1) {
    return "dsc_no_signal_color";
  } else if (signalLevel === 2) {
    return "dsc_signal_low_color";
  } else if (signalLevel > 2) {
    return "pc_value_text";
  }
  return "dsc_no_signal_color";
};

export const getBatteryTextColor = (batterPercentage) => {
  const batteryLevel = parseInt(batterPercentage ? batterPercentage : 0);
  if (!batteryLevel || batteryLevel <= 0) {
    return "dsc_no_signal_color";
  } else if (batteryLevel > 0 && batteryLevel <= 25) {
    return "dsc_signal_low_color";
  } else if (batteryLevel > 25) {
    return "pc_value_text";
  }
  return "dsc_no_signal_color";
};

export const convertToTimeZone = (utcDate, timeZone, format12Hour = false) => {
  if (!utcDate || !timeZone) {
    return;
  }
  try {
    const localDate = moment.utc(utcDate).tz(timeZone);
    const timeZoneData = moment?.tz(timeZone)?.format("z");
    const format = format12Hour ? "MM/DD/YYYY hh:mm A" : "MM/DD/YYYY HH:mm";
    return `${localDate.format(format)} ${timeZoneData}`;
  } catch (error) {
    return null;
  }
};

export const checkTimeZone = async () => {
  const localTimeZoneData = await getData(keys?.timeZoneSettings);
  if (!localTimeZoneData) {
    return false;
  } else {
    return JSON.parse(localTimeZoneData);
  }
}

export const getCalculatedLastRefreshTime = (
  lastRefreshTime,
  latestDataTime
) => {
  try {
    if(!lastRefreshTime){
      return lastRefreshTime
    }
    const specificTime = moment.utc(latestDataTime);
    const currentTime = moment.utc(lastRefreshTime);
    if (currentTime?.isAfter(specificTime)) {
      return lastRefreshTime;
    } else {
      return latestDataTime;
    }
  } catch (e) {
    return lastRefreshTime;
  }
};