import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper";
import MainLayout from "components/layout/main-layout/MainLayout";
import CustomInput from "components/widgets/custom-input/CustomInput";
import { titles } from "constants/titles";
import React, { useEffect, useState } from "react";
import "./BillingDetails.css";
import { IconBillingCards, IconQuestion } from "assets/SVGs/SVGs";
import InformationModal from "components/layout/information-modal/InformationModal";
import CustomButton from "components/widgets/custom-button/CustomButton";
import CustomSelectBox from "components/widgets/custom-select-box/CustomSelectBox";
import { useDispatch, useSelector } from "react-redux";
import {
  addSpaceAfterFourNumbers,
  getAllRegionListNames,
  getCardType,
  getDataFromId,
  getExpiryYear,
  getMonthList,
  isEmpty,
  isFutureMonthAndYear,
  isSessionTimeExpired,
  removeSpecialChar,
} from "utility";
import moment from "moment";
import {
  getInvoiceList,
  updateMedicalAddress,
} from "redux/MedicalAlertUsers/actions";
import {
  addUpdateBillingBankDetail,
  addUpdateBillingCardDetail,
  payment,
} from "redux/Payments/actions";
import { useHistory, useLocation } from "react-router-dom";
import alerts from "constants/alerts";
import ChangesSavedModal from "components/layout/changes-saved-modal/ChangesSavedModal";
import { getRegionList } from "redux/Common/action";
import { setSessionExpiryModalVisibility } from "redux/auth/actions";
import { CustomRadio } from "components/widgets/custom-radio/CustomRadio";
import ConfirmationModal from "components/layout/confirmation-modal/ConfirmationModal";

const monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const BillingDetails = () => {
  const [showConfirmation, setConfirmation] = useState(false);
  const [changesSavedModal, setChangesSaveModal] = useState(false);
  const [billingDetailsData, setBillingDetailsData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    apt: "",
    city: "",
    state: null,
    zipCode: "",
    creditCardNumber: "",
    masterCard: "",
    expMonth: new Date(),
    expYear: new Date(),
    cvn: "",
    selectedCard: null,
    isCardDetailUpdate: false,
    isBankDetailUpdate: false,
    accountHolderName: "",
    bankAccountNumber: "",
    confirmBankAccountNumber: "",
    bankRoutingNumber: "",
    bankAccountType: null,
  });
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionBeforeChange, setSelectedOptionBeforeChange] =
    useState(null);
  const [infoModalPopup, setInfoModalPopup] = useState();
  const { loading, selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );
  const userData = useSelector((store) => store?.auth?.userData);

  const accountHolderData = useSelector(
    (store) => store?.userDetail?.accountHolderData
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const medicalUserDetail = location?.state?.medicalUserDetail;
  const allRegionList = location?.state?.allRegionList;
  const { cardDetails, cardList, bankDetails, accountTypes } =
    location?.state?.payments;

  useEffect(() => {
    const param = {
      dispatch,
      history,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      emergency_contract_id: selectedMedicalUser?.service_contract_id,
      medical_alert_user_id: selectedMedicalUser?.medical_alert_user_id,
      setSnackBarMessage,
      is_visible: false,
    };
    getRegionList(param);
    setInitialData();
    if (medicalUserDetail?.payment_method_type == "card") {
      setSelectedOption(titles.credit_card);
    } else {
      setSelectedOption(titles.bank_account);
    }
  }, []);

  const handleRadioSelect = (option) => {
    setSelectedOptionBeforeChange(option);
    setConfirmation(true);
  };

  const setInitialData = () => {
    const billingAddress = medicalUserDetail?.billing_address;
    const regionData = getDataFromId(
      allRegionList,
      "region_id",
      billingAddress?.region_id
    );

    const expiryDate = new Date();
    if (cardDetails) {
      expiryDate?.setMonth(cardDetails?.expiration_month - 1);
      expiryDate?.setFullYear(cardDetails?.expiration_year);
    }
    handleBankTypeDropdownSelect(
      false,
      bankDetails?.return_value,
      bankDetails?.account_type_id
    );
    setBillingDetailsData((prevState) => ({
      ...prevState,
      firstName: billingAddress?.firstname,
      lastName: billingAddress?.lastname,
      address: billingAddress?.street,
      apt: billingAddress?.street2 || "",
      city: billingAddress?.city,
      zipCode: billingAddress?.zip,
      accountHolderName: bankDetails?.name_on_account,
      bankRoutingNumber: bankDetails?.routing_number,
      bankAccountNumber: bankDetails?.last_4_digits
        ? `XXXX${bankDetails?.last_4_digits}`
        : "",
      state:
        regionData && regionData?.length > 0
          ? {
              id: regionData[0]?.region_id,
              value: regionData[0]?.region_code,
            }
          : null,
      expMonth: expiryDate,
      expYear: expiryDate,
      creditCardNumber: cardDetails?.last_4_digits
        ? `XXXX XXXX XXXX ${cardDetails?.last_4_digits}`
        : "",
      masterCard: cardDetails?.display_value ? cardDetails?.display_value : "",
      cvn: cardDetails?.last_4_digits ? "---" : "",
    }));
  };

  useEffect(() => {
    const billingAddress = medicalUserDetail?.billing_address;
    const regionData = getDataFromId(
      allRegionList,
      "region_id",
      billingAddress?.region_id
    );
    handleFieldChange(
      "state",
      regionData && regionData?.length > 0
        ? {
            id: regionData[0]?.region_id,
            value: regionData[0]?.region_code,
          }
        : null
    );
  }, [allRegionList]);

  const handleFieldChange = (key, value) => {
    let formattedText = value;
    let cardType = "";

    if (key === "creditCardNumber") {
      formattedText = addSpaceAfterFourNumbers(formattedText);
      formattedText = formattedText?.trim();
      const type = getCardType(formattedText);
      const selectedCard = cardList?.filter(
        (item) => item?.return_value === type
      );
      if (selectedCard && selectedCard?.length > 0) {
        cardType = selectedCard[0]?.display_value;
      }
      setBillingDetailsData((prevState) => ({
        ...prevState,
        masterCard: cardType ? cardType : "",
        selectedCard: cardType ? selectedCard[0] : null,
      }));
    }
    setBillingDetailsData((prevState) => ({
      ...prevState,
      [key]: formattedText,
    }));

    if (key === "creditCardNumber" || key === "cvn") {
      resetCardValue();
    }
    if (
      key === "bankAccountNumber" ||
      key === "bankRoutingNumber" ||
      key === "accountHolderName"
    ) {
      resetBankValue(false);
    }
  };

  const getInvoices = (medical_alert_user_id) => {
    getInvoiceList({
      dispatch,
      history,
      medical_alert_user_id: medical_alert_user_id,
    });
  };

  const onCardUpdateSuccess = (medical_alert_user_id, cardId) => {
    setTimeout(() => {
      payment({
        dispatch,
        history,
        setSnackBarMessage,
        setChangesSaveModal,
        getInvoices,
        payload: {
          card_id: cardId
            ? cardId
            : selectedOption?.toLowerCase() ==
              titles.bank_account?.toLowerCase()
            ? bankDetails?.bank_account_id
            : cardDetails?.card_id,
          medical_alert_user_id: medical_alert_user_id,
          invoices: location?.state?.invoices,
        },
      });
    }, 1000);
  };

  const creditCardValidation = () => {
    if (isEmpty(billingDetailsData?.creditCardNumber)) {
      setSnackBarMessage({ message: alerts?.enter_credit_card_number });
      return false;
    } else if (isEmpty(billingDetailsData?.masterCard)) {
      setSnackBarMessage({ message: alerts?.enter_valid_card_number });
      return false;
    } else if (
      !isFutureMonthAndYear(
        billingDetailsData?.expMonth,
        billingDetailsData?.expYear
      )
    ) {
      setSnackBarMessage({ message: alerts?.future_month_year });
      return false;
    } else if (isEmpty(billingDetailsData?.cvn)) {
      setSnackBarMessage({ message: alerts?.enter_cvn_number });
      return false;
    } else if (billingDetailsData?.cvn?.length < 3) {
      setSnackBarMessage({ message: alerts?.CVN_number_validation });
      return false;
    }
    return true;
  };

  const bankAccountValidation = () => {
    if (isEmpty(billingDetailsData?.accountHolderName)) {
      setSnackBarMessage({ message: alerts?.enter_account_holder_name });
      return false;
    } else if (isEmpty(billingDetailsData?.bankAccountNumber)) {
      setSnackBarMessage({ message: alerts?.enter_account_number });
      return false;
    } else if (
      billingDetailsData?.isBankDetailUpdate &&
      billingDetailsData?.bankAccountNumber !==
        billingDetailsData?.confirmBankAccountNumber
    ) {
      setSnackBarMessage({ message: alerts?.confirm_account_match });
      return false;
    } else if (isEmpty(billingDetailsData?.bankRoutingNumber)) {
      setSnackBarMessage({ message: alerts?.enter_bank_routing_number });
      return false;
    } else if (
      billingDetailsData?.bankAccountType == null ||
      billingDetailsData?.bankAccountType == ""
    ) {
      setSnackBarMessage({ message: alerts?.select_bank_account_type });
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    let timeDifference = await isSessionTimeExpired();
    if (isEmpty(billingDetailsData?.firstName)) {
      setSnackBarMessage({ message: alerts?.enter_first_name });
      return;
    } else if (isEmpty(billingDetailsData?.lastName)) {
      setSnackBarMessage({ message: alerts?.enter_last_name });
      return;
    } else if (isEmpty(billingDetailsData?.address)) {
      setSnackBarMessage({ message: alerts?.enter_address });
      return;
    } else if (isEmpty(billingDetailsData?.city)) {
      setSnackBarMessage({ message: alerts?.enter_city });
      return;
    } else if (isEmpty(billingDetailsData?.state)) {
      setSnackBarMessage({ message: alerts?.select_state });
      return;
    } else if (isEmpty(billingDetailsData?.zipCode)) {
      setSnackBarMessage({ message: alerts?.enter_zipcode });
      return;
    }
    let isValid = true;

    if (medicalUserDetail?.is_ach) {
      if (selectedOption == titles.credit_card) {
        isValid = creditCardValidation();
      } else if (selectedOption == titles.bank_account) {
        isValid = bankAccountValidation();
      }
    } else if (!medicalUserDetail?.is_ach) {
      isValid = creditCardValidation();
    }

    if (!isValid) {
      return;
    }

    if (timeDifference) {
      setSessionExpiryModalVisibility(dispatch, true);
      return;
    } else {
      const shippingAddress = medicalUserDetail?.billing_address;
      const selectedRegion = getDataFromId(
        allRegionList,
        "region_id",
        billingDetailsData?.state?.id
      );

      let payload = {
        address_id: shippingAddress?.address_id,
        region_id: billingDetailsData?.state?.id,
        reference_region_id:
          selectedRegion && selectedRegion?.length > 0
            ? selectedRegion[0]?.reference_region_id
            : null,
        street: billingDetailsData?.address,
        street2: billingDetailsData?.apt,
        postcode: billingDetailsData?.zipCode,
        city: billingDetailsData?.city,
        firstname: billingDetailsData?.firstName,
        lastname: billingDetailsData?.lastName,
        medical_alert_user_id: medicalUserDetail?.medical_alert_user_id,
      };
      const params = {
        dispatch,
        history,
        setSnackBarMessage,
        setChangesSaveModal,
        onCardUpdateSuccess: onCardUpdateSuccess,
        payload,
        medical_alert_user_id: medicalUserDetail?.medical_alert_user_id,
        userType: userData?.isGuestUser ? "guestuser" : "accountowner",
        engagementType: "Update CC",
        isPaymentRequired:
          location?.state?.isFromPayment &&
          !billingDetailsData?.isCardDetailUpdate &&
          !billingDetailsData?.isBankDetailUpdate,
        isRefreshRequired:
          !location?.state?.isFromPayment &&
          !billingDetailsData?.isCardDetailUpdate &&
          !billingDetailsData?.isBankDetailUpdate,
      };

      const payload2 = {
        ...payload,
        zip: parseInt(billingDetailsData?.zipCode),
        mobile: accountHolderData?.phone_one,
        card_number: billingDetailsData?.creditCardNumber?.replace(/\s/g, ""),
        expiration_year: parseInt(
          moment(billingDetailsData?.expYear)?.format("YYYY")
        ),
        expiration_month: parseInt(
          moment(billingDetailsData?.expMonth)?.format("MM")
        ),
        service_contract_id: medicalUserDetail?.service_contract_id,
        card_type_id: billingDetailsData?.selectedCard?.card_id,
        cvv: parseInt(removeSpecialChar(billingDetailsData?.cvn)),
      };

      const payload3 = {
        ...payload,
        zip: parseInt(billingDetailsData?.zipCode),
        mobile: accountHolderData?.phone_one,
        account_number: billingDetailsData.bankAccountNumber.replace(
          /\s+/g,
          ""
        ),
        routing_number: billingDetailsData?.bankRoutingNumber,
        name_on_account: billingDetailsData?.accountHolderName,
        service_contract_id: medicalUserDetail?.service_contract_id,
        account_type_id: billingDetailsData?.bankAccountType?.id,
      };

      updateMedicalAddress(params);

      const params2 = {
        ...params,
        payload: payload2,
        onCardUpdateSuccess: onCardUpdateSuccess,
        isFromPayment: location?.state?.isFromPayment,
      };

      const params3 = {
        ...params,
        payload: payload3,
        onCardUpdateSuccess: onCardUpdateSuccess,
        isFromPayment: location?.state?.isFromPayment,
      };

      if (medicalUserDetail?.is_ach) {
        if (
          billingDetailsData?.isCardDetailUpdate &&
          selectedOption == titles.credit_card
        ) {
          addUpdateBillingCardDetail(params2);
        }
        if (
          billingDetailsData?.isBankDetailUpdate &&
          selectedOption == titles.bank_account
        ) {
          addUpdateBillingBankDetail(params3);
        }
      } else if (billingDetailsData?.isCardDetailUpdate) {
        addUpdateBillingCardDetail(params2);
      }
    }
  };

  const resetCardValue = () => {
    if (!billingDetailsData?.isCardDetailUpdate) {
      setTimeout(() => {
        setBillingDetailsData((prevState) => ({
          ...prevState,
          isCardDetailUpdate: true,
          cvn: "",
          creditCardNumber: "",
          masterCard: "",
        }));
      }, 1000);
    }
  };

  const resetBankValue = (isBankType) => {
    if (
      !billingDetailsData?.isBankDetailUpdate &&
      bankDetails &&
      bankDetails?.last_4_digits
    ) {
      setTimeout(() => {
        if (isBankType) {
          setBillingDetailsData((prevState) => ({
            ...prevState,
            isBankDetailUpdate: true,
            confirmBankAccountNumber: "",
            accountHolderName: "",
            bankAccountNumber: "",
            bankRoutingNumber: "",
          }));
        } else {
          setBillingDetailsData((prevState) => ({
            ...prevState,
            isBankDetailUpdate: true,
            confirmBankAccountNumber: "",
            accountHolderName: "",
            bankAccountNumber: "",
            bankRoutingNumber: "",
            bankAccountType: null,
          }));
        }
      }, 1000);
    }
    setBillingDetailsData((prevState) => ({
      ...prevState,
      isBankDetailUpdate: true,
    }));
  };

  const convertArray = (data) => {
    const convertedArray = data?.map((item) => ({
      label: item?.display_value,
      value: item?.return_value,
      id: item?.bank_account_type_id,
    }));
    return convertedArray;
  };

  const handleDropdownSelect = (value, id) => {
    handleFieldChange("state", { id: id, value: value });
  };

  const handleBankTypeDropdownSelect = (isReset, value, id) => {
    handleFieldChange("bankAccountType", { id: id, value: value });
    if (isReset) {
      resetBankValue(true);
    }
  };

  const handleExpiryMonthYearSelect = (value, id, key) => {
    let selectedDate = new Date();
    const month = new Date(billingDetailsData?.expMonth);
    selectedDate?.setMonth(key === "expMonth" ? id : month.getMonth());
    selectedDate?.setFullYear(
      key === "expYear"
        ? value
        : moment(billingDetailsData?.expYear).format("YYYY")
    );
    if (selectedDate >= new Date()) {
      handleFieldChange(key, selectedDate);
      handleFieldChange(
        key == "expMonth" ? "expYear" : "expMonth",
        selectedDate
      );
      resetCardValue();
    } else {
      handleFieldChange("expMonth", selectedDate);
      handleFieldChange("expYear", selectedDate);
      resetCardValue();
    }
  };

  const showModal = (description) => {
    setInfoModalPopup({
      description,
      isVisible: true,
    });
  };

  const renderCreditCardDetail = () => {
    return (
      <div>
        <div className="payment_card_container mb15">
          <p className="sd_label_text display_h3">
            {titles?.credit_card_details}
          </p>
          <IconQuestion
            className={"sd_notify_icon"}
            onClick={() => showModal(titles?.credit_card_payment_description)}
          />
        </div>
        <div className="icon_gang_container mb20">
          <IconBillingCards />
        </div>
        <CustomInput
          placeholderStyle="bd_placeholder_style"
          placeholder={titles?.credit_card_number}
          type="text"
          className="mb10"
          required
          value={billingDetailsData?.creditCardNumber}
          maxLength={19}
          onChange={(e) => {
            handleFieldChange("creditCardNumber", e?.target?.value);
          }}
        />
        <CustomInput
          placeholderStyle="bd_placeholder_style"
          placeholder={titles?.credit_card_type}
          type="text"
          className="mb10"
          disabled={true}
          value={
            billingDetailsData?.creditCardNumber
              ? billingDetailsData?.masterCard
              : "Card Type"
          }
        />
        <CustomSelectBox
          required={true}
          options={getMonthList(monthArray)}
          selectedValue={
            billingDetailsData?.expMonth
              ? moment(billingDetailsData?.expMonth).format("MMMM")
              : ""
          }
          onChange={(item) =>
            handleExpiryMonthYearSelect(item?.value, item?.id, "expMonth")
          }
          title={titles?.exp_month}
          className={"mb10"}
        />
        <div className="sd_fields_container mb20">
          <CustomSelectBox
            required={true}
            options={getExpiryYear()}
            selectedValue={
              billingDetailsData && billingDetailsData?.expYear
                ? moment(billingDetailsData?.expYear)?.format("YYYY")
                : null
            }
            onChange={(item) =>
              handleExpiryMonthYearSelect(item?.value, item?.id, "expYear")
            }
            title={titles?.exp_year}
          />
          <div className="cvv_input_container">
            <CustomInput
              isOnlyNumber={true}
              placeholderStyle="bd_placeholder_style"
              placeholder={titles?.cvn}
              type="password"
              hideEyeIcon={true}
              required={true}
              value={billingDetailsData?.cvn}
              maxLength={4}
              onChange={(e) => {
                handleFieldChange("cvn", e?.target?.value);
              }}
            />
            <IconQuestion
              className={"bd_que_icon"}
              onClick={() => showModal(titles?.billing_cvn_description)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderBankCardDetail = () => {
    return (
      <div>
        <div className="payment_card_container mb15">
          <p className="sd_label_text display_h3">
            {titles?.bank_account_details}
          </p>
          <IconQuestion
            className={"sd_notify_icon"}
            onClick={() => showModal(titles?.credit_card_payment_description)}
          />
        </div>
        <CustomInput
          placeholderStyle="bd_placeholder_style"
          placeholder={titles?.account_holder_name}
          type="text"
          className="mb10"
          required
          value={billingDetailsData?.accountHolderName}
          maxLength={19}
          onChange={(e) => {
            handleFieldChange("accountHolderName", e?.target?.value);
          }}
        />
        <CustomInput
          placeholderStyle="bd_placeholder_style"
          placeholder={titles?.bank_account_number}
          type="text"
          className="mb10"
          required
          value={billingDetailsData?.bankAccountNumber}
          onChange={(e) => {
            handleFieldChange("bankAccountNumber", e?.target?.value);
          }}
        />
        {billingDetailsData?.isBankDetailUpdate && (
          <CustomInput
            placeholderStyle="bd_placeholder_style"
            placeholder={titles?.confirm_bank_account_number}
            type="text"
            className="mb10"
            required
            value={billingDetailsData?.confirmBankAccountNumber}
            onChange={(e) => {
              handleFieldChange("confirmBankAccountNumber", e?.target?.value);
            }}
          />
        )}
        <CustomInput
          placeholderStyle="bd_placeholder_style"
          placeholder={titles?.bank_routing_number}
          type="text"
          className="mb10"
          required
          value={billingDetailsData?.bankRoutingNumber}
          onChange={(e) => {
            handleFieldChange("bankRoutingNumber", e?.target?.value);
          }}
        />
        <CustomSelectBox
          required={true}
          options={convertArray(accountTypes)}
          selectedValue={billingDetailsData?.bankAccountType?.value}
          onChange={(item) =>
            handleBankTypeDropdownSelect(true, item?.value, item?.id)
          }
          title={titles?.bank_account_type}
          className={"mb10"}
        />
      </div>
    );
  };

  return (
    <MainLayout
      text={titles?.billing_details}
      isBackHide={false}
      isNavbar={true}
      white={true}
      snackBarMessage={snackBarMessage}
      isLoading={loading}
    >
      {showConfirmation && (
        <ConfirmationModal
          show={showConfirmation}
          hideImage
          description={titles?.payment_change}
          title={" "}
          btnText={titles?.yes}
          setShow={() => setConfirmation(false)}
          onClick={() => {
            setSelectedOption(selectedOptionBeforeChange);
            setConfirmation(false);
            resetBankValue(false);
            resetCardValue();
          }}
        />
      )}
      <ChangesSavedModal
        show={changesSavedModal}
        setShow={setChangesSaveModal}
      />
      <InformationModal
        show={infoModalPopup?.isVisible}
        setShow={setInfoModalPopup}
        infoText={infoModalPopup?.description}
      />
      <CardLayoutWrapper>
        <p className="display_h2 mb5">
          {medicalUserDetail?.firstname + " " + medicalUserDetail?.lastname}
        </p>
        <p className="body_h1 acc_status_text">{`${titles?.account} #${medicalUserDetail?.account_no}`}</p>
        <p className="body_h1 acc_status_text">{`${titles?.status}: ${
          medicalUserDetail?.is_subscription_cancelled ? "Inavtive" : "Active"
        }`}</p>
      </CardLayoutWrapper>
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb10 mr5">
          {titles?.name_on_card}
        </p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() => showModal(titles?.modal_texts?.name_on_card_help_tip)}
        />
      </div>
      <CustomInput
        placeholder={titles?.first_name}
        type="text"
        className="mb10"
        placeholderStyle="bd_placeholder_style"
        value={billingDetailsData?.firstName}
        onChange={(e) => handleFieldChange("firstName", e?.target?.value)}
        required
      />
      <CustomInput
        placeholder={titles?.last_name}
        type="text"
        className="mb20"
        placeholderStyle="bd_placeholder_style"
        required
        value={billingDetailsData?.lastName}
        onChange={(e) => {
          handleFieldChange("lastName", e?.target?.value);
        }}
      />
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb10 mr5">
          {titles?.billing_details}
        </p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() =>
            showModal(titles?.modal_texts?.billing_details_help_tip)
          }
        />
      </div>
      <p className="body_h1 left mb10">{titles?.street_address}</p>
      <CustomInput
        placeholderStyle="bd_placeholder_style"
        placeholder={titles?.address_placeholder_text}
        type="text"
        className="mb10"
        required
        value={billingDetailsData?.address}
        onChange={(e) => {
          handleFieldChange("address", e?.target?.value);
        }}
      />
      <CustomInput
        placeholderStyle="bd_placeholder_style"
        placeholder={titles?.apt}
        type="text"
        className="mb10"
        value={billingDetailsData?.apt}
        onChange={(e) => {
          handleFieldChange("apt", e?.target?.value);
        }}
      />
      <CustomInput
        placeholderStyle="bd_placeholder_style"
        placeholder={titles?.city}
        type="text"
        className="mb10"
        required
        value={billingDetailsData?.city}
        onChange={(e) => {
          handleFieldChange("city", e?.target?.value);
        }}
      />
      <div className="sd_fields_container mb10">
        <CustomSelectBox
          options={getAllRegionListNames(allRegionList)}
          title={titles?.state}
          required
          selectedValue={billingDetailsData?.state?.value}
          onChange={(item) => handleDropdownSelect(item?.value, item?.id)}
        />
      </div>
      <CustomInput
        placeholderStyle="bd_placeholder_style"
        placeholder={titles?.zip_code}
        type="number"
        className="mb10"
        required
        value={billingDetailsData?.zipCode}
        onChange={(e) => {
          handleFieldChange("zipCode", e?.target?.value);
        }}
      />
      {medicalUserDetail?.is_ach ? (
        <>
          <p className="sd_label_text display_h3 mb10">Payment method</p>
          <CustomRadio
            options={[titles.credit_card, titles.bank_account]}
            selectedOption={selectedOption}
            onPress={handleRadioSelect}
          />
        </>
      ) : (
        renderCreditCardDetail()
      )}
      {selectedOption === titles.credit_card && medicalUserDetail?.is_ach
        ? renderCreditCardDetail()
        : null}
      {selectedOption === titles.bank_account && medicalUserDetail?.is_ach
        ? renderBankCardDetail()
        : null}
      <CustomButton
        width={"100%"}
        height={"50px"}
        className={"bd_submit_btn"}
        text={titles?.submit}
        onClick={() => {
          onSubmit();
        }}
      />
    </MainLayout>
  );
};

export default BillingDetails;
