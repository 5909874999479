import React, { useEffect, useState } from "react";
import "./DeviceSignalHistory.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { titles } from "constants/titles";
import { Link } from "react-router-dom";
import SignalDetailCard from "components/layout/signal-detail-card/SignalDetailCard";
import DeviceInfoModal from "components/layout/device-info-modal/DeviceInfoModal";
import {
  getDeviceSignalHistory,
  setSelectedDeviceDetails,
} from "redux/Devices/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  ANELTO_DEVICE_DETAILS_SUCCESS,
  CLAIRVOYANT_DEVICE_DETAIL_SUCESS,
  EVIEW_DEVICE_DETAIL_SUCESS,
  FREEUS_DEVICE_DETAILS_SUCCESS,
} from "redux/Devices/actionTypes";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { convertInRequiredTimeZone } from "utility";

const DeviceSignalHistory = (props) => {
  const [showDeviceInfo, setShowDeviceInfo] = useState(false);
  const userData = useSelector((store) => store?.auth?.userData);
  const { selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );
  const {
    deviceList,
    loading,
    deviceSignalHistory,
    aneltoDeviceDetails,
    freeusDeviceDetails,
    eViewDeviceDetails,
    selectedDeviceDetails,
    lastDate,
  } = useSelector((store) => store?.devices);

  const { currentTimeZone } = useSelector((store) => store?.common);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isAllSignalHistory = location?.state?.isAllSignalHistory;

  useEffect(() => {
    getSignalHistory(false);
  }, []);

  const getSignalHistory = () => {
    const param = {
      dispatch,
      history,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      medical_alert_user_id: selectedMedicalUser
        ? selectedMedicalUser?.medical_alert_user_id
        : selectedDeviceDetails?.medical_alert_user_id,
      user_device_id: isAllSignalHistory
        ? undefined
        : selectedDeviceDetails?.user_device_id,
    };
    getDeviceSignalHistory(param);
  };

  const onDeviceProfileClick = (item) => {
    if (isAllSignalHistory) {
      const selectedDevice = deviceList?.filter(
        (device) => device?.user_device_id === item?.user_device_id
      );
      if (selectedDevice && selectedDevice?.length > 0) {
        setSelectedDeviceDetails(dispatch, selectedDevice[0]);
        redirectToDeviceProfile(item);
      }
    } else {
      //remove redirection for device signal history. will add it later if required
      // redirectToDeviceProfile()
    }
  };

  const redirectToDeviceProfile = (item) => {
    setSelectedDeviceDetails(dispatch, item);
    dispatch({
      type: ANELTO_DEVICE_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: FREEUS_DEVICE_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: CLAIRVOYANT_DEVICE_DETAIL_SUCESS,
      payload: null,
    });
    dispatch({
      type: EVIEW_DEVICE_DETAIL_SUCESS,
      payload: null,
    });
    history.push(ROUTES_CONSTANTS.deviceProfile, {
      isFromSignalHistory: true,
    });
  };

  const customSchemaForUI = () => {
    let autoFallDetection = false;
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (deviceType === "anelto") {
      autoFallDetection =
        aneltoDeviceDetails?.is_fall_detect === 1 ? true : false;
    } else if (deviceType === "freeus") {
      autoFallDetection =
        freeusDeviceDetails?.is_fall_detect === 1 ? true : false;
    } else if (deviceType === "eview") {
      const fallDownAlert = eViewDeviceDetails?.features?.fallDownAlert;
      const fallDownStatus = eViewDeviceDetails?.generalData?.fallDownStatu;
      autoFallDetection =
        fallDownAlert === 1
          ? true
          : fallDownAlert === 0
          ? false
          : fallDownStatus === 1
          ? true
          : false;
    }
    return {
      deviceName: selectedDeviceDetails?.device_name,
      autoFallDetection: autoFallDetection, // check
      deviceId: selectedDeviceDetails?.provider_device_id,
      userDeviceId: selectedDeviceDetails?.user_device_id,
      medicalAlertUserId: selectedDeviceDetails?.medical_alert_user_id,
    };
  };

  return (
    <MainLayout
      text={
        isAllSignalHistory
          ? titles?.all_signal_history
          : titles?.device_signal_history
      }
      isBackHide={false}
      isSemiHeader={true}
      white={true}
      isLoading={loading}
    >
      <DeviceInfoModal
        setShow={setShowDeviceInfo}
        show={showDeviceInfo}
        selectedDeviceDetails={selectedDeviceDetails}
        deviceSchema={customSchemaForUI()}
      />
      {isAllSignalHistory ? (
        <CardLayoutWrapper>
          <p className="display_h2 mb5">
            {selectedMedicalUser?.firstname +
              " " +
              selectedMedicalUser?.lastname}
          </p>
          <p className="body_h1 acc_status_text">{`${titles?.account} #${selectedMedicalUser?.account_no}`}</p>
          <p className="body_h1 acc_status_text">{`${titles?.status}: ${
            selectedMedicalUser?.is_subscription_cancelled
              ? "Inactive"
              : "Active"
          }`}</p>
          <p className="dp_card_last_refresh body_h1">
            {`Last Refreshed At : ${convertInRequiredTimeZone(
              lastDate,
              selectedMedicalUser?.timezone,
              currentTimeZone
            )}`}
          </p>
        </CardLayoutWrapper>
      ) : (
        <CardLayoutWrapper>
          <p className="dp_card_title display_h2 mb10">
            {customSchemaForUI()?.deviceName}
          </p>
          <Link
            onClick={() => setShowDeviceInfo(true)}
            className="dp_card_link web_link mb10"
            to={"#"}
          >
            {titles?.about_this_device}
          </Link>
          <CustomButton
            text={titles?.refresh_signal}
            width={"250px"}
            className={"mb10"}
            onClick={() => getSignalHistory(true)}
          />
          <p className="dp_card_last_refresh body_h1">
            {deviceSignalHistory &&
            deviceSignalHistory?.length > 0 &&
            deviceSignalHistory[0]?.date
              ? `${
                  titles?.last_refresh +
                  convertInRequiredTimeZone(
                    deviceSignalHistory?.[0]?.date,
                    selectedMedicalUser?.timezone,
                    currentTimeZone
                  )
                }`
              : ""}
          </p>
        </CardLayoutWrapper>
      )}
      <p className="body_h1 mb10 dsh_text">
        {isAllSignalHistory
          ? titles?.view_past_all_signals
          : titles?.view_past_signals}
      </p>
      {deviceSignalHistory?.length > 0 ? (
        deviceSignalHistory?.map((item) => {
          return (
            <SignalDetailCard
              className={"mb10"}
              signalData={item}
              onCardClick={onDeviceProfileClick}
            />
          );
        })
      ) : (
        <p className="display_h4 no_data_text">
          {titles?.no_history_available}
        </p>
      )}
    </MainLayout>
  );
};

export default DeviceSignalHistory;
