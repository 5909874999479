import React, { useEffect, useState } from "react";
import "./AddAccountContactModal.css";
import { Modal } from "react-bootstrap";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { IconReorder, IconTrash } from "assets/SVGs/SVGs";
import CustomInput from "components/widgets/custom-input/CustomInput";
import CustomCheckbox from "components/widgets/custom-checkbox/CustomCheckbox";
import CustomSelectWithModal from "components/widgets/custom-select-with-modal/CustomSelectWithModal";
import { formatPhoneNumber, isEmpty } from "utility";
import alerts from "constants/alerts";
import SnackBar from "components/widgets/snack-bar/SnackBar";
import { useDispatch, useSelector } from "react-redux";
import { getPhoneTypeList, setSelectedPhoneType } from "redux/Common/action";
import { useHistory } from "react-router-dom";

const AddAccountContactModal = ({
  show,
  onSave,
  onClose,
  phoneInfo,
  onDeleteContact,
}) => {
  const [phoneNumberState, setPhoneNumberState] = useState({
    phoneNumber: "",
    type: "",
    isAcceptSignature: false,
  });
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const { phoneTypeList = [], selectedPhoneType } = useSelector(
    (store) => store?.common
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setSnackBarMessage({ message: "" });
    setSelectedPhoneType(dispatch, null);
    getPhoneTypeList({
      dispatch,
      history,
      userType: "accountowner",
    });
  }, []);

  useEffect(() => {
    onValueChange("type", selectedPhoneType?.phone_type);
  }, [show, selectedPhoneType]);

  useEffect(() => {
    onValueChange("phoneNumber", formatPhoneNumber(phoneInfo?.phoneNumber));
    onValueChange("isAcceptSignature", phoneInfo?.auto_notify);
  }, [phoneInfo]);

  const setInitialState = () => {
    setPhoneNumberState({
      phoneNumber: "",
      type: "",
      isAcceptSignature: false,
    });
  };

  const onSaveChanges = () => {
    if (isEmpty(phoneNumberState?.phoneNumber)) {
      setSnackBarMessage({ message: alerts?.enter_phone_number });
    } else if (isEmpty(phoneNumberState?.type)) {
      setSnackBarMessage({ message: alerts?.select_type });
    } else {
      if (onSave) {
        onSave(phoneNumberState);
      } else {
        onClose();
      }
      setInitialState();
    }
  };

  const onCancel = () => {
    onClose();
    setInitialState();
  };

  const onValueChange = (type, value) => {
    setPhoneNumberState((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const handlePhoneNumberChange = (inputVal) => {
    const formattedPhoneNumber = formatPhoneNumber(inputVal);
    onValueChange("phoneNumber", formattedPhoneNumber);
  };

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="agu_container"
      show={show}
    >
      <Modal.Body className="agu_body">
        <SnackBar snackBarMessage={snackBarMessage} />
        <div className="agu_header ah_phone_container">
          <IconReorder />
          <p className="display_h5 flex_item2">
            {phoneInfo?.phoneNumber
              ? formatPhoneNumber(phoneInfo?.phoneNumber)
              : `(555) 555-5555`}
          </p>
          <IconTrash
            onClick={() => {
              onDeleteContact(phoneInfo);
            }}
            color="#000000"
          />
        </div>
        <div className="agu_content_section">
          <CustomInput
            type="text"
            required
            className="mb10 ah_input_style"
            placeholder={"(555) 555-5555"}
            placeholderStyle={"acc_holder_placeholder"}
            value={phoneNumberState?.phoneNumber}
            onChange={(e) => {
              handlePhoneNumberChange(e?.target?.value);
            }}
          />
          <CustomSelectWithModal
            keyValue={"phone_type"}
            required={true}
            placeholder={"Select"}
            selectedValue={phoneNumberState?.type}
            className={"mb15"}
            onChange={(value) => onValueChange("type", value)}
            options={phoneTypeList}
          />
          <p className="display_h3 sms_note mb15">
            {titles?.text_message_permissions}
          </p>
          <p className="body_h1 sms_note mb15">
            {titles?.message_permission_desc}
          </p>

          <div className="consent_note_container mb15">
            <CustomCheckbox
              className="acm_checkbox_field"
              checked={phoneNumberState?.isAcceptSignature ? true : false}
              onChange={() => {
                onValueChange(
                  "isAcceptSignature",
                  !phoneNumberState?.isAcceptSignature
                );
              }}
            />
            <span className="body_h1 ml-1 sms_note ">
              {titles?.message_permission_checkbox}{" "}
              <span className="opt_out_text">{titles?.opt_out}</span>
              {titles?.receiving_text_messages}
            </span>
          </div>
          <CustomButton
            className={"agu_card_btn agu_save_btn"}
            text={titles?.save_changes}
            width={"100%"}
            onClick={() => {
              onSaveChanges();
            }}
          />
          <CustomButton
            className={"agu_card_btn"}
            text={titles?.cancel}
            width={"100%"}
            onClick={() => {
              onCancel();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAccountContactModal;
