import React from "react";
import "./ConfirmationModal.css";
import { Modal } from "react-bootstrap";
import { CrossIconBtn } from "assets/SVGs/SVGs";
import CustomButton from "components/widgets/custom-button/CustomButton";

const ConfirmationModal = ({
  show,
  setShow,
  onClose,
  svgIcon,
  title,
  description,
  btnText,
  onClick,
  isNotClosable,
}) => {
  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="cm_container"
      show={show}
    >
      <Modal.Body className="cm_body">
        {!isNotClosable ? (
          <CrossIconBtn
            className="cm_cross_icon"
            onClick={() => (onClose ? onClose() : setShow(!show))}
          />
        ) : null}
        <div className="cm_container">
          <div className="cm_svg_container mb10">{svgIcon}</div>
          <p className="display_h5 cm_text mb10">{title}</p>
          <p className="body_h1 cm_text mb10">{description}</p>
          {btnText ? (
            <CustomButton
              text={btnText}
              width={"100%"}
              className={"cm_confirm_btn"}
              onClick={() => {
                onClick();
              }}
            />
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
