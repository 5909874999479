import React from "react";
import "./CustomButton.css";

const CustomButton = (props) => {
  const {
    text,
    onClick,
    className,
    height = "38px",
    width = "280px",
    disabled = false,
    isGrayButton = false
  } = props;
  return (
    <button
      className={`cb_container display_h4 ${className} ${isGrayButton ? "cb_container_gray" : ''}`}
      onClick={onClick}
      disabled={disabled}
      style={{
        height,
        width,
      }}
    >
      {text}
    </button>
  );
};

export default CustomButton;
