import axios from "axios";
import { API_VERSION, APP } from "constants/routes/api";

export const getApi = (url, token, userType = "") => {
  return new Promise(async (resolve, reject) => {
    axios.defaults.headers.common.Authorization = token;
    axios.defaults.headers.common.isguestuserrequest =
      userType == "guestuser" ? true : false;
    axios.defaults.headers.common.isaccountownerrequest =
      userType == "accountowner" ? true : false;
    axios.defaults.headers.common.isadminrequest =
      userType == "admin" ? true : false;
    axios.defaults.headers.common.devicetype = "android";
    axios.defaults.headers.common.appversion = API_VERSION;
    axios.defaults.headers.common.app = APP;

    axios
      .get(url)
      .then((response) => {
        if (response.data !== null) {
          if (response.status === 200) {
            resolve(response);
          }
        } else {
          reject(new Error(response.data.message));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postApi = (
  url,
  token,
  data,
  userType,
  isFormData = false,
  isAdminRequest = false
) => {
  return new Promise(async (resolve, reject) => {
    axios.defaults.headers.common.Authorization = token;
    axios.defaults.headers.common.isguestuserrequest =
      userType == "guestuser" ? true : false;
    axios.defaults.headers.common.isaccountownerrequest =
      userType == "accountowner" ? true : false;
    axios.defaults.headers.common.isadminrequest =
      userType == "admin" ? true : false;
    axios.defaults.headers.common.devicetype = "ios";
    axios.defaults.headers.common.appversion = API_VERSION;
    axios.defaults.headers.common.app = APP;

    axios
      .post(url, data, {
        headers: {
          "Content-Type": isFormData ? false : "application/json",
          mimeType: isFormData ? "multipart/form-data" : undefined,
        },
      })
      .then((response) => {
        if (response.data !== null) {
          if (response.status === 200) {
            resolve(response);
          }
        } else {
          reject(new Error(response.data.message));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateApi = (url, token, data, userType) => {
  return new Promise(async (resolve, reject) => {
    axios.defaults.headers.common.Authorization = token;
    axios.defaults.headers.common.isguestuserrequest =
      userType == "guestuser" ? true : false;
    axios.defaults.headers.common.isaccountownerrequest =
      userType == "accountowner" ? true : false;

    axios.defaults.headers.common.devicetype = "android";
    axios.defaults.headers.common.appversion = API_VERSION;
    axios.defaults.headers.common.app = APP;

    axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data !== null) {
          if (response.status === 200) {
            resolve(response);
          }
        } else {
          reject(new Error(response.data.message));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
