import MainLayout from "components/layout/main-layout/MainLayout";
import "./DeviceProfile.css";
import { titles } from "constants/titles";
import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper";
import { Link } from "react-router-dom";
import CustomButton from "components/widgets/custom-button/CustomButton";
import ParameterCard from "components/layout/device-detail-card/DeviceDetailCard";
import {
  ArrowRight,
  IconEdit,
  IconGPSDevice,
  IconQuestion,
  IconStep,
  IconTracking,
} from "assets/SVGs/SVGs";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import DeviceInfoModal from "components/layout/device-info-modal/DeviceInfoModal";
import EditDeviceNameModal from "components/layout/edit-device-name-modal/EditDeviceNameModal";
import AddStepGoalModal from "components/layout/add-step-goal-modal/AddStepGoalModal";
import ConfirmationModal from "components/layout/confirmation-modal/ConfirmationModal";
import ChangesSavedModal from "components/layout/changes-saved-modal/ChangesSavedModal";
import RingDeviceModal from "components/layout/ring-device-modal/RingDeviceModal";
import {
  checkIsCurrentDate,
  convertInRequiredTimeZone,
  getLastRefreshTimeInMinutes,
  getManualRefreshTime,
  getRefreshDisabledMessage,
  getStaticUrlByKey,
  isDisableManualRefresh,
  isEmpty,
} from "utility";
import moment from "moment";
import {
  callHeartBeatForClairvoyantDevice,
  getAneltoDeviceDetail,
  getAneltoDeviceInitialLoadData,
  getAneltoTimeline,
  getClairvoyantDeviceDetail,
  getClairvoyantTimeline,
  getEviewTimeLine,
  getFreeusDeviceDetail,
  getFreeusDeviceInitialLoadData,
  getFreeusTimeline,
  ringMyDevice,
  setDeviceDetailLoaderVisibility,
  updateEviewFunction,
  getEviewDeviceDetail,
} from "redux/Devices/actions";
import { useDispatch, useSelector } from "react-redux";
import { loader_gif } from "assets/images/images";
import {
  deviceLocationPayload,
  getCalculatedLastRefreshTime,
} from "../../utility/deviceDetailUtils";
import InformationModal from "components/layout/information-modal/InformationModal";
import DeviceSignalCard from "components/layout/device-signal-card/DeviceSignalCard";
import FrequentRequestPopup from "components/layout/frequent-request-popup/FrequentRequestPopup";
import Loader from "react-spinners/ClipLoader";

const DeviceProfile = () => {
  const [showDeviceInfo, setShowDeviceInfo] = useState(false);
  const [showEditDeviceModal, setShowEditDeviceModal] = useState(false);
  const [showAddStepGoalModal, setShowAddStepGoalModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showSavedSuccessModal, setShowSavedSuccessModal] = useState(false);
  const [showRingDeviceModal, setShowRingDeviceModal] = useState(false);
  const [eViewIntervalIndex, setEviewIntervalIndex] = useState(0);
  const [eViewInterval, setEviewInterval] = useState(false);
  const [isMapLoaded, setMapLoaded] = useState(true);
  const [informationModal, setInformationModal] = useState(false);
  const [frequentRequestPopup, setFrequentRequestPopup] = useState({
    data: null,
    isVisible: false,
  });
  const history = useHistory();

  const accordianButtons = [
    {
      name: titles?.device_signal_history,
      onClick: () => history.push(ROUTES_CONSTANTS?.deviceSignalHistory),
    },
    {
      name: titles?.get_user_help_guide,
      onClick: () => onUserHelpGuide(),
    },
  ];

  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });

  const [deviceLocation, setDeviceLocation] = useState({
    latitude: 0,
    longitude: 0,
    latitudeDelta: 0.008,
    longitudeDelta: 0.008,
  });

  const [disableManualRefresh, setDisableManualRefresh] = useState(false);
  const [manualRefreshIntervalTime, setManualRefreshIntervalTime] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const userData = useSelector((store) => store?.auth?.userData);
  const {
    aneltoDeviceDetails,
    selectedDeviceDetails,
    freeusDeviceDetails,
    clairvoyantDeviceDetails,
    loading,
    freeusIntervals,
    aneltoIntervals,
    clairvoyantIntervals,
    eViewDeviceDetails,
    deviceDetailLoading,
    eViewIntervalArray,
    manualRefreshLoading,
    deviceRefreshData,
  } = useSelector((store) => store?.devices);
  const { staticUrls, currentTimeZone } = useSelector((store) => store?.common);
  const { selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );
  const dispatch = useDispatch();
  const isInHomDevice = isEmpty(selectedDeviceDetails?.device_type);

  useEffect(() => {
    if (!isInHomDevice) {
      const param = {
        dispatch,
        userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      };
      getAneltoTimeline(param);
      getFreeusTimeline(param);
      getClairvoyantTimeline(param);
      getEviewTimeLine(param);
      getDeviceProfileDetail(true);
    }
    return () => {
      setDeviceDetailLoaderVisibility(dispatch, false);
    };
  }, []);

  useEffect(() => {
    if (!disableManualRefresh) return;
    const intervalId = setInterval(() => {
      setManualRefreshIntervalTime((prevTimer) => {
        if (prevTimer <= 0) {
          setDisableManualRefresh(false);
          if (intervalId) clearInterval(intervalId);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [disableManualRefresh]);

  useEffect(() => {
    if (selectedDeviceDetails) {
      animateMapToRegion();
    }
  }, [
    selectedDeviceDetails,
    freeusDeviceDetails?.longitude,
    aneltoDeviceDetails?.latitude,
    clairvoyantDeviceDetails?.longitude,
    eViewDeviceDetails?.latestLocation?.lat,
  ]);

  useEffect(() => {
    try {
      if (
        aneltoDeviceDetails &&
        Object?.keys(aneltoDeviceDetails)?.length > 0
      ) {
        checkManualRefreshButtonAccess();
        if (
          !aneltoDeviceDetails?.is_data_found ||
          aneltoDeviceDetails?.is_active_request
        ) {
          setTimeout(() => {
            getDeviceDetailManually();
          }, aneltoDeviceDetails?.next_interval_call);
        }
      }
    } catch (e) {}
  }, [aneltoDeviceDetails, deviceRefreshData]);

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    let timeoutId = null;
    if (
      clairvoyantDeviceDetails &&
      deviceType === "clairvoyant" &&
      Object?.keys(clairvoyantDeviceDetails)?.length <= 0 &&
      currentIndex < clairvoyantIntervals?.intervals?.length
    ) {
      timeoutId = setTimeout(() => {
        getDeviceDetailManually();
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, clairvoyantIntervals?.intervals[currentIndex]);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    clairvoyantIntervals?.intervals,
    clairvoyantDeviceDetails,
    selectedDeviceDetails,
  ]);

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    let timeoutId = null;
    if (
      eViewInterval &&
      deviceType === "eview" &&
      eViewIntervalArray?.intervals &&
      eViewIntervalIndex < eViewIntervalArray?.intervals?.length - 1 &&
      !disableManualRefresh
    ) {
      timeoutId = setTimeout(() => {
        getDeviceProfileDetail(false);
        setEviewIntervalIndex(eViewIntervalIndex + 1);
      }, eViewIntervalArray?.intervals?.[eViewIntervalIndex]);
    } else {
      setTimeout(() => {
        setDeviceDetailLoaderVisibility(dispatch, false);
        clearEviewInterval(timeoutId);
      }, 2000);
    }
    return () => {
      clearEviewInterval(timeoutId);
    };
  }, [eViewInterval, eViewIntervalIndex, selectedDeviceDetails]);

  const clearEviewInterval = (timeoutId) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (freeusDeviceDetails && deviceType === "freeus") {
      checkManualRefreshButtonAccess();
    }
  }, [freeusDeviceDetails, deviceRefreshData]);

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (clairvoyantDeviceDetails && deviceType === "clairvoyant") {
      checkManualRefreshButtonAccess();
    }
  }, [clairvoyantDeviceDetails, deviceRefreshData]);

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (eViewDeviceDetails && deviceType === "eview") {
      checkManualRefreshButtonAccess();
    }
  }, [eViewDeviceDetails, deviceRefreshData]);

  useEffect(() => {
    if (disableManualRefresh && deviceDetailLoading) {
      setDeviceDetailLoaderVisibility(dispatch, false);
    }
  }, [disableManualRefresh]);

  const onUserHelpGuide = async () => {
    try {
      const url = getStaticUrlByKey(staticUrls, "user_help_guide");
      await window.open(url, "_blank");
    } catch (e) {}
  };

  const checkManualRefreshButtonAccess = () => {
    setTimeout(
      () => {
        if (selectedDeviceDetails) {
          let deviceDetail = null;
          const deviceType = selectedDeviceDetails?.device_type?.trim();
          if (deviceType) {
            if (deviceType === "anelto") {
              deviceDetail = aneltoDeviceDetails;
            } else if (deviceType === "clairvoyant") {
              deviceDetail = clairvoyantDeviceDetails;
            } else if (deviceType === "eview") {
              deviceDetail = {
                ...eViewDeviceDetails,
                created_at: eViewDeviceDetails?.generalData?.dateTime,
              };
            } else {
              deviceDetail = freeusDeviceDetails;
            }
            const lastRequestedTime = getCalculatedLastRefreshTime(
              deviceRefreshData?.last_refresh_timestamp,
              deviceDetail?.data_timestamp
            );
            if (lastRequestedTime) {
              const manualRefreshTime = getManualRefreshTime(
                deviceType,
                aneltoIntervals,
                freeusIntervals,
                clairvoyantIntervals,
                eViewIntervalArray
              );
              const isDisable = isDisableManualRefresh(
                lastRequestedTime,
                manualRefreshTime
              );
              setDisableManualRefresh(isDisable);
              if (isDisable) {
                const diffInMin =
                  getLastRefreshTimeInMinutes(lastRequestedTime);
                setManualRefreshIntervalTime(
                  (manualRefreshTime - diffInMin) * 60
                );
              } else {
                setManualRefreshIntervalTime(0);
              }
            }
          }
        }
      },
      deviceRefreshData ? 0 : 2000
    );
  };

  const getDeviceDetailManually = (isManual = false) => {
    setShowLocationModal(false);
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    const payload = {
      user_device_id: selectedDeviceDetails?.user_device_id,
      medical_alert_user_id: selectedDeviceDetails?.medical_alert_user_id,
      last_interval_call:
        deviceType === "anelto"
          ? aneltoDeviceDetails?.next_interval_call || 0
          : undefined,
    };
    let params = {
      dispatch,
      payload,
      history,
      setSnackBarMessage,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
    };

    if (deviceType === "anelto") {
      getAneltoDeviceDetail(params, isManual);
    } else if (deviceType === "clairvoyant") {
      callHeartBeatForClairvoyantDevice({
        ...params,
        isRepeated: isManual ? false : true,
      });
    } else if (deviceType === "eview") {
      params.payload = {
        ...params?.payload,
        ...deviceLocationPayload,
        providerDeviceId: selectedDeviceDetails?.provider_device_id,
      };
      params = {
        ...params,
        isInitialLoad: false,
      };
      setEviewInterval(false);
      setEviewIntervalIndex(0);
      updateEviewFunction(params);
      setEviewInterval(true);
    } else {
      getFreeusDeviceDetail(params);
    }
  };

  const onRefresh = () => {
    if (!isInHomDevice) {
      getDeviceProfileDetail();
    }
  };

  const animateMapToRegion = () => {
    const formattedObj = customSchemaForUI();
    const regionLatlong = {
      ...deviceLocation,
      latitude: formattedObj?.latitude ? parseFloat(formattedObj?.latitude) : 0,
      longitude: formattedObj?.longitude
        ? parseFloat(formattedObj?.longitude)
        : 0,
    };
    setDeviceLocation(regionLatlong);
  };

  const getDeviceProfileDetail = (isInitialLoad) => {
    setShowLocationModal(false);
    const payload = {
      user_device_id: selectedDeviceDetails?.user_device_id,
      medical_alert_user_id: selectedDeviceDetails?.medical_alert_user_id,
      providerDeviceId: selectedDeviceDetails?.provider_device_id,
    };

    let param = {
      dispatch,
      payload,
      history,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      isInitialLoad: isInitialLoad,
    };
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (deviceType === "anelto") {
      getAneltoDeviceInitialLoadData(param);
    } else if (deviceType === "clairvoyant") {
      getClairvoyantDeviceDetail(param);
    } else if (deviceType === "eview") {
      getEviewDeviceDetail(param);
    } else {
      getFreeusDeviceInitialLoadData(param);
    }
  };

  const customSchemaForUI = () => {
    let deviceDetails = null;
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (deviceType === "anelto") {
      deviceDetails = aneltoDeviceDetails;
    } else if (deviceType === "clairvoyant") {
      deviceDetails = {
        ...clairvoyantDeviceDetails,
        battery: clairvoyantDeviceDetails?.battery_level,
        device_sku: clairvoyantDeviceDetails?.device_id,
        steps: clairvoyantDeviceDetails?.steps_since_midnight,
      };
    } else if (deviceType === "eview") {
      deviceDetails = {
        ...eViewDeviceDetails,
        device_sku: selectedDeviceDetails?.provider_device_id,
        battery: eViewDeviceDetails?.generalData?.battery,
        is_fall_detect:
          eViewDeviceDetails?.features?.fallDownAlert ||
          eViewDeviceDetails?.generalData?.fallDownStatus,
        latitude: eViewDeviceDetails?.latestLocation?.lat,
        longitude: eViewDeviceDetails?.latestLocation?.lng,
        // created_at: eViewDeviceDetails?.generalData?.dateTime,
        signal_strength: eViewDeviceDetails?.generalData?.signal_strength || 0,
      };
    } else {
      deviceDetails = freeusDeviceDetails;
    }
    const lastRequestedTime = getCalculatedLastRefreshTime(
      deviceRefreshData?.last_refresh_timestamp,
      deviceDetails?.data_timestamp
    );
    return {
      deviceName: selectedDeviceDetails?.device_name,
      autoFallDetection: deviceDetails?.is_fall_detect === 0 ? false : true,
      deviceId: selectedDeviceDetails?.provider_device_id,
      latitude: deviceDetails?.latitude,
      longitude: deviceDetails?.longitude,
      batteryLife: deviceDetails?.battery ? deviceDetails?.battery : "0",
      stepCounter: deviceDetails?.steps ? deviceDetails?.steps : "0",
      totalSteps: selectedDeviceDetails?.step_goal,
      locateMyDeviceId: deviceDetails?.device_sku || "-",
      userDeviceId: selectedDeviceDetails?.user_device_id,
      medicalAlertUserId: deviceDetails?.medical_alert_user_id,
      lastRefreshTime: lastRequestedTime, //deviceRefreshData?.last_refresh_timestamp,
      dataTimeStamp: deviceDetails?.data_timestamp,
      signal_strength: deviceDetails?.signal_strength,
      otherData: { ...deviceDetails },
    };
  };

  const onCaregavireTracking = () => {
    setDeviceDetailLoaderVisibility(dispatch, false);
    history?.push(ROUTES_CONSTANTS.caregiverTracking, {
      isButtonDisable: disableManualRefresh,
    });
  };

  const onLocationHistory = () => {
    setDeviceDetailLoaderVisibility(dispatch, false);
    history?.push(ROUTES_CONSTANTS.location, {
      isButtonDisable: disableManualRefresh,
    });
  };

  const onTapToRing = () => {
    ringMyDevice(
      dispatch,
      {
        user_device_id: selectedDeviceDetails?.user_device_id,
        medical_alert_user_id: selectedDeviceDetails?.medical_alert_user_id,
        providerDeviceId: selectedDeviceDetails?.provider_device_id,
      },
      setSnackBarMessage,
      setShowRingDeviceModal,
      selectedDeviceDetails?.device_type,
      userData?.isGuestUser ? "guestuser" : "accountowner",
      history
    );
  };

  const onManualRefreshClick = () => {
    if (manualRefreshLoading) {
      return;
    } else if (disableManualRefresh) {
      setFrequentRequestPopup({
        isVisible: true,
        lastRefreshTime: customSchemaForUI()?.lastRefreshTime,
      });
    } else {
      setShowLocationModal(true);
    }
  };

  const convertInLocalTimeZone = (date) => {
    return convertInRequiredTimeZone(
      date,
      selectedMedicalUser?.timezone,
      currentTimeZone
    );
  };

  return (
    <MainLayout
      text={titles?.device_profile}
      isBackHide={false}
      isSemiHeader={true}
      white={true}
      loading={loading}
      snackBarMessage={snackBarMessage}
    >
      {deviceDetailLoading ? (
        <div className="gif_loader_container">
          <img height="50px" width="50px" src={loader_gif} alt="loader" />
        </div>
      ) : null}
      <div className="dp_container">
        <RingDeviceModal
          setShow={setShowRingDeviceModal}
          show={showRingDeviceModal}
          selectedDeviceDetails={selectedDeviceDetails}
          onClick={onTapToRing}
        />
        <DeviceInfoModal
          selectedDeviceDetails={selectedDeviceDetails}
          deviceSchema={customSchemaForUI()}
          setShow={setShowDeviceInfo}
          show={showDeviceInfo}
        />
        <EditDeviceNameModal
          setShow={setShowEditDeviceModal}
          show={showEditDeviceModal}
          onCancel={() => setShowEditDeviceModal(false)}
        />
        <AddStepGoalModal
          show={showAddStepGoalModal}
          setShow={setShowAddStepGoalModal}
          currentGoal={customSchemaForUI()?.totalSteps}
        />
        <ConfirmationModal
          show={showLocationModal}
          setShow={setShowLocationModal}
          svgIcon={<IconTracking color="#000000" />}
          btnText={titles?.OK}
          title={titles?.location_signal}
          description={titles?.location_signal_desc}
          onClick={() => getDeviceDetailManually(true)}
        />
        <ChangesSavedModal
          show={showSavedSuccessModal}
          setShow={setShowSavedSuccessModal}
        />
        <InformationModal
          show={informationModal}
          setShow={setInformationModal}
          infoText={titles?.modal_texts?.device_profile_footer_help_tip}
        />
        <FrequentRequestPopup
          show={frequentRequestPopup?.isVisible}
          setShow={setFrequentRequestPopup}
          lastRefreshTime={frequentRequestPopup?.lastRefreshTime}
          deviceType={selectedDeviceDetails?.device_type?.trim()}
        />
        <CardLayoutWrapper
          className={"dp_img_position"}
          image_url={selectedDeviceDetails?.image_url}
        >
          <div className="dp_title_container mb5">
            <p className="dp_card_title display_h2 ">
              {customSchemaForUI()?.deviceName}
            </p>
            <IconEdit
              className={"dp_card_edit_icon"}
              color="#6B6B6B"
              onClick={() => setShowEditDeviceModal(true)}
            />
          </div>
          <Link
            onClick={() => setShowDeviceInfo(true)}
            className="dp_card_link web_link mb15"
            to={"#"}
          >
            {titles?.about_this_device}
          </Link>
          {!isInHomDevice ? (
            <>
              <CustomButton
                text={manualRefreshLoading ? titles?.refreshing : titles?.refresh_signal}
                width={"250px"}
                className={"mb15 refresh_signal_button"}
                isGrayButton={disableManualRefresh || manualRefreshLoading}
                onClick={() => onManualRefreshClick()}
              />
              {convertInLocalTimeZone(customSchemaForUI()?.lastRefreshTime) ? (
                <div className="field_title_container dp_time_center">
                  <p className="dp_card_last_refresh body_h1 mr5">
                    {`${titles?.last_refresh} ${convertInLocalTimeZone(
                      customSchemaForUI()?.lastRefreshTime
                    )}`}
                  </p>
                  <IconQuestion
                    className={"sd_notify_icon lock_helpful_icon"}
                    onClick={() => setInformationModal(true)}
                  />
                </div>
              ) : null}
              {manualRefreshLoading ? (
                <div className="dp_time_container">
                  <Loader color={"#248ca7"} />
                  <p className="dp_card_last_refresh body_h1 mr5">
                    {titles?.hang_tight_app_refreshing}
                  </p>
                </div>
              ) : null}
              <p className="dp_card_last_refresh body_h1">
                {disableManualRefresh
                  ? getRefreshDisabledMessage(
                      selectedDeviceDetails?.device_type?.trim(),
                      aneltoIntervals?.refresh_signal,
                      freeusIntervals?.refresh_signal,
                      clairvoyantIntervals?.refresh_signal,
                      eViewIntervalArray?.refresh_signal
                    )
                  : ""}
              </p>
            </>
          ) : null}
        </CardLayoutWrapper>
        {!isInHomDevice ? (
          <>
            <div className="caregiver_card_container">
              <p className="caregiver_card_title display_h3">
                {titles?.caregiver_tracking}
              </p>
              <p className="map_info_left body_h1 mr5">
                {customSchemaForUI()?.dataTimeStamp
                  ? `${titles?.map_information} ${convertInLocalTimeZone(
                      customSchemaForUI()?.dataTimeStamp
                    )}`
                  : ""}
              </p>
              <div className="map_container">
                {!deviceLocation?.latitude ? (
                  <div className="empty_map">
                    <p className="caregiver_card_title display_h5">
                      {titles?.fetching_location}
                    </p>
                  </div>
                ) : (
                  <iframe
                    src={`https://maps.google.com/maps?q=${deviceLocation?.latitude},${deviceLocation?.longitude}&z=15&output=embed`}
                    width="100%"
                    height="150"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="gmap"
                    className="mb15"
                    onLoad={(e) => {
                      e.preventDefault();
                      setMapLoaded(false);
                    }}
                  ></iframe>
                )}
              </div>
              <div className="button_div">
                <CustomButton
                  text={titles?.see_map}
                  width={"250px"}
                  disabled={!deviceLocation?.latitude}
                  className={"caregiver_card_btn"}
                  onClick={() => onCaregavireTracking()}
                />

                {selectedDeviceDetails?.device_type !== "anelto" &&
                selectedDeviceDetails?.device_type !== "freeus" ? (
                  <CustomButton
                    text={titles?.location_history}
                    width={"250px"}
                    className={"caregiver_card_btn"}
                    onClick={() => onLocationHistory()}
                  />
                ) : null}
              </div>
            </div>
            <DeviceSignalCard
              type={selectedDeviceDetails?.device_type}
              deviceInfo={customSchemaForUI()}
              statusUpdateAt={customSchemaForUI()?.dataTimeStamp ? `${
                titles?.device_status_information
              }${convertInLocalTimeZone(customSchemaForUI()?.dataTimeStamp)}`: ''}
              className={"dp_battery_btn"}
            />
            {selectedDeviceDetails?.device_type === "anelto" ||
            selectedDeviceDetails?.device_type === "eview" ? null : (
              <ParameterCard
                title={titles?.step_counter}
                value={`${customSchemaForUI()?.stepCounter} ${
                  checkIsCurrentDate(customSchemaForUI()?.dataTimeStamp)
                    ? titles?.today_steps
                    : titles?.past_steps
                } `}
                icon={<IconStep />}
                statusUpdateAt={customSchemaForUI()?.dataTimeStamp ? `${
                  titles?.step_status_information
                }${convertInLocalTimeZone(
                  customSchemaForUI()?.dataTimeStamp
                )}`: ''}
                className={"mb15"}
                isShowButton={true}
                text={titles?.add_step_goal}
                onClick={() => setShowAddStepGoalModal(true)}
              />
            )}
            <ParameterCard
              title={titles?.locate}
              value={customSchemaForUI()?.locateMyDeviceId}
              icon={<IconGPSDevice />}
              statusUpdateAt={"11:41 AM 07/20/23"}
              isHideStatus={true}
              isShowButton={true}
              className={"mb15"}
              text={titles?.ring_device}
              onClick={() => setShowRingDeviceModal(true)}
            />
          </>
        ) : null}
        <div className="dp_btn_container">
          {accordianButtons?.map((data, index) => {
            return (
              <>
                <div
                  key={`accordian-btn${index}`}
                  className="accordian_btn_container"
                  onClick={() => {
                    setDeviceDetailLoaderVisibility(dispatch, false);
                    data?.onClick();
                  }}
                >
                  <p className="display_h5">{data?.name}</p>
                  <ArrowRight />
                </div>
                <div
                  className="horizontal_divider_line"
                  key={`tab ${index}`}
                ></div>
              </>
            );
          })}
        </div>
      </div>
    </MainLayout>
  );
};

export default DeviceProfile;
