import React, { useEffect, useState } from "react";
import { storeData } from "../../../utility";
import { checkTimeZone } from "../../../utility/deviceDetailUtils";
import { useDispatch } from "react-redux";
import alerts from "../../../constants/alerts";
import keys from "../../../constants/keys";
import MainLayout from "components/layout/main-layout/MainLayout";
import CustomSelectBox from "components/widgets/custom-select-box/CustomSelectBox";
import { useHistory } from "react-router-dom";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { setCurrentTimeZone } from "redux/Common/action";

const timeFormatList = [
  { label: "24 Hours", value: "24", id: 1 },
  { label: "12 Hours", value: "12", id: 2 },
];

const timeZoneList = [
    { label: "Phone Timezone", value: "device", id: 1 },
    { label: "Protected Address Timezone", value: "medical", id: 2 },
  ];

const TimeZoneSettings = () => {
  const [selectedValue, setSelectedValue] = useState(timeZoneList?.[0]);
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(timeFormatList?.[0]);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentTimeZoneData();
  }, []);

  const handleDropdownSelect = (value) => {
    setSelectedValue(value);
  };
  const handleDropdownSelectTimeZone = (value) => {
    setSelectedTimeFormat(value);
  };

  const onSaveClick = () => {
    if (!selectedValue || !selectedTimeFormat) {
      return;
    }
    const updateSettings = {
      timezone: selectedValue?.value === "medical",
      format: selectedTimeFormat?.value === "12",
    };

    storeData(keys?.timeZoneSettings, JSON.stringify(updateSettings));
    setCurrentTimeZone(dispatch, JSON.stringify(updateSettings));
    setSnackBarMessage({ message: alerts?.timezone_settings_updated, isSuccess: true });
    history?.goBack();
  };

  const getCurrentTimeZoneData = async () => {
    const localData = await checkTimeZone();
    if (localData) {
      setSelectedTimeFormat(localData?.format ? timeFormatList?.[1] : timeFormatList?.[0]);
      setSelectedValue(localData?.timezone ? timeZoneList?.[1] : timeZoneList?.[0]);
    }
  };

  return (
    <MainLayout
      text={titles?.timezone_time_format}
      isBackHide={false}
      isNavbar={false}
      snackBarMessage={snackBarMessage}
    >
      <div className="acp_container">
        <p className="feedback_desc_text hc_color body_h1">
          {titles?.time_zone_settings}
        </p>
        <CustomSelectBox
          selectedValue={selectedValue?.value}
          onChange={handleDropdownSelect}
          options={timeZoneList}
          title={titles?.select_issue_type}
          className={"cs_select_dropdown"}
        />
        <p className="feedback_desc_text hc_color body_h1">
          {titles?.timeFormat}
        </p>
        <CustomSelectBox
          selectedValue={selectedTimeFormat?.value}
          onChange={handleDropdownSelectTimeZone}
          options={timeFormatList}
          title={titles?.select_issue}
          className={"cs_select_dropdown"}
        />
        <CustomButton
          height={"50px"}
          className={"blue_button hc_contact_btn mb20 text-white-color"}
          text={titles?.save}
          onClick={() => onSaveClick()}
        />
      </div>
    </MainLayout>
  );
};

export default TimeZoneSettings;
