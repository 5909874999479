import { getApi, postApi, updateApi } from "../../api/commonApi";
import {
  ADMIN_ADD_SUCCESS,
  ADMIN_ADD_REQUEST,
  ADMIN_ADD_FAILURE,
  ADMIN_HIDE_LOADER,
  ADMIN_LIST_REQUEST,
  ADMIN_EDIT_REQUEST,
  ADMIN_EDIT_SUCCESS,
  ADMIN_DELETE_REQUEST,
  ADMIN_DELETE_SUCCESS,
  ADMIN_DELETE_FAILURE,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_FAILURE,
  ADMIN_LIST_SUCCESS,
  ADMIN_EDIT_FAILURE,
  ADMIN_LIST_FAILURE,
  ADMIN_MENU_LIST_SUCESS,
  ADMIN_MENU_LIST_REQUEST,
  ADMIN_MENU_LIST_FAILURE,
  ADMIN_GENRATE_OTP_SUCESS,
  ADMIN_VERIFY_OTP_SUCESS,
  ADMIN_VERIFY_OTP_FAILURE,
} from "./actionTypes";
import apis from "../../api/apiEndPoints";
import {
  clearData,
  encryptData,
  getData,
  handleResponses,
  handleUnAuthorizedAccess,
  storeData,
} from "../../utility";
import keys from "../../constants/keys";
import { ACCESS_TOKEN, AUTH_LODER_VISIBILITY } from "redux/auth/actionTypes";
import { logoutAndClear, setActiveScreen } from "redux/auth/actions";
import moment from "moment";
import { BASE_URL } from "constants/routes/api";
import { ROUTES_CONSTANTS } from "routes/route-constants";

export const addAdminUser = async (dispatch, payload, insets, navigation) => {
  const API = BASE_URL + apis?.addNewAdmin;
  let accessToken = await getData(keys?.adminAccessTokenKey);

  returnToDispatch(dispatch, ADMIN_ADD_REQUEST);

  postApi(API, accessToken, payload, "admin")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, ADMIN_ADD_SUCCESS);
        handleResponses(true, "Admin User Created Successfully", insets);
        getAdminUserList(dispatch, navigation);
        navigation.navigate("Admin List");
      } else {
        returnToDispatch(dispatch, ADMIN_ADD_FAILURE);
        handleResponses(false, response?.data?.message, insets);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, ADMIN_HIDE_LOADER);
    });
};

export const getAdminUserList = async (dispatch, navigation) => {
  const API = BASE_URL + apis?.getAdminList;
  let accessToken = await getData(keys?.adminAccessTokenKey);

  returnToDispatch(dispatch, ADMIN_LIST_REQUEST);
  getApi(API, accessToken, "admin")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, ADMIN_LIST_SUCCESS, response?.data?.data);
      } else {
        returnToDispatch(dispatch, ADMIN_LIST_FAILURE);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, ADMIN_HIDE_LOADER);
    });
};

export const updateAdminUser = async (
  dispatch,
  payload,
  insets,
  navigation
) => {
  const API = BASE_URL + apis?.editAdmin;
  let accessToken = await getData(keys?.adminAccessTokenKey);
  returnToDispatch(dispatch, ADMIN_EDIT_REQUEST);
  updateApi(API, accessToken, payload, "admin")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, ADMIN_EDIT_SUCCESS);
        handleResponses(true, "Admin User Updated Successfully", insets);
        getAdminUserList(dispatch, navigation);
        navigation.navigate("Admin List");
      } else {
        returnToDispatch(dispatch, ADMIN_EDIT_FAILURE);
        handleResponses(false, response?.data?.message, insets);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, ADMIN_HIDE_LOADER);
    });
};

export const deleteAdminUser = async (
  dispatch,
  payload,
  insets,
  setGuestUserPopupMode
) => {
  const API = BASE_URL + apis?.deleteAdmin;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, ADMIN_DELETE_REQUEST);

  postApi(API, accessToken, payload, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, ADMIN_DELETE_SUCCESS);
        handleResponses(true, "Admin Deleted Successfully", insets);
        getAdminUserList(dispatch);
        setGuestUserPopupMode("");
      } else {
        returnToDispatch(dispatch, ADMIN_DELETE_FAILURE);
        handleResponses(false, response?.data?.message, insets);
        if (response?.data?.code === 401) {
          // await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, ADMIN_HIDE_LOADER);
    });
};

//admin login

export const adminLogin = async (
  dispatch,
  payload,
  history,
  setSnackBarMessage
) => {
  const API = BASE_URL + apis?.adminLogin;
  returnToDispatch(dispatch, ADMIN_LOGIN_REQUEST);
  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });
  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);

  postApi(API, "", encryptedPayload, "admin")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        try {
          storeData(keys?.adminAccessTokenKey, response?.data?.data?.token);
          returnToDispatch(dispatch, ACCESS_TOKEN, response?.data?.data?.token);
          storeData(keys?.loggnedInUser, JSON.stringify(payload));
          storeData(keys?.adminDetail, JSON.stringify(response?.data?.data));
          returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
          history.push(ROUTES_CONSTANTS.customerLogin);
        } catch (error) {
          returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        }
        setActiveScreen(dispatch, "DrawerNavigator");
      } else {
        returnToDispatch(dispatch, ADMIN_LOGIN_FAILURE);
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        setTimeout(() => {
          setSnackBarMessage({ message: response?.data?.message });
        }, 500);
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, ADMIN_LOGIN_FAILURE, error);
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

export const adminLogout = async (
  dispatch,
  history,
  onClosePopup
) => {
  const API = BASE_URL + apis?.adminLogout;

  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);
  let accessToken = await getData(keys?.adminAccessTokenKey);

  getApi(API, accessToken, "admin")
    .then(async (response) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
      if (response?.data?.code === 200) {
        onClosePopup(false);
        await handleUnAuthorizedAccess(history);
        logoutAndClear(dispatch, {});
        history.replace(ROUTES_CONSTANTS.login);
      } else {
        onClosePopup(false);
        logoutAndClear(dispatch, {});
        history.replace(ROUTES_CONSTANTS.login);
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
      }
    })
    .catch((error) => {
      onClosePopup(false);
      logoutAndClear(dispatch, {});
      history.replace(ROUTES_CONSTANTS.login);
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

// get Menue List

export const getMenuList = async (dispatch) => {
  const API = BASE_URL + apis?.getMenu;
  returnToDispatch(dispatch, ADMIN_MENU_LIST_REQUEST, []);
  let accessToken = await getData(keys?.adminAccessTokenKey);
  getApi(API, accessToken, "admin")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          ADMIN_MENU_LIST_SUCESS,
          response?.data?.data
        );
      } else {
        returnToDispatch(dispatch, ADMIN_MENU_LIST_FAILURE, []);
      }
    })
    .catch((error) => {});
};

//Change PAssword

export const adminChangePassword = async (
  dispatch,
  payload,
  navigation,
  insets
) => {
  const API = BASE_URL + apis?.adminChangePassword;
  let accessToken = await getData(keys?.adminAccessTokenKey);
  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);

  updateApi(API, accessToken, payload, "admin")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);

        handleResponses(true, "Password Changed Successfully.", insets);
        await clearData();

        navigation?.reset({
          index: 0,
          routes: [{ name: "Landing" }],
        });
      } else {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, insets);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

export const adminGenrateOtp = async (
  dispatch,
  payload,
  history,
  openPopUP,
  setSnackBarMessage
) => {
  const API = BASE_URL + apis?.adminGenrateOtp;
  let accessToken = await getData(keys?.adminAccessTokenKey);
  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);

  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });

  postApi(API, accessToken, encryptedPayload, "admin")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        returnToDispatch(
          dispatch,
          ADMIN_GENRATE_OTP_SUCESS,
          response?.data?.data
        );

        openPopUP(true);
      } else {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

export const adminVerifyOtp = async (
  dispatch,
  payload,
  history,
  setSnackBarMessage,
  popUpMenu
) => {
  const API = BASE_URL + apis?.adminVerifyOtp;
  let accessToken = await getData(keys?.adminAccessTokenKey);

  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });
  postApi(API, accessToken, encryptedPayload, "admin")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          ADMIN_VERIFY_OTP_SUCESS,
          response?.data?.data
        );
        const updatedSessionTime = moment().format("YYYY-MM-DD HH:mm:ss");
        storeData(keys?.logInTime, updatedSessionTime);
        await storeData(keys?.accessTokenKey, response?.data?.data?.token);
        popUpMenu(false);
        history?.replace(ROUTES_CONSTANTS.home);
      } else {
        returnToDispatch(dispatch, ADMIN_VERIFY_OTP_FAILURE, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
