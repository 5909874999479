export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";

export const PHONE_TYPE_LIST_SUCCESS = "PHONE_TYPE_LIST_SUCCESS";

export const COMMON_API_LOADER_VISIBILITY = "COMMON_API_LOADER_VISIBILITY";

export const SET_SELECTED_PHONE_TYPE = "SET_SELECTED_PHONE_TYPE";

export const SIDE_MENU_LIST_SUCCESS = "SIDE_MENU_LIST_SUCCESS";
export const DASHBOARD_MENU_LIST_SUCCESS = "DASHBOARD_MENU_LIST_SUCCESS";
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";

export const STATIC_URL_SUCCESS = "STATIC_URL_SUCCESS";
export const CUSTOMER_TIMEZONE_SUCCESS = "CUSTOMER_TIMEZONE_SUCCESS";
export const CURRENT_TIMEZONE = "CURRENT_TIMEZONE";
