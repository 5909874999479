import React, { useEffect } from "react";
import "./OrderHistory.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList } from "redux/MedicalAlertUsers/actions";
import { useHistory } from "react-router-dom";
import { formatDate } from "utility";
import { ROUTES_CONSTANTS } from "routes/route-constants";

const OrderHistory = () => {
  const { orderList, loading, selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getOrderList({
      dispatch,
      history,
      medical_alert_user_id: selectedMedicalUser?.medical_alert_user_id,
    });
  }, []);

  const onPressOrderCard = (orderDetail) => {
    history.push(ROUTES_CONSTANTS?.orderDetails, { orderDetail: orderDetail });
  };

  return (
    <MainLayout
      text={titles?.order_history}
      isBackHide={false}
      isNavbar={true}
      white={true}
      isLoading={loading}
    >
      {orderList?.map((item, index) => {
        const order_detail = item?.order_detail;
        return (
          <div
            onClick={() => onPressOrderCard(item)}
            className="invoice_card_container mb15"
          >
            <div className="card_title_cotainer mb5">
              <p className="display_h4 mb5">{titles?.order}</p>
              <p className="display_h5 left invoice_title">
                {order_detail?.order_id}
              </p>
            </div>
            <div className="card_details_container">
              <div>
                <p className="display_h4 ">{titles?.date}</p>
                <p className="body_h4 left card_values">
                  {formatDate(order_detail?.order_date)}
                </p>
              </div>
              <div>
                <p className="display_h4 ">{titles?.subscription}</p>
                <p className="body_h4 left card_values">
                  {order_detail?.subscription}
                </p>
              </div>
              <div>
                <p className="display_h4 ">{titles?.total}</p>
                <p className={`body_h4 left card_values`}>
                  {"$" + order_detail?.total_recurring_fees?.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </MainLayout>
  );
};

export default OrderHistory;
