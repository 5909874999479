export const DEVICES_LIST_SUCCESS = "DEVICES_LIST_SUCCESS";
export const DEVICES_COMMON_LOADER_VISIBILITY =
  "DEVICES_COMMON_LOADER_VISIBILITY";

export const UPDATE_STEP_COUNTER_SUCCESS = "UPDATE_STEP_COUNTER_SUCCESS";
export const SET_SELECTED_DEVICE_DETAILS = "SET_SELECTED_DEVICE_DETAILS";

export const DEVICES_SIGNAL_LIST_SUCCESS = "DEVICES_SIGNAL_LIST_SUCCESS";
export const DEVICES_SIGNAL_LIST_REQUEST = "DEVICES_SIGNAL_LIST_REQUEST";
export const DEVICE_REFRESH_VISIBILITY = "DEVICE_REFRESH_VISIBILITY";

export const ANELTO_DEVICE_DETAILS_SUCCESS = "ANELTO_DEVICE_DETAILS_SUCCESS";

export const SET_SELECTED_DEVICE_DETAIL = "SET_SELECTED_DEVICE_DETAIL";

export const FREEUS_DEVICE_DETAILS_SUCCESS = "FREEUS_DEVICE_DETAILS_SUCCESS";

export const ANELTO_TIME_INTERVAL_SUCCESS = "ANELTO_TIME_INTERVAL_SUCCESS";
export const FREEUS_TIME_INTERVAL_SUCCESS = "FREEUS_TIME_INTERVAL_SUCCESS";
export const CLAIRVOYANT_TIME_INTERVAL_SUCCESS =
  "CLAIRVOYANT_TIME_INTERVAL_SUCCESS";
export const RING_DEVICE_SUCCESS = "RING_DEVICE_SUCCESS";

export const ANELTO_DEVICE_INITIAL_LOAD_REQUEST =
  "ANELTO_DEVICE_INITIAL_LOAD_REQUEST";
export const ANELTO_DEVICE_INITIAL_LOAD_SUCCESS =
  "ANELTO_DEVICE_INITIAL_LOAD_SUCCESS";

export const ANELTO_DEVICE_SYNC_DETAIL_SUCCESS =
  "ANELTO_DEVICE_SYNC_DETAIL_SUCCESS";

export const FREE_US_DEVICE_INITIAL_LOAD_REQUEST =
  "FREE_US_DEVICE_INITIAL_LOAD_REQUEST";
export const FREE_US_DEVICE_INITIAL_LOAD_SUCCESS =
  "FREE_US_DEVICE_INITIAL_LOAD_SUCCESS";

export const FREEUS_DEVICE_SYNC_DETAIL_SUCCESS =
  "FREEUS_DEVICE_SYNC_DETAIL_SUCCESS";
export const CLAIRVOYANT_DEVICE_DETAIL_SUCESS =
  "CLAIRVOYANT_DEVICE_DETAIL_SUCESS";

export const EVIEW_DEVICE_DETAIL_SUCESS = "EVIEW_DEVICE_DETAIL_SUCESS";
export const EVIEW_DEVICE_TIMELINE_SUCESS = "EVIEW_DEVICE_TIMELINE_SUCESS";

export const DEVICE_DETAIL_LOADER = "DEVICE_DETAIL_LOADER";

export const BREADCRUM_DEVICE_DETAIL_REQUEST = "BREADCRUM_DEVICE_DETAIL_REQUEST";
export const BREADCRUM_DEVICE_DETAIL_SUCCESS = "BREADCRUM_DEVICE_DETAIL_SUCCESS";
export const BREADCRUM_DEVICE_DETAIL_LOADER = "BREADCRUM_DEVICE_DETAIL_LOADER";

export const DEVICE_DETAIL_MANUAL_REFRESH_LOADER = "DEVICE_DETAIL_MANUAL_REFRESH_LOADER";

export const SET_DEVICE_LAST_REFRESH_TIME = "SET_DEVICE_LAST_REFRESH_TIME";
