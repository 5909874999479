import React from "react";
import "./DeviceInfoModal.css";
import { Modal } from "react-bootstrap";
import { titles } from "constants/titles";
import { CrossIconBtn } from "assets/SVGs/SVGs";
import { getAneltoNetworkProvider, getFreeusNetworkProvider } from "utility";

const DeviceInfoModal = ({
  show,
  setShow,
  selectedDeviceDetails,
  deviceSchema,
}) => {
  const deviceType = selectedDeviceDetails?.device_type?.trim();
  const isAneltDevice = deviceType === "anelto";
  const networkProvider = isAneltDevice
    ? getAneltoNetworkProvider(deviceSchema?.deviceId)
    : getFreeusNetworkProvider(deviceSchema?.deviceId);

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="di_container"
      show={show}
    >
      <Modal.Body className="di_body">
        <CrossIconBtn
          className="di_cross_icon"
          onClick={() => setShow(!show)}
        />
        {deviceType !== "clairvoyant" ? (
          <>
            <p className="display_h5">{titles?.auto_fall_detection}</p>
            <p className="body_h1 di_value_text mb10">
              {deviceSchema?.autoFallDetection ? "Activated" : "Not Activated"}
            </p>
          </>
        ) : null}
        <p className="display_h5">{titles?.device_id}</p>
        <p className="body_h1 di_value_text mb10">{deviceSchema?.deviceId}</p>
        {deviceType !== "clairvoyant" && deviceType !== "eview" && networkProvider ? (
          <>
            <p className="display_h5">{titles?.network_provider}</p>
            <p className="body_h1 di_value_text mb10">{networkProvider}</p>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default DeviceInfoModal;
