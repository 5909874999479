import React from "react";
import "./CustomRadio.css";

export const CustomRadio = ({ options, onPress, selectedOption }) => {
  return (
    <div className="custom-radio-main-container">
      {options.map((option, index) => (
        <div
          key={index}
          className="custom-radio-button-container"
          onClick={() => onPress(option)}
        >
          <div className="radio-outer-circle">
            {selectedOption === option && (
              <div className="radio-inner-circle" />
            )}
          </div>
          <p>{option}</p>
        </div>
      ))}
    </div>
  );
};
