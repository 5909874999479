import React, { useEffect, useState } from "react";
import "./RingDeviceModal.css";
import { Modal } from "react-bootstrap";
import { CrossIconBtn } from "assets/SVGs/SVGs";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { titles } from "constants/titles";

const RingDeviceModal = ({ show, setShow, onClick, selectedDeviceDetails }) => {
  const [ringingDevice, setRingingDevice] = useState(false);

  useEffect(() => {
    if (ringingDevice) {
      setTimeout(() => {
        setRingingDevice(false);
        setShow(false);
      }, [5000]);
    }
  }, [ringingDevice]);

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="cm_container"
      show={show}
    >
      <Modal.Body className="cm_body">
        <CrossIconBtn
          className="cm_cross_icon"
          onClick={() => setShow(!show)}
        />
        <div className="cm_container">
          <p className="display_h5 cm_text">{titles?.ring_device}</p>
          <p className="body_h1 cm_text mb10">
            {titles?.ring_device_modal_desc}
          </p>
          {!ringingDevice ? (
            <>
              <div className="cm_svg_container mb10">
                <img
                  src={selectedDeviceDetails?.image_url || ""}
                  className="ring_device_image"
                  alt=""
                />
              </div>
              <CustomButton
                text={titles?.ring_device}
                width={"100%"}
                className={"cm_confirm_btn"}
                onClick={() => {
                  onClick();
                  setRingingDevice(true);
                }}
              />
            </>
          ) : (
            <div className="progress-container">
              <svg
                className="progress-circle"
                width="150"
                height="150"
                viewBox="0 0 150 150"
              >
                <circle className="circle-background" cx="75" cy="75" r="70" />
                <circle className="circle-progress" cx="75" cy="75" r="70" />
              </svg>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RingDeviceModal;
