import React, { useEffect, useState } from "react";
import "./Settings.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { ArrowRight } from "assets/SVGs/SVGs";
import { logout } from "redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { getSideMenuList } from "redux/Common/action";

const Settings = () => {
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const userData = useSelector((store) => store?.auth?.userData);
  const { sideMenuList } = useSelector((store) => store?.common);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    updateList();
  }, []);

  const updateList = async () => {
    try {
      getSideMenuList({
        dispatch,
        history,
        userType: userData?.isGuestUser ? "guestuser" : "accountowner",
        finger_print: "disable",
      });
    } catch (e) {}
  };

  const onItemPress = async (item) => {
    switch (item?.menu_key) {
      case titles?.notifications:
        history?.push(ROUTES_CONSTANTS.notificationSetting);
        return;
      case titles?.manage_guest_users:
        history?.push(ROUTES_CONSTANTS.manageGuestUsers);
        return;
      case titles?.timezone_time_format:
        history?.push(ROUTES_CONSTANTS.timeZoneSettings);
        return;
      case titles?.change_password:
        history?.push(
          userData?.isGuestUser
            ? "GuestChangePassword"
            : ROUTES_CONSTANTS.changePassword,
          { isChangePassword: true }
        );
        return;
      case titles?.enable_fingerprint_face_id:
        history?.push("EnableFingerPrintAndFaceId", {
          data: item,
        });
        return;
      case titles?.disable_fingerprint_face_id:
        history?.push("EnableFingerPrintAndFaceId", {
          data: item,
        });
        return;
      case titles?.about_bay_alarm_medical:
        history?.push(ROUTES_CONSTANTS.about);
        return;
      case titles?.help_center:
        history?.push(ROUTES_CONSTANTS.helpCenter);
        return;
      default:
        break;
    }
  };

  const onLogout = async () => {
    logout(dispatch, history, setSnackBarMessage, userData?.isGuestUser);
  };

  return (
    <MainLayout
      text={titles?.settings}
      isBackHide={true}
      isSemiHeader={true}
      white={false}
      snackBarMessage={snackBarMessage}
    >
      {sideMenuList?.map((item, index) => {
        if (
          item?.menu_key === titles?.enable_fingerprint_face_id ||
          item?.menu_key === titles?.disable_fingerprint_face_id
        ) {
          return <></>;
        }
        return (
          <>
            <div
              key={`btn ${index}`}
              className="accordian_btn_container"
              onClick={() => onItemPress(item)}
            >
              <p className="display_h5 settings_text">{item?.menu_name}</p>
              <ArrowRight color="#ffffff" />
            </div>
            <div className="horizontal_divider_line"></div>
          </>
        );
      })}

      <div className="accordian_btn_container" onClick={() => onLogout()}>
        <p className="display_h5 settings_text">{"Switch To Admin Account"}</p>
        <ArrowRight color="#ffffff" />
      </div>
      <div className="horizontal_divider_line"></div>
    </MainLayout>
  );
};

export default Settings;
