import {
  ACCOUNT_TYPE_SUCCESS,
  ADD_EDIT_BANK_REQUEST,
  ADD_EDIT_BANK_SUCCESS,
  ADD_EDIT_CARD_REQUEST,
  ADD_EDIT_CARD_SUCCESS,
  CARD_LIST_SUCCESS,
  GET_BANK_REQUEST,
  GET_BANK_SUCCESS,
  GET_CARD_REQUEST,
  GET_CARD_SUCCESS,
  PAYMENT_LOADER_VISIBILITY,
  PAYMENT_SUCCESS,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  cardDetails: null,
  bankDetails: null,
  cardList: [],
  accountTypes: [],
  bankList: [],
};

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYMENT_LOADER_VISIBILITY:
      return {
        ...state,
        loading: action?.payload,
      };
    case ADD_EDIT_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_EDIT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_BANK_REQUEST:
      return {
        ...state,
        loading: true,
        bankDetails: null,
      };
    case GET_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        bankDetails: action?.payload,
      };

    case ADD_EDIT_BANK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADD_EDIT_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case GET_CARD_REQUEST:
      return {
        ...state,
        loading: true,
        cardDetails: null,
      };
    case GET_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        cardDetails: action?.payload,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        accountTypes: action?.payload,
        loading: false,
      };

    case CARD_LIST_SUCCESS:
      return {
        ...state,
        cardList: action?.payload,
      };
    default:
      return state;
  }
};

export default paymentReducer;