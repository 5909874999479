import React, { useEffect, useState } from "react";
import "./ShippingDetails.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper";
import CustomInput from "components/widgets/custom-input/CustomInput";
import { IconQuestion } from "assets/SVGs/SVGs";
import CustomButton from "components/widgets/custom-button/CustomButton";
import InformationModal from "components/layout/information-modal/InformationModal";
import CustomSelectBox from "components/widgets/custom-select-box/CustomSelectBox";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRegionListNames,
  getDataFromId,
  isEmpty,
  isSessionTimeExpired,
} from "utility";
import { useLocation } from "react-router-dom";
import alerts from "constants/alerts";
import { useHistory } from "react-router-dom";
import { updateMedicalAddress } from "redux/MedicalAlertUsers/actions";
import ChangesSavedModal from "components/layout/changes-saved-modal/ChangesSavedModal";
import { getRegionList } from "redux/Common/action";
import { setSessionExpiryModalVisibility } from "redux/auth/actions";

const ShippingDetails = () => {
  const [informativePopupVisible, setInformativePopupVisible] = useState(false);
  const [shippingDetailsData, setShippingDetailsData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    street: "",
    zip_code: "",
    city: "",
    apt: "",
    state: null,
    loading: false,
  });
  const [changesSavedModal, setChangesSaveModal] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const allRegionList = useSelector((store) => store?.common?.allRegionList);
  const { medicalUserDetail, loading, selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );
  const userData = useSelector((store) => store?.auth?.userData);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const param = {
      dispatch,
      history,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      emergency_contract_id: selectedMedicalUser?.service_contract_id,
      medical_alert_user_id: selectedMedicalUser?.medical_alert_user_id,
      setSnackBarMessage,
      is_visible: false,
    };
    getRegionList(param);
    setInitialData();
  }, []);

  const setInitialData = () => {
    const shippingAddress =
      location?.state?.medicalUserDetail?.shipping_address;
    const regionData = getDataFromId(
      allRegionList,
      "region_id",
      shippingAddress?.region_id
    );
    handleFieldChange("firstName", shippingAddress?.firstname);
    handleFieldChange("lastName", shippingAddress?.lastname);
    handleFieldChange("apt", shippingAddress?.street2 || "");
    handleFieldChange("street", shippingAddress?.street);
    handleFieldChange("street2", shippingAddress?.street2);
    handleFieldChange("city", shippingAddress?.city);
    handleFieldChange("zip_code", shippingAddress?.zip);
    handleFieldChange(
      "state",
      regionData && regionData?.length > 0
        ? {
            id: regionData[0]?.region_id,
            value: regionData[0]?.region_code,
          }
        : null
    );
  };

  useEffect(() => {
    const shippingAddress =
      location?.state?.medicalUserDetail?.shipping_address;
    const regionData = getDataFromId(
      allRegionList,
      "region_id",
      shippingAddress?.region_id
    );
    handleFieldChange(
      "state",
      regionData && regionData?.length > 0
        ? {
            id: regionData[0]?.region_id,
            value: regionData[0]?.region_code,
          }
        : null
    );
  }, [allRegionList]);

  const handleFieldChange = (key, value) => {
    setShippingDetailsData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onSubmit = async () => {
    let timeDifference = await isSessionTimeExpired();
    if (isEmpty(shippingDetailsData?.firstName)) {
      setSnackBarMessage({ message: alerts?.enter_first_name });
    } else if (isEmpty(shippingDetailsData?.lastName)) {
      setSnackBarMessage({ message: alerts?.enter_last_name });
    } else if (isEmpty(shippingDetailsData?.street)) {
      setSnackBarMessage({ message: alerts?.enter_street });
    } else if (isEmpty(shippingDetailsData?.city)) {
      setSnackBarMessage({ message: alerts?.enter_city });
    } else if (isEmpty(shippingDetailsData?.state)) {
      setSnackBarMessage({ message: alerts?.select_state });
    } else if (isEmpty(shippingDetailsData?.zip_code)) {
      setSnackBarMessage({ message: alerts?.enter_zipcode });
    } else if (timeDifference) {
      setSessionExpiryModalVisibility(dispatch, true);
      return;
    } else {
      const shippingAddress =
        location?.state?.medicalUserDetail?.shipping_address;
      const selectedRegion = getDataFromId(
        allRegionList,
        "region_id",
        shippingDetailsData?.state?.id
      );
      let payload = {
        address_id: shippingAddress?.address_id,
        region_id: shippingDetailsData?.state?.id,
        reference_region_id:
          selectedRegion && selectedRegion?.length > 0
            ? selectedRegion[0]?.reference_region_id
            : null,
        street: shippingDetailsData?.street,
        street2: shippingDetailsData?.apt,
        postcode: shippingDetailsData?.zip_code,
        city: shippingDetailsData?.city,
        firstname: shippingDetailsData?.firstName,
        lastname: shippingDetailsData?.lastName,
        medical_alert_user_id: medicalUserDetail?.medical_alert_user_id,
      };

      const params = {
        dispatch,
        history,
        setSnackBarMessage,
        setChangesSaveModal,
        payload,
        medical_alert_user_id: medicalUserDetail?.medical_alert_user_id,
        userType: userData?.isGuestUser ? "guestuser" : "accountowner",
        engagementType: "Update Shipping Address",
      };
      updateMedicalAddress(params);
    }
  };

  const handleDropdownSelect = (value, id) => {
    handleFieldChange("state", { id: id, value: value });
  };

  return (
    <MainLayout
      text={titles?.shipping_details}
      isBackHide={false}
      isNavbar={true}
      white={true}
      isLoading={loading}
      snackBarMessage={snackBarMessage}
    >
      <InformationModal
        show={informativePopupVisible}
        setShow={setInformativePopupVisible}
        infoText={titles?.shipping_informative_details}
      />
      <ChangesSavedModal
        show={changesSavedModal}
        setShow={setChangesSaveModal}
      />
      <CardLayoutWrapper>
        <div className="sp_title_container">
          <p className="display_h2 mb5">
            {selectedMedicalUser?.firstname +
              " " +
              selectedMedicalUser?.lastname}
          </p>
        </div>
        <p className="body_h1 acc_status_text">{`${titles?.account} #${selectedMedicalUser?.account_no}`}</p>
        <p className="body_h1 acc_status_text">{`${titles?.status}: ${
          selectedMedicalUser?.is_subscription_cancelled ? "Inactive" : "Active"
        }`}</p>
      </CardLayoutWrapper>
      <p className="sd_label_text display_h3 mb10">{titles?.name}</p>
      <CustomInput
        placeholder={titles?.first_name}
        type="text"
        className="mb10"
        value={shippingDetailsData?.firstName}
        onChange={(e) => handleFieldChange("firstName", e?.target?.value)}
        placeholderStyle="bd_placeholder_style"
        required
      />
      <CustomInput
        placeholder={titles?.last_name}
        type="text"
        className="mb20"
        value={shippingDetailsData?.lastName}
        onChange={(e) => handleFieldChange("lastName", e?.target?.value)}
        placeholderStyle="bd_placeholder_style"
        required
      />
      <div className="sd_shipping_title_container mb15">
        <p className="sd_label_text display_h3">{titles?.shipping_address}</p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() => setInformativePopupVisible(true)}
        />
      </div>
      <p className="body_h1 left mb10">{titles?.shipping_address_details}</p>
      <CustomInput
        placeholder={titles?.street}
        placeholderStyle="bd_placeholder_style"
        type="text"
        className="mb10"
        value={shippingDetailsData?.street}
        onChange={(e) => handleFieldChange("street", e?.target?.value)}
        required
      />
      <CustomInput
        placeholder={titles?.apt}
        placeholderStyle="bd_placeholder_style"
        type="text"
        className="mb10"
        value={shippingDetailsData?.apt}
        onChange={(e) => handleFieldChange("apt", e?.target?.value)}
      />
      <CustomInput
        placeholder={titles?.city}
        placeholderStyle="bd_placeholder_style"
        type="text"
        className="mb10"
        value={shippingDetailsData?.city}
        onChange={(e) => handleFieldChange("city", e?.target?.value)}
        required
      />
      <div className="sd_fields_container mb10">
        <CustomSelectBox
          required={true}
          options={getAllRegionListNames(allRegionList)}
          title={titles?.state}
          selectedValue={shippingDetailsData?.state?.value}
          onChange={(item) => handleDropdownSelect(item?.value, item?.id)}
        />
      </div>
      <CustomInput
        placeholder={titles?.zip_code}
        placeholderStyle="bd_placeholder_style"
        type="number"
        className="mb15"
        value={shippingDetailsData?.zip_code}
        onChange={(e) => handleFieldChange("zip_code", e?.target?.value)}
        required
      />
      <CustomButton
        width={"100%"}
        height={"50px"}
        className={"sd_submit_btn"}
        text={titles?.submit}
        onClick={() => onSubmit()}
      />
    </MainLayout>
  );
};

export default ShippingDetails;
