import keys from "constants/keys";
import {
  ACCOUNT_HOLDER_DETAILS_REQUEST,
  ACCOUNT_HOLDER_DETAILS_SUCCESS,
  ACCOUNT_HOLDER_DETAILS_FAILURE,
  ACCOUNT_HOLDER_UPDATE_REQUEST,
  ACCOUNT_HOLDER_UPDATE_SUCCESS,
  ACCOUNT_HOLDER_UPDATE_FAILURE,
  ESIGN_HISTORY_REQUEST,
  ESIGN_HISTORY_SUCCESS,
  ESIGN_HISTORY_FAILURE,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  accountHolderData: localStorage.getItem(keys.accountHolderUserdetail)
    ? JSON.parse(localStorage.getItem(keys.accountHolderUserdetail))
    : {},
};

const userDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACCOUNT_HOLDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACCOUNT_HOLDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        accountHolderData: action?.payload,
      };
    case ACCOUNT_HOLDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACCOUNT_HOLDER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACCOUNT_HOLDER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACCOUNT_HOLDER_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ESIGN_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        eSignHistory: {},
      };
    case ESIGN_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        eSignHistory: action?.payload,
      };
    case ESIGN_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default userDetailsReducer;
