import MainLayout from "components/layout/main-layout/MainLayout";
import DatePicker from "react-datepicker";
import { titles } from "constants/titles";
import React, { useEffect, useRef, useState } from "react";
import "./LocationHistory.css";
import { ArrowRight, BackIcon } from "assets/SVGs/SVGs";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { getBreadcrumData } from "redux/Devices/actions";
import { useHistory } from "react-router-dom";
import SnackBar from "components/widgets/snack-bar/SnackBar";
import { loader_gif } from "assets/images/images";
import { convertDateForLocation } from "utility";

const LocationHistory = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [locationTime, setLocationTime] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const userData = useSelector((store) => store?.auth?.userData);
  const { selectedDeviceDetails, breadcrumData, breadCrumloading } =
    useSelector((store) => store?.devices);
  const mapRef = useRef(null);
  const history = useHistory();
  const dateRef = useRef();
  const dispatch = useDispatch();

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short",
  };

  const handleChange = (date) => {
    setSelectedDate(date);
    fetchTrackingDetail(date);
  };

  const fetchTrackingDetail = (date) => {
    const orignalDate = new Date(date);
    const time = orignalDate.toLocaleTimeString("en-IN", timeOptions);
    setLocationTime(time);
    const formattedDate = orignalDate.toISOString().split("T")[0];
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    const timeZone = moment.tz.guess();
    const payload = {
      user_device_id: selectedDeviceDetails?.user_device_id,
      medical_alert_user_id: selectedDeviceDetails?.medical_alert_user_id,
      date: formattedDate,
      timezone: timeZone,
    };

    getBreadcrumData({
      dispatch,
      history,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      payload: payload,
      deviceType,
    });
  };

  const handleNextMonth = () => {
    if (selectedDate) {
      if (
        moment(selectedDate)?.format("YYYY-MM-DD") !==
        moment().format("YYYY-MM-DD")
      ) {
        const previousDate = moment(selectedDate).add(1, "days").toDate();
        setSelectedDate(previousDate);
        fetchTrackingDetail(moment(previousDate).format("YYYY-MM-DD"));
      } else {
        setSnackBarMessage({ message: "You cannot select future dates" });
      }
    }
  };

  const handlePrevDay = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate?.getDate() - 1);
      return newDate;
    });
  };

  useEffect(() => {
    const loadMap = () => {
      if (!window?.google || !mapRef?.current || breadcrumData?.length === 0)
        return;

      const map = new window.google.maps.Map(mapRef.current, {
        center: {
          lat: Number(breadcrumData?.[0]?.latitude),
          lng: Number(breadcrumData?.[0]?.longitude),
        },
        zoom: 15,
      });

      const latestLocation = breadcrumData?.reduce(
        (latest, current) =>
          new Date(current?.timestamp) > new Date(latest?.timestamp)
            ? current
            : latest,
        breadcrumData[0]
      );

      breadcrumData.forEach((location, index) => {
        if (location?.latitude && location?.longitude) {
          const markerPosition = {
            lat: Number(location?.latitude),
            lng: Number(location?.longitude),
          };

          const marker = new window.google.maps.Marker({
            position: markerPosition,
            map: map,
            title: "Click to see more",
          });

          const infoWindow = new window.google.maps.InfoWindow({
            content: `<div style="font-weight:bold;padding-top:8px">
                        <span>Location:</span>
                        <span>${index + 1}</span>
                      </div>
                      <div>${convertDateForLocation(location?.timestamp)}</div>`,
          });

          marker.addListener("click", () => {
            infoWindow.open(map, marker);
          });

          if (location?.timestamp === latestLocation?.timestamp) {
            setTimeout(() => infoWindow.open(map, marker), 500);
          }
        }
      });
    };

    if (window?.google) {
      loadMap();
    } else {
      const intervalId = setInterval(() => {
        if (window?.google) {
          loadMap();
          clearInterval(intervalId);
        }
      }, 500);
    }
  }, [selectedDate, breadcrumData]);

  useEffect(() => {
    const currentDate = selectedDate;
    const formattedDate = currentDate?.toISOString()?.split("T")?.[0];
    fetchTrackingDetail(formattedDate);
  }, [selectedDate]);

  const CustomInput = ({ value, onClick }) => {
    return (
      <>
        <div className="location_history_calendar_div">
          <span onClick={handlePrevDay}>
            <BackIcon height={16} />
          </span>
          <div className="location_history" onClick={onClick}>
            <span>{value.split("/").join("-")}</span>
            <span className="location_history_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
                <line x1="3" y1="14" x2="21" y2="14"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
                <line x1="7" y1="10" x2="7" y2="22"></line>
                <line x1="12" y1="10" x2="12" y2="22"></line>
                <line x1="17" y1="10" x2="17" y2="22"></line>
              </svg>
            </span>
          </div>
          <span onClick={handleNextMonth}>
            <ArrowRight color="#fff" height="16" />
          </span>
        </div>
      </>
    );
  };
  return (
    <>
      <SnackBar snackBarMessage={snackBarMessage} />
      <MainLayout
        text={titles?.location_history}
        isBackHide={false}
        isNavbar={true}
        white={false}
        fullWidthScreen={true}
        isLocationHistory={true}
      >
        {breadCrumloading ? (
          <div className="gif_loader_container">
            <img height="50px" width="50px" src={loader_gif} alt="loader" />
          </div>
        ) : null}
        <div>
          <DatePicker
            ref={dateRef}
            onChange={(date) => handleChange(date)}
            selected={selectedDate}
            dateFormat="yyyy/MM/dd"
            placeholderText="MM/DD/YYYY"
            customInput={<CustomInput value={selectedDate} />}
            maxDate={new Date()}
          />
        </div>
        {breadcrumData.length > 0 ? (
          <div className="location_history_lt_map_container">
            <div id="map" ref={mapRef} className="location_history_map_div" />;
          </div>
        ) : (
          <div className="location_history_text_div location_history_text_styles">
            <p>No location detail for selected date</p>
          </div>
        )}
      </MainLayout>
    </>
  );
};

export default LocationHistory;
