import {
  NOTIFICATION_LOADER_VISIBILITY,
  NOTIFICATION_SETTINGS_SUCCESS,
  NOTFICATION_LIST_SUCCESS,
  FETCH_MORE_NOTFICATION_LIST_SUCCESS,
  SET_NOTIFICATION_UNREAD_COUNT,
  NOTIFICATION_PULL_REFRESH_VISIBILITY,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  notificationPullRefreshing: false,
  notificationSettings: [],
  notificationListCount: 0,
  notificationList: [],
  enableNext: false,
  totalUnreadCount: 0,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationSettings: action?.payload,
      };
    case NOTIFICATION_LOADER_VISIBILITY:
      return {
        ...state,
        loading: action?.payload,
      };
    case NOTIFICATION_PULL_REFRESH_VISIBILITY:
      return {
        ...state,
        notificationPullRefreshing: action?.payload,
      };
    case NOTFICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationList: action?.payload?.result,
        notificationListCount: action?.payload?.total_count,
        enableNext: action?.payload?.enable_next,
        ...(!Array.isArray(action?.payload)
          ? { totalUnreadCount: action?.payload?.total_unread_count }
          : {}),
      };
    case FETCH_MORE_NOTFICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationList: [
          ...state?.notificationList,
          ...action?.payload?.result,
        ],
        notificationListCount: action?.payload?.total_count,
        enableNext: action?.payload?.enable_next,
        totalUnreadCount: action?.payload?.total_unread_count,
      };
    case SET_NOTIFICATION_UNREAD_COUNT:
      return {
        ...state,
        totalUnreadCount: action?.payload?.total_unread_count,
      };
    default:
      return state;
  }
};

export default notificationReducer;
