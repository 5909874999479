import React, { useState } from "react";
import "./CustomSelectWithModal.css";
import { Modal } from "react-bootstrap";
import { CrossIconBtn, IconUpDown } from "assets/SVGs/SVGs";
import { titles } from "constants/titles";
import { setSelectedPhoneType } from "redux/Common/action";
import { useDispatch } from "react-redux";

const FieldsModal = ({
  show,
  setShow,
  options,
  selectedValue,
  keyValue,
  onChange,
}) => {
  const dispatch = useDispatch();
  const SelectedOptionIndex = (array, value) => {
    if (selectedValue) {
      return array?.findIndex((item) => item?.[keyValue] === value);
    }
  };

  const onHandleSelect = (item) => {
    setShow(false);
    if (keyValue === "phone_type") {
      setSelectedPhoneType(dispatch, item);
    } else {
      onChange(item);
    }
  };

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="cm_container"
      show={show}
    >
      <Modal.Body className="custon_select_modal_body">
        <CrossIconBtn
          className="cm_cross_icon"
          onClick={() => setShow(!show)}
        />
        <div className="select_modal_container">
          {options && options?.length > 0 ? (
            options?.map((item, index) => {
              return (
                <p
                  className={`dropdown_option body_h1 ${
                    SelectedOptionIndex(options, selectedValue) === index
                      ? "selected_option"
                      : ""
                  }`}
                  onClick={() => {
                    onHandleSelect(item);
                  }}
                  key={`list-${index}`}
                >
                  {item?.[keyValue]}
                </p>
              );
            })
          ) : (
            <p>{titles?.no_options_available}</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const CustomSelectWithModal = ({
  className,
  selectedValue,
  options,
  placeholder,
  required,
  keyValue = "phone_type",
  onChange,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const placeholderText = (
    <p className="cs_placeholder_text">
      {placeholder}
      {required && <span className="error_text">*</span>}
    </p>
  );

  return (
    <div className={`custom_select_with_modal_container ${className}`}>
      <p
        className="custom_select_box"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {selectedValue ? selectedValue : placeholderText}
      </p>
      <IconUpDown
        onClick={() => setShowDropdown(!showDropdown)}
        className="custom_select_box_icon"
      />
      <FieldsModal
        show={showDropdown}
        setShow={setShowDropdown}
        options={options}
        selectedValue={selectedValue}
        keyValue={keyValue}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomSelectWithModal;
