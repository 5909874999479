import React, { useEffect } from "react";
import "./DeviceAndTracking.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAneltoTimeline,
  getClairvoyantTimeline,
  getDeviceList,
  getEviewTimeLine,
  getFreeusTimeline,
  setSelectedDeviceDetails,
} from "redux/Devices/actions";
import {
  ANELTO_DEVICE_DETAILS_SUCCESS,
  CLAIRVOYANT_DEVICE_DETAIL_SUCESS,
  EVIEW_DEVICE_DETAIL_SUCESS,
  FREEUS_DEVICE_DETAILS_SUCCESS,
  SET_DEVICE_LAST_REFRESH_TIME,
} from "redux/Devices/actionTypes";

const DeviceAndTracking = () => {
  const { deviceList, loading } = useSelector((store) => store?.devices);
  const { selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );
  const userData = useSelector((store) => store?.auth?.userData);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getIntervals();
  }, []);

  const getIntervals = () => {
    const param = {
      dispatch,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      medical_alert_user_id: selectedMedicalUser?.medical_alert_user_id,
    };
    getDeviceList(param);
    getAneltoTimeline(param);
    getFreeusTimeline(param);
    getClairvoyantTimeline(param);
    getEviewTimeLine(param);
  };

  const onSeeDevicePress = (item) => {
    setSelectedDeviceDetails(dispatch, item);
    dispatch({
      type: ANELTO_DEVICE_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: FREEUS_DEVICE_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: CLAIRVOYANT_DEVICE_DETAIL_SUCESS,
      payload: null,
    });
    dispatch({
      type: EVIEW_DEVICE_DETAIL_SUCESS,
      payload: null,
    });
    dispatch({
      type: SET_DEVICE_LAST_REFRESH_TIME,
      payload: null,
    });
    setSelectedDeviceDetails(dispatch, item);
    history?.push(ROUTES_CONSTANTS.deviceProfile);
  };

  return (
    <MainLayout
      text={titles?.device_and_tracking}
      isBackHide={false}
      isSemiHeader={true}
      white={true}
      isLoading={loading}
    >
      <div className="common_container">
        <CardLayoutWrapper>
          <div className="sp_title_container">
            <p className="display_h2 mb5">
              {selectedMedicalUser?.firstname +
                " " +
                selectedMedicalUser?.lastname}
            </p>
          </div>
          <p className="body_h1 acc_status_text">{`${titles?.account} #${selectedMedicalUser?.account_no}`}</p>
          <p className="body_h1 acc_status_text">{`${titles?.status}:  ${
            selectedMedicalUser?.is_subscription_cancelled
              ? "Inactive"
              : "Active"
          }`}</p>
        </CardLayoutWrapper>
        {deviceList?.map((item, index) => {
          return (
            <CardLayoutWrapper
              key={`device-list${index}`}
              image_url={item?.image_url}
            >
              <p className="display_h3 mb5">{item?.device_name}</p>
              <p className="body_h1 center mb18">
                {item?.subTitle
                  ? item?.subTitle
                  : "Subscriber" +
                    ": " +
                    `${item?.firstname + " " + item?.lastname}`}
              </p>
              <CustomButton
                text={titles?.see_device}
                width={"250px"}
                onClick={() => onSeeDevicePress(item)}
              />
            </CardLayoutWrapper>
          );
        })}
        {deviceList?.length <= 0 && !loading ? (
          <p className="body_h3">{titles?.no_device_available}</p>
        ) : null}
      </div>
    </MainLayout>
  );
};

export default DeviceAndTracking;
