import React, { useEffect, useRef, useState } from "react";
import "./CancelServices.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import CustomInput from "components/widgets/custom-input/CustomInput";
import { Link } from "react-router-dom";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { IconTrash } from "assets/SVGs/SVGs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getContactUsApi,
  setSessionExpiryModalVisibility,
} from "redux/auth/actions";
import { formatPhoneNumber, isEmpty, isSessionTimeExpired } from "utility";
import alerts from "constants/alerts";
import { useHistory } from "react-router-dom";
import { cancelSubscription } from "redux/MedicalAlertUsers/actions";
import { ROUTES_CONSTANTS } from "routes/route-constants";

const CancelServices = () => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const callLinkRef = useRef(null);

  const [cancelServicesData, setCancelServicesData] = useState({
    subscriberName: "",
    subscriberAccountNumber: "",
    subscriberPhoneNumber: "",
    documentList: [],
    loading: false,
  });
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const { accountHolderData } = useSelector((store) => store?.userDetail);
  const { loading } = useSelector((store) => store?.medicalAlertUsers);
  const userData = useSelector((store) => store?.auth?.userData);
  const contactData = useSelector((store) => store?.auth?.contactUsDetails);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const medicalUserData = location?.state?.medicalUserData;

  useEffect(() => {
    handleFieldChange(
      "subscriberPhoneNumber",
      formatPhoneNumber(accountHolderData?.phone_one),
      false
    );
    handleFieldChange(
      "subscriberName",
      medicalUserData?.firstname + " " + medicalUserData?.lastname,
      false
    );
    handleFieldChange(
      "subscriberAccountNumber",
      medicalUserData?.account_no,
      false
    );

    getContactUsApi(
      dispatch,
      !userData?.isGuestUser ? "accountowner" : "guestuser"
    );
  }, []);

  const handleFieldChange = (key, value, isNumeric = true) => {
    let numericText = value;
    if (isNumeric) {
      numericText = value.replace(/[^0-9]/g, "");
    }
    setCancelServicesData((prevState) => ({
      ...prevState,
      [key]: numericText,
    }));
  };

  const handleLinkClick = (event, call) => {
    event.preventDefault();
    if (call) {
      callLinkRef.current.click();
    } else {
      fileInputRef.current.click();
    }
  };

  const handleChange = (e) => {
    const { files } = e?.target;
    setFile(files[0]);
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e?.target?.value);
    handleFieldChange("subscriberPhoneNumber", formattedPhoneNumber, false);
  };

  const onSubmitPress = async () => {
    let timeDifference = await isSessionTimeExpired();
    if (isEmpty(cancelServicesData?.subscriberPhoneNumber)) {
      setSnackBarMessage({
        message: alerts?.enter_account_holder_phone_number,
      });
    } else if (isEmpty(cancelServicesData?.subscriberPhoneNumber)) {
      setSnackBarMessage({
        message: alerts?.enter_account_holder_phone_number,
      });
    } else if (!file?.name?.length) {
      setSnackBarMessage({ message: alerts?.upload_death_certificate });
    } else if (timeDifference) {
      setSessionExpiryModalVisibility(dispatch, true);
      return;
    } else {
      let data = new FormData();
      data.append(
        "medical_alert_user_id",
        medicalUserData?.medical_alert_user_id
      );
      data.append(
        "account_holder_phone_no",
        cancelServicesData?.subscriberPhoneNumber
      );
      if (file && file?.name?.length > 0) {
        data.append("", file, file?.name);
      }
      const params = {
        dispatch,
        setSnackBarMessage,
        history,
        payload: data,
        medical_alert_user_id: medicalUserData?.medical_alert_user_id,
      };
      cancelSubscription(params);
    }
  };

  return (
    <MainLayout
      text={titles?.cancel_services}
      isBackHide={false}
      isSemiHeader={true}
      white={false}
      isLoading={loading}
      snackBarMessage={snackBarMessage}
    >
      <p className="display_h4 cs_end_contract_text mb10">
        {titles?.end_of_contract_due_to_passing}
      </p>
      <p className="body_h1 cs_end_contract_text mb20">
        {titles?.end_of_contract_due_to_passing_desc}
      </p>
      <CustomInput
        placeholderStyle="bd_placeholder_style"
        placeholder={titles?.cancel_service_name_placeholder}
        type="text"
        disabled={true}
        value={cancelServicesData?.subscriberName}
        className="mb15"
      />
      <CustomInput
        placeholderStyle="bd_placeholder_style"
        placeholder={titles?.cs_id_placeholder}
        type="text"
        disabled={true}
        value={cancelServicesData?.subscriberAccountNumber}
        className="mb15"
      />
      <CustomInput
        placeholderStyle="bd_placeholder_style"
        placeholder={titles?.cs_phone_number_placeholder}
        required
        type="text"
        className="mb15"
        value={cancelServicesData?.subscriberPhoneNumber}
        onChange={(e) => {
          handlePhoneNumberChange(e);
        }}
      />
      <span className="body_h1 cs_end_contract_text">
        {titles?.cancel_services_certificate_upload_details}
      </span>
      <Link
        to={ROUTES_CONSTANTS?.helpCenter}
        className="web_link cs_end_contract_text cs_contact_us_link"
      >
        {titles?.contact_us}.
      </Link>
      <span className="body_h1 cs_end_contract_text">
        {"  "}
        {titles?.file_types}
      </span>
      <div>
        {!file ? (
          <a
            className="web_link cs_upload_doc_link"
            href="#"
            onClick={handleLinkClick}
          >
            {titles?.upload_document_and_upload}
          </a>
        ) : (
          <div className="selected_file_name">
            <p className="body_h1">{file?.name}</p>
            <IconTrash
              onClick={() => setFile(null)}
              className="hc_trash_icon"
              color="#000000"
            />
          </div>
        )}
        <input
          className="hc_file_input"
          type="file"
          onChange={handleChange}
          name="file"
          ref={fileInputRef}
          style={{ display: "none" }}
        />
      </div>
      <CustomButton
        height={"50px"}
        className={"mb20 cs_submit_btn"}
        text={titles?.submit_request}
        onClick={onSubmitPress}
      />
      <div className="horizontal_divider_line"></div>
      <p className="display_h4 cs_non_passing_text mb10">
        {titles?.end_of_contract_due_to_non_passing}
      </p>
      <p className="body_h1 cs_end_contract_text mb20">
        {titles?.end_of_contract_due_to_non_passing_details}
      </p>
      <a
        ref={callLinkRef}
        href={`tel:${contactData?.customer_care_no} alt="Call 555-555-5555"`}
      />
      <CustomButton
        onClick={(e) => handleLinkClick(e, true)}
        height={"50px"}
        className={"blue_button hc_contact_btn text-white-color"}
        text={contactData?.customer_care_no}
      />
    </MainLayout>
  );
};

export default CancelServices;
