import React, { useEffect, useState } from "react";
import "./AdminLogin.css";
import { useHistory } from "react-router-dom";
import MainLayout from "components/layout/main-layout/MainLayout.js";
import CustomInput from "components/widgets/custom-input/CustomInput.js";
import CustomButton from "components/widgets/custom-button/CustomButton.js";
import { titles } from "constants/titles";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isValidEmail } from "utility";
import { adminLogin } from "redux/AdminScreens/action";

const AdminLogin = () => {
  const [loginState, setLoginState] = useState({
    email: "",
    password: "",
    device_token: "",
    isShowPassword: false,
  });
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const isLoading = useSelector((store) => store?.auth?.loading);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    history.push(history.location.pathname);
    history.replace(history.location.pathname);
  }, [history]);

  const onLogin = async () => {
    if (isEmpty(loginState?.email)) {
      setSnackBarMessage({ message: "Please Enter Email." });
    } else if (!isValidEmail(loginState?.email)) {
      setSnackBarMessage({ message: "Please Enter Valid Email." });
    } else if (isEmpty(loginState?.password)) {
      setSnackBarMessage({ message: "Please Enter Password." });
    } else {
      let adminLoginPayload = await getLoginPayload();
      adminLogin(dispatch, adminLoginPayload, history, setSnackBarMessage);
    }
  };

  // required later
  const getLoginPayload = async () => {
    return {
      email: loginState?.email,
      password: loginState?.password,
    };
  };

  const onValueChange = (type, e) => {
    let newText = e?.target?.value;
    if (type === "password") {
      newText = newText?.replace(/\s/g, "");
    }
    setLoginState((prev) => {
      return { ...prev, [type]: newText };
    });
  };
  return (
    <>
      <MainLayout
        text={titles?.sign_in}
        isBackHide={false}
        isNavbar={false}
        isLoading={isLoading}
        snackBarMessage={snackBarMessage}
      >
        <div className="admin_sign_in_fields_container">
          <CustomInput
            label={titles?.email}
            type="email"
            className="mb15"
            value={loginState?.email}
            onChange={(value) => {
              onValueChange("email", value);
            }}
          />
          <CustomInput
            label={titles?.password}
            type="password"
            className="mb22"
            value={loginState?.password}
            onChange={(value) => {
              onValueChange("password", value);
            }}
          />
          <CustomButton
            height={"50px"}
            className={"mb20 blue_button text-white-color"}
            text={titles?.go_to_app}
            onClick={() => onLogin()}
          />
          {/* <Link className="forgot_link web_link" to={ROUTES_CONSTANTS.forgotPassword}>
            {titles?.forgot_password}
          </Link> */}
        </div>
      </MainLayout>
    </>
  );
};

export default AdminLogin;
