import { getApi, updateApi } from "../../api/commonApi";
import apis from "../../api/apiEndPoints";
import {
  getData,
  handleResponses,
  handleUnAuthorizedAccess,
} from "../../utility";
import keys from "../../constants/keys";
import {
  NOTIFICATION_PULL_REFRESH_VISIBILITY,
  FETCH_MORE_NOTFICATION_LIST_SUCCESS,
  NOTFICATION_LIST_SUCCESS,
  NOTIFICATION_LOADER_VISIBILITY,
  NOTIFICATION_SETTINGS_SUCCESS,
  SET_NOTIFICATION_UNREAD_COUNT,
} from "./actionTypes";
import { BASE_URL } from "constants/routes/api";
import { ROUTES_CONSTANTS } from "routes/route-constants";

export const getNotificationSettings = async (params) => {
  const { dispatch, insets, userType, navigation } = params;

  const API = BASE_URL + apis?.notifictionSettingsList;
  let accessToken = await getData(keys?.accessTokenKey);

  returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, true);
  getApi(API, accessToken, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          NOTIFICATION_SETTINGS_SUCCESS,
          response?.data?.data?.[0]?.customer_devices || []
        );
      } else {
        returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, insets);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, false);
    });
};

export const updateNotificationSettings = async (params) => {
  const { dispatch, insets, item, userType } = params;
  const API = BASE_URL + apis?.updateNotificationSettings;
  //  may be required later
  // + `?id=${item?.setting_id}`;

  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, true);
  const payload = {
    notification_type_id: item?.notification_type_id,
    is_enabled: item?.is_enabled ? false : true,
  };

  updateApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        getNotificationSettings(params);
      } else {
        returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, insets);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(params?.navigation);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, false);
    });
};

export const getNotificationList = async (
  params,
  pullRefresh,
  medicalUserTimezones = []
) => {
  const { dispatch, userType, history, page = 1 } = params;

  const API = BASE_URL + apis?.notificationList + `?page=${page}&limit=10`;
  let accessToken = await getData(keys?.accessTokenKey);
  if (!params?.fetchMore && !pullRefresh) {
    returnToDispatch(dispatch, NOTFICATION_LIST_SUCCESS, []);
  }
  returnToDispatch(
    dispatch,
    pullRefresh
      ? NOTIFICATION_PULL_REFRESH_VISIBILITY
      : NOTIFICATION_LOADER_VISIBILITY,
    true
  );
  returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, true);
  getApi(API, accessToken, userType)
    .then(async (response) => {
      let notifData = response?.data?.data || {};
      const notifList = notifData?.result || [];
      let updatedList = [];
      notifList?.map((item) => {
        const selectedMedicalUser = medicalUserTimezones?.filter(
          (medicalUser) =>
            medicalUser?.medicalAlertUserId === item?.medical_alert_user_id
        );
        updatedList.push({
          ...item,
          medicalUserTimezone: selectedMedicalUser?.[0]?.timezone,
        });
      });
      notifData.result = updatedList;
      if (response?.data?.code === 200) {
        if (params?.fetchMore) {
          returnToDispatch(
            dispatch,
            FETCH_MORE_NOTFICATION_LIST_SUCCESS,
            notifData
          );
        } else {
          returnToDispatch(dispatch, NOTFICATION_LIST_SUCCESS, notifData);
        }
      } else {
        returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
      setTimeout(() => {
        returnToDispatch(dispatch, NOTIFICATION_PULL_REFRESH_VISIBILITY, false);
      }, 300);
    })
    .catch((error) => {
      returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, false);
      returnToDispatch(dispatch, NOTIFICATION_PULL_REFRESH_VISIBILITY, false);
    });
};

export const markAsReadNotification = async (params, medicalUserTimezone) => {
  const { dispatch, userType, history, notification_id } = params;
  const API = BASE_URL + apis?.notificationStatus;
  let accessToken = await getData(keys?.accessTokenKey);
  let param = {
    notification_id: notification_id,
    is_read: true,
  };
  returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, true);
  updateApi(API, accessToken, param, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          SET_NOTIFICATION_UNREAD_COUNT,
          response?.data?.total_unread_count
        );
        getNotificationList(params, false, medicalUserTimezone);
        history?.push(ROUTES_CONSTANTS.deviceProfile);
      } else {
        returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, NOTIFICATION_LOADER_VISIBILITY, false);
    });
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
