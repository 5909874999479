import { CrossIconBtn } from "assets/SVGs/SVGs";
import React from "react";
import { Modal } from "react-bootstrap";

const InformationModal = ({ show, setShow, infoText }) => {
  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="di_container"
      show={show}
    >
      <Modal.Body className="di_body">
        <CrossIconBtn
          className="di_cross_icon"
          onClick={() => setShow(!show)}
        />
        <p className="display_h5 new_line_text">{infoText}</p>
      </Modal.Body>
    </Modal>
  );
};

export default InformationModal;
