import { getApi, postApi } from "../../api/commonApi";
import apis from "../../api/apiEndPoints";
import {
  encryptData,
  getData,
  handleResponses,
  handleUnAuthorizedAccess,
} from "../../utility";
import keys from "../../constants/keys";
import {
  ACCOUNT_TYPE_SUCCESS,
  ADD_EDIT_BANK_REQUEST,
  ADD_EDIT_BANK_SUCCESS,
  ADD_EDIT_CARD_REQUEST,
  ADD_EDIT_CARD_SUCCESS,
  CARD_LIST_SUCCESS,
  GET_BANK_REQUEST,
  GET_BANK_SUCCESS,
  GET_CARD_REQUEST,
  GET_CARD_SUCCESS,
  PAYMENT_LOADER_VISIBILITY,
  PAYMENT_SUCCESS,
} from "./actionTypes";
import { BASE_URL } from "constants/routes/api";
import { getMedicalAlertUserDetail } from "../MedicalAlertUsers/actions";

export const getBillingBankDetail = async (params) => {
  const { dispatch, medical_alert_user_id, history, userType } =
    params;

  const API = BASE_URL + apis?.getBankDetail;
  let accessToken = await getData(keys?.accessTokenKey);

  const payload = {
    medical_alert_user_id: medical_alert_user_id,
  };
  returnToDispatch(dispatch, GET_BANK_REQUEST);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        let data = response?.data?.data;

        if (Object.keys(data).length === 0 && data?.constructor === Object) {
          data = null;
        }
        returnToDispatch(dispatch, GET_BANK_SUCCESS, data);
      } else {
        returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
    });
};

export const getBillingCardDetail = async (params) => {
  const { dispatch, setSnackBarMessage, medical_alert_user_id, history, userType } =
    params;

  const API = BASE_URL + apis?.getCardDetail;
  let accessToken = await getData(keys?.accessTokenKey);

  const payload = {
    medical_alert_user_id: medical_alert_user_id,
  };
  returnToDispatch(dispatch, GET_CARD_REQUEST);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        let data = response?.data?.data;

        if (Object.keys(data).length === 0 && data?.constructor === Object) {
          data = null;
        }
        returnToDispatch(dispatch, GET_CARD_SUCCESS, data);
      } else {
        returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
    });
};

export const getCardList = async (params) => {
  const { dispatch, history, userType } = params;

  const API = BASE_URL + apis?.cardList;
  let accessToken = await getData(keys?.accessTokenKey);

  returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, true);
  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, CARD_LIST_SUCCESS, res?.data?.data);
      } else {
        returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
    });
};

export const getBankAccountTypes = async (params) => {
  const { dispatch, history, userType } = params;

  const API = BASE_URL + apis?.bankAccountType;
  let accessToken = await getData(keys?.accessTokenKey);

  returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, true);
  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, ACCOUNT_TYPE_SUCCESS, res?.data?.data);
      } else {
        returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
    });
};


export const addUpdateBillingCardDetail = async (params) => {
  const {
    dispatch,
    setSnackBarMessage,
    payload,
    history,
    setChangesSaveModal,
    isFromPayment,
    onCardUpdateSuccess,
  } = params;

  const API = BASE_URL + apis?.addUpdateCardDetail;
  let accessToken = await getData(keys?.accessTokenKey);

  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });

  returnToDispatch(dispatch, ADD_EDIT_CARD_REQUEST,true);
  postApi(API, accessToken, encryptedPayload, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        let data = response?.data?.data;
        returnToDispatch(dispatch, ADD_EDIT_CARD_SUCCESS, data);
        getBillingCardDetail(params);
        getMedicalAlertUserDetail({
          ...params,
          disableReset: true
        });
        if (isFromPayment && onCardUpdateSuccess) {
          setTimeout(() => {
            onCardUpdateSuccess(payload?.medical_alert_user_id, response?.data?.data);
          }, 700);
        } else {
          setChangesSaveModal(true);

          setTimeout(() => {
            setChangesSaveModal(false);
            history?.goBack();
          }, 2000);
        }
      } else {
        returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
        setTimeout(()=>{
          handleResponses(false, response?.data?.message, setSnackBarMessage);
        },1000)
       
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
    });
};

export const addUpdateBillingBankDetail = async (params) => {
  const {
    dispatch,
    setSnackBarMessage,
    payload,
    history,
    setChangesSaveModal,
    isFromPayment,
    onCardUpdateSuccess,
  } = params;

  const API = BASE_URL + apis?.addUpdateBankDetail;
  let accessToken = await getData(keys?.accessTokenKey);
  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });

  returnToDispatch(dispatch, ADD_EDIT_BANK_REQUEST, true);
  postApi(API, accessToken, encryptedPayload, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        let data = response?.data?.data;
        returnToDispatch(dispatch, ADD_EDIT_BANK_SUCCESS, data);
        getBillingBankDetail(params);
        getMedicalAlertUserDetail({
          ...params,
          disableReset: true
        });
        if (isFromPayment && onCardUpdateSuccess) {
          setTimeout(() => {
            onCardUpdateSuccess(payload?.medical_alert_user_id,response?.data?.data);
          }, 700);
        } else {
          setChangesSaveModal(true);

          setTimeout(() => {
            setChangesSaveModal(false);
            history?.goBack();
          }, 2000);
        }
      } else {
        returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
        setTimeout(() => {
          handleResponses(false, response?.data?.message, setSnackBarMessage);
        }, 1000);

        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      console.log(error);
      returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
    });
};

export const payment = async (params) => {
  const {
    dispatch,
    setSnackBarMessage,
    payload,
    history,
    getInvoices,
    setChangesSaveModal,
  } = params;

  const API = BASE_URL + apis?.doPayment;
  let accessToken = await getData(keys?.accessTokenKey);

  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });

  returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, true);
  postApi(API, accessToken, encryptedPayload, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        let data = response?.data?.data;
        returnToDispatch(dispatch, PAYMENT_SUCCESS, data);
        setChangesSaveModal(true);
        if (getInvoices) {
          getInvoices(payload?.medical_alert_user_id);
        }
        setTimeout(() => {
          setChangesSaveModal(false);
          history?.goBack();
        }, 2000);
      } else {
        returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, PAYMENT_LOADER_VISIBILITY, false);
    });
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
