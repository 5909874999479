import React, { useState } from "react";
import "./CustomerLogin.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import CustomInput from "components/widgets/custom-input/CustomInput";
import CustomButton from "components/widgets/custom-button/CustomButton";
import OTPModal from "components/layout/otp-modal/OTPModal";
import { isEmpty, isValidEmail } from "utility";
import { adminGenrateOtp } from "redux/AdminScreens/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const CustomerLogin = () => {
  const [loginState, setLoginState] = useState({
    email: "",
    password: "",
    device_token: "",
    isShowPassword: false,
  });
  const [verificationPopUp, setVerificationPopUp] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const loading = useSelector((store) => store?.auth?.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  const onValueChange = (type, e) => {
    let newText = e?.target?.value;
    setLoginState((prev) => {
      return { ...prev, [type]: newText };
    });
  };

  const handleOnGetOtp = () => {
    if (isEmpty(loginState?.email)) {
      setSnackBarMessage({ message: "Please Enter Email." });
    } else if (!isValidEmail(loginState?.email)) {
      setSnackBarMessage({ message: "Please Enter Valid Email." });
    } else {
      adminGenrateOtp(
        dispatch,
        { email: loginState?.email },
        history,
        setVerificationPopUp,
        setSnackBarMessage
      );
    }
  };

  const getAdminOtpCall = () => {
    adminGenrateOtp(
      dispatch,
      { email: loginState?.email },
      history,
      setVerificationPopUp,
      setSnackBarMessage
    );
  };

  return (
    <MainLayout
      text={titles?.customer_login}
      isBackHide={false}
      isNavbar={false}
      isLoading={loading}
      snackBarMessage={snackBarMessage}
    >
      <OTPModal
        show={verificationPopUp}
        setShow={setVerificationPopUp}
        onResendOtp={getAdminOtpCall}
      />
      <CustomInput
        label={titles?.email}
        type="email"
        value={loginState?.email}
        onChange={(value) => {
          onValueChange("email", value);
        }}
        className="mb22"
      />
      <CustomButton
        height={"50px"}
        text={titles?.get_otp}
        className={"blue_button cl_button text-white-color"}
        onClick={() => handleOnGetOtp()}
      />
    </MainLayout>
  );
};

export default CustomerLogin;
