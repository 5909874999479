import { titles } from "constants/titles";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  ACCESS_TOKEN,
  ACTIVE_SCREEN,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  SESSION_EXPIRY_POPUP,
  AUTH_LODER_VISIBILITY,
  FEEDBACK_ISSUE_TYPE_REQUEST,
  FEEDBACK_ISSUE_TYPE_SUCESS,
  FEEDBACK_ISSUE_TYPE_FAILURE,
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCESS,
  SUBMIT_FEEDBACK_FAILURE,
  FEEDBACK_ISSUE_SUB_TYPE_SUCESS,
  FEEDBACK_RESET_VALUES,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  userData: null,
  accessToken: "",
  selectedTab: titles?.home,
  activeScreen: titles?.home,
  contactUsDetails: {},
  otpData: "",
  isSessionExpiredModal: false,
  feedBackType:[],
  subIssues: []
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action?.payload,
      };
    case ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: action?.payload,
      };
    case CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        contactUsDetails: action?.payload,
      };
    case CONTACT_US_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GENERATE_OTP_SUCCESS:
      return {
        ...state,
        otpData: action?.payload,
      };
    case GENERATE_OTP_FAILURE:
      return {
        ...state,
        otpData: "",
      };
    case SESSION_EXPIRY_POPUP:
      return {
        ...state,
        isSessionExpiredModal: action?.payload,
      };
    case AUTH_LODER_VISIBILITY:
      return {
        ...state,
        loading: action?.payload,
      };
      case FEEDBACK_ISSUE_TYPE_REQUEST:{
        return{
          ...state,
          loading:action?.payload
        }
      }
      case FEEDBACK_ISSUE_TYPE_SUCESS:{
        return{
          ...state,
          loading:false,
          feedBackType:action?.payload
        }
      }
      case FEEDBACK_ISSUE_TYPE_FAILURE:{
        return{
          ...state,
          loading:false,
        }
      }
      
    case SUBMIT_FEEDBACK_REQUEST:{
      return{
        ...state,
        loading:false
      }
    }
    case SUBMIT_FEEDBACK_SUCESS:{
      return{
        ...state,
        loading:false
      }
    }
    case SUBMIT_FEEDBACK_FAILURE:{
      return{
        ...state,
        loading:false
      }
    }
    case FEEDBACK_ISSUE_SUB_TYPE_SUCESS: {
      return {
        ...state,
        loading: false,
        subIssues: action?.payload
      }
    }
    case FEEDBACK_RESET_VALUES:{
      return {
        ...state,
        loading: false,
        subIssues:[],
        feedBackType: []
      }
    }
    default:
      return state;
  }
};

export default authReducer;