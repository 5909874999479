import React, { useEffect, useState } from "react";
import "./CustomDraggableCards.css";
import { IconEdit, IconReorder, IconTrash } from "assets/SVGs/SVGs";
import { formatPhoneNumber } from "utility";

const CustomDraggableCards = ({
  initialItems,
  className,
  title = "",
  onEdit,
  onDelete,
  onDragEnd,
  isFormat = true,
}) => {
  const [items, setItems] = useState([]);
  const [isDragindex, setDragIndex] = useState(false);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const onDragStart = (event, index) => {
    event?.dataTransfer?.setData("text/plain", index);
    setDragIndex(index);
  };

  const onDragOver = (event) => {
    event?.preventDefault();
  };

  const onDrop = (event, dropIndex) => {
    event?.preventDefault();
    const dragIndex = event?.dataTransfer?.getData("text/plain");
    const updatedItems = [...items];
    const [draggedItem] = updatedItems?.splice(dragIndex, 1);
    updatedItems.splice(dropIndex, 0, draggedItem);
    setItems(updatedItems);
    onDragEnd(updatedItems);
    setDragIndex(false);
  };

  return (
    <div className={className}>
      {items?.map((item, index) => {
        return item?.is_delete ? null : (
          <div
            key={item?.id}
            className={`draggable_item `}
            draggable
            onDragStart={(event) => onDragStart(event, index)}
            onDragOver={onDragOver}
            onDrop={(event) => onDrop(event, index)}
          >
            <div
              className={`ah_phone_container mb10 ${
                isDragindex === index ? "isDragging_div" : ""
              } `}
            >
              {!item?.isDefaultPhone ? <IconReorder /> : null}
              <div className="flex_item2">
                <p className="display_h5 new_line_text">
                  {item?.phoneNumber
                    ? isFormat
                      ? formatPhoneNumber(item?.phoneNumber) + "    "
                      : item?.phoneNumber
                    : title + "   "}
                </p>
              </div>
              {!item?.isDefaultPhone ? (
                <>
                  <IconEdit onClick={() => onEdit(item)} color="#000000" />
                  <IconTrash onClick={() => onDelete(item)} color="#000000" />
                </>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomDraggableCards;
