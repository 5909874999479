import {
  ANELTO_DEVICE_INITIAL_LOAD_REQUEST,
  ANELTO_DEVICE_INITIAL_LOAD_SUCCESS,
  ANELTO_DEVICE_SYNC_DETAIL_SUCCESS,
  ANELTO_TIME_INTERVAL_SUCCESS,
  BREADCRUM_DEVICE_DETAIL_LOADER,
  BREADCRUM_DEVICE_DETAIL_REQUEST,
  BREADCRUM_DEVICE_DETAIL_SUCCESS,
  CLAIRVOYANT_DEVICE_DETAIL_SUCESS,
  CLAIRVOYANT_TIME_INTERVAL_SUCCESS,
  DEVICES_COMMON_LOADER_VISIBILITY,
  DEVICES_LIST_SUCCESS,
  DEVICES_SIGNAL_LIST_REQUEST,
  DEVICES_SIGNAL_LIST_SUCCESS,
  DEVICE_DETAIL_LOADER,
  EVIEW_DEVICE_DETAIL_SUCESS,
  EVIEW_DEVICE_TIMELINE_SUCESS,
  FREEUS_DEVICE_SYNC_DETAIL_SUCCESS,
  FREEUS_TIME_INTERVAL_SUCCESS,
  FREE_US_DEVICE_INITIAL_LOAD_REQUEST,
  FREE_US_DEVICE_INITIAL_LOAD_SUCCESS,
  RING_DEVICE_SUCCESS,
  SET_SELECTED_DEVICE_DETAIL,
  DEVICE_DETAIL_MANUAL_REFRESH_LOADER,
  DEVICE_REFRESH_VISIBILITY,
  SET_DEVICE_LAST_REFRESH_TIME,
} from "./actionTypes";
import apis from "../../api/apiEndPoints";
import keys from "../../constants/keys";
import {
  getData,
  handleResponses,
  handleUnAuthorizedAccess,
} from "../../utility";
import { getApi, postApi } from "../../api/commonApi";
import { ringDevicePayload } from "../../utility/deviceDetailUtils";
import { BASE_URL } from "constants/routes/api";

export const getDeviceList = async (
  params,
  isDeviceDetail,
  deviceId,
  pullRefresh = false
) => {
  const { dispatch, history, userType, medical_alert_user_id } = params;
  const param = medical_alert_user_id
    ? `?medical_alert_user_id=${medical_alert_user_id}`
    : "";
  const API = BASE_URL + apis?.getDeviceList + param;
  let accessToken = await getData(keys?.accessTokenKey);

  if (!isDeviceDetail && !pullRefresh) {
    returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, true);
  }
  if (pullRefresh == true) {
    returnToDispatch(dispatch, DEVICE_REFRESH_VISIBILITY, true);
  }
  getApi(API, accessToken, userType)
    .then(async (res) => {
      returnToDispatch(dispatch, DEVICE_REFRESH_VISIBILITY, false);
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, DEVICES_LIST_SUCCESS, res?.data?.data);
        if (isDeviceDetail) {
          const list = res?.data?.data;
          const selectedDevice = list?.filter(
            (item) => item?.user_device_id === deviceId
          );
          if (selectedDevice && selectedDevice?.length > 0) {
            returnToDispatch(
              dispatch,
              SET_SELECTED_DEVICE_DETAIL,
              selectedDevice[0]
            );
          }
        }
      } else {
        returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICE_REFRESH_VISIBILITY, false);
      returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
    });
};

export const getBreadcrumData = async (params) => {
  const { dispatch, payload, history, userType, deviceType } = params;
  const API =
    BASE_URL +
    (deviceType === "anelto"
      ? apis?.getAneltoBreadcrumData
      : deviceType === "freeus"
      ? apis?.getFreeusBreadcrumData
      : deviceType === "clairvoyant"
      ? apis?.getClairvoyantBreadcrumData
      : apis?.getEviewBreadcrumData);

  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, BREADCRUM_DEVICE_DETAIL_REQUEST);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          BREADCRUM_DEVICE_DETAIL_SUCCESS,
          response?.data?.data
        );
      } else {
        returnToDispatch(dispatch, BREADCRUM_DEVICE_DETAIL_LOADER, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((e) => {
      returnToDispatch(dispatch, BREADCRUM_DEVICE_DETAIL_LOADER, false);
      returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
    });
};

export const updateDeviceName = async (params) => {
  const { dispatch, payload, setSnackBarMessage, history, onClose } = params;
  const API = BASE_URL + apis?.updateDeviceName;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, true);
  postApi(API, accessToken, payload, params?.userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        handleResponses(true, response?.data?.message, setSnackBarMessage);
        returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
        onClose();
        getDeviceList(params, true, payload?.user_device_id);
      } else {
        returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
    });
};

export const updateStepGoal = async (params) => {
  const { dispatch, payload, setSnackBarMessage, history, onClose, userType } =
    params;
  const API = BASE_URL + apis?.updateStepGoal;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, true);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        handleResponses(true, response?.data?.message, setSnackBarMessage);
        returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
        onClose();
        getDeviceList(params, true, payload?.user_device_id);
      } else {
        returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
    });
};

export const getDeviceSignalHistory = async (params, pullRefresh) => {
  const { dispatch, history, userType, medical_alert_user_id, user_device_id } =
    params;
  const API = BASE_URL + apis?.getSignalHistoryList;
  let accessToken = await getData(keys?.accessTokenKey);
  if (pullRefresh == true) {
    returnToDispatch(dispatch, DEVICE_REFRESH_VISIBILITY, pullRefresh);
  } else {
    returnToDispatch(dispatch, DEVICES_SIGNAL_LIST_REQUEST);
  }
  const payload = {
    medical_alert_user_id: medical_alert_user_id,
    user_device_id: user_device_id,
  };
  postApi(API, accessToken, payload, userType)
    .then(async (res) => {
      returnToDispatch(dispatch, DEVICE_REFRESH_VISIBILITY, false);
      if (res?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          DEVICES_SIGNAL_LIST_SUCCESS,
          res?.data?.data
        );
      } else {
        returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((e) => {
      returnToDispatch(dispatch, DEVICE_REFRESH_VISIBILITY, false);
      returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
    });
};

export const ringMyDevice = async (
  dispatch,
  payload,
  setSnackBarMessage,
  setActiveModal,
  deviceType,
  userType,
  history
) => {
  let API = "";
  if (deviceType === "anelto") {
    API = BASE_URL + apis?.ringAneltoDevice;
  } else if (deviceType === "clairvoyant") {
    API = BASE_URL + apis?.ringClairvoyantDevice;
  } else if (deviceType === "eview") {
    API = BASE_URL + apis?.updateEviewFunction;
    payload = {
      ...payload,
      ...ringDevicePayload,
    };
  } else {
    API = BASE_URL + apis?.ringFreeusDevice;
  }
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, RING_DEVICE_SUCCESS, false);
  returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, true);

  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, RING_DEVICE_SUCCESS, true);
        returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
      } else {
        returnToDispatch(dispatch, DEVICES_COMMON_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, RING_DEVICE_SUCCESS, false);
    });
};

export const getAneltoDeviceInitialLoadData = async (params) => {
  const { dispatch, payload, history, userType } = params;
  const API = BASE_URL + apis?.getAnelToDeviceDetails;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, ANELTO_DEVICE_INITIAL_LOAD_REQUEST);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      const statusCode = response?.data?.code;
      returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
      if (statusCode === 200 || statusCode === 202) {
        returnToDispatch(
          dispatch,
          ANELTO_DEVICE_INITIAL_LOAD_SUCCESS,
          response?.data?.data
        );
      } else {
        if (statusCode === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
      getDeviceRefreshTime(dispatch, params);
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
    });
};

export const getAneltoDeviceDetail = async (params, isManual) => {
  const { dispatch, payload, history, userType, setSnackBarMessage } = params;
  const API = BASE_URL + apis?.getAneltoDeviceSyncDetail;
  let accessToken = await getData(keys?.accessTokenKey);
  if (isManual) {
    returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, true);
  }
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      const statusCode = response?.data?.code;
      returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, false);
      if (statusCode === 200 || statusCode === 202) {
        returnToDispatch(dispatch, ANELTO_DEVICE_SYNC_DETAIL_SUCCESS, null);
        returnToDispatch(
          dispatch,
          ANELTO_DEVICE_SYNC_DETAIL_SUCCESS,
          response?.data?.data
        );
        if (response?.data?.data?.is_out_of_range) {
          handleResponses(false, response?.data?.message, setSnackBarMessage);
        }
      } else {
        returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
        if (statusCode === 401) {
          await handleUnAuthorizedAccess(history);
        } else {
          handleResponses(false, response?.data?.message, setSnackBarMessage);
        }
      }
      getDeviceRefreshTime(dispatch, params);
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, false);
      returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
    });
};

export const getFreeusDeviceInitialLoadData = async (params) => {
  const { dispatch, payload, history, userType } = params;
  const API = BASE_URL + apis?.getInitialFreeusDeviceDetail;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, FREE_US_DEVICE_INITIAL_LOAD_REQUEST);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          FREE_US_DEVICE_INITIAL_LOAD_SUCCESS,
          response?.data?.data
        );
      } else {
        returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
      getDeviceRefreshTime(dispatch, params);
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
    });
};

export const getFreeusDeviceDetail = async (params) => {
  const { dispatch, payload, history, userType } = params;
  const API = BASE_URL + apis?.getFreeusDeviceSyncDetail;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, true);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, false);
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          FREEUS_DEVICE_SYNC_DETAIL_SUCCESS,
          response?.data?.data
        );
      } else {
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
      getDeviceRefreshTime(dispatch, params);
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, false);
    });
};

export const getFreeusTimeline = async (params) => {
  const { dispatch, userType } = params;
  const API = BASE_URL + apis?.getFreeusTimeline;
  let accessToken = await getData(keys?.accessTokenKey);
  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          FREEUS_TIME_INTERVAL_SUCCESS,
          res?.data?.data
        );
      }
    })
    .catch(() => {});
};

export const getAneltoTimeline = async (params) => {
  const { dispatch, userType } = params;
  const API = BASE_URL + apis?.getAneltoTimeline;
  let accessToken = await getData(keys?.accessTokenKey);
  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          ANELTO_TIME_INTERVAL_SUCCESS,
          res?.data?.data
        );
      }
    })
    .catch(() => {});
};

export const getClairvoyantDeviceDetail = async (params) => {
  const {
    dispatch,
    payload,
    history,
    userType,
    isRepeated = false,
    manualRefresh = false,
  } = params;
  const API = BASE_URL + apis?.getClairvoyantDeviceDetail;
  let accessToken = await getData(keys?.accessTokenKey);
  if (manualRefresh) {
    returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, true);
  } else {
    returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, isRepeated ? false : true);
  }
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
        returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, false);
        returnToDispatch(
          dispatch,
          CLAIRVOYANT_DEVICE_DETAIL_SUCESS,
          response?.data?.data
        );
      } else {
        returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
        returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
      getDeviceRefreshTime(dispatch, params);
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
      returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, false);
    });
};

export const updateEviewFunction = async (params) => {
  const { dispatch, payload, history, userType, isInitialLoad = true } = params;
  const API = BASE_URL + apis?.updateEviewFunction;
  let accessToken = await getData(keys?.accessTokenKey);
  // returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, true);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (isInitialLoad) {
        returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
      }
      if (response?.data?.code === 401) {
        await handleUnAuthorizedAccess(history);
      }
      getDeviceRefreshTime(dispatch, params);
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
    });
};

export const getEviewDeviceDetail = async (params) => {
  const { dispatch, payload, history, userType, isInitialLoad = true } = params;
  const API = BASE_URL + apis?.getEviewDeviewDetail;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(
    dispatch,
    isInitialLoad ? DEVICE_DETAIL_LOADER : DEVICE_DETAIL_MANUAL_REFRESH_LOADER,
    true
  );
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        if (isInitialLoad) {
          returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
        }
        returnToDispatch(
          dispatch,
          EVIEW_DEVICE_DETAIL_SUCESS,
          response?.data?.data
        );
      } else {
        if (isInitialLoad) {
          returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
        }
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
      getDeviceRefreshTime(dispatch, params);
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, false);
    });
};

export const callHeartBeatForClairvoyantDevice = async (params) => {
  const { dispatch, payload, history, userType, isRepeated = false } = params;
  const API = BASE_URL + apis?.callCalirvoyantHeartBeat;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, isRepeated ? false : true);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        getClairvoyantDeviceDetail({
          ...params,
          manualRefresh: true,
        });
      } else {
        returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
      getDeviceRefreshTime(dispatch, params);
    })
    .catch(() => {
      returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, false);
    });
};

export const getClairvoyantTimeline = async (params) => {
  const { dispatch, userType } = params;
  const API = BASE_URL + apis?.getClairvoyantTimeline;
  let accessToken = await getData(keys?.accessTokenKey);
  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          CLAIRVOYANT_TIME_INTERVAL_SUCCESS,
          res?.data?.data
        );
      }
    })
    .catch(() => {});
};

export const getEviewTimeLine = async (params) => {
  const { dispatch, userType } = params;
  const API = BASE_URL + apis?.getEviewTimeLine;
  let accessToken = await getData(keys?.accessTokenKey);
  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          EVIEW_DEVICE_TIMELINE_SUCESS,
          res?.data?.data
        );
      }
    })
    .catch(() => {});
};

export const getDeviceRefreshTime = async (dispatch, params) => {
  const { payload, userType } = params;
  const API = BASE_URL + apis?.getDeviceLastRefreshTime;

  let accessToken = await getData(keys?.accessTokenKey);
  postApi(API, accessToken, payload, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          SET_DEVICE_LAST_REFRESH_TIME,
          response?.data?.data
        );
      }
    })
    .catch((e) => {
    });
};

export const setSelectedDeviceDetails = async (dispatch, data) => {
  localStorage.setItem(keys.selectedDeviceDetail, JSON.stringify(data));
  returnToDispatch(dispatch, SET_SELECTED_DEVICE_DETAIL, data);
};

export const setDeviceDetailLoaderVisibility = (dispatch, data) => {
  returnToDispatch(dispatch, DEVICE_DETAIL_LOADER, data);
  returnToDispatch(dispatch, DEVICE_DETAIL_MANUAL_REFRESH_LOADER, data);
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
