import { CrossIconBtn } from "assets/SVGs/SVGs";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getLastRefreshTimeInMinutes, getManualRefreshTime } from "utility";

const FrequentRequestPopup = (props) => {
  const { show, setShow } = props;
  const [remainingTime, setRemainingTime] = useState(1);
  const {
    freeusIntervals,
    aneltoIntervals,
    clairvoyantIntervals,
    eViewIntervalArray,
  } = useSelector((store) => store?.devices);

  useEffect(() => {
    if (show) {
      const manualRefreshTime = getManualRefreshTime(
        props?.deviceType,
        aneltoIntervals,
        freeusIntervals,
        clairvoyantIntervals,
        eViewIntervalArray
      );
      const diffInMin = getLastRefreshTimeInMinutes(
        props?.lastRefreshTime,
        true
      );
      setRemainingTime(manualRefreshTime * 60 - diffInMin);
    }
  }, [show]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      }
      if (remainingTime <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [remainingTime]);

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="di_container"
      show={show}
    >
      <Modal.Body className="di_body">
        <CrossIconBtn
          className="di_cross_icon"
          onClick={() => setShow({ isVisible: false })}
        />
        <p className="display_h5 new_line_text">
          {remainingTime > 0
            ? `Frequent requests can drain battery. you can refresh again ${remainingTime} in seconds.`
            : "You can refresh now."}
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default FrequentRequestPopup;
