import CryptoJS from "crypto-js";
import moment from "moment-timezone";
import { ENCRYPTION_KEY } from "constants/routes/api";
import keys from "constants/keys";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { convertToTimeZone } from "./deviceDetailUtils";

export const numberRex = /^[1-9]\d*$/;

export const filterNumericValue = (value) => {
  return value.replace(/[^0-9]/g, "");
};

export const storeData = async (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {}
};

export const getData = async (key) => {
  try {
    const jsonValue = await localStorage.getItem(key);
    return jsonValue != null ? jsonValue : null;
  } catch (e) {}
};

export const deleteData = async (key) => {
  try {
    await localStorage?.removeItem(key);
  } catch (e) {}
};

export const clearData = async () => {
  try {
    const keyList = Object.keys(localStorage);
    const list = keyList.filter(
      (item) =>
        item !== keys?.loggnedInUser &&
        item !== keys?.isFingerPrintEnable &&
        item !== keys?.loggedInGuestUser &&
        item !== keys?.isGuestUserFingerPrintEnable &&
        item !== keys?.isDetailUpdate &&
        item !== keys?.isReviewPopUpShow &&
        item !== keys?.firstLoginTime &&
        item !== keys?.timeZoneSettings
    );
    list?.forEach(async (key) => await localStorage.removeItem(key));
    return;
  } catch (e) {}
};

export const isValidEmail = (email) => {
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (reg.test(email) === true) {
    return true;
  }
  return false;
};

export const isValidPassword = (password, length = 8) => {
  if (password.length >= length) {
    return true;
  }
  return false;
};

export const checkForSameInputs = (inputOne, inputTwo) => {
  if (inputOne?.trim() === inputTwo?.trim()) {
    return true;
  } else {
    return false;
  }
};

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const notifyError = (title, topMargin = 0) => {
  //   return Snackbar.show({
  //     text: title,
  //     backgroundColor: getColors().redColor,
  //     marginBottom:
  //       Platform?.OS === "android"
  //         ? common?.screenHeight -
  //         48 -
  //         (title?.length > 30 ? (title?.length > 100 ? 145 : 10) : 0)
  //         : common?.screenHeight -
  //         topMargin -
  //         40 -
  //         (title?.length > 30 ? (title?.length > 100 ? 145 : 10) : 0),
  //     duration: Snackbar.LENGTH_LONG,
  //     numberOfLines: 20,
  //   });
};

export const notifySuccess = (title, topMargin = 0) => {
  //   return Snackbar.show({
  //     text: title,
  //     backgroundColor: getColors().darkGreenColor,
  //     marginBottom:
  //       Platform?.OS === "android"
  //         ? common?.screenHeight -
  //         48 -
  //         (title?.length > 30 ? (title?.length > 100 ? 145 : 10) : 0)
  //         : common?.screenHeight -
  //         topMargin -
  //         40 -
  //         (title?.length > 30 ? (title?.length > 100 ? 145 : 10) : 0),
  //     duration: Snackbar.LENGTH_LONG,
  //     numberOfLines: 20,
  //   });
};

export const isFingerPrintEnable = async () => {
  const isEnable = await localStorage.getItem(keys?.isFingerPrintEnable);
  return isEnable;
};

export const isGuestUserFingerPrintEnable = async () => {
  const isEnable = await localStorage.getItem(
    keys?.isGuestUserFingerPrintEnable
  );
  return isEnable;
};

export const encryptData = (data) => {
  let encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    ENCRYPTION_KEY
  ).toString();
  return encryptedData;
};

export const decryptData = (data) => {
  let bytes = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY);
  let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
};

export const handleUnAuthorizedAccess = async (history) => {
  await clearData();
  history?.replace(ROUTES_CONSTANTS.landingScreen);
};

export const handleResponses = (isSuccess, message, setSnackBarMessage) => {
  setTimeout(() => {
    if (isSuccess) {
      setSnackBarMessage({ message, isSuccess: true });
    } else {
      setSnackBarMessage({ message });
    }
  }, 500);
};

export const isSessionTimeExpired = async () => {
  let lastLoggedInTime = await getData(keys?.logInTime);
  const storedTimeMoment = moment(lastLoggedInTime, "YYYY-MM-DD HH:mm:ss");
  let currentTime = moment();
  if (lastLoggedInTime && lastLoggedInTime !== null) {
    const duration = moment.duration(currentTime.diff(storedTimeMoment));
    if (duration?.asHours() > 24) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const getAllRegionListNames = (allRegionList = []) => {
  const updateList = allRegionList?.map((ele) => {
    return {
      label: ele?.region_name,
      value: ele?.region_code,
      id: ele?.region_id,
    };
  });
  return updateList;
};

export const getMonthList = (monthList = []) => {
  const updateList = monthList?.map((ele, index) => {
    return {
      label: ele + "",
      value: ele + "",
      id: index,
    };
  });
  return updateList;
};

export const getExpiryYear = () => {
  const yearList = Array.from(
    { length: 40 },
    (_, i) => new Date().getFullYear() + i
  );
  const updateList = getMonthList(yearList);
  return updateList;
};

export const getDataFromId = (list = [], key, value) => {
  const filteredList = list?.filter((ele) => {
    return ele[key] == value;
  });
  return filteredList;
};

const validFileTypes = [
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "image/png",
];

export const isFileValid = (fileType) => {
  if (validFileTypes?.includes(fileType)) {
    return true;
  }
  return false;
};

export const getLanguageId = (languageList = [], selectedLanguage = " ") => {
  let languageId = "";
  languageList?.filter((ele) => {
    if (ele?.language?.trim() === selectedLanguage?.trim()) {
      languageId = ele?.language_id;
    }
  });
  return languageId.toString();
};

export const getFirstAndLastName = (fullName = " ", key) => {
  const nameArray = fullName?.split(" ");
  if (key === "firstName") {
    return nameArray[0];
  } else if (key === "lastName") {
    if (nameArray.slice(1).join(" ")) {
      return nameArray.slice(1).join(" ");
    } else return "";
  } else {
    return "Invalid key. Use 'firstName' or 'lastName'.";
  }
};

export const formatDate = (date = "") => {
  if (date) {
    const formattedDate = moment(date).format("MM/DD/YYYY");
    return formattedDate;
  } else return "";
};

export const extractAptFromData = (inputString, isApt) => {
  const match = inputString?.match(/\d+/);
  if (isApt) {
    return match && match?.length > 0 ? match[0] : "";
  } else {
    return match && match?.length > 0
      ? inputString?.replace(match, "")?.trim()
      : inputString?.trim();
  }
};

export const getProtectedAddress = (inputString) => {
  let address = extractAptFromData(inputString);
  const apt = extractAptFromData(inputString, true);
  if (apt) {
    return address + `${address ? ", " : ""} Apt ${apt}`;
  }
  return address;
};

export const getSubscriberProtectedAddress = (protected_address) => {
  let address = protected_address?.street;
  let address2 = protected_address?.street2;
  return `${address} ${address2 ? `\n${address2}` : ""}\n ${
    protected_address?.city
  }, ${protected_address?.zip}`;

  // let address = getProtectedAddress(protected_address?.street);
  // return `${address}\n ${protected_address?.city} ${protected_address?.zip}`;
};

export const getLastRefreshTime = async (key, payload) => {
  let existingList = await getData(key);
  if (!existingList) {
    existingList = [];
  } else {
    existingList = JSON.parse(existingList);
  }

  const filteredList = existingList?.filter(
    (item) =>
      item?.medical_alert_user_id === payload?.medical_alert_user_id &&
      item?.user_device_id === payload?.user_device_id
  );
  if (filteredList && filteredList?.length > 0) {
    const lastRefreshTime = moment(
      moment(filteredList[0]?.lastRefreshTime)?.format("YYYY-MM-DD HH:mm:ss")
    );
    var endTime = moment(moment()?.format("YYYY-MM-DD HH:mm:ss"));
    var difftm = endTime.diff(lastRefreshTime, "minutes");
    return { minuteDiff: difftm === 0 ? 1 : difftm, item: filteredList[0] };
  }
  return { minuteDiff: 6 };
};

export const getStaticUrlByKey = (urls, key) => {
  const selectedURL = urls?.filter((item) => item?.url_for === key);
  if (selectedURL && selectedURL?.length > 0) {
    return selectedURL[0]?.url;
  }
  return null;
};

export function formatPhoneNumber(phoneNumber = "") {
  const inputPhoneNumber = phoneNumber?.replace(/\D/g, "");
  if (inputPhoneNumber?.length > 0) {
    if (inputPhoneNumber.length <= 3) {
      return `(${inputPhoneNumber}`;
    } else if (inputPhoneNumber.length <= 6) {
      return `(${inputPhoneNumber.slice(0, 3)}) ${inputPhoneNumber.slice(3)}`;
    } else {
      return `(${inputPhoneNumber.slice(0, 3)}) ${inputPhoneNumber.slice(
        3,
        6
      )}-${inputPhoneNumber.slice(6, 10)}`;
    }
  } else {
    return phoneNumber;
  }
}

export const getManualRefreshTime = (
  type,
  aneltoIntervals,
  freeusIntervals,
  clairvoyantIntervals,
  eViewIntervals
) => {
  try {
    let time = freeusIntervals?.manual_refresh_time;
    if (type === "anelto") {
      time = aneltoIntervals?.manual_refresh_time;
    }
    if (type === "eview") {
      time = eViewIntervals?.manual_refresh_time;
    }
    time = time / 60000;
    return time;
  } catch (e) {
    return 3;
  }
};

export const getAutoRefreshTime = (type, aneltoIntervals, freeusIntervals) => {
  try {
    let time = freeusIntervals?.auto_refresh_time;
    if (type === "anelto") {
      time = aneltoIntervals?.auto_refresh_time;
    }
    time = time / 60000;
    return time;
  } catch (e) {
    return 5;
  }
};

export const addSpaceAfterFourNumbers = (input) => {
  // Remove any existing spaces from the input
  var strippedInput = input?.replace(/\s/g, "");

  // Insert a space after every 4 numbers using regular expressions
  var formattedInput = strippedInput?.replace(/(\d{4})(?=\d)/g, "$1 ");

  return formattedInput;
};

export const getCardType = (number) => {
  const numberFormated = number?.toString()?.replace(/\D/g, "");
  var patterns = {
    VI: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MC: /^5[1-5][0-9]{14}$/,
    AE: /^3[47][0-9]{13}$/,
    DI: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
    DN: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  };
  for (var key in patterns) {
    if (patterns[key].test(numberFormated)) {
      return key;
    }
  }
  return "";
};

export const removeSpecialChar = (text) => {
  return text?.replace(/[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
};

export const splitFullName = (fullName = "") => {
  if (!fullName) {
    return {
      firstName: "",
      lastName: "",
    };
  }
  const nameArray = fullName.split(" ");
  if (nameArray.length === 1) {
    return {
      firstName: nameArray[0],
      lastName: " ",
    };
  } else if (nameArray.length > 1) {
    const [firstName, ...rest] = nameArray;
    const lastName = rest.join(" ");
    return {
      firstName,
      lastName,
    };
  }

  return {
    firstName: " ",
    lastName: " ",
  };
};

export const getAneltoNetworkProvider = (serialNumber) => {
  if (serialNumber) {
    if (serialNumber && serialNumber?.startsWith("BAMA9")) {
      return "Verizon";
    } else if (
      serialNumber?.startsWith("AIOA0") ||
      serialNumber?.startsWith("BAMA0") ||
      serialNumber?.startsWith("BAMA5") ||
      serialNumber?.startsWith("BAMA6") ||
      serialNumber?.startsWith("BAMA7")
    ) {
      return "AT&T";
    }
  }
  return "";
};

export const getFreeusNetworkProvider = (serialNumber) => {
  if (serialNumber && serialNumber?.startsWith("A")) {
    return "AT&TT";
  } else if (serialNumber && serialNumber?.startsWith("V")) {
    return "Verizon";
  }
  return "";
};

export const getRefreshDisabledMessage = (
  deviceType,
  aneltoMessage,
  freeusMessage,
  clairvoyantMessage,
  eViewMessage
) => {
  let message = "";
  if (deviceType?.trim() === "anelto") {
    message = aneltoMessage;
  } 
  else if (deviceType?.trim() === "eview") {
    message = eViewMessage;
  } else {
    message = freeusMessage;
  }
  return message;
};

export const isDisableManualRefresh = (startTime, interval) => {
  try {
    let differenceInMinutes = getLastRefreshTimeInMinutes(startTime);
    if (differenceInMinutes >= 3) {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

export const getLastRefreshTimeInMinutes = (startTime, seconds = false) => {
  let endTime = moment(new Date());
  try {
    let differenceInMinutes = endTime?.diff(
      startTime,
      seconds ? "seconds" : "minutes"
    );
    return differenceInMinutes;
  } catch (e) {
    return 5;
  }
};

export const isFutureMonthAndYear = (months, year) => {
  const selectedDate = new Date();
  const monthDate = new Date(months);
  selectedDate.setMonth(monthDate.getMonth());
  selectedDate.setFullYear(year.getFullYear());

  if (selectedDate >= new Date()) {
    return true;
  } else {
    return false;
  }
};

export const checkInAppReviewVisibility = async () => {
  try {
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    const firstLoginTime = await getData(keys?.firstLoginTime);
    const isDetailUpdate = await getData(keys?.isDetailUpdate);
    const isReviewPopUpShow = await getData(keys?.isReviewPopUpShow);
    const parsedDateToCheck = moment(currentTime);
    let differenceInDays = parsedDateToCheck?.diff(firstLoginTime, "days");

    if (
      isReviewPopUpShow == null &&
      (isDetailUpdate || differenceInDays >= 10)
    ) {
      return true;
    }
    return false;
  } catch (error) {}
};

export const convertDateFormate = (date, format12Hour = false) => {
  try {
    if (date && moment(date)?.isValid()) {
      const timeZone = moment?.tz(moment?.tz?.guess())?.format("z");
      const format = format12Hour ? "MM/DD/YYYY hh:mm A" : "MM/DD/YYYY HH:mm";
      const formatedDate = moment(date)?.local()?.format(format);
      return `${formatedDate} ${timeZone}`;
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};

export const convertDateForLocation = (date) => {
  try {
    if (date && moment(date)?.isValid()) {
      const timeZone = moment?.tz(moment?.tz?.guess())?.format("z");
      const formatedDate = moment(date)?.local()?.format("hh:mm A");
      return `${formatedDate} ${timeZone}`;
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};

export const isFutureDate = (date) => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  if (isNaN(inputDate.getTime())) {
    return false;
  }
  return inputDate > currentDate;
};

export const checkIsCurrentDate = (date) => {
  try {
    if (date) {
      return moment(date)?.isSame(moment(), "day");
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const convertInRequiredTimeZone = (
  date,
  medicalUserTimezone,
  currentSelectedTimeZoneSetting
) => {
  const selectedSettings = typeof(currentSelectedTimeZoneSetting) === 'string' ? JSON.parse(
    currentSelectedTimeZoneSetting || {}
  ): currentSelectedTimeZoneSetting;
  if (!selectedSettings || !selectedSettings?.timezone) {
    return convertDateFormate(date, selectedSettings?.format);
  } else {
    return convertToTimeZone(
      date,
      medicalUserTimezone,
      selectedSettings?.format
    );
  }
};
