const apis = {
  login: "customer/login",
  forgotPassword: "customer/forgotpassword",
  changePassword: "customer/changepassword",
  addGuestUser: "guest/add",
  updateGuestUser: "guest/update",
  guestUserList: "guest/list",
  deleteGuestUser: "guest/delete",
  contactUs: "customer/contactus",
  guestUserLogin: "guest/login",
  accountOwnerDetails: "customer/accountowner",
  generateOtp: "generate/otp",
  verifyOtp: "verify/otp",
  notifictionSettingsList: "web/notificationsettings/list",
  updateNotificationSettings: "notificationsettings/update",
  notificationList: "notifications/lists",
  medicalAlertUserList: "medicalalertusers/list",
  medicalAlertUserDetail: "medicalalertusers/dashboard",
  customerLogout: "customer/logout",
  guestLogout: "guest/logout",
  emergencyRelationList: "master/relation",
  languages: "master/language",
  phoneType: "master/phonetype",
  dashboardMenuList: "dashboard/menu",
  sideMenuList: "side/menu",
  regions: "master/regions",
  getEmergencyPlan: "customer/emergencyplan",
  cancelsubscription: "medicalalertusers/cancelsubscription",
  getInvoiceList: "medicalalertusers/invoices",
  getOrderList: "medicalalertusers/orders",
  updateMedicalAddress: "customer/updateaddressofmedicalalertusers",
  getSignalHistoryList: "device/history",
  getDeviceList: "devices/get",
  getAnelToDeviceDetails: "anelto/device/get",
  getAnelToSubscriberDetails: "anelto/device/subscriber",
  ringAneltoDevice: "anelto/device/ring",
  locateAneltoDevice: "anelto/device/locate",
  ringFreeusDevice: "freeus/device/ring",
  updateStepGoal: "devices/steps",
  updateDeviceName: "devices/name",
  getFreeusLocateDevice: "freeus/device/locate",
  getFreeusDeviceStatus: "freeus/device/status",
  getFreeusTimeline: "freeus/timeline",
  getAneltoTimeline: "anelto/timeline",
  getStaticUrl: "master/staticurls",
  getFreeUsDeviceProperties: "freeus/device/properties",
  getCardDetail: "medicalalertusers/cards",
  getBankDetail: "medicalalertusers/bank-account",
  addUpdateCardDetail: "cards/add",
  addUpdateBankDetail: "bank-account/add",
  bankAccountType: "master/bank-account-types",
  doPayment: "invoice/pay",
  cardList: "master/cards",
  issueType: 'issue/types',
  submitFeedback: "support/requests",
  subIssueType: 'issue/list',
  notificationStatus: 'notifications/status',
  getAneltoDeviceSyncDetail: 'anelto/device/sync',
  getInitialFreeusDeviceDetail: 'freeus/device/get',
  getFreeusDeviceSyncDetail: 'freeus/device/sync',
  getClairvoyantDeviceDetail: 'clairvoyant/get',
  ringClairvoyantDevice: "clairvoyant/noise",
  callCalirvoyantHeartBeat: "clairvoyant/heartbeat",
  getClairvoyantTimeline: "clairvoyant/timeline",
  updateEviewFunction: "eview/function",
  getEviewDeviewDetail: "eview/get",
  getEviewTimeLine: "eview/timeline",
  getSignHistory: "esignature/history",
  getAneltoBreadcrumData:"anelto/device/breadcrumb-data",
  getFreeusBreadcrumData: "freeus/device/breadcrumb-data",
  getClairvoyantBreadcrumData: "clairvoyant/device/breadcrumb-data",
  getEviewBreadcrumData: "eview/device/breadcrumb-data",
  // admin static apis *****Static ******
  adminLogin: "users/login",
  getAdminList: "users/list",
  addNewAdmin: "users/add",
  editAdmin: "users/update",
  deleteAdmin: "master/deleteAdmin",
  getAdmindata: "master/getAdminDetails",
  adminLogout: "users/logout",
  getMenu: "users/menu",
  adminChangePassword: "users/update/password",
  adminGenrateOtp: "users/generate/otp",
  adminVerifyOtp: "users/verify/otp",
  getCustomerTimezone: "customer/timezones",
  getDeviceLastRefreshTime: "device/last-refresh-time"
};

export default apis;
