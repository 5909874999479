import React, { useEffect } from "react";
import MainLayout from "components/layout/main-layout/MainLayout.js";
import { titles } from "constants/titles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEsignHistoryList } from "redux/UserDetails/actions";
import CustomButton from "components/widgets/custom-button/CustomButton";
import "./AddReviewAndSign.css";

const AddReviewAndSign = () => {
  const { loading, eSignHistory } = useSelector((store) => store?.userDetail);
  const histroy = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getEsignHistoryList(dispatch, histroy);
  }, []);

  const renderSeviceList = (eSignList) => {
    return eSignList?.map((item) => {
      return (
        <div
        className="account_holder_card_container"
        >
          <p
          className="service_name"
          >
            {item?.name}
          </p>
          <CustomButton
            text={titles?.see_details}
            onClick={() => {
              window.open(item?.url, "_blank");
            }}
          />
        </div>
      );
    });
  };

  return (
    <MainLayout
      text={titles?.review_and_sign}
      isBackHide={false}
      isSemiHeader={true}
      isNavbar={false}
      isLoading={loading}
      white={true}
    >
      {!loading ? (
        eSignHistory?.active_subscription_changes ||
        eSignHistory?.account_holder_info_changes ? (
          <div>
            <p className="approval_needed_text">{titles?.approval_needed}</p>
            <p className="signature_need_desc">
              {titles?.signature_needed_desc}
            </p>
            {eSignHistory?.active_subscription_changes?.length > 0 ? (
              <div>
                <p className="signature_need_desc active_subscription_changes account_holder_info_changes">
                  {titles?.active_subscription_changes}
                </p>
                {renderSeviceList(
                  eSignHistory?.active_subscription_changes || []
                )}
              </div>
            ) : null}
            {eSignHistory?.account_holder_info_changes?.length > 0 ? (
              <div>
                <p className="signature_need_desc active_subscription_changes account_holder_info_changes">
                  {titles?.account_holder_information_changes}
                </p>
                {renderSeviceList(
                  eSignHistory?.account_holder_info_changes || []
                )}
              </div>
            ) : null}
            {eSignHistory?.sale_orders?.length > 0 ? (
              <div>
                <p className="signature_need_desc active_subscription_changes account_holder_info_changes">
                  {titles?.new_orders}
                </p>
                {renderSeviceList(eSignHistory?.sale_orders || [])}
              </div>
            ) : null}
          </div>
        ) : (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p className="signature_need_desc active_subscription_changes account_holder_info_changes text-center">
              No records require your approval
            </p>
          </div>
        )
      ) : null}
    </MainLayout>
  );
};

export default AddReviewAndSign;
