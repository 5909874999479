import React from "react";
import "./SemiHeader.css";
import { BackIcon, IconMenu } from "assets/SVGs/SVGs";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "routes/route-constants";

const SemiHeader = (props) => {
  const history = useHistory();
  const { text, isBackHide, show, setShow } = props;
  const pathName = history?.location?.pathname;
  return (
    <div className="sh_container">
      {!isBackHide ? (
        pathName === ROUTES_CONSTANTS?.customerLogin ? (
          <IconMenu
            width={"20"}
            className={"sh_backIcon"}
            onClick={() => setShow(!show)}
          />
        ) : (
          <BackIcon
            height={"20"}
            className={"sh_backIcon"}
            onClick={() =>
              history.location.pathname === ROUTES_CONSTANTS?.login
                ? history.push(ROUTES_CONSTANTS?.landingScreen)
                : history.goBack()
            }
          />
        )
      ) : null}
      <h3 className="sh_title display_h4">{text}</h3>
    </div>
  );
};

export default SemiHeader;
