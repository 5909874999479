import React, { useEffect, useRef, useState } from "react";
import "./MainLayout.css";
import SemiHeader from "../semi-header/SemiHeader.js";
import CustomTabBar from "../custom-tab-bar/CustomTabBar.js";
import Sidebar from "../sidebar/Sidebar";
import { logo_bam_full_dark } from "assets/images/images";
import SnackBar from "components/widgets/snack-bar/SnackBar";
import Loader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardMenuList, setCurrentTimeZone } from "redux/Common/action";
import { getData } from "utility";
import keys from "constants/keys";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmationModal from "../confirmation-modal/ConfirmationModal";
import { titles } from "constants/titles";
import { IconAlert } from "assets/SVGs/SVGs";
import SessionExpiredPopup from "../session-expired-popup/SessionExpiredPopup";
import { setSessionExpiryModalVisibility } from "redux/auth/actions";
import { checkTimeZone } from "utility/deviceDetailUtils";

const MainLayout = ({
  children,
  text = "",
  isBackHide = true,
  isNavbar = true,
  isSemiHeader = true,
  white,
  fullWidthScreen,
  snackBarMessage = "",
  isLoading = false,
  isHomeHeader = false,
  isScrollHide = false,
  isLocationHistory = false,
}) => {
  const [show, setShow] = useState(false);
  const divRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { isSessionExpiredModal } = useSelector((store) => store?.auth);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    getDashboardMenuList(dispatch);
    getUserInfo();
    storeCurrentTimezone();
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    document.documentElement.style.setProperty(
      "--scrollbar-track-color",
      white ? "#fff" : "#000"
    );

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const storeCurrentTimezone = async () => {
    const localData = await checkTimeZone();
    setCurrentTimeZone(dispatch, localData);
  };

  const getUserInfo = async () => {
    let adminAccessTokenKey = await getData(keys?.adminAccessTokenKey);
    if (
      !adminAccessTokenKey &&
      location.pathname !== ROUTES_CONSTANTS.landingScreen
    ) {
      history?.push(ROUTES_CONSTANTS.login);
    }
  };

  const handleClickOutside = (event) => {
    if (divRef?.current && !divRef?.current?.contains(event.target)) {
      setShow(false);
    }
  };

  return (
    <div className="main_layout">
      <ConfirmationModal
        isNotClosable={true}
        show={!isOnline}
        svgIcon={<IconAlert />}
        title={titles?.no_internet}
        description={titles?.check_network_connectivity}
      />
      <SnackBar snackBarMessage={snackBarMessage} />
      <Sidebar ref={divRef} show={show} setShow={setShow} />
      {isSemiHeader ? (
        <SemiHeader
          show={show}
          setShow={setShow}
          text={text}
          isBackHide={isBackHide}
        />
      ) : null}
      {isHomeHeader ? (
        <div className="home_header">
          <div className="ahh_logo_bam_std">
            <img src={logo_bam_full_dark} alt="logo_bam_std" />
          </div>
        </div>
      ) : null}
      {isLoading ? (
        <div className="layout_loader_container">
          <Loader color={"#248ca7"} />
        </div>
      ) : null}
      <div
        className={`${isScrollHide ? "" : "layout_container"} ${
          !isHomeHeader && !isSemiHeader
            ? "h100"
            : isNavbar && !isLocationHistory
            ? "h160"
            : "h100"
        } ${white ? "layout_white_bg" : "layout_black_bg"} ${
          fullWidthScreen ? "layout_padding_full_width" : "layout_padding"
        }`}
      >
        {children}
      </div>

      {isNavbar ? <CustomTabBar /> : null}

      {isSessionExpiredModal ? (
        <SessionExpiredPopup
          isVisible={isSessionExpiredModal || false}
          onClosePopup={() => {
            setSessionExpiryModalVisibility(dispatch, false);
          }}
          history={history}
        />
      ) : null}
    </div>
  );
};

export default MainLayout;
