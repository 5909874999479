import React, { useEffect, useState } from "react";
import "./LocationTracking.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { IconTracking } from "assets/SVGs/SVGs";
import {
  callHeartBeatForClairvoyantDevice,
  getAneltoDeviceDetail,
  getAneltoTimeline,
  getClairvoyantTimeline,
  getEviewTimeLine,
  getFreeusDeviceDetail,
  getFreeusTimeline,
  getEviewDeviceDetail,
  setDeviceDetailLoaderVisibility,
  updateEviewFunction,
} from "redux/Devices/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  convertInRequiredTimeZone,
  getLastRefreshTimeInMinutes,
  getManualRefreshTime,
  isDisableManualRefresh,
} from "utility";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmationModal from "components/layout/confirmation-modal/ConfirmationModal";
import { loader_gif } from "assets/images/images";
import {
  deviceLocationPayload,
  getCalculatedLastRefreshTime,
} from "../../utility/deviceDetailUtils";
import Loader from "react-spinners/ClipLoader";
import FrequentRequestPopup from "components/layout/frequent-request-popup/FrequentRequestPopup";

const LocationTracking = () => {
  const [locationSignalPopup, setLocationSignalPopup] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [deviceLocation, setDeviceLocation] = useState({
    latitude: location?.state?.item?.latitude
      ? parseFloat(location?.state?.item?.latitude, 10)
      : 0,
    longitude: location?.state?.item?.longitude
      ? parseFloat(location?.state?.item?.longitude, 10)
      : 0,
    latitudeDelta: 0.02,
    longitudeDelta: 0.02,
  });
  const [lastRefreshDetails, setLastRefreshDetails] = useState({
    time: "",
    city: "",
    state: "",
  });
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const [disableManualRefresh, setDisableManualRefresh] = useState(false);
  const [manualRefreshIntervalTime, setManualRefreshIntervalTime] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [eViewIntervalIndex, setEviewIntervalIndex] = useState(0);
  const [eViewInterval, setEviewInterval] = useState(false);
  const [frequentRequestPopup, setFrequentRequestPopup] = useState({
    data: null,
    isVisible: false,
  });
  const {
    aneltoDeviceDetails,
    selectedDeviceDetails,
    freeusDeviceDetails,
    loading,
    freeusIntervals,
    aneltoIntervals,
    clairvoyantIntervals,
    clairvoyantDeviceDetails,
    deviceDetailLoading,
    eViewDeviceDetails,
    eViewIntervalArray,
    manualRefreshLoading,
    deviceRefreshData,
  } = useSelector((store) => store?.devices);
  const userData = useSelector((store) => store?.auth?.userData);
  const { selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );
  const { currentTimeZone } = useSelector((store) => store?.common);
  const item = location?.state?.item;
  const isFromSignalHistory = location?.state?.isFromSignalHistory;
  const dispatch = useDispatch();

  useEffect(() => {
    const param = {
      dispatch,
      history,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
    };
    getAneltoTimeline(param);
    getFreeusTimeline(param);
    getClairvoyantTimeline(param);
    getEviewTimeLine(param);
    return () => {
      setDeviceDetailLoaderVisibility(dispatch, false);
    };
  }, []);

  useEffect(() => {
    try {
      if (
        !isFromSignalHistory &&
        aneltoDeviceDetails &&
        Object?.keys(aneltoDeviceDetails)?.length > 0
      ) {
        checkManualRefreshButtonAccess();
        if (
          !aneltoDeviceDetails?.is_data_found ||
          aneltoDeviceDetails?.is_active_request
        ) {
          setTimeout(() => {
            getDeviceDetailManually();
          }, aneltoDeviceDetails?.next_interval_call);
        }
      }
    } catch (e) {}
  }, [aneltoDeviceDetails, deviceRefreshData]);

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (
      !isFromSignalHistory &&
      freeusDeviceDetails &&
      deviceType === "freeus"
    ) {
      checkManualRefreshButtonAccess();
    }
  }, [freeusDeviceDetails, deviceRefreshData]);

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (
      !isFromSignalHistory &&
      clairvoyantDeviceDetails &&
      deviceType === "clairvoyant"
    ) {
      checkManualRefreshButtonAccess();
    }
  }, [clairvoyantDeviceDetails, deviceRefreshData]);

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (eViewDeviceDetails && deviceType === "eview") {
      checkManualRefreshButtonAccess();
    }
  }, [eViewDeviceDetails, deviceRefreshData]);

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    let timeoutId = null;
    if (
      clairvoyantDeviceDetails &&
      deviceType === "clairvoyant" &&
      Object?.keys(clairvoyantDeviceDetails)?.length <= 0 &&
      currentIndex < clairvoyantIntervals?.intervals?.length
    ) {
      timeoutId = setTimeout(() => {
        getDeviceDetailManually();
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, clairvoyantIntervals?.intervals[currentIndex]);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [clairvoyantIntervals?.intervals, clairvoyantDeviceDetails]);

  useEffect(() => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    let timeoutId = null;
    if (
      eViewInterval &&
      deviceType === "eview" &&
      eViewIntervalArray?.intervals &&
      eViewIntervalIndex < eViewIntervalArray?.intervals?.length - 1 &&
      !disableManualRefresh
    ) {
      timeoutId = setTimeout(() => {
        const payload = {
          user_device_id: selectedDeviceDetails?.user_device_id,
          medical_alert_user_id: selectedDeviceDetails?.medical_alert_user_id,
          providerDeviceId: selectedDeviceDetails?.provider_device_id,
        };

        let param = {
          dispatch,
          payload,
          history,
          userType: userData?.isGuestUser ? "guestuser" : "accountowner",
          isInitialLoad: false,
        };
        getEviewDeviceDetail(param);
        setEviewIntervalIndex(eViewIntervalIndex + 1);
      }, eViewIntervalArray?.intervals?.[eViewIntervalIndex]);
    } else {
      setTimeout(() => {
        setDeviceDetailLoaderVisibility(dispatch, false);
        clearEviewInterval(timeoutId);
      }, 2000);
    }
    return () => {
      clearEviewInterval(timeoutId);
    };
  }, [eViewInterval, eViewIntervalIndex, selectedDeviceDetails]);

  const clearEviewInterval = (timeoutId) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  useEffect(() => {
    if (!disableManualRefresh) return;
    const intervalId = setInterval(() => {
      setManualRefreshIntervalTime((prevTimer) => {
        if (prevTimer <= 0) {
          setDisableManualRefresh(false);
          if (intervalId) clearInterval(intervalId);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [disableManualRefresh]);

  useEffect(() => {
    if (!isFromSignalHistory) {
      let deviceDetail = null;
      const deviceType = selectedDeviceDetails?.device_type?.trim();
      if (deviceType === "anelto") {
        deviceDetail = aneltoDeviceDetails;
      } else if (deviceType === "clairvoyant") {
        deviceDetail = clairvoyantDeviceDetails;
      } else if (deviceType === "eview") {
        deviceDetail = {
          ...eViewDeviceDetails,
          latitude: eViewDeviceDetails?.latestLocation?.lat,
          longitude: eViewDeviceDetails?.latestLocation?.lng,
          created_at: eViewDeviceDetails?.generalData?.dateTime,
        };
      } else {
        deviceDetail = freeusDeviceDetails;
      }

      if (deviceDetail?.latitude || deviceDetail?.longitude) {
        setDeviceDetails(deviceDetail, deviceType);
      }
    }
  }, [selectedDeviceDetails]);

  const setDeviceDetails = async (deviceDetail, deviceType) => {
    setDeviceLocation({
      ...deviceLocation,
      latitude: parseFloat(deviceDetail?.latitude, 10),
      longitude: parseFloat(deviceDetail?.longitude, 10),
    });
    setLastRefreshDetails({
      ...lastRefreshDetails,
      time: deviceDetail?.created_at,
      city: deviceDetail?.city ?? "",
      state: deviceDetail?.state ?? "",
    });
  };

  const checkManualRefreshButtonAccess = () => {
    let deviceDetail = null;
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    if (deviceType === "anelto") {
      deviceDetail = aneltoDeviceDetails;
    } else if (deviceType === "clairvoyant") {
      deviceDetail = clairvoyantDeviceDetails;
    } else if (deviceType === "eview") {
      deviceDetail = {
        ...eViewDeviceDetails,
        created_at: eViewDeviceDetails?.generalData?.dateTime,
      };
    } else {
      deviceDetail = freeusDeviceDetails;
    }
    const manualRefreshTime = getManualRefreshTime(
      deviceType,
      aneltoIntervals,
      freeusIntervals,
      clairvoyantIntervals,
      eViewIntervalArray
    );
    const lastRequestedTime = getCalculatedLastRefreshTime(
      deviceRefreshData?.last_refresh_timestamp,
      deviceDetail?.data_timestamp
    );
    const isDisable = isDisableManualRefresh(
      lastRequestedTime,
      manualRefreshTime
    );
    setDisableManualRefresh(isDisable);
    if (isDisable && lastRequestedTime) {
      const diffInMin = getLastRefreshTimeInMinutes(lastRequestedTime);
      setManualRefreshIntervalTime((manualRefreshTime - diffInMin) * 60);
    } else {
      setManualRefreshIntervalTime(0);
    }
  };

  const getDeviceDetailManually = (isManual = false) => {
    setLocationSignalPopup(false);
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    const payload = {
      user_device_id: selectedDeviceDetails?.user_device_id,
      medical_alert_user_id: selectedDeviceDetails?.medical_alert_user_id,
      last_interval_call:
        deviceType === "anelto"
          ? aneltoDeviceDetails?.next_interval_call || 0
          : undefined,
    };
    let param = {
      dispatch,
      payload,
      history,
      setSnackBarMessage,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
    };
    if (selectedDeviceDetails?.device_type?.trim() === "anelto") {
      getAneltoDeviceDetail(param, isManual);
    } else if (selectedDeviceDetails?.device_type?.trim() === "clairvoyant") {
      callHeartBeatForClairvoyantDevice({
        ...param,
        isRepeated: isManual ? false : true,
      });
    } else if (deviceType === "eview") {
      param.payload = {
        ...param?.payload,
        ...deviceLocationPayload,
        providerDeviceId: selectedDeviceDetails?.provider_device_id,
        isInitialLoad: false,
      };
      param = {
        ...param,
        isInitialLoad: false,
      };
      setEviewInterval(false);
      setEviewIntervalIndex(0);
      updateEviewFunction(param);
      setEviewInterval(true);
    } else {
      getFreeusDeviceDetail(param);
    }
  };

  const convertInLocalTimeZone = (date) => {
    return convertInRequiredTimeZone(
      date,
      selectedMedicalUser?.timezone,
      currentTimeZone
    );
  };

  const customSchemaForUI = () => {
    const deviceType = selectedDeviceDetails?.device_type?.trim();
    let deviceDetail = null;
    if (deviceType === "anelto") {
      deviceDetail = aneltoDeviceDetails;
    } else if (deviceType === "clairvoyant") {
      deviceDetail = clairvoyantDeviceDetails;
    } else if (deviceType === "eview") {
      deviceDetail = {
        ...eViewDeviceDetails,
        created_at: eViewDeviceDetails?.generalData?.dateTime,
      };
    } else {
      deviceDetail = freeusDeviceDetails;
    }
    const lastRequestedTime = getCalculatedLastRefreshTime(
      deviceRefreshData?.last_refresh_timestamp,
      deviceDetail?.data_timestamp
    );

    return {
      lastRefreshTime: lastRequestedTime, //deviceRefreshData?.last_refresh_timestamp,
      dataTimeStamp: deviceDetail?.data_timestamp,
    };
  };

  const onManualRefreshClick = () => {
    if (manualRefreshLoading) {
      return;
    } else if (disableManualRefresh) {
      setFrequentRequestPopup({
        isVisible: true,
        lastRefreshTime: customSchemaForUI()?.lastRefreshTime,
      });
    } else {
      setLocationSignalPopup(true);
    }
  };

  return (
    <MainLayout
      text={titles?.caregiver_tracking}
      isBackHide={false}
      isSemiHeader={true}
      white={false}
      loading={loading}
      snackBarMessage={snackBarMessage}
      fullWidthScreen
    >
      {deviceDetailLoading ? (
        <div className="gif_loader_container">
          <img height="50px" width="50px" src={loader_gif} alt="loader" />
        </div>
      ) : null}
      <ConfirmationModal
        show={locationSignalPopup}
        setShow={setLocationSignalPopup}
        svgIcon={<IconTracking color="#000000" />}
        btnText={titles?.OK}
        title={titles?.location_signal}
        description={titles?.location_signal_desc}
        onClick={() => getDeviceDetailManually(true)}
      />
      <FrequentRequestPopup
        show={frequentRequestPopup?.isVisible}
        setShow={setFrequentRequestPopup}
        lastRefreshTime={frequentRequestPopup?.lastRefreshTime}
        deviceType={selectedDeviceDetails?.device_type?.trim()}
      />
      <p className="display_h2 lt_title mb10">
        {selectedDeviceDetails?.device_name}
      </p>
      {!isFromSignalHistory ? (
        <CustomButton
          className={"lt_btn mb10"}
          text={
            manualRefreshLoading ? titles?.updating : titles?.update_location
          }
          onClick={() => {
            onManualRefreshClick();
          }}
          isGrayButton={disableManualRefresh || manualRefreshLoading}
          width={"250px"}
        />
      ) : null}
      <p className="body_h1 lt_title center mb18 new_line_text">
        {isFromSignalHistory ? (
          `Map information as of ${convertInLocalTimeZone(item?.date)(
            item?.date
          )}`
        ) : (
          <>
            {customSchemaForUI()?.lastRefreshTime
              ? titles?.last_refresh +
                convertInLocalTimeZone(customSchemaForUI()?.lastRefreshTime)
              : ""}
            {customSchemaForUI()?.dataTimeStamp
              ? "\nMap " +
                titles?.information_as_of +
                convertInLocalTimeZone(customSchemaForUI()?.dataTimeStamp)
              : ""}
          </>
        )}
        {`\n${lastRefreshDetails?.city} ${
          lastRefreshDetails?.state ? "," : ""
        } ${lastRefreshDetails?.state}`}
      </p>
      {manualRefreshLoading ? (
        <div className="dp_time_container">
          <Loader color={"#248ca7"} />
          <p className="dp_card_last_refresh body_h1 mr5 text-white-color">
            {titles?.hang_tight_app_refreshing}
          </p>
        </div>
      ) : null}
      <div className="lt_map_container">
        <iframe
          src={`https://maps.google.com/maps?q=${deviceLocation?.latitude},${deviceLocation?.longitude}&z=15&output=embed`}
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="lt_map"
          className="lt_map"
        ></iframe>
        <div
          className="lt_tracking_icon"
          onClick={() =>
            window.open(
              `https://www.google.com/maps?q=${deviceLocation?.latitude},${deviceLocation?.longitude}`,
              "_blank"
            )
          }
        >
          <IconTracking />
          <p className="display_h5 lt_icon_text">{titles?.maps}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default LocationTracking;
