import { getApi, postApi, updateApi } from "../../api/commonApi";
import {
  GUEST_USER_LIST_SUCCESS,
  GUEST_USER_LOGIN_SUCCESS,
  GUEST_USER_LOADER_VISIBILITY
} from "./actionTypes";
import apis from "../../api/apiEndPoints";
import {
  encryptData,
  getData,
  handleResponses,
  handleUnAuthorizedAccess,
  storeData,
} from "../../utility";
import { setActiveScreen } from "../auth/actions";
import { LOGIN_SUCCESS } from "../auth/actionTypes";
import keys from "../../constants/keys";
import { BASE_URL } from "constants/routes/api";
import { ROUTES_CONSTANTS } from "routes/route-constants";


export const addGuestUser = async (params) => {
  const { dispatch, payload, setSnackBarMessage, onClose } = params;
  const API = BASE_URL + apis?.addGuestUser;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, true);

  postApi(API, accessToken, payload, 'accountowner')
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
        handleResponses(true, "Guest User Added Successfully", setSnackBarMessage);
        getGuestUserList(dispatch);
        onClose();
      } else {
        returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(params?.history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
    });
};

export const getGuestUserList = async (dispatch, history) => {
  const API = BASE_URL + apis?.guestUserList;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, true);

  getApi(API, accessToken, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
        returnToDispatch(
          dispatch,
          GUEST_USER_LIST_SUCCESS,
          response?.data?.data
        );
      } else {
        returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
    });
};

export const updateGuestUser = async (params) => {
  const { dispatch, payload, setSnackBarMessage, onClose } = params;

  const API = BASE_URL + apis?.updateGuestUser;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, true);
  updateApi(API, accessToken, payload, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
        getGuestUserList(dispatch);
        onClose();
      } else {
        returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(params?.history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
    });
};

export const deleteGuestUser = async (
  dispatch,
  payload,
  setSnackBarMessage,
  setGuestUserPopupMode,
  history
) => {
  const API = BASE_URL + apis?.deleteGuestUser;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, true);

  postApi(API, accessToken, payload, 'accountowner')
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
        handleResponses(true, "Guest User Deleted Successfully", setSnackBarMessage);
        getGuestUserList(dispatch);
        setGuestUserPopupMode("");
      } else {
        returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
    });
};

export const login = (dispatch, payload, history, setSnackBarMessage) => {
  const API = BASE_URL + apis?.guestUserLogin;
  returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, true);
  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });
  postApi(API, "", encryptedPayload)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        storeData(keys?.accessTokenKey, response?.data?.data?.token);
        storeData(
          keys?.loggedInGuestUserData,
          JSON.stringify(response?.data?.data)
        );
        returnToDispatch(
          dispatch,
          GUEST_USER_LOGIN_SUCCESS,
          response?.data?.data
        );
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            isGuestUser: true,
          },
        });
        let storedUser = await getData(keys?.loggedInGuestUser);
        if (storedUser && JSON.parse(storedUser)) {
          let storedUserObj = JSON.parse(storedUser);
          if (storedUserObj?.username !== payload?.username) {
            storeData(
              keys?.isGuestUserFingerPrintEnable,
              JSON.stringify(false)
            );
          }
        }
        storeData(keys?.loggedInGuestUser, JSON.stringify(payload));
        history?.replace(ROUTES_CONSTANTS.home);
        setActiveScreen(dispatch, "Home");
        // logCustomEvents(
        //   "login",
        //   "engagement",
        //   "Guest User Login",
        //   payload?.username
        // );
      } else {
        returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, GUEST_USER_LOADER_VISIBILITY, false);
    });
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
