export const titles = {
  first_name: "First Name",
  last_name: "Last Name",
  get_otp: "Get OTP",
  admin_sign_in: "Admin Sign In",
  login: "Login",
  logout: "Logout",
  sign_in: "Sign In",
  guest_sign_in: "Guest Sign In",
  number_one_alert_system: `America’s #1 Rated`,
  medical_alert_system: `Medical Alert Systems`,
  bay_alarm_medical: `Bay Alarm Medical`,
  all_rights_reserved: `© 2023 All Rights Reserved.`,
  email: "Email",
  password: "Password",
  go_to_app: "Go to App",
  forgot_password: "Forgot Password?",
  use_touch_id: "Use Touch ID",
  account_holder_email: "Account Holder Email",
  guest_password: "Guest Password",
  forgot_password_title: "Forgot Password",
  send_instruction: "Send Instructions",
  send_you_reset_link: `We’ll send you a reset link.`,
  return_to_guest_sign_in: "Return to Guest Sign In",
  please_contact_account_holder: `Please contact the Account Holder\nfor help with app access.`,
  home: "Home",
  devices: "Devices",
  alerts: "Alerts",
  settings: "Settings",
  notifications: "Notifications",
  manage_guest_users: "Manage Guest Users",
  change_password: "Change Password",
  enable_fingerprint_face_id: "Enable Fingerprint & Face ID",
  disable_fingerprint_face_id: "Disable FingerPrint & Face ID",
  about_bay_alarm_medical: "About Bay Alarm Medical",
  help_center: "Help Center",
  sign_out: "Sign Out",
  reset_password: "Reset Password",
  current_password: "Current Password",
  current_goal: "Current Goal",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  new_password_must_be_different:
    "Your new password must be different \n from previously used ones.",
  at_least_eight_char: "Must be at least 8 characters",
  passwords_must_match: "Both passwords must match",
  see_subscriber_hub: "See Subscriber Profile",
  all_subscriber_devices: "All Subscriber Devices",
  get_access_each_subscriber: "Get access to each subscriber service.",
  see_device: "See Device",
  add_subscriber: "+ Add a Subscriber",
  tapping_add_will_jump_pricing_page:
    "Tapping Add will jump to our pricing page",
  view_canceled_subscribers: "View Canceled Subscribers",
  edit_details: "Edit Details",
  review_and_sign: "Review & Sign",
  welcome: "Welcome",
  view_subscriber_profile: "View Subscriber Profile",
  welcome_guest: "Welcome, Guest",
  account_holder: "Account Holder",
  name_email: "Name & Email",
  please_call_to_name_email: "To change Account Holder name or email, please ",
  contact_us: "contact us",
  mailing_address: "Mailing Address",
  street: "Street",
  apt: "Apt #",
  city: "City",
  numbers_used_fo_account_management:
    "These numbers are used for account management only.",
  hold_drag_item_desc: "Hold & drag item to swap priority order.",
  add_contact: "+ Add a Contact",
  submit: "Submit",
  state: "State",
  zip_code: "Zip code",
  changes_saved: "Changes saved!",
  delete_contact: "Delete Contact",
  are_you_sure_to_remove_number:
    "Are you sure you want to remove this number ?",
  remove: "Remove",
  save_changes: "Save Changes",
  cancel: "Cancel",
  text_message_permissions: "Text Message Permissions",
  message_permission_desc: "Regular text messaging rates may apply",
  message_permission_checkbox: "Check this box to ",
  receiving_text_messages: " of receiving text messages about your account.",
  opt_out: "opt out",
  select: "Select",
  about: "About",
  service_and_support: "Service & Support",
  faqs: "FAQs",
  help_guides: "Help Guides",
  videos: "Videos",
  privacy_policy: "Privacy Policy",
  version: "Version",
  help_center_contact_number: "1 (877) 522-9633",
  help_center_assist_text: "How can we assist you?",
  help_center_customer_service: "Customer Service",
  help_center_billing: "Billing",
  phone: "Phone",
  contact_to_change_password:
    "Please contact the Account Holder\nto change the password.",
  add_upto_three_guest:
    "A Guest User gets read-only access to a Subscriber’s medical alert profile when they sign in with the Account Holder email address & a unique guest password that is created for them.",
  guest_notification: "You may add up to 3 guests.",
  add_guest_user: "+ Add a Guest User",
  delete_guest: "Delete Guest",
  remove_guest_desc: "Are you sure you want to\nremove this user ?",
  guest_name: "Guest Name",
  guest: "Guest",
  add_your_guest: "Add Your Guest",
  edit_your_guest: "Edit Your Guest",
  passwords_must_characters:
    "Passwords must be at least\n6 characters in length",
  notification_sos: "Get all Emergency SOS Alerts",
  notification_low_battery: "Get Device Low Battery Alerts",
  notification_device_power_loss: "Get Device Power Loss Alerts",
  notification_missed_timer: "Get Missed Test Signal Alerts",
  subscriber_hub: "Subscriber Profile",
  devices_tracking: "Devices & Tracking",
  see_devices: "See Devices",
  edit_subscriber_details: "Edit Subscriber Details",
  protected_address: "Protected Address",
  edit_plan: "Edit Plan",
  emergency_plan: "Emergency Plan",
  see_all: "See All",
  all_signal_history: "All Signal History",
  signal_history: "Signal History",
  edit: "Edit",
  shipping_address: "Shipping Address",
  billing_details: "Billing Details",
  edit_card: "Edit Card",
  add_card: "Add Card",
  edit_bank: "Edit Details",
  add_bank: "Add Details",
  more: "More",
  invoices_bills: "Invoices & Bills",
  sales_orders_subscriptions: "Sales Orders / Subscriptions",
  cancel_services: "Cancel Services",
  see_plan: "See Plan",
  end_of_contract_due_to_non_passing: "End contract due to non-passing",
  end_of_contract_due_to_non_passing_details:
    "If you would like to cancel for any reason that is not related to the passing of a loved one, then please call.",
  end_of_contract_due_to_passing:
    "Need to cancel service due to the passing of your loved one?",
  end_of_contract_due_to_passing_desc:
    "Please ensure the information below is correct & submit any necessary documentation using the Upload Document link.",
  death_certificate: "Death Certificate",
  cancel_services_certificate_upload_details:
    "Please submit a copy of the death certificate so that we may expedite the cancellation. If you do not have this, please ",
  file_types: " File types accepted: JPG, PNG, PDF.",
  upload_document_and_upload: "Select & Upload Document",
  death_certificate_not_available: "If you do not have this, please call.",
  submit_request: "Submit Request",
  emergency_info_tooltip:
    "Bay Alarm Medical will dispatch Emergency Services to this protected address by default.",
  subscriber: "Subscriber",
  profile_name: "Profile Name",
  subscriber_name_desc:
    "The name or nickname entered here will be used for any device, page or screen associated with this Subscriber.",
  phone_contact: "Phone",
  number_used_subscriber:
    "These numbers are only used if we need to contact a Subscriber directly.",
  accept: "Accept",
  digital_signature: "**DIGITAL SIGNATURE**",
  degital_signature_terms_condition:
    "By checking this box you are authorizing the Premise Address or Name Change to be updated in our system.",
  date_of_birth: "Date of Birth",
  system_cannot_recognize_protected_address:
    "Our system cannot recognize the protected address",
  notifications_tab_header:
    "Get access to SOS alerts & service agreement messages to review & sign",
  attention_required: "Attention Required",
  message_center: "Message Center",
  shipping_details: "Shipping Details",
  name: "Name",
  shipping_address_details:
    "Where should any Medical Alert System devices or equipment be shipped?",
  shipping_informative_details:
    "Updates to this shipping address may not affect orders that are pending shipment. Please call Bay Alarm Medical at (877) 522-9633 if you have an order pending shipment and you would like to update your shipping address. \n\nUpdates to this shipping address will not affect parcels that are already shipped. If you have received an emailed link to shipment tracking, please follow the instructions available through that link to manage the routing of your parcel.",
  unique_guest_password: "Unique Guest Password",
  approval_needed: "Approval Needed",
  signature_needed_desc:
    "Below are the changes that require your review and approval:",
  services: "Services",
  billing_cycle: "Billing Cycle",
  download_service_agreement: "Download Service Agreement (PDF)",
  please_read_the_following: "Please read the following:",
  verify_your_signature:
    "Please verify your signature (First Name, Last Name) & check the box. Tap or click “Accept” to submit signed agreement.",
  verify_review_signature: "Check the box below to approve all terms.",
  signature_terms:
    'By checking the "eSign" box and clicking "Accept" to sign with an electronic signature, you understand that (i) your signature is legally binding, whether electronic or signed on paper. (ii) You have the right to receive a paper copy of the Service Agreement(s), provided you pay the shipping fee associated with mailing the documents out. (iii) You agree that by your use of a keypad, mouse, or other device used to check the box is evidence that you intended to sign the document(s). (iv) You also agree that no other verification is necessary to validate your electronic signature. (v) You also understand that in order to retain an electronic copy of the Signature Agreement, your computer must be equipped and compatible with the most common internet browsers such as: Mozilla Firefox - Latest version, Safari - latest version, Chrome - latest version. (vi) After signing, you may still withdraw your consent after any associated fees with your account are paid in full. (vii) That you have received and read a complete copy of the full Service Agreement(s) found in the link(s) above.',
  street_address: "Street Address",
  address_placeholder_text: "Address",
  name_on_card: "Name on Card",
  credit_card_details: "Credit Card Details",
  bank_account_details: "ACH Details",
  exp_year: "Exp. Year",
  cvn: "CVV",
  exp_month: "Exp. Month",
  bank_account_type: "Bank Account Type",
  credit_card_payment_description:
    "After a payment method has been successfully updated, Bay Alarm Medical will use the new payment method when renewing a Subscription. Bay Alarm Medical may also automatically reattempt payment of any prior unpaid invoices. Please contact Bay Alarm Medical for assistance if any payments require special handling.",
  billing_cvn_description:
    "A three-digit security number that usually appears on the back of your credit card",
  add_a_device: "+ Add a device",
  problem_verifying_credit_card: "We had a problem verifying your credit card",
  problem_verifying_credit_card_desc:
    "Hi there, just a friendly reminder to please update your credit card on this account. You can do so from this page, \nor from the Subscriber Hub.",
  your_payment_received: "Thank You,\nyour payment has\nbeen received",
  billing_address: "Billing Address",
  caregiver_tracking: "Caregiver Tracking",
  update_location: "Update Location",
  maps: "Maps",
  map_cordinates: "Map Cordinates",
  location_signal: "Please wait while we locate your device.",
  location_signal_desc:
    "Sufficient cell coverage is required for location services.",
  OK: "OK",
  refresh_signal: "Refresh Signal",
  last_refresh: "Last Requested: ",
  view_past_signals: "View the past 60 days of signal \n for this device",
  view_past_all_signals:
    "View the past 60 days of signal. Tap or click on a report to go to the device page.",
  device_signal_history: "Device Signal History",
  about_this_device: "About This Device",
  auto_fall_detection: "Auto Fall Detection",
  not_activated: "Not Activated",
  device_id: "Device Id",
  dummy_device_id: "VBOBCD456",
  network_provider: "Network Provider",
  dummy_network_provider_name: "AT&T",
  set_device_name: "Set display name for the device",
  set_device_name_instruction:
    "Use a nickname to distinguish it from other devices or subscribers",
  device_name_placeholder: "ID#VB0BCD234",
  see_map: "See Map",
  location_history:"Location History",
  device_profile: "Device Profile",
  device_name: "Device Name",
  tap_to_ring: "Ring Device",
  current_device_status: "Status as of ",
  step_counter: "Step Counter",
  number_of_steps: "7542 steps today",
  locate: "Locate My Device",
  battery_life: "Battery Life",
  battery_life_value: "98%",
  get_user_help_guide: "Get User Help Guide",
  emergency_signature_desc:
    'By checking the box below and by submitting this information digitally I acknowledge that I am or that I represent the user of the Bay Alarm Medical Personal Emergency Response System. I have read the agreement terms and conditions which are available in the online dashboard, and pursuant to which Bay has agreed to provide the system and monitoring services, and understand and agree that I am bound by all of the terms and conditions, including the limitation of liability set forth in the paragraph titled "Bay is not an Insurer; Liquidated Damaged; Limitation of Liability." In case of extreme emergency, I hereby authorize any licensed Physician to administer all emergency medical treatment deemed necessary.',
  emergency_contacts: "Emergency Contacts",
  emergency_contacts_desc: "These numbers are used in case of an Emergency.",
  emergency_contacts_second_desc:
    "To edit a contact, tap the pencil icon. To delete a contact, tap the trash icon. To change the priority order, hold and drag the contact up or down.",
  emergency_contacts_guest_desc: "These numbers are used in case of Emergency.",
  select_phone_type: "Select Phone Type",
  emergency_contact_name: "Emergency Contact Name",
  relation_to_the_subscriber: "Relation to the Subscriber",
  residence_details: "Residence Details",
  residence_entry: "Residence Entry",
  township_borough: "Township / Borough",
  jurisdiction: "Jurisdiction",
  cross_street: "Cross Street",
  special_instructions: "Special Instructions",
  spoken_language_inside_home: "Spoken Language Inside Home",
  emergency_textfield_desc: "This helps us ensure efficient services",
  tell_us_details_should_know: "Tell us any details we should know",
  gate_instructions: "Gate Instructions",
  tell_us_pass_codes_get_through: "Tell us any pass codes to get through",
  lock_box_location: "Lock Box Location",
  tell_where_find_it: "Tell us where to find it",
  hidden_key_location: "Hidden Key Location",
  vial_life_location: "Vial of Life Location",
  access_to_residence: "Access To Residence",
  tell_us_any_details: "Tell us any details",
  lock_box_code: "Lock Box Code",
  please_fill_out_applies_subscriber:
    "Please fill out all that applies to subscriber",
  update_code: "Update Code",
  invoice: "Invoice",
  date: "Date",
  total_invoice: "Total",
  status: "Status",
  paid: "Paid",
  unpaid: "Unpaid",
  invoice_history: "Invoice history",
  order_history: "Order History",
  order: "Order",
  subscription: "Subscription",
  total: "Total",
  order_information: "Order Information",
  credit_card_number: "Credit Card Number",
  credit_card: "Credit card",
  bank_account: "Bank Account",
  credit_card_type: "Credit Card Type",
  shipping_method: "Shipping Method",
  payment_method: "Payment Method",
  admin_shipping_method: "Admin Shipping Method",
  total_price: "Total Price",
  grand_total: "Grand Total",
  free: "Free",
  dummy_credit_card_number: "7778-8541-4157-4247",
  business: "Business",
  order_detail: "Order Detail",
  dummy_order_number: "000085112",
  quantity: "Quantity",
  products_ordered: "Products Ordered",
  pay_past_due: "Pay Past Due",
  see_details: "See Details",
  enter_step_goal: "Enter New Step Goal",
  save_goal: "Save Goal",
  ring_device: "Ring Device",
  ring_device_desc:
    "A loud sound will commence. Tap the SOS Elite below to Ring.",
  no_internet: "No Internet",
  check_network_connectivity: "Check Your Network Connectivity",
  payment_change: "Are you sure \nyou want to change the payment method? ",
  yes: "Yes",
  login_caps: "LOGIN",
  re_login: "RE-LOGIN",
  verify: "Verify",
  cannot_access_email: "Can't Access Email?",
  session_time_out:
    "Your session has been timed out, please re-authorize to complete this action",
  cannot_access_relogin: "Can't access email?. Please relogin to continue",
  for_account_security:
    "For account security, user authentication is only valid for 24hours. Re-authenticate with a one-time code sent to your e-mail.",
  sign_out_desc: "Are you sure you want to sign out?",
  empty_invoice: "No Invoice Data Found!",
  empty_order_list: "No Order History Found!",
  empty_notification: "No Notification Found!",
  finger_print_face_id_status: "Finger Print & Face Id Status",
  add_step_goal: "Add step goal",
  emergency_service_default: "**EMERGENCY SERVICES**",
  default: "DEFAULT",
  Biometrics: "Biometrics",
  enable_finger_print_face_id_sign_in:
    "Enable Finger Print and Face ID Sign-In",
  pay: "Pay",
  lock_box_code_security:
    "For your security once the lockbox code is set, it is not shown for security purposes.",
  no_device_available: "No device is associated \n with this subscriber!",
  fetching_location: "we're trying to get a location.",
  must_be_minimum_6: "Must be atleast 6 characters",
  must_be_minimum_8: "Must be atleast 8 characters",
  are_you_sure_to_remove_admin: "Are you sure you want to remove this admin ?",
  delete_admin: "Delete Admin",
  send_feedback: "Send Feedback",
  write_feedback: "Write Feedback",
  feedback_success: "Thank you! Your feedback has been sent to our team.",
  feedback_failure:
    "Sorry, your request cannot be completed. please try again in sometime or email us at @bammedical.com",
  issue_type: "Feedback Type",
  select_issue_type: "Select Feedback Type",
  issue: "Feedback",
  description: "Description",
  upload_media: "Upload Photo/Video",
  upload_media_and_upload: "Select & Upload Media",
  select_issue: "Select Feedback",
  today_steps: "steps today",
  past_steps: "steps at last report",
  call_us_toll_free: "CALL US TOLL FREE",
  try_risk_free: "Try Risk-FREE for 30 Days",
  phone_no: "1 (877) 522-9633",
  acc_holder_email_placeholder_text: "**charlotte_bucket@gmail.com**",
  guest_name_placeholder_text: "Ex: Jane - Senior Care",
  customer_login: "Customer Login",
  code_expires_in: "Code Expires in",
  resend_code_in: "Resend Code in",
  cancel_service_name_placeholder: "**NONNA JOSEPHINE**",
  cs_id_placeholder: "**0002345**",
  cs_phone_number_placeholder: "Account Holder Phone Number",
  ring_device_modal_desc:
    "A loud sound will commentce. Tap the SOS Elite below to Ring.",
  application_issues: "Application Issues",
  account_Issues: "Account Issues",
  general_feedback: "General Feedback",
  customer_acc_details: "Customer Account Details",
  device_and_tracking: "Device & Tracking",
  subscriber_addresses: "Subscriber Addresses",
  account: "Account",
  edit_admin_profile: "Edit Admin Profile",
  no_options_available: "No options available",
  no_history_available: "No history available!",
  active_subscription_changes: "Active Subscription Changes",
  account_holder_information_changes: "Account Holder Information Changes",
  new_orders: "New Orders",
  device_type: "Device Type",
  select_device: "Select Device",
  account_holder_name: 'Account Holder Name',
  bank_account_number: 'Bank Account Number',
  confirm_bank_account_number: 'Confirm Bank Account Number',
  bank_routing_number: 'Bank Routing Number',
  account_holder_details: 'Account Holder Details',
  help_tips_text_1: "Need helpful tips or more information? Tap the",
  help_tips_text_2: "icon wherever available!",
  got_it: "Got it",
  modal_texts: {
    name_email_help_tip:
      "The Account Holder is the primary person responsible for managing all aspects of the account, including billing, subscription updates, and emergency contact management. The name and email provided here will be used for all account-related communications and decisions.",
    mail_address_help_tip:
      "The Mailing Address is where all physical documentation related to the account will be sent. This should be the Account Holder's address, as they are responsible for managing the account and all correspondence related to it.",
    phone_no_help_tip:
      "This phone number is used exclusively for account management purposes. If you'd like to receive notifications for alarm activity including emergency button presses, please update the Emergency Contact list in the Subscriber Profile section.",
    protected_address_help_tip: `The Protected Address is the
subscriber's location where Emergency Services will respond if an alarm is triggered. If the subscriber uses a device with a location tracker, our Monitoring Center will attempt to locate the subscriber using the provided coordinates and dispatch assistance to that location. If no location is found, Emergency Services will default to the Protected Address.`,
    dob_help_tip: "This is an optional field.",
    phone_subscriber_help_tip:
      "These numbers are only used if we need to contact a subscriber directly.",
    emergency_contact_help_tip: `If you'd like a contact to be called before Emergency Services, drag the contact tile above 'Emergency Services' in the list.
Otherwise, Emergency Services will be contacted first, then your listed contacts will be notified afterward.
If you prefer that Hospice be called instead of Emergency Services, please contact us directly at 877-522-9633.`,
    residence_details_help_tip: "Residence details",
    residence_entry_help_tip: `This section provides emergency personnel with the information they need to safely access the home if they are dispatched to the location.
Bay Alarm Medical will always relay this information to your local emergency services, but please note that emergency personnel reserve the right to make their own decisions based on the situation.`,
    logbox_help_tip:
      "Residence entry is used to notify emergency services during dispatch on entering the premises.",
    name_on_card_help_tip:
      "Enter the full name as it appears on the payment account or card. This should be the name of the person or entity responsible for managing payments, ensuring that the billing address and information match the payment method you are using. Incorrect information may cause delays in processing your payment.",
    billing_details_help_tip:
      "Enter the billing address associated with the payment method you're using. This address will only be used for billing and payment processing, and it must match the address on file with your payment provider to avoid any issues with transaction approval.",
    guest_user_help_tip:
      "When setting up a guest user, they will only have permission to view the list of subscribers, devices, ECL list and the signal history for each device. Guest users won't be able to make any changes or updates to the account.",
    device_profile_footer_help_tip: `If the 'Last Refreshed' time doesnt update after attempting to refresh the signal, the device may either be powered off or experiencing a weak cell signal.\n\nTo troubleshoot: Ensure the device is powered on and properly charged. Check your area for sufficient cell signal strength. If these steps don't resolve the issue, please contact us at 877-522-9633 for further assistance.`,
    signal_history: "Signal history details",
  },
  device_status: 'Device Status',
  battery: 'Battery',
  signal: 'Signal',
  timeFormat: "Time Format",
  information_as_of: "Information as of:",
  hang_tight_app_refreshing: "Hang tight! App is Refreshing.",
  time_zone_settings: "Timezone",
  save: "Save",
  timezone_time_format: "Timezone & Time Format",
  device_status_information: "Device status information as of ",
  step_status_information: "Step counter information as of ",
  map_information: "Map information as of ",
  refreshing: "Refreshing...",
  updating: "Updating..."
};
