import React, { useEffect, useState } from "react";
import "./AddEmergencyContactModal.css";
import { Modal } from "react-bootstrap";
import { IconReorder, IconTrash } from "assets/SVGs/SVGs";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";
import CustomInput from "components/widgets/custom-input/CustomInput";
import CustomSelectWithModal from "components/widgets/custom-select-with-modal/CustomSelectWithModal";
import { useDispatch, useSelector } from "react-redux";
import { formatPhoneNumber, isEmpty } from "utility";
import { getPhoneTypeList, setSelectedPhoneType } from "redux/Common/action";
import { getRelationList } from "redux/EmergencyPlan/actions";
import { useHistory } from "react-router-dom";
import alerts from "constants/alerts";
import SnackBar from "components/widgets/snack-bar/SnackBar";

const AddEmergencyContactModal = ({
  isVisible,
  onSave,
  selectedContact,
  onClosePopup,
  onDeleteContact,
}) => {
  const [phoneNumberState, setPhoneNumberState] = useState({
    phoneNumber: "",
    type: "",
    contactName: "",
    relation: "",
  });
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const { phoneTypeList = [], selectedPhoneType } = useSelector(
    (store) => store?.common
  );
  const relationList = useSelector(
    (store) => store?.emergencyData?.relationList
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((store) => store?.auth?.userData);

  useEffect(() => {
    setSnackBarMessage({ message: "" });
    setSelectedPhoneType(dispatch, null);
    getStaticData();
  }, []);

  useEffect(() => {
    if (isVisible) {
      onValueChange("relation", selectedContact?.relation);
      onValueChange("type", selectedPhoneType?.phone_type);
    } else {
      setSnackBarMessage({ message: "" });
    }
  }, [isVisible]);

  useEffect(() => {
    onValueChange("type", selectedPhoneType?.phone_type);
  }, [selectedPhoneType]);

  useEffect(() => {
    onValueChange("phoneNumber", formatPhoneNumber(selectedContact?.phone));
    onValueChange("contactName", selectedContact?.name);
  }, [selectedContact]);

  const getStaticData = () => {
    const reqPayload = {
      dispatch,
      history,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
    };
    getRelationList(reqPayload);
    getPhoneTypeList(reqPayload);
  };

  const onSaveChanges = () => {
    if (isEmpty(phoneNumberState?.phoneNumber)) {
      setSnackBarMessage({ message: alerts?.enter_phone_number });
    } else if (isEmpty(phoneNumberState?.type)) {
      setSnackBarMessage({ message: alerts?.select_type });
    } else if (isEmpty(phoneNumberState?.contactName)) {
      setSnackBarMessage({ message: alerts?.enter_contact_name });
    } else if (isEmpty(phoneNumberState?.relation)) {
      setSnackBarMessage({ message: alerts?.select_relationship });
    } else {
      onSave(phoneNumberState);
    }
  };

  const onValueChange = (type, value) => {
    setPhoneNumberState((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const handlePhoneNumberChange = (inputVal) => {
    const formattedPhoneNumber = formatPhoneNumber(inputVal);
    onValueChange("phoneNumber", formattedPhoneNumber);
  };

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="agu_container"
      show={isVisible}
    >
      <Modal.Body className="agu_body">
        <SnackBar snackBarMessage={snackBarMessage} />
        <div className="agu_header ah_phone_container">
          <IconReorder />
          <p className="display_h5 flex_item2">
            {selectedContact?.phoneNumber
              ? formatPhoneNumber(selectedContact?.phoneNumber)
              : `(555) 555-5555`}
          </p>
          <IconTrash
            onClick={() => {
              onDeleteContact(selectedContact);
            }}
            color="#000000"
            className={"aec_trash_icon"}
          />
        </div>
        <div className="agu_content_section">
          <CustomInput
            type="text"
            className="mb10"
            required
            placeholder={titles?.emergency_contact_name}
            placeholderStyle={"aec_placeholder_style"}
            value={phoneNumberState?.contactName}
            onChange={(e) => {
              onValueChange("contactName", e?.target?.value);
            }}
          />
          <CustomSelectWithModal
            required={true}
            placeholder={titles?.relation_to_the_subscriber}
            selectedValue={phoneNumberState?.relation?.relation}
            className={"mb15"}
            onChange={(value) => {
              onValueChange("relation", value);
            }}
            options={relationList}
            keyValue={"relation"}
          />
          <CustomInput
            type="text"
            className="mb10"
            required
            placeholder={"(555) 555-5555"}
            placeholderStyle={"aec_placeholder_style"}
            value={phoneNumberState?.phoneNumber}
            onChange={(e) => {
              handlePhoneNumberChange(e?.target?.value);
            }}
          />

          <CustomSelectWithModal
            required={true}
            placeholder={titles?.select_phone_type}
            selectedValue={phoneNumberState?.type}
            className={"mb20"}
            onChange={(value) => onValueChange("type", value)}
            options={phoneTypeList}
            keyValue={"phone_type"}
          />
          <CustomButton
            className={"agu_card_btn agu_save_btn"}
            text={titles?.save_changes}
            width={"100%"}
            onClick={() => {
              onSaveChanges();
            }}
          />
          <CustomButton
            className={"agu_card_btn"}
            text={titles?.cancel}
            width={"100%"}
            onClick={onClosePopup}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEmergencyContactModal;
