import React, { useEffect, useRef, useState } from "react";
import "./Alert.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import { ArrowRight, IconInfo, IconReveal } from "assets/SVGs/SVGs";
import InformationModal from "components/layout/information-modal/InformationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationList,
  markAsReadNotification,
} from "redux/Notifications/actions";
import { useHistory } from "react-router-dom";
import { setSelectedDeviceDetails } from "redux/Devices/actions";
import alerts from "constants/alerts";
import { convertInRequiredTimeZone } from "utility";
import { getCustomerTimeZones } from "redux/Common/action";

const Alert = () => {
  const userData = useSelector((store) => store?.auth?.userData);
  const { notificationList, loading, notificationListCount, enableNext } =
    useSelector((store) => store?.notifications);
  const { medicalUserTimezone } = useSelector(
    (store) => store?.common
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const containerRef = useRef(null);

  useEffect(() => {
    getCustomerTimeZones({
      dispatch,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
    });
    getList();
  }, []);

  const getList = (fetchMore = false) => {
    let params = {
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      history,
      dispatch,
      fetchMore,
      page: fetchMore ? notificationList?.length / 10 + 1 : 1,
    };
    getNotificationList(params, false, medicalUserTimezone);
  };

  const handleScroll = () => {
    const container = containerRef?.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 20;
      if (isBottom) {
        if (
          notificationListCount > notificationList?.length &&
          enableNext &&
          !loading
        ) {
          getList(true);
        }
      }
    }
  };

  const onNotificationClick = (notif) => {
    let params = {
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      history,
      dispatch,
      notification_id: notif?.notification_id,
    };
    setSelectedDeviceDetails(dispatch, {
      ...notif,
    });
    markAsReadNotification(params);
  };

  return (
    <MainLayout
      text={titles?.notifications}
      isBackHide={true}
      isSemiHeader={true}
      white={false}
      isLoading={loading}
      isScrollHide={true}
      fullWidthScreen={true}
    >
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className="h160 layout_padding layout_container"
      >
        <div className="alert_cards_container">
          {notificationList && notificationList?.length > 0 ? (
            notificationList?.map((item, index) => {
              return (
                <AlertCard
                  item={item}
                  index={index}
                  onNotificationClick={() => onNotificationClick(item)}
                />
              );
            })
          ) : (
            <p className="body_h1 dl_title mb10">
              {titles?.empty_notification}
            </p>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

const AlertCard = ({ item, index, onNotificationClick }) => {
  const [show, setShow] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const { currentTimeZone } = useSelector((store) => store?.common);

  function processString(isTrue, str) {
    if (isTrue) {
      return str?.slice(10);
    }
    return str;
  }

  return (
    <div className={`card_with_buttons`}>
      <InformationModal
        show={openInfoModal}
        setShow={setOpenInfoModal}
        infoText={item?.alarm_code_tips || alerts?.empty_info}
      />
      <div
        onClick={() => setShow(!show)}
        className={`alert_card_container ${show ? "swiped_card_effect" : ""}`}
      >
        <div className={`alert_left_container`}>
          {!show ? (
            <div
              className={`alert_dot ${
                item?.is_read ? "alert_dot_white_color" : "alert_dot_blue_color"
              }`}
            ></div>
          ) : null}
          <div className="alert_text_container">
            <p className="body_h1 alert_text_color">
              {item?.notification_title}
            </p>
            <p className="body_h1 alert_text_color">
              {processString(
                show,
                `${titles?.subscriber}: ${
                  item?.firstname + " " + item?.lastname
                }`
              )}
            </p>
            <p className="body_h1 alert_text_color alert_date_time_color">
              {convertInRequiredTimeZone(
                item?.created_at,
                item?.medicalUserTimezone,
                currentTimeZone
              )}
            </p>
          </div>
        </div>
        <ArrowRight color="#f3f4f6" />
      </div>

      <div
        className={`info_bg ${
          show ? "alert_detail_show" : "alert_detail_hide"
        }`}
        onClick={() => setOpenInfoModal(true)}
      >
        <IconInfo color={"#FFFFFF"} />
      </div>
      <div
        className={`view_bg ${
          show ? "alert_detail_show" : "alert_detail_hide"
        }`}
        onClick={() => onNotificationClick(item)}
      >
        <IconReveal color={"#FFFFFF"} />
      </div>
    </div>
  );
};

export default Alert;
