import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { updateStepGoal } from "redux/Devices/actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isSessionTimeExpired } from "utility";
import alerts from "constants/alerts";
import SnackBar from "components/widgets/snack-bar/SnackBar";
import Loader from "react-spinners/ClipLoader";
import CustomInput from "components/widgets/custom-input/CustomInput";
import SessionExpiredPopup from "../session-expired-popup/SessionExpiredPopup";

const AddStepGoalModal = ({ show, setShow, currentGoal }) => {
  const [stepCount, setStepCount] = useState("");
  const [verificationPopUp, setVerificationPopUp] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const { selectedDeviceDetails, loading } = useSelector(
    (store) => store?.devices
  );
  const userData = useSelector((store) => store?.auth?.userData);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (show) {
      setStepCount("");
    } else {
      setTimeout(() => {
        setSnackBarMessage({ message: "" });
      }, 2000);
    }
  }, [show]);

  const onClose = () => {
    setShow(false);
  };

  const onSaveChanges = () => {
    let payload = {
      medical_alert_user_id: selectedDeviceDetails?.medical_alert_user_id,
      user_device_id: selectedDeviceDetails?.user_device_id,
      step_goal: parseInt(stepCount),
      userData: userData?.isGuestUser ? "guestuser" : "accountowner",
    };
    updateStepGoal({
      dispatch,
      payload,
      history,
      setSnackBarMessage,
      onClose,
      medical_alert_user_id: selectedDeviceDetails?.medical_alert_user_id,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
    });
  };

  const handleOnAddStepGoal = async () => {
    let timeDifference = await isSessionTimeExpired();

    if (isEmpty(stepCount)) {
      setSnackBarMessage({ message: alerts?.enter_steps });
    } else if (timeDifference && !userData?.isGuestUser) {
      setVerificationPopUp(true);
      return;
    } else {
      onSaveChanges();
    }
  };

  return (
    <>
      <Modal
        backdrop="true"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="edm_container"
        show={show}
      >
        <Modal.Body className="edm_body">
          <SnackBar snackBarMessage={snackBarMessage} />
          {loading ? (
            <div className="layout_loader_container">
              <Loader color={"#248ca7"} />
            </div>
          ) : null}
          <div className="edm_content_section">
            <p className="body_h1 edm_input_label">{titles?.enter_step_goal}</p>
            <CustomInput
              className="edm_input_field mb10"
              type={"number"}
              value={stepCount}
              onChange={(e) => {
                setStepCount(e?.target?.value);
              }}
            />
            <CustomButton
              className={"asg_card_btn edm_save_btn"}
              text={titles?.save_goal}
              width={"100%"}
              onClick={() => handleOnAddStepGoal()}
            />
            <CustomButton
              className={"asg_card_btn"}
              text={titles?.cancel}
              width={"100%"}
              onClick={() => setShow(!show)}
            />
            <p className="current_goal_text body_h1">{`${titles?.current_goal} : ${currentGoal} Steps`}</p>
          </div>
        </Modal.Body>
        {verificationPopUp ? (
          <SessionExpiredPopup
            isVisible={verificationPopUp}
            onClosePopup={(parentPopupHide) => {
              setVerificationPopUp(false);
              if (parentPopupHide) {
                onClose();
              }
            }}
            history={history}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default AddStepGoalModal;
