import React, { useState } from "react";
import "./AdminChangePassword.css";
import CustomInput from "components/widgets/custom-input/CustomInput.js";
import CustomButton from "components/widgets/custom-button/CustomButton.js";
import MainLayout from "components/layout/main-layout/MainLayout.js";
import { titles } from "constants/titles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  checkForSameInputs,
  isEmpty,
  isSessionTimeExpired,
  isValidPassword,
} from "utility";
import { changePassword } from "redux/auth/actions";

const AdminChangePassword = () => {
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const [loginState, setLoginState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const isLoading = useSelector((store) => store?.auth?.loading);
  const histroy = useHistory();
  const dispatch = useDispatch();

  const onResetPassword = async () => {
    let timeDifference = await isSessionTimeExpired();
    if (isEmpty(loginState?.currentPassword)) {
      setSnackBarMessage({ message: "Enter current password." });
    } else if (isEmpty(loginState?.newPassword)) {
      setSnackBarMessage({ message: "Enter new password." });
    } else if (isEmpty(loginState?.confirmPassword)) {
      setSnackBarMessage({ message: "Enter confirm password." });
    } else if (!isValidPassword(loginState?.currentPassword)) {
      setSnackBarMessage({
        message: "CurrentPassword must be minimum 8 characters",
      });
    } else if (!isValidPassword(loginState?.newPassword)) {
      setSnackBarMessage({
        message: "NewPassword must be minimum 8 characters",
      });
    } else if (!isValidPassword(loginState?.confirmPassword)) {
      setSnackBarMessage({
        message: "ConfirmPassword must be minimum 8 characters",
      });
    } else if (
      !checkForSameInputs(loginState?.confirmPassword, loginState?.newPassword)
    ) {
      setSnackBarMessage({
        message: "New password and Confirm new password should be same",
      });
    } else {
      changePassword(
        dispatch,
        {
          existing_password: loginState?.currentPassword,
          new_password: loginState?.newPassword,
        },
        histroy,
        setSnackBarMessage
      );
    }
  };

  const onValueChange = (type, e, isEyeIconChange = false) => {
    let newText = e?.target?.value;
    if (!isEyeIconChange) {
      newText = newText?.replace(/\s/g, "");
    }
    setLoginState((prev) => {
      return { ...prev, [type]: newText };
    });
  };

  return (
    <MainLayout
      text={titles?.change_password}
      isBackHide={false}
      isNavbar={false}
      isLoading={isLoading}
      snackBarMessage={snackBarMessage}
    >
      <div className="acp_container">
        <h1 className="acp_header_text body_h1 mb30">
          {titles?.new_password_must_be_different}
        </h1>
        <CustomInput
          placeholderStyle={"cp_placeholder_style"}
          label={titles?.current_password}
          type="password"
          className="mb22"
          value={loginState?.currentPassword}
          onChange={(value) => {
            onValueChange("currentPassword", value);
          }}
        />
        <CustomInput
          placeholderStyle={"cp_placeholder_style"}
          label={titles?.new_password}
          type="password"
          className="mb22"
          required={true}
          placeholder={titles?.must_be_minimum_8}
          value={loginState?.newPassword}
          onChange={(value) => {
            onValueChange("newPassword", value);
          }}
        />
        <CustomInput
          placeholderStyle={"cp_placeholder_style"}
          label={titles?.confirm_new_password}
          type="password"
          className="mb22"
          required={true}
          placeholder={titles?.must_be_minimum_8}
          value={loginState?.confirmPassword}
          onChange={(value) => {
            onValueChange("confirmPassword", value);
          }}
        />
        <CustomButton
          height={"50px"}
          className={"mb20 blue_button text-white-color"}
          text={titles?.reset_password}
          onClick={() => onResetPassword()}
        />
      </div>
    </MainLayout>
  );
};

export default AdminChangePassword;
