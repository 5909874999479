import React, { useState } from "react";
import "./AccountHolderHome.css";
import CustomButton from "components/widgets/custom-button/CustomButton.js";
import { titles } from "constants/titles";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "utility";
import { IconQuestion, IconSmallQuestion } from "assets/SVGs/SVGs";
import ConfirmationModal from "components/layout/confirmation-modal/ConfirmationModal";

const AccountHolderHome = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const accountHolderData = useSelector(
    (store) => store?.userDetail?.accountHolderData
  );
  const userData = useSelector((store) => store?.auth?.userData);
  const guestUserData = useSelector((store) => store?.guestUser?.guestUserData);
  const { eSignHistory } = useSelector((store) => store?.userDetail);

  const history = useHistory();

  const isEsignHistoryAvailable = () => {
    if (
      eSignHistory?.account_holder_info_changes &&
      eSignHistory?.active_subscription_changes &&
      eSignHistory?.sale_orders
    ) {
      return (
        eSignHistory?.account_holder_info_changes?.length > 0 ||
        eSignHistory?.active_subscription_changes?.length > 0 ||
        eSignHistory?.sale_orders?.length > 0
      );
    }
    return false;
  };

  const onReviewAndSign = () => {
    history.push(ROUTES_CONSTANTS?.reviewAndSign);
  };

  const renderAccountHolderDetail = (title, value, isSeprator) => {
    if (!value) return <></>;
    return (
      <>
        {isSeprator ? <div className="ahh_user_item_seperator" /> : null}
        <div className="ahh_user_item_container">
          <p className="display_h3 ahh_detail_text ahh_margin_right">{title}</p>
          <p className="display_h5 ahh_detail_text ahh_right_text">{value}</p>
        </div>
      </>
    );
  };

  return accountHolderData && Object.keys(accountHolderData)?.length > 0 ? (
    <div className="account_holder_home_container mb10 ahh_detail_container">
      <div className="tip_icon_container">
        <IconQuestion fillColor={"#fff"} className={'view_enable_curser'}
        onClick={()=> setShowConfirmationModal(true)} />
      </div>
      <p className="body_p ahh_detail_text">
        {userData?.isGuestUser ? titles?.welcome_guest : titles?.account_holder}
      </p>
      <p className="ahh_name display_h2 mb10 ahh_detail_text">
        {userData?.isGuestUser
          ? guestUserData?.guest_user_name
          : accountHolderData?.firstname + " " + accountHolderData?.lastname}
      </p>
      {!userData?.isGuestUser ? (
        <>
          <div className="ahh_user_detail_margin">
            {renderAccountHolderDetail(titles?.email, accountHolderData?.email)}
            {renderAccountHolderDetail(
              "Address",
              `${
                accountHolderData?.street
                  ? accountHolderData?.street + `\n`
                  : ""
              }${
                accountHolderData?.apt_no
                  ? accountHolderData?.apt_no + `\n`
                  : ""
              }${
                accountHolderData?.city ? accountHolderData?.city + ", " : ""
              }${
                accountHolderData?.region_code
                  ? accountHolderData?.region_code + " "
                  : ""
              }${
                accountHolderData?.post_code ? accountHolderData?.post_code : ""
              }`,
              true
            )}
            {renderAccountHolderDetail(
              "Phone1",
              formatPhoneNumber(accountHolderData?.phone_one),
              accountHolderData?.phone_one
            )}
            {renderAccountHolderDetail(
              "Phone2",
              formatPhoneNumber(accountHolderData?.phone_two),
              accountHolderData?.phone_two
            )}
          </div>
          <CustomButton
            text={titles?.account_holder_details}
            width={"250px"}
            className={"mb10"}
            onClick={() => history.push(ROUTES_CONSTANTS?.accountHolder)}
          />
          {!userData?.isGuestUser && isEsignHistoryAvailable() ? (
            <CustomButton
              text={titles?.review_and_sign}
              width={"250px"}
              className={
                "caregiver_card_btn mb10 blue_button"
              }
              onClick={() => {
                onReviewAndSign();
              }}
            />
          ) : null}
        </>
      ) : null}
      <ConfirmationModal
          show={showConfirmationModal}
          title={" "}
          description={
            <p>
              {titles.help_tips_text_1 + " "}
              <IconSmallQuestion
                className={'tip_text_icon'}
              />
              {" " + titles.help_tips_text_2}
            </p>
          }
          btnText={titles?.got_it}
          setShow={() => setShowConfirmationModal(false)}
          onClick={() => setShowConfirmationModal(false)}
        />
    </div>
  ) : (
    <></>
  );
  // return accountHolderData && Object.keys(accountHolderData)?.length > 0 ? (
  //   <div className="account_holder_home_container mb10">
  //     <p className="body_p">
  //       {userData?.isGuestUser ? titles?.welcome_guest : titles?.welcome}
  //     </p>
  //     <p className="ahh_name display_h2 mb10">
  //       {userData?.isGuestUser
  //         ? guestUserData?.guest_user_name
  //         : accountHolderData?.firstname + " " + accountHolderData?.lastname}
  //     </p>
  //     <p className="body_h2">{accountHolderData?.email}</p>
  //     <p className="body_h2">
  //       {"Phone1: " +
  //         (accountHolderData?.phone_one
  //           ? formatPhoneNumber(accountHolderData?.phone_one)
  //           : "NA")}
  //     </p>
  //     {accountHolderData?.phone_two ? (
  //       <p className="body_h2 mb10">
  //         {"Phone2: " +
  //           (accountHolderData?.phone_two
  //             ? formatPhoneNumber(accountHolderData?.phone_two)
  //             : "NA")}
  //       </p>
  //     ) : null}

  //     <p className="body_h2">{titles?.mailing_address}</p>
  //     <p className="body_h2">
  //       {accountHolderData && accountHolderData?.street
  //         ? accountHolderData?.street + `\n`
  //         : ""}
  //       {accountHolderData && accountHolderData?.apt_no
  //         ? accountHolderData?.apt_no
  //         : ""}
  //     </p>
  //     <p className="body_h2 mb10">
  //       {accountHolderData?.city +
  //         ", " +
  //         accountHolderData?.region_code +
  //         " " +
  //         accountHolderData?.post_code}
  //     </p>
  //     <CustomButton
  //       text={titles?.edit_details}
  //       width={"250px"}
  //       className={"mb10"}
  //       onClick={() => history.push(ROUTES_CONSTANTS?.accountHolder)}
  //     />
  //     {!userData?.isGuestUser && isEsignHistoryAvailable() ? (
  //       <CustomButton
  //         text={titles?.review_and_sign}
  //         width={"250px"}
  //         className={"caregiver_card_btn mb10"}
  //         onClick={() => {
  //           onReviewAndSign();
  //         }}
  //       />
  //     ) : null}
  //   </div>
  // ) : (
  //   <></>
  // );
};

export default AccountHolderHome;
