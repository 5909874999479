import React from "react";
import "./CustomCheckbox.css";

const CustomCheckbox = ({ checked, onChange, className }) => {
  return (
    <label className={`custom-checkbox ${className}`}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkmark"></span>
    </label>
  );
};

export default CustomCheckbox;
