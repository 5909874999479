import React, { useEffect } from "react";
import "./AboutSetting.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import { ArrowRight } from "assets/SVGs/SVGs";
import { useHistory } from "react-router-dom";
import { getStaticUrlByKey } from "utility";
import { useDispatch, useSelector } from "react-redux";
import { getStaticUrls } from "redux/Common/action";

const AboutSetting = () => {
  const { staticUrls } = useSelector((store) => store?.common);
  const userData = useSelector((store) => store?.auth?.userData);
  const history = useHistory();
  const dispatch = useDispatch();

  const aboutOptionsList = [
    {
      id: 0,
      title: titles?.service_and_support,
      type: "service_and_supports",
      url: "https://www.bayalarmmedical.com/customer-service/",
    },
    {
      id: 1,
      title: titles?.faqs,
      type: "faqs",
      url: "https://www.bayalarmmedical.com/frequently-asked-questions/",
    },
    {
      id: 2,
      title: titles?.help_guides,
      type: "help_guides",
      url: "https://www.bayalarmmedical.com/help-guides/",
    },
    {
      id: 3,
      title: titles?.videos,
      type: "videos",
      url: "https://www.bayalarmmedical.com/videos/",
    },
    {
      id: 4,
      title: titles?.privacy_policy,
      type: "privacy_policies",
      url: "https://www.bayalarmmedical.com/privacy-policy/",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      const params = {
        dispatch,
        userType: userData?.isGuestUser ? "guestuser" : "accountowner",
        history,
      };
      getStaticUrls(params);
    }, 500);
  }, []);

  const onItemPress = async ({ type = "" }) => {
    try {
      const url = getStaticUrlByKey(staticUrls, type);
      window.open(url, "_blank");
    } catch (e) {}
  };

  return (
    <MainLayout
      text={titles?.about}
      isBackHide={false}
      isSemiHeader={true}
      white={false}
    >
      <p className="display_h5 as_version">{titles?.version}</p>
      <p className="body_h1 version_value mb20">4.0</p>
      {aboutOptionsList?.map((item, index) => {
        return (
          <>
            <div
              key={`btn ${index}`}
              className="accordian_btn_container"
              onClick={() => onItemPress(item)}
            >
              <p className="display_h5 settings_text">{item?.title}</p>
              <ArrowRight color="#ffffff" />
            </div>
            {index + 1 < aboutOptionsList?.length ? (
              <div className="horizontal_divider_line"></div>
            ) : null}
          </>
        );
      })}
      <p className="as_copyright_text body_h2 as_bam_text">
        {titles?.bay_alarm_medical}
      </p>
      <h2 className="as_copyright_text body_h2">
        {titles?.all_rights_reserved}
      </h2>
    </MainLayout>
  );
};

export default AboutSetting;
