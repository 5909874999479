import { getApi } from "../../api/commonApi";
import apis from "../../api/apiEndPoints";
import { getData, handleUnAuthorizedAccess, storeData } from "../../utility";
import keys from "../../constants/keys";
import {
  GET_LANGUAGE_SUCCESS,
  COMMON_API_LOADER_VISIBILITY,
  PHONE_TYPE_LIST_SUCCESS,
  SET_SELECTED_PHONE_TYPE,
  GET_REGIONS_SUCCESS,
  DASHBOARD_MENU_LIST_SUCCESS,
  SIDE_MENU_LIST_SUCCESS,
  STATIC_URL_SUCCESS,
  CUSTOMER_TIMEZONE_SUCCESS,
  CURRENT_TIMEZONE,
} from "./actionTypes";
import { BASE_URL } from "constants/routes/api";

export const getLanguages = async ({ dispatch, userType }) => {
  const API = BASE_URL + apis?.languages;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, true);
  getApi(API, accessToken, userType)
    .then((res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, GET_LANGUAGE_SUCCESS, res?.data?.data);
      } else {
        returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, false);
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, false);
    });
};

export const getRegionList = async ({
  dispatch,
  userType,
  is_visible = true,
}) => {
  const API = BASE_URL + apis?.regions + `?is_visible=${is_visible}`;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, true);
  getApi(API, accessToken, userType)
    .then((res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, GET_REGIONS_SUCCESS, res?.data?.data);
      } else {
        returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, false);
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, false);
    });
};

export const getPhoneTypeList = async (params) => {
  const { dispatch, history, userType } = params;
  const API = BASE_URL + apis?.phoneType;
  let accessToken = await getData(keys?.accessTokenKey);

  returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, true);

  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, PHONE_TYPE_LIST_SUCCESS, res?.data?.data);
      } else {
        returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, false);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, false);
    });
};

export const getDashboardMenuList = async (dispatch) => {
  const API = BASE_URL + apis?.dashboardMenuList;

  getApi(API, "", "")
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          DASHBOARD_MENU_LIST_SUCCESS,
          res?.data?.data
        );
      }
    })
    .catch((error) => {});
};

export const getStaticUrls = async (params) => {
  const { dispatch } = params;
  const API = BASE_URL + apis?.getStaticUrl;
  let accessToken = await getData(keys?.accessTokenKey);

  getApi(API, accessToken, params?.userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, STATIC_URL_SUCCESS, res?.data?.data);
      } else {
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(params?.history);
        }
      }
    })
    .catch((error) => {});
};

export const getSideMenuList = async (params) => {
  const { dispatch, history, userType, finger_print } = params;
  const API = BASE_URL + apis?.sideMenuList + `?finger_print=${finger_print}`;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, true);

  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, SIDE_MENU_LIST_SUCCESS, res?.data?.data);
      } else {
        returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, false);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, COMMON_API_LOADER_VISIBILITY, false);
    });
};

export const getCustomerTimeZones = async (params) => {
  const { dispatch, userType } = params;
  const API = BASE_URL + apis?.getCustomerTimezone;
  let accessToken = await getData(keys?.accessTokenKey);
  getApi(API, accessToken, userType)
    .then((res) => {
      if (res?.data?.code === 200) {
        storeData(
          keys?.medicalUserTimezone,
          JSON.stringify(res?.data?.data?.medicalAlertUsers)
        );
        returnToDispatch(
          dispatch,
          CUSTOMER_TIMEZONE_SUCCESS,
          res?.data?.data?.medicalAlertUsers
        );
      }
    })
    .catch((error) => {});
};

export const setCurrentTimeZone = (dispatch, data) => {
  returnToDispatch(dispatch, CURRENT_TIMEZONE, data);
};

export const setSelectedPhoneType = (dispatch, phoneType) => {
  returnToDispatch(dispatch, SET_SELECTED_PHONE_TYPE, phoneType);
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
