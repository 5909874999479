import React from "react";
import "./OrderDetails.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import { useLocation } from "react-router-dom";
import moment from "moment";

const OrderDetails = () => {
  const location = useLocation();
  const orderData = location?.state?.orderDetail;
  const orderDetail = orderData?.order_detail;
  const products = orderData?.products;
  const payment_details = orderData?.payment_details;

  return (
    <MainLayout
      text={titles?.order_detail}
      isBackHide={false}
      isNavbar={true}
      white={true}
    >
      <p className="sd_label_text display_h3 mb20">{`${
        titles?.order
      }:${` ${orderDetail?.order_id}`} `}</p>
      <p className="display_h5 left mb5">{titles?.date}</p>
      <p className="body_h1 left od_value_text mb15">{moment(orderDetail?.date)?.format("YYYY-MM-DD")}</p>
      <p className="display_h5 left mb5">{titles?.subscription}</p>
      <p className="body_h1 left od_value_text mb15">{orderDetail?.subscription}</p>
      <p className="display_h5 left mb5">{titles?.grand_total}</p>
      <p className="body_h1 left od_value_text mb15">{`$${orderDetail?.total_recurring_fees?.toFixed(2)}`}</p>
      <div className="horizontal_divider_line mb15"></div>
      <p className="sd_label_text display_h3 mb20">
        {titles?.products_ordered}
      </p>
      {products?.map((item, index) => {
        return (
          <div className="product_container">
            <p className="display_h5 left mb10">{item?.product_name}</p>
            <div className="product_details_container mb10">
              <p className="body_h1 left od_value_text od_left_spacing">
                {titles?.quantity}
              </p>
              <p className="body_h1 left od_value_text">{item?.product_quantity}</p>
            </div>
            <div className="product_details_container mb10">
              <p className="body_h1 left od_value_text od_left_spacing">
                {titles?.total}
              </p>
              <p className="body_h1 left od_value_text">{`$${item?.total_price?.toFixed(2)}`}</p>
            </div>
          </div>
        );
      })}
      <div className="horizontal_divider_line od_top_margin mb15"></div>
      <p className="sd_label_text display_h3 mb15">{titles?.payment_method}</p>
      <p className="display_h5 left mb5">{titles?.credit_card_type}</p>
      <p className="body_h1 left od_value_text mb15">{payment_details?.card_type || '-'}</p>
      <p className="display_h5 left mb5">{titles?.credit_card_number}</p>
      <p className="body_h1 left od_value_text mb15">{payment_details?.card_number || '-'}</p>
    </MainLayout>
  );
};

export default OrderDetails;
