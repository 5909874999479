const alerts = {
  logout: "Are you sure you want to logout?",
  finger_print_enabled_successfully: "Finger Print Enabled Successfully",
  finger_print_disabled_successfully: "Finger Print Disabled Successfully",
  enable_fingerprint_faceid_username_password:
    "Please enable for Fingerprint/FaceID login once logged using username and password",
  enter_guest_user_name: "Please Enter Guest User Name.",
  enter_guest_user_password: "Please Enter Guest User Password.",
  password_minimum_message: "Password must be minimum 6 characters",
  guest_user_maximum_allowed: "You can add up to 3 guest user only.",
  enter_email: "Please Enter Email.",
  enter_valid_email: "Please Enter Valid Email",
  enter_password: "Please Enter Password",
  enter_otp: "Please Enter OTP.",
  otp_minimum_required: "Otp must be minimum 6 characters",
  invalid_otp: "OTP is invalid. Please enter correct OTP",
  otp_verified: "OTP Verified Successfully.",
  enter_street: "Please Enter Street",
  enter_city: "Please Enter City",
  select_state: "Please Select State",
  enter_zipcode: "Please Enter Zip Code",
  enter_first_name: "Please Enter First Name",
  enter_last_name: "Please Enter Last Name",
  select_bank_account_type: "Please Select Bank Account Type",
  enter_address: "Please Enter Address",
  enter_credit_card_number: "Please Enter Credit Card Number",
  enter_account_number: "Please Enter Account Number",
  enter_account_holder_name: "Please Enter Account Holder Name",
  enter_master_card_detail: "Please Enter Master Card Detail",
  select_exp_month_and_exp_year: "Please Select Expiry Month & Year",
  enter_valid_card_number: "Please enter valid card number",
  enter_cvn_number: "Please Enter CVV Number",
  enter_bank_routing_number: "Please Enter Bank Routing Number",
  valid_bank_routing_number: "Please Enter valid Routing Number",
  valid_bank_account_number: "Please Enter valid Account Number",
  CVN_number_validation: "CVV number must be minimum atleast 3 digit",
  enter_subscriber_name: "Please Enter Subscriber Name",
  please_upload_jpg_png_pdf_file: "Please upload jpg, png, or pdf file",
  enter_account_holder_phone_number: "Please Enter Account Holder Phone Number",
  upload_death_certificate: "Please upload death certificate",
  enter_device_name: "Please Enter Device Name",
  enter_township: "Please Enter Township",
  enter_jurisdiction: "Please Enter Jurisdiction",
  enter_special_instruction: "Please Enter Special Instruction",
  select_language: "Please Select Language",
  enter_gate_instruction: "Please Enter Gate Instruction",
  enter_lock_box_code: "Please Enter Lock Box Code",
  enter_box_location: "Please Enter Box Location",
  enter_key_location: "Please Enter Key Location ",
  enter_life_location: "Please Life Locatio",
  enter_steps: "Please Enter Steps",
  account_details_updated: "Account Details Updated Successfully",
  select_type: "Please Select Type",
  enter_phone_number: "Please Enter Number",
  enter_contact_name: "Please Enter Contact Name",
  select_relationship: "Please Select Relationship",
  emergency_details_updated: "Emergency Details Updated Successfully",
  minimum_one_contact_required: "Minimum one emergency contact is required",
  minimum_one_medical_contact_required: "Minimum one contact is required",
  successfully_ringed: "Successfully Ringed",
  enter_new_password: "Please Enter New Password",
  enter_confirm_password: "Please Enter Confirm Password",
  new_and_confirm_password_should_be_same:
    "New password and Confirm new password should be same",
  current_password_minimum_6: "Current Password must be atleast 6 characters",
  new_password_minimum_6: "New Password must be atleast 6 characters",
  confirm_password_minimum_6: "Confirm Password must be atleast 6 characters",
  select_feedback_type:"Please Select Feedback Type",
  enter_feedback_value:"Please Enter Feedback",
  select_sub_feedback_type:"Please Select Feedback",
  feedback_submission: "Your feedback has been submitted!",
  empty_info:"No info found! ",
  select_sub_feedback_type:"Please Select Feedback",
  empty_info:"No info found! ",
  future_month_year:"Please select future expiry month or year",
  confirm_account_match:
  "Account Number and Confirm Account Number must be the same.",
  future_birth_date:"Invalid birth date. Birth date cannot be in the future. Please choose a date from the past.",
  timezone_settings_updated: "Timezone settings updated successfully."
};
export default alerts;
