import {
  RELATION_LIST_SUCCESS,
  EMERGENCY_CONTACT_LOADER_VISIBILITY,
  GET_MERGENCY_DATA_SUCCESS,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  relationList: [],
  emergencyData: null,
};

const emergencyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMERGENCY_CONTACT_LOADER_VISIBILITY:
      return {
        ...state,
        loading: action?.payload,
      };
    case RELATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        relationList: action?.payload,
      };
    case GET_MERGENCY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        emergencyData: action?.payload,
      };
    default:
      return state;
  }
};

export default emergencyReducer;