import MainLayout from "components/layout/main-layout/MainLayout";
import "./SubscriberProfile.css";
import { titles } from "constants/titles";
import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper";
import {
  ArrowRight,
  IconBilling,
  IconEmergency,
  IconReport,
  IconSmallQuestion,
  IconTracking,
} from "assets/SVGs/SVGs";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import InformationModal from "components/layout/information-modal/InformationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getLanguages,
  getPhoneTypeList,
  getRegionList,
} from "redux/Common/action";
import { getEmergencyData, getRelationList } from "redux/EmergencyPlan/actions";
import { getMedicalAlertUserDetail } from "redux/MedicalAlertUsers/actions";
import { getDeviceList, getDeviceSignalHistory } from "redux/Devices/actions";
import {
  getBankAccountTypes,
  getBillingBankDetail,
  getBillingCardDetail,
  getCardList,
} from "redux/Payments/actions";
import {
  convertInRequiredTimeZone,
  formatDate,
  formatPhoneNumber,
  getSubscriberProtectedAddress,
} from "utility";

const SubscriberProfile = () => {
  const [showEmergencyInfoModal, setShowEmergencyInfoModal] = useState(false);
  const [emergencyContactList, setEmergencyContactList] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const userData = useSelector((store) => store?.auth?.userData);
  const { emergencyData, relationList } = useSelector(
    (store) => store?.emergencyData
  );
  const { loading, medicalUserDetail, selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );
  const { deviceSignalHistory } = useSelector((store) => store?.devices);
  const { cardDetails, cardList, bankDetails, accountTypes } = useSelector(
    (store) => store?.payments
  );
  const { phoneTypeList = [], languageList, currentTimeZone } = useSelector(
    (store) => store?.common
  );
  const allRegionList = useSelector((store) => store?.common?.allRegionList);

  const dispatch = useDispatch();
  const history = useHistory();

  const accordianButtons = [
    {
      name: titles?.invoices_bills,
      onClick: () =>
        history.push(ROUTES_CONSTANTS?.invoicesAndBills, {
          medicalUserDetail: medicalUserDetail,
          cardList: cardList,
        }),
    },
    {
      name: titles?.sales_orders_subscriptions,
      onClick: () => history.push(ROUTES_CONSTANTS?.orderHistory),
    },
    {
      name: titles?.cancel_services,
      onClick: () =>
        history.push(ROUTES_CONSTANTS?.cancelServices, {
          medicalUserData: selectedMedicalUser,
        }),
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    initialStates();
  }, [emergencyData?.emergency_contacts]);

  const getData = () => {
    const param = {
      dispatch,
      history,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      emergency_contract_id: selectedMedicalUser?.service_contract_id,
      medical_alert_user_id: selectedMedicalUser?.medical_alert_user_id,
      user_device_id: undefined,
      setSnackBarMessage,
      is_visible: false,
    };
    getEmergencyData(param);
    getLanguages(param);
    getRelationList(param);
    getPhoneTypeList(param);
    getRegionList(param);
    getMedicalAlertUserDetail(param);
    getDeviceList(param);
    getDeviceSignalHistory(param);
    getBankAccountTypes(param);
    if (!userData?.isGuestUser) {
      getBillingCardDetail(param);
      getBillingBankDetail(param);
      getCardList(param);
    }
  };

  const initialStates = () => {
    let list = emergencyData?.emergency_contacts || [];
    let formattedList = [];
    list?.map((item, index) => {
      const relation = relationList?.filter(
        (obj) => obj?.relation_id === item?.relation_to_user
      );
      const selectedPhoneType = phoneTypeList?.filter(
        (obj) => obj?.primary_phone_type === item?.phone_type_id
      );
      const relationName =
        relation && relation?.length > 0 ? relation[0]?.relation : "";
      const name = item?.first_name + " " + item?.last_name;
      let obj = {
        dragKey: name + index,
        key: index,
        relation: relation && relation?.length > 0 ? relation[0] : undefined,
        phone: item?.primary_number,
        name: name,
        phoneNumber: name + `, ${relationName}` + "\n" + item?.primary_number,
        primary_phone_type:
          selectedPhoneType && selectedPhoneType?.length > 0
            ? selectedPhoneType[0]
            : undefined,
        otherData: item,
        is_delete: false,
      };
      formattedList.push(obj);
    });
    let newList = [];
    formattedList?.map((item) => {
      const isBefore = item?.otherData?.call_before_911;
      if (isBefore) {
        newList?.push(item);
      }
    });
    newList.push({
      is_delete: false,
      key: 10,
      dragKey: 10,
      name: "Default",
      phone: "(123) 456-7890",
      phoneNumber: titles?.emergency_service_default,
      isDefaultPhone: true,
    });
    formattedList?.map((item) => {
      const isBefore = item?.otherData?.call_before_911;
      if (!isBefore) {
        newList?.push(item);
      }
    });
    setEmergencyContactList(newList);
  };

  const regionList = (is_visible = true) => {
    getRegionList({
      dispatch,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      is_visible: is_visible,
    });
  };

  const onEditSubscriberDetail = () => {
    regionList(false);
    history?.push(ROUTES_CONSTANTS?.editSubscriberDetails, {
      medicalUserDetail,
      phoneTypeList,
    });
  };

  const getCardDetail = () => {
    if (!cardDetails?.expiration_year) return;
    return `XXXX-XXXX-XXXX-${cardDetails?.last_4_digits}\nExp: ${
      cardDetails?.expiration_month
    }/${cardDetails?.expiration_year?.toString()?.slice(-2)}`;
  };

  const getBankDetail = () => {
    return `Name on Account: ${bankDetails.name_on_account}\nAccount Number: XXXX${bankDetails?.last_4_digits}\nBank Account Type: ${bankDetails?.display_value}`;
  };

  const protected_address = medicalUserDetail?.protected_address;

  return (
    <MainLayout
      text={titles?.subscriber_hub}
      isBackHide={false}
      isSemiHeader={true}
      white={true}
      isLoading={loading}
      snackBarMessage={snackBarMessage}
    >
      <InformationModal
        show={showEmergencyInfoModal}
        setShow={setShowEmergencyInfoModal}
        infoText={titles?.emergency_info_tooltip}
      />
      <div className="sp_container">
        <CardLayoutWrapper>
          <div className="sp_title_container">
            <p className="display_h2 mb5">
              {medicalUserDetail?.firstname + " " + medicalUserDetail?.lastname}
            </p>
          </div>

          <p className="body_h1 acc_status_text">{`${titles?.account} #${medicalUserDetail?.account_no}`}</p>
          <p className="body_h1 acc_status_text">{`${titles?.status}: ${
            medicalUserDetail?.is_subscription_cancelled ? "Inactive" : "Active"
          }`}</p>
        </CardLayoutWrapper>
        <div className="sp_device_tracking_card mb15">
          <IconTracking />
          <p className="display_h3 sp_tracking_text">
            {titles?.device_and_tracking}
          </p>
          <CustomButton
            text={titles?.see_devices}
            width={"250px"}
            onClick={() => history.push(ROUTES_CONSTANTS?.deviceAndTracking)}
          />
        </div>
        <div className="sp_sub_detail_card mb15">
          {medicalUserDetail?.date_of_birth ? (
            <p className="body_h2">{`DOB: ${formatDate(
              medicalUserDetail?.date_of_birth
            )}`}</p>
          ) : null}
          {protected_address?.mobile ? (
            <p className="body_h2">{`Phone 1: ${formatPhoneNumber(
              protected_address?.mobile
            )}`}</p>
          ) : null}
          {protected_address?.cellphone ? (
            <p className="body_h2 mb10">{`Phone 2: ${formatPhoneNumber(
              protected_address?.cellphone
            )}`}</p>
          ) : null}
          <div className="sp_tittle_icon_container">
            <p className="body_h2">{titles?.protected_address}</p>
            <IconSmallQuestion
              onClick={() => setShowEmergencyInfoModal(true)}
              className={"sp_que_icon"}
            />
          </div>
          <p className="body_h2 mb10 new_line_text">
            {getSubscriberProtectedAddress(protected_address)}
          </p>
          <CustomButton
            text={titles?.edit_subscriber_details}
            width={"250px"}
            onClick={() => onEditSubscriberDetail()}
          />
        </div>
        {userData?.isGuestUser && emergencyContactList?.length <= 0 ? null : (
          <div className="sp_sub_detail_card mb15 ">
            <IconEmergency />
            <p className="display_h3 mb10">{titles?.emergency_plan}</p>
            {emergencyContactList?.map((item, index) => {
              if (item?.isDefaultPhone) {
                return (
                  <p key={`emergency_phone ${index}`} className="body_h2 mb10">
                    {item?.phoneNumber?.trim()}
                  </p>
                );
              }
              const dataToRender =
                item?.name?.trim() +
                ", " +
                item?.relation?.relation +
                "\n" +
                formatPhoneNumber(item?.phone);

              return (
                <p className="body_h2 mb10 new_line_text">{dataToRender}</p>
              );
            })}

            <CustomButton
              text={titles?.edit_plan}
              width={"250px"}
              onClick={() =>
                history.push(ROUTES_CONSTANTS?.emergencyPlan, {
                  medicalUserDetail: medicalUserDetail,
                  emergencyContactList: emergencyContactList,
                  emergencyData,
                  languages: languageList,
                })
              }
            />
          </div>
        )}
        <div className="sp_sub_detail_card mb15">
          <IconReport className={"mb5"} />
          <p className="display_h3 mb10">{titles?.signal_history}</p>
          {deviceSignalHistory?.length > 0 ? (
            <p className="body_h2 mb10 new_line_text">{`Status as of\n${convertInRequiredTimeZone(
              deviceSignalHistory?.[0]?.date,
              selectedMedicalUser?.timezone,
              currentTimeZone
            )}\n${deviceSignalHistory[0]?.device_name}${
              deviceSignalHistory[0]?.signal
                ? `\n${deviceSignalHistory[0]?.signal}`
                : ""
            }`}</p>
          ) : null}
          <CustomButton
            text={titles?.see_all}
            width={"250px"}
            onClick={() =>
              history.push(ROUTES_CONSTANTS?.deviceSignalHistory, {
                isAllSignalHistory: true,
              })
            }
          />
        </div>
        {!userData?.isGuestUser ? (
          <>
            <div className="sp_sub_detail_card mb15">
              <IconBilling className={"mb5"} />
              <p className="display_h3 mb10">{titles?.billing_details}</p>
              {medicalUserDetail?.payment_method_type == "bank" &&
              bankDetails ? (
                <p className="body_h2 new_line_text mb10">{getBankDetail()}</p>
              ) : cardDetails ? (
                <div className="sp_card_detail_container mb10">
                  <img
                    src={cardDetails?.image_url}
                    alt=""
                    className="sdc_device_image"
                  />
                  <p className="body_h2 new_line_text">{getCardDetail()}</p>
                </div>
              ) : null}
              <CustomButton
                text={
                  cardDetails || bankDetails
                    ? titles?.edit_bank
                    : titles?.add_bank
                }
                width={"250px"}
                onClick={() =>
                  history.push(ROUTES_CONSTANTS?.billingDetails, {
                    medicalUserDetail: medicalUserDetail,
                    payments: {
                      cardList,
                      cardDetails,
                      bankDetails,
                      accountTypes,
                    },
                    allRegionList,
                  })
                }
              />
            </div>
            <div className="sp_shipping_address_card mb15">
              <p className="display_h3 mb10">{titles?.shipping_address}</p>
              <p className="body_h2 mb10 new_line_text">
                {getSubscriberProtectedAddress(
                  medicalUserDetail?.shipping_address
                )}
              </p>
              <CustomButton
                text={titles?.edit}
                width={"250px"}
                onClick={() =>
                  history.push(ROUTES_CONSTANTS?.shippingDetails, {
                    medicalUserDetail: medicalUserDetail,
                  })
                }
              />
            </div>
            <div className="dp_btn_container">
              {accordianButtons?.map((data, index) => {
                return (
                  <>
                    <div
                      key={`accordian_btns-${index}`}
                      className="accordian_btn_container"
                      onClick={data?.onClick}
                    >
                      <p className="display_h5">{data?.name}</p>
                      <ArrowRight />
                    </div>
                    <div
                      className="horizontal_divider_line"
                      key={`tab ${index}`}
                    ></div>
                  </>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </MainLayout>
  );
};

export default SubscriberProfile;
