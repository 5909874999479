import React, { useEffect, useState } from "react";
import "./AccountHolder.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper";
import CustomInput from "components/widgets/custom-input/CustomInput";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { Link } from "react-router-dom";
import CustomDraggableCards from "components/layout/custom-draggable-cards/CustomDraggableCards";
import AddAccountContactModal from "components/layout/add-account-contact-modal/AddAccountContactModal";
import ConfirmationModal from "components/layout/confirmation-modal/ConfirmationModal";
import { IconQuestion, IconTrash } from "assets/SVGs/SVGs";
import CustomSelectBox from "components/widgets/custom-select-box/CustomSelectBox";
import {
  getPhoneTypeList,
  getRegionList,
  setSelectedPhoneType,
} from "redux/Common/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAllRegionListNames,
  getDataFromId,
  isEmpty,
  isSessionTimeExpired,
} from "utility";
import alerts from "constants/alerts";
import { updateAccountOwnerDetails } from "redux/UserDetails/actions";
import ChangesSavedModal from "components/layout/changes-saved-modal/ChangesSavedModal";
import { setSessionExpiryModalVisibility } from "redux/auth/actions";
import InformationModal from "components/layout/information-modal/InformationModal";

const AccountHolder = () => {
  const [accountHolderState, setAccountHolderState] = useState({
    email: "",
    street: "",
    zip_code: "",
    city: "",
    apt: "",
    state: "",
    loading: false,
  });
  const [deleteContactModal, setDeleteContactModal] = useState(false);
  const [changesSaveModal, setChangesSaveModal] = useState(false);
  const [contactModal, setContactModal] = useState({ mode: "" });
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const [infoModalPopup, setInfoModalPopup] = useState();
  const { selectedPhoneType, phoneTypeList = [] } = useSelector(
    (store) => store?.common
  );
  const allRegionList = useSelector((store) => store?.common?.allRegionList);
  const { accountHolderData, loading } = useSelector(
    (store) => store?.userDetail
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [phoneList, setPhoneList] = useState([]);

  useEffect(() => {
    getRegionList({
      dispatch,
      userType: "accountowner",
    });
    getPhoneTypeList({
      dispatch,
      history,
      userType: "accountowner",
    });
  }, []);

  useEffect(() => {
    if (accountHolderData?.firstname?.length > 0) {
      setAccountHolderState({
        ...accountHolderState,
        email: accountHolderData?.email ?? "",
        street: accountHolderData?.street,
        apt: accountHolderData?.apt_no,
        city: accountHolderData?.city ?? "",
        state: getRegionName(accountHolderData?.region_id),
        zip_code: accountHolderData?.post_code ?? "",
      });
      let cloneData = [];
      const phoneType1 = getDataFromId(
        phoneTypeList,
        "phone_type_id",
        accountHolderData?.phone_type_one
      );
      const phoneType2 = getDataFromId(
        phoneTypeList,
        "phone_type_id",
        accountHolderData?.phone_type_two
      );
      if (accountHolderData?.phone_one) {
        cloneData?.push({
          phoneNumber: accountHolderData?.phone_one,
          id: 0,
          type: phoneType1 && phoneType1?.length > 0 ? phoneType1[0] : "",
          auto_notify:
            accountHolderData?.is_phone_one_auto_notify === 0 ? true : false,
        });
      }
      if (accountHolderData?.phone_two) {
        cloneData?.push({
          phoneNumber: accountHolderData?.phone_two,
          id: 1,
          type: phoneType2 && phoneType2?.length > 0 ? phoneType2[0] : "",
          auto_notify:
            accountHolderData?.is_phone_two_auto_notify === 0 ? true : false,
        });
      }
      setPhoneList(cloneData);
    }
  }, [accountHolderData, allRegionList]);

  const getRegionName = (id) => {
    let preSelectedRegionName = "";
    allRegionList?.filter((ele) => {
      if (parseInt(ele?.region_id) === parseInt(id)) {
        preSelectedRegionName = ele?.region_code;
      }
    });

    return preSelectedRegionName;
  };

  const getRegionId = (name) => {
    let regionId = null;

    allRegionList?.filter((ele) => {
      if (ele?.region_code === name) {
        regionId = ele?.region_id;
      }
    });
    return parseInt(regionId);
  };

  const onValueChange = (type, value) => {
    setAccountHolderState((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const onSubmit = async () => {
    let timeDifference = await isSessionTimeExpired();
    if (isEmpty(accountHolderState?.street)) {
      setSnackBarMessage({ message: alerts?.enter_street });
    } else if (isEmpty(accountHolderState?.city)) {
      setSnackBarMessage({ message: alerts?.enter_city });
    } else if (isEmpty(accountHolderState?.state)) {
      setSnackBarMessage({ message: alerts?.select_state });
    } else if (isEmpty(accountHolderState?.zip_code)) {
      setSnackBarMessage({ message: alerts?.enter_zipcode });
    } else if (phoneList?.length === 0) {
      setSnackBarMessage({
        message: alerts?.minimum_one_medical_contact_required,
      });
      return;
    } else if (timeDifference) {
      setSessionExpiryModalVisibility(dispatch, true);
      return;
    } else {
      let payload = {
        mailing_address: accountHolderState?.street,
        apt_no: accountHolderState?.apt,
        mailing_region_id: getRegionId(accountHolderState?.state),
        mailing_city: accountHolderState?.city,
        mailing_postcode: accountHolderState?.zip_code,
        phone_one: phoneList[0]?.phoneNumber ?? false,
        phone_two: phoneList[1]?.phoneNumber ?? null,
        phone_type_one:
          phoneList[0]?.type?.phone_type_id ?? phoneTypeList[0]?.phone_type_id,
        phone_type_two: phoneList[1]?.type?.phone_type_id ?? null,
        name: accountHolderData?.firstname + " " + accountHolderData?.lastname,
        reference_phone_type_one:
          phoneList[0]?.type?.reference_phone_type_id ??
          phoneTypeList[0]?.reference_phone_type_id,
        reference_phone_type_two:
          phoneList[1]?.type?.reference_phone_type_id ??
          phoneTypeList[0]?.reference_phone_type_id,
        is_phone_one_auto_notify: phoneList[0]?.auto_notify ? false : true,
        is_phone_two_auto_notify: phoneList[1]?.auto_notify ? false : true,
      };

      updateAccountOwnerDetails(
        dispatch,
        payload,
        history,
        setSnackBarMessage,
        setChangesSaveModal
      );
    }
  };

  const onAddContact = () => {
    setSelectedPhoneType(dispatch, "");
    setContactModal({ mode: "add" });
  };

  const onRemoveContact = () => {
    const deletedItem = deleteContactModal?.item;
    if (deletedItem) {
      const deletedList = phoneList?.filter((item) => item !== deletedItem);
      setPhoneList([...deletedList]);
    }
    setDeleteContactModal(false);
  };

  const handleDropdownSelect = (selectedOption) => {
    onValueChange("state", selectedOption);
  };

  const onPhoneNumberSave = (item) => {
    const phoneId = contactModal?.id;
    if (phoneId || phoneId == 0) {
      const selectedPhoneId = phoneList?.findIndex(
        (item) => item?.id === phoneId
      );
      if (selectedPhoneId > -1) {
        let selectedPhone = phoneList[selectedPhoneId];
        selectedPhone = {
          ...selectedPhone,
          phoneNumber: item?.phoneNumber,
          type: selectedPhoneType,
          auto_notify: item?.isAcceptSignature,
        };
        phoneList[selectedPhoneId] = selectedPhone;
        setPhoneList([...phoneList]);
      }
    } else {
      let list = phoneList;
      let obj = {
        id: phoneList?.length,
        phoneNumber: item?.phoneNumber,
        type: selectedPhoneType,
        auto_notify: item?.isAcceptSignature,
      };
      list.push(obj);
      setPhoneList([...list]);
    }
    setContactModal({ mode: "" });
  };

  const showModal = (description) => {
    setInfoModalPopup({
      description,
      isVisible: true,
    });
  };

  return (
    <MainLayout
      text={titles?.account_holder}
      isBackHide={false}
      isNavbar={true}
      white={true}
      isLoading={loading}
      snackBarMessage={snackBarMessage}
    >
      <ChangesSavedModal
        show={changesSaveModal}
        setShow={setChangesSaveModal}
      />
      <AddAccountContactModal
        show={contactModal?.mode !== ""}
        setShow={setContactModal}
        onClose={() => setContactModal({ mode: "" })}
        onSave={(item) => {
          onPhoneNumberSave(item);
        }}
        phoneInfo={contactModal?.item}
        onDeleteContact={(item) => {
          setDeleteContactModal({ item: item });
          setContactModal({ mode: "" });
        }}
      />
      <ConfirmationModal
        show={deleteContactModal?.item ? true : false}
        setShow={setDeleteContactModal}
        svgIcon={<IconTrash color="#000000" />}
        title={titles?.delete_contact}
        description={titles?.are_you_sure_to_remove_number}
        btnText={titles?.remove}
        onClick={() => onRemoveContact()}
      />
      <InformationModal
        show={infoModalPopup?.isVisible}
        setShow={setInfoModalPopup}
        infoText={infoModalPopup?.description}
      />
      <CardLayoutWrapper className={"ah_image_position"}>
        <div className="sp_title_container">
          <p className="display_h2 mb5">
            {accountHolderData?.firstname + " " + accountHolderData?.lastname}
          </p>
        </div>
      </CardLayoutWrapper>
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb10 mr5">
          {titles?.name_email}
        </p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() => showModal(titles?.modal_texts?.name_email_help_tip)}
        />
      </div>
      <p className="body_h1 left mb10">
        {titles?.please_call_to_name_email}{" "}
        <Link className="web_link" to={ROUTES_CONSTANTS?.helpCenter}>
          {titles?.contact_us}
        </Link>
      </p>
      <CustomInput
        disabled
        value={
          `*${
            accountHolderData?.firstname + " " + accountHolderData?.lastname
          }*` || ""
        }
        type="text"
        className="mb10"
      />
      <CustomInput
        disabled
        value={`*${accountHolderData?.email}*` || ""}
        type="text"
        className="mb15"
      />
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb15 mr5">
          {titles?.mailing_address}
        </p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() => showModal(titles?.modal_texts?.mail_address_help_tip)}
        />
      </div>
      <CustomInput
        placeholderStyle={"acc_holder_placeholder"}
        placeholder={titles?.street}
        type="text"
        required
        className="mb10 ah_input_style"
        value={accountHolderState?.street}
        onChange={(e) => onValueChange("street", e?.target?.value)}
      />
      <CustomInput
        placeholderStyle={"acc_holder_placeholder"}
        placeholder={titles?.apt}
        type="text"
        className="mb10 ah_input_style"
        value={accountHolderState?.apt}
        onChange={(e) => onValueChange("apt", e?.target?.value)}
      />
      <CustomInput
        placeholderStyle={"acc_holder_placeholder"}
        placeholder={titles?.city}
        required
        type="text"
        className="mb10 ah_input_style"
        value={accountHolderState?.city}
        onChange={(e) => onValueChange("city", e?.target?.value)}
      />
      <div className="sd_fields_container mb10">
        <CustomSelectBox
          required={true}
          options={getAllRegionListNames(allRegionList)}
          title={titles?.state}
          selectedValue={accountHolderState?.state}
          onChange={(item) => handleDropdownSelect(item?.value)}
        />
      </div>
      <CustomInput
        placeholderStyle={"acc_holder_placeholder"}
        placeholder={titles?.zip_code}
        type="number"
        required
        className={"ah_input_style mb15"}
        value={accountHolderState?.zip_code}
        onChange={(e) => onValueChange("zip_code", e?.target?.value)}
      />
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb15 mr5">{titles?.phone}</p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() => showModal(titles?.modal_texts?.phone_no_help_tip)}
        />
      </div>
      <p className="body_h1 left mb10">
        {titles?.numbers_used_fo_account_management}
      </p>
      <p className="body_h1 left mb15">{titles?.hold_drag_item_desc}</p>
      <CustomDraggableCards
        className={"mb15"}
        initialItems={phoneList}
        onDelete={(item) => setDeleteContactModal({ item: item })}
        onEdit={(item) => {
          setSelectedPhoneType(dispatch, item?.type);
          setContactModal({ mode: "edit", item: item, id: item?.id });
        }}
        onDragEnd={(data) => setPhoneList(data)}
      />
      {phoneList?.length < 2 ? (
        <p
          className="ah_add_contact_text display_h5 mb22"
          onClick={() => onAddContact()}
        >
          {titles?.add_contact}
        </p>
      ) : null}
      <CustomButton
        width={"100%"}
        height={"50px"}
        className={"sd_submit_btn"}
        text={titles?.submit}
        onClick={() => onSubmit()}
      />
    </MainLayout>
  );
};

export default AccountHolder;
