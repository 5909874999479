import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import React, { useEffect, useState } from "react";
import "./EmergencyPlan.css";
import {
  IconHide,
  IconQuestion,
  IconReveal,
  IconSmallQuestion,
  IconTrash,
} from "assets/SVGs/SVGs";
import InformationModal from "components/layout/information-modal/InformationModal";
import CustomDraggableCards from "components/layout/custom-draggable-cards/CustomDraggableCards";
import ConfirmationModal from "components/layout/confirmation-modal/ConfirmationModal";
import AddEmergencyContactModal from "components/layout/add-emergency-contact-modal/AddEmergencyContactModal";
import CustomInput from "components/widgets/custom-input/CustomInput";
import CustomButton from "components/widgets/custom-button/CustomButton";
import CustomCheckbox from "components/widgets/custom-checkbox/CustomCheckbox";
import CustomSelectBox from "components/widgets/custom-select-box/CustomSelectBox";
import { updateEmergencyPlan } from "redux/EmergencyPlan/actions";
import {
  formatDate,
  formatPhoneNumber,
  getFirstAndLastName,
  getLanguageId,
  getSubscriberProtectedAddress,
  isSessionTimeExpired,
} from "utility";
import alerts from "constants/alerts";
import { getLanguages, setSelectedPhoneType } from "redux/Common/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ChangesSavedModal from "components/layout/changes-saved-modal/ChangesSavedModal";
import { setSessionExpiryModalVisibility } from "redux/auth/actions";

const EmergencyPlan = () => {
  const [popupMode, setPopUpMode] = useState({ mode: "" });
  const [emergencyPlanState, setEmergencyPlanState] = useState({
    isAcceptSignature: false,
  });
  const [contactList, setContactList] = useState([]);
  const [emergencyDetailState, setEmergencyDetailState] = useState({
    township: "",
    jurisdiction: "",
    street: "",
    spacialInstruction: "",
    language: "",
    gateInstruction: "",
    lockBoxCode: "",
    isLockBox: true,
    boxLocation: "",
    keyLocation: "",
    lifeLocation: "",
    accessToResidence: "",
    isShowLockBoxCode: false,
    showPasswordIcon: true
  });
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const [hasTypedOnce, setHasTypedOnce] = useState(false);
  const [infoModalPopup, setInfoModalPopup] = useState();
  const { loading } = useSelector((store) => store?.emergencyData);
  const selectedPhoneType = useSelector(
    (store) => store?.common?.selectedPhoneType
  );
  const { accountHolderData } = useSelector((store) => store?.userDetail);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const emergencyContactList = location?.state?.emergencyContactList;
  const languages = location?.state?.languages;
  const emergencyData = location?.state?.emergencyData;
  const selectedMedicalUser = useSelector(
    (store) => store?.medicalAlertUsers?.selectedMedicalUser
  );
  const medicalUserDetail = location?.state?.medicalUserDetail;

  useEffect(() => {
    getLanguages({
      dispatch,
      userType: "accountowner",
    });
    setContactList(emergencyContactList);
  }, []);

  useEffect(() => {
    if (emergencyData) {
      const { language, vial_of_life, emergency_access } = emergencyData;
      const l = languages?.filter(
        (item) => item?.language_id === language?.primary_language
      );
      let selectedLanguage = "";
      if (l && l?.length > 0) {
        selectedLanguage = l[0];
      }
      setEmergencyDetailState({
        ...emergencyPlanState,
        township: emergency_access?.township_borough,
        jurisdiction: emergency_access?.jurisdiction_info,
        street: emergency_access?.cross_street,
        spacialInstruction: emergency_access?.special_instruction,
        language: selectedLanguage,
        gateInstruction: emergency_access?.gate_code,
        lockBoxCode: emergency_access?.lock_box_code,
        boxLocation: emergency_access?.lock_box_location,
        keyLocation: emergency_access?.hidden_key,
        lifeLocation: vial_of_life?.location_of_vial_of_life,
        accessToResidence: emergency_access?.access_to_residence,
      });
    }
  }, [emergencyData]);

  const onRemoveContact = () => {
    const indexToDelete = contactList?.findIndex(
      (item) => item?.key === popupMode?.item?.key
    );

    let cloneData = [...contactList];
    if (cloneData[indexToDelete]) {
      cloneData[indexToDelete].is_delete = true;
      setContactList(cloneData);
      setPopUpMode({ mode: "", item: null });
    }
  };

  const onAccept = async () => {
    let timeDifference = await isSessionTimeExpired();
    if (timeDifference) {
      setSessionExpiryModalVisibility(dispatch, true);
      return;
    } else if (contactList?.length === 0) {
      setSnackBarMessage({ message: alerts?.minimum_one_contact_required });
      return;
    } else {
      const indexOfDefaultContact = contactList?.findIndex(
        (item) => item?.isDefaultPhone == true
      );

      const formattedContact = contactList?.map((ele, index) => {
        if (ele?.isDefaultPhone) {
          return { isDefaultPhone: true };
        }
        return {
          isDefaultPhone: false,
          id: ele?.otherData?.id > 0 ? ele?.otherData?.id?.toString() : "new",
          contact_order: ele?.otherData?.contact_order
            ? ele?.otherData?.contact_order * 10
            : null,
          first_name: getFirstAndLastName(ele?.name, "firstName"),
          last_name: getFirstAndLastName(ele?.name, "lastName"),
          has_keys: true,
          is_delete: ele?.is_delete ? ele?.is_delete : false,
          relation_to_user: ele?.relation?.relation_id?.toString(),
          call_before_911: index > indexOfDefaultContact ? false : true,
          primary_phone_id: ele?.otherData?.primary_phone_id
            ? ele?.otherData?.primary_phone_id?.toString()
            : "new",
          primary_number: ele?.phone,
          primary_phone_type:
            ele?.primary_phone_type?.phone_type_id?.toString(),
          primary_okay_to_text: false,
          primary_delete: false,
          secondary_phone_id: "",
          secondary_number: "",
          secondary_phone_type: "",
          secondary_okay_to_text: false,
          secondary_delete: false,
        };
      });
      let contactDataToSend = formattedContact?.filter(
        (item) => item?.isDefaultPhone != true
      );
      contactDataToSend = contactDataToSend?.map((item, index) => {
        return {
          ...item,
          contact_order: (index + 1) * 10,
        };
      });
      let payload = {
        emergency_contract_id:
          selectedMedicalUser?.service_contract_id?.toString(),
        cif_signature: "cif_signature",
        lock_box_code: emergencyDetailState?.lockBoxCode
          ? emergencyDetailState?.lockBoxCode
          : undefined,
        lock_box_code_change: 0,
        lock_box_location: emergencyDetailState?.boxLocation,
        gate_code: emergencyDetailState?.gateInstruction,
        home_security_alarm_code: 0,
        add_other_access_info: "",
        access_to_residence: emergencyDetailState?.accessToResidence,
        location_of_vial_of_life: emergencyDetailState?.lifeLocation,
        primary_language:
          emergencyDetailState?.language?.language_id > 0
            ? emergencyDetailState?.language?.language_id?.toString()
            : getLanguageId(languages, emergencyDetailState?.language),
        township_borough: emergencyDetailState?.township,
        jurisdiction_info: emergencyDetailState?.jurisdiction,
        cross_street: emergencyDetailState?.street,
        special_instruction: emergencyDetailState?.spacialInstruction,
        hidden_key: emergencyDetailState?.keyLocation,
        emergency_contacts: contactDataToSend,
      };
      updateEmergencyPlan(
        dispatch,
        payload,
        setSnackBarMessage,
        history,
        setPopUpMode
      );
    }
  };

  const handleFieldChange = (key, value) => {
    setEmergencyPlanState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onEditContact = (item) => {
    setSelectedPhoneType(dispatch, item?.primary_phone_type);
    setPopUpMode({ mode: "editContact", item: item });
  };

  const onAddContact = () => {
    setSelectedPhoneType(dispatch, null);
    setPopUpMode({ mode: "addContact", item: null });
  };

  const onDeleteContact = (item) => {
    setPopUpMode({ mode: "deleteContact", item: item });
  };

  const handleResidanceFieldChange = (type, value) => {
    if (type === "isLockBox" && value === true) {
      const isOriginalCode =
        emergencyDetailState?.lockBoxCode ===
        emergencyData?.emergency_access?.lock_box_code
          ? true
          : false;
      setEmergencyDetailState((prev) => {
        return {
          ...prev,
          [type]: value,
          ...(isOriginalCode ? { lockBoxCode: "" } : {}),
        };
      });
    } else if (type === "lockBoxCode") {
      if (value?.length <= 5) {
        setEmergencyDetailState((prev) => {
          return { ...prev, [type]: value };
        });
      }
    } else {
      setEmergencyDetailState((prev) => {
        return { ...prev, [type]: value };
      });
    }
  };

  const onLockBoxIconClick = () => {
    if (emergencyDetailState?.isLockBox) {
      setEmergencyDetailState((prev) => {
        return {
          ...prev,
          isShowLockBoxCode: !prev?.isShowLockBoxCode,
          showPasswordIcon: !prev?.showPasswordIcon,
        };
      });
    } else {
      showModal(titles?.lock_box_code_security);
    }
  };

  const handleDropdownSelect = (selectedOption) => {
    setEmergencyDetailState((prev) => {
      return { ...prev, language: selectedOption };
    });
  };

  const modifyTheContent = (data, nameUpdate, relationUpdate, phoneUpdate) => {
    let inputString = data;
    let parts = inputString.split(",");
    let firstPart = parts[0];
    let secondPart = parts[1].split("\n")[0];
    let thirdPart = parts[1].split("\n")[1];

    if (nameUpdate) {
      firstPart = nameUpdate;
    }
    if (relationUpdate) {
      secondPart = relationUpdate;
    }
    if (phoneUpdate) {
      thirdPart = phoneUpdate;
    }
    let concludedString = firstPart + ", " + secondPart + "\n" + thirdPart;
    return concludedString;
  };

  const onEmergencyContactSave = (item) => {
    const phoneId = popupMode?.item?.key;
    if (phoneId || phoneId == 0) {
      const selectedPhoneId = contactList?.findIndex(
        (item) => item?.key === phoneId
      );
      if (selectedPhoneId > -1) {
        let selectedPhone = contactList[selectedPhoneId];
        selectedPhone = {
          ...selectedPhone,
          phone: item?.phoneNumber,
          phoneNumber: modifyTheContent(
            selectedPhone?.phoneNumber,
            item?.contactName,
            item?.relation?.relation,
            item?.phoneNumber
          ),
          name: item?.contactName,
          relation: item?.relation,
          primary_phone_type: selectedPhoneType,
        };
        contactList[selectedPhoneId] = selectedPhone;
        setContactList(contactList);
      }
    } else {
      let list = JSON.parse(JSON.stringify(contactList));
      let obj = {
        key: contactList?.length,
        phoneNumber:
          item?.contactName +
          ", " +
          item?.relation?.relation +
          "\n" +
          item?.phoneNumber,
        name: item?.contactName,
        phone: item?.phoneNumber,
        relation: item?.relation,
        primary_phone_type: selectedPhoneType,
      };
      if (obj?.primary_phone_type?.phone_type_code === "ECV") {
        const indexOfDefaultContact = list?.findIndex(
          (item) => item?.isDefaultPhone === true
        );
        list?.splice(indexOfDefaultContact, 0, obj);

        setContactList(list);
      } else {
        list.push(obj);
        setContactList(list);
      }
    }
    setPopUpMode({ mode: "" });
  };

  const renderPhoneList = () => {
    const updatedArrayForAddContact = contactList?.filter(
      (obj) => !obj.is_delete
    );
    return (
      <>
        <CustomDraggableCards
          className={"mb15"}
          initialItems={updatedArrayForAddContact}
          onDelete={(item) => onDeleteContact(item)}
          onEdit={(item) => {
            onEditContact(item);
          }}
          isFormat={false}
          onDragEnd={(data) => {
            setContactList(data);
          }}
        />
        {!emergencyData?.max_contact_limit ||
        emergencyData?.max_contact_limit >=
          updatedArrayForAddContact?.length ? (
          <p
            className="ah_add_contact_text display_h5 mb22"
            onClick={() => onAddContact()}
          >
            {titles?.add_contact}
          </p>
        ) : null}
      </>
    );
  };

  const showModal = (description) => {
    setInfoModalPopup({
      description,
      isVisible: true,
    });
  };

  const protected_address = medicalUserDetail?.protected_address;
  return (
    <MainLayout
      text={titles?.emergency_plan}
      isBackHide={false}
      isNavbar={true}
      isLoading={loading}
      snackBarMessage={snackBarMessage}
      white
    >
      <AddEmergencyContactModal
        isVisible={
          popupMode?.mode === "addContact" || popupMode?.mode === "editContact"
        }
        onSave={(item) => {
          onEmergencyContactSave(item);
        }}
        selectedContact={popupMode?.item}
        onClosePopup={() => setPopUpMode({ mode: "" })}
        onDeleteContact={(item) => {
          onDeleteContact(item);
        }}
      />

      <ChangesSavedModal
        show={popupMode?.mode === "changesSaved"}
        onClose={() => setPopUpMode({ mode: "" })}
      />
      <InformationModal
        show={infoModalPopup?.isVisible}
        setShow={setInfoModalPopup}
        infoText={infoModalPopup?.description}
      />
      <ConfirmationModal
        show={popupMode?.mode === "deleteContact"}
        setShow={setPopUpMode}
        svgIcon={<IconTrash color="#000000" />}
        title={titles?.delete_contact}
        description={titles?.are_you_sure_to_remove_number}
        btnText={titles?.remove}
        onClick={() => onRemoveContact()}
      />
      <CardLayoutWrapper>
        <div className="sp_title_container">
          <p className="display_h2 mb5">
            {medicalUserDetail?.firstname + " " + medicalUserDetail?.lastname}
          </p>
        </div>
        <p className="body_h1 acc_status_text">{`${titles?.account} #${medicalUserDetail?.account_no}`}</p>
        <p className="body_h1 acc_status_text">{`${titles?.status}: ${
          medicalUserDetail?.is_subscription_cancelled ? "Inactive" : "Active"
        }`}</p>
      </CardLayoutWrapper>
      <div className="sp_sub_detail_card mb20">
        {medicalUserDetail?.date_of_birth ? (
          <p className="body_h2">
            {medicalUserDetail?.date_of_birth
              ? `DOB: ${formatDate(medicalUserDetail?.date_of_birth)}`
              : ""}
          </p>
        ) : null}
        {protected_address?.mobile ? (
          <p className="body_h2">{`Phone 1: ${formatPhoneNumber(
            protected_address?.mobile
          )}`}</p>
        ) : null}
        {protected_address?.cellphone ? (
          <p className="body_h2 mb10">{`Phone 2: ${formatPhoneNumber(
            protected_address?.cellphone
          )}`}</p>
        ) : null}
        <div className="sp_tittle_icon_container">
          <p className="body_h2">{titles?.protected_address}</p>
          <IconSmallQuestion
            onClick={() => showModal(titles?.emergency_info_tooltip)}
            className={"sp_que_icon"}
          />
        </div>
        <p className="body_h2 new_line_text">
          {getSubscriberProtectedAddress(protected_address)}
        </p>
      </div>
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb15 mr5">
          {titles?.emergency_contacts}
        </p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() =>
            showModal(titles?.modal_texts?.emergency_contact_help_tip)
          }
        />
      </div>
      <p className="body_h1 left mb10">{titles?.emergency_contacts_desc}</p>
      <p className="body_h1 left mb20">
        {titles?.emergency_contacts_second_desc}
      </p>
      {renderPhoneList()}
      <div className="mg_horizontal_line"></div>
      <p className="sd_label_text display_h3 mb15">
        {titles?.residence_details}
      </p>
      <CustomInput
        label={titles?.township_borough}
        labelClassName={"ep_input_label body_h1 left"}
        type="text"
        className="mb10"
        placeholder={titles?.emergency_textfield_desc}
        required
        placeholderStyle="ep_placeholder_style"
        value={emergencyDetailState?.township}
        onChange={(e) =>
          handleResidanceFieldChange("township", e?.target?.value)
        }
      />
      <CustomInput
        label={titles?.jurisdiction}
        labelClassName={"ep_input_label body_h1 left"}
        type="text"
        className="mb10"
        placeholder={titles?.emergency_textfield_desc}
        required
        placeholderStyle="ep_placeholder_style"
        value={emergencyDetailState?.jurisdiction}
        onChange={(e) =>
          handleResidanceFieldChange("jurisdiction", e?.target?.value)
        }
      />
      <CustomInput
        label={titles?.cross_street}
        labelClassName={"ep_input_label body_h1 left"}
        type="text"
        className="mb10"
        placeholder={titles?.emergency_textfield_desc}
        required
        placeholderStyle="ep_placeholder_style"
        value={emergencyDetailState?.street}
        onChange={(e) => handleResidanceFieldChange("street", e?.target?.value)}
      />
      <CustomInput
        label={titles?.special_instructions}
        characters={50}
        labelClassName={"ep_input_label body_h1 left"}
        type="text"
        className="mb10"
        multiline={true}
        placeholder={titles?.tell_us_details_should_know}
        placeholderStyle="ep_placeholder_style_characters"
        value={emergencyDetailState?.spacialInstruction}
        onChange={(e) =>
          handleResidanceFieldChange("spacialInstruction", e?.target?.value)
        }
      />
      <p className="ep_input_label body_h1 mb5">
        {titles?.spoken_language_inside_home}
      </p>
      <CustomSelectBox
        required={true}
        options={languages?.map((ele) => {
          return { label: ele?.language, value: ele?.language };
        })}
        selectedValue={
          emergencyDetailState?.language?.length > 0
            ? emergencyDetailState?.language
            : emergencyDetailState?.language?.language
        }
        title={titles?.spoken_language_inside_home}
        onChange={(item) => handleDropdownSelect(item?.value)}
        className="mb15 ep_input_label"
      />
      <div className="mg_horizontal_line"></div>
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb15 mr5">
          {titles?.residence_entry}
        </p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() =>
            showModal(titles?.modal_texts?.residence_entry_help_tip)
          }
        />
      </div>
      <p className="body_h1 left mb10">
        {titles?.please_fill_out_applies_subscriber}
      </p>
      <CustomInput
        label={titles?.gate_instructions}
        labelClassName={"ep_input_label body_h1 left"}
        type="text"
        className="mb10"
        placeholder={titles?.tell_us_pass_codes_get_through}
        characters={50}
        multiline={true}
        placeholderStyle="ep_placeholder_style_characters"
        value={emergencyDetailState?.gateInstruction}
        onChange={(e) =>
          handleResidanceFieldChange("gateInstruction", e?.target?.value)
        }
      />
      <CustomInput
        label={titles?.access_to_residence}
        labelClassName={"ep_input_label body_h1 left"}
        type="text"
        className="mb15"
        placeholder={titles?.tell_us_any_details}
        characters={50}
        multiline={true}
        placeholderStyle="ep_placeholder_style_characters"
        value={emergencyDetailState?.accessToResidence}
        onChange={(e) =>
          handleResidanceFieldChange("accessToResidence", e?.target?.value)
        }
      />
      <div className="field_title_container">
        <p className="ci_label display_h4 ep_input_label body_h1 left mr5">
          {titles?.lock_box_code}
        </p>
        <IconSmallQuestion
          className={"sd_notify_icon lock_helpful_icon"}
          onClick={() => showModal(titles?.modal_texts?.logbox_help_tip)}
        />
      </div>
      <div className="lock_box_code_container mb15">
        <div className="radio_container">
          <input
            onClick={() => {
              handleResidanceFieldChange(
                "isLockBox",
                !emergencyDetailState?.isLockBox
              );
            }}
            checked={emergencyDetailState?.isLockBox ? true : false}
            type="radio"
            className="ep_radio"
          />
          <span className="ci_label display_h4 ep_input_label ep_radio_label body_h1 ">
            {titles?.update_code}
          </span>
        </div>
        <div className="lock_box_code_input_container">
          <input
            maxLength={5}
            disabled={!emergencyDetailState?.isLockBox ? true : false}
            className="ci_input lock_box_code_input"
            type={emergencyDetailState?.showPasswordIcon || !emergencyDetailState?.isLockBox ? "password" : "text"}
            value={emergencyDetailState["lockBoxCode"]}
            onChange={(e) => {
              handleResidanceFieldChange("lockBoxCode", e?.target?.value);
            }}
          />
          {emergencyDetailState?.isLockBox ? (
            emergencyDetailState?.showPasswordIcon ? (
              <IconReveal
                className={"ci_iconHide"}
                onClick={() => onLockBoxIconClick()}
              />
            ) : (
              <IconHide
                className={"ci_iconHide"}
                onClick={() => onLockBoxIconClick()}
              />
            )
          ) : (
            <IconQuestion
              className={"lock_input_note_icon"}
              onClick={() => onLockBoxIconClick()}
            />
          )}
        </div>
      </div>

      <CustomInput
        label={titles?.lock_box_location}
        labelClassName={"ep_input_label body_h1 left"}
        type="text"
        className="mb10"
        placeholder={titles?.tell_where_find_it}
        characters={50}
        multiline={true}
        placeholderStyle="ep_placeholder_style_characters"
        value={emergencyDetailState?.boxLocation}
        onChange={(e) => {
          handleResidanceFieldChange("boxLocation", e?.target?.value);
        }}
      />
      <CustomInput
        label={titles?.hidden_key_location}
        labelClassName={"ep_input_label body_h1 left"}
        type="text"
        className="mb10"
        placeholder={titles?.tell_where_find_it}
        characters={50}
        multiline={true}
        placeholderStyle="ep_placeholder_style_characters"
        value={emergencyDetailState?.keyLocation}
        onChange={(e) => {
          handleResidanceFieldChange("keyLocation", e?.target?.value);
        }}
      />
      <CustomInput
        label={titles?.vial_life_location}
        labelClassName={"ep_input_label body_h1 left"}
        type="text"
        className="mb10"
        placeholder={titles?.tell_where_find_it}
        characters={50}
        multiline={true}
        placeholderStyle="ep_placeholder_style_characters"
        value={emergencyDetailState?.lifeLocation}
        onChange={(e) => {
          handleResidanceFieldChange("lifeLocation", e?.target?.value);
        }}
      />
      <div className="mg_horizontal_line"></div>
      <p className="display_h4 mb10">{titles?.digital_signature}</p>
      <p className="body_h1 left mb20">{titles?.please_read_the_following}</p>
      <div className="ep_consent_box mb20">
        <p className="body_h1 ml-1 sms_note">
          {titles?.emergency_signature_desc}
        </p>
      </div>
      <p className="body_h1 left mb20">{titles?.verify_your_signature}</p>
      <div className="consent_note_container mb20">
        <CustomCheckbox
          className="acm_checkbox_field"
          checked={emergencyPlanState?.isAcceptSignature}
          onChange={() => {
            handleFieldChange(
              "isAcceptSignature",
              !emergencyPlanState?.isAcceptSignature
            );
          }}
        />
        <div>
          <p className="body_h1 ml-1 sms_note new_line_text">{`**${
            accountHolderData?.firstname + " " + accountHolderData?.lastname
          }\nc/o ${
            medicalUserDetail?.firstname + " " + medicalUserDetail?.lastname
          }**`}</p>
        </div>
      </div>
      <CustomButton
        disabled={!emergencyPlanState?.isAcceptSignature}
        width={"100%"}
        height={"50px"}
        className={"esd_submit_btn"}
        text={titles?.accept}
        onClick={() => onAccept()}
      />
    </MainLayout>
  );
};

export default EmergencyPlan;
