const keys = {
  accessTokenKey: "Access-Token",
  isFingerPrintEnable: "isFingerPrintEnable",
  loggnedInUser: "loggnedInUser",
  loggedInGuestUser: "loggedInGuestUser",
  isGuestUserFingerPrintEnable: "isGuestUserFingerPrintEnable",
  loggedInGuestUserData: "loggedInGuestUserData",
  logInTime: "logInTime",
  freeusDeviceList: "freeusDeviceList",
  aneltoDeviceList: "aneltoDeviceList",
  adminAccessTokenKey: "adminAccessToken",
  adminDetail:'adminDetail',
  isFirstInstall: 'isFirstInstall',
  inAppReviewData: 'inAppReviewData',
  isDetailUpdate: 'isDetailUpdate',
  isReviewPopUpShow: 'isReviewPopUpShow',
  firstLoginTime: 'firstLoginTime',
  selectedDeviceDetail: 'selectedDeviceDetail',
  accountHolderUserdetail: 'accountHolderUserDetail',
  freeusDeviceDetail: 'freeusDeviceDetail',
  aneltoDeviceDetail: 'aneltoDeviceDetail',
  clairvoyantDeviceDetail: 'ClairvoyantDeviceDetail',
  selectedMedicalUser: 'selectedMedicalUser',
  timeZoneSettings: "timeZoneSettings",
  medicalUserTimezone: "medicalUserTimezone"
};

export default keys;
