import { Switch, Route } from "react-router-dom";
import React from "react";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import "../assets/fonts/fonts.css";

import LandingScreen from "views/landing-screen/LandingScreen.js";
import AdminLogin from "views/admin-login/AdminLogin.js";
import AdminForgotPassword from "views/admin-forgot-password/AdminForgotPassword.js";
import AdminChangePassword from "views/admin-change-password/AdminChangePassword.js";
import DeviceList from "views/device-list/DeviceList";
import Home from "views/home/Home.js";
import DeviceProfile from "views/device-profile/DeviceProfile";
import DeviceSignalHistory from "views/device-signal-history/DeviceSignalHistory";
import LocationTracking from "views/location-tracking/LocationTracking";
import Alert from "views/alert/Alert";
import Settings from "views/settings/Settings";
import NotificationSetting from "views/settings/notification-setting/NotificationSetting";
import ManageGuestUsers from "views/settings/manage-guest-users/ManageGuestUsers";
import AboutSetting from "views/settings/about-setting/AboutSetting";
import HelpCenter from "views/settings/help-center/HelpCenter";
import CustomerLogin from "views/customer-login/CustomerLogin";
import CancelServices from "views/settings/cancel-services/CancelServices";
import SubscriberProfile from "views/subscriber_profile/SubscriberProfile";
import DeviceAndTracking from "views/device-and-tracking/DeviceAndTracking";
import EditAdminProfile from "views/edit-admin-profile/EditAdminProfile";
import InvoicesAndBills from "views/invoices-and-bills/InvoicesAndBills";
import ShippingDetails from "views/shipping-details/ShippingDetails";
import AccountHolder from "views/accout-holder/AccountHolder";
import EditSubscriberDetails from "views/edit-subscriber-details/EditSubscriberDetails";
import OrderHistory from "views/order-history/OrderHistory";
import BillingDetails from "views/billing-details/BillingDetails";
import EmergencyPlan from "views/emergency-plan/EmergencyPlan";
import OrderDetails from "views/order-details/OrderDetails";
import AddReviewAndSign from "views/add-review-sign/AddReviewAndSign";
import LocationHistory from "views/location-history/LocationHistory";
import TimeZoneSettings from "views/settings/timezone-settings/TimeZoneSettings";

const MainRoutes = () => {
  return (
    <Switch>
      <Route
        path={ROUTES_CONSTANTS.landingScreen}
        exact={true}
        component={LandingScreen}
      />
      <Route
        path={ROUTES_CONSTANTS.login}
        exact={true}
        component={AdminLogin}
      />
      <Route
        path={ROUTES_CONSTANTS.forgotPassword}
        exact={true}
        component={AdminForgotPassword}
      />
      <Route
        path={ROUTES_CONSTANTS.changePassword}
        exact={true}
        component={AdminChangePassword}
      />
      <Route path={ROUTES_CONSTANTS.home} exact={true} component={Home} />
      <Route
        path={ROUTES_CONSTANTS.deviceProfile}
        exact={true}
        component={DeviceProfile}
      />
      <Route
        path={ROUTES_CONSTANTS.deviceList}
        exact={true}
        component={DeviceList}
      />
      <Route
        path={ROUTES_CONSTANTS.deviceSignalHistory}
        exact={true}
        component={DeviceSignalHistory}
      />
      <Route
        path={ROUTES_CONSTANTS.caregiverTracking}
        exact={true}
        component={LocationTracking}
      />
      <Route path={ROUTES_CONSTANTS.alerts} exact={true} component={Alert} />
      <Route
        path={ROUTES_CONSTANTS.settings}
        exact={true}
        component={Settings}
      />
      <Route
        path={ROUTES_CONSTANTS.notificationSetting}
        exact={true}
        component={NotificationSetting}
      />
      <Route
        path={ROUTES_CONSTANTS.manageGuestUsers}
        exact={true}
        component={ManageGuestUsers}
      />
      <Route
        path={ROUTES_CONSTANTS.timeZoneSettings}
        exact={true}
        component={TimeZoneSettings}
      />
      <Route
        path={ROUTES_CONSTANTS.about}
        exact={true}
        component={AboutSetting}
      />
      <Route
        path={ROUTES_CONSTANTS.helpCenter}
        exact={true}
        component={HelpCenter}
      />
      <Route
        path={ROUTES_CONSTANTS.customerLogin}
        exact={true}
        component={CustomerLogin}
      />
      <Route
        path={ROUTES_CONSTANTS.cancelServices}
        exact={true}
        component={CancelServices}
      />
      <Route
        path={ROUTES_CONSTANTS.subscriberProfile}
        exact={true}
        component={SubscriberProfile}
      />
      <Route
        path={ROUTES_CONSTANTS.deviceAndTracking}
        exact={true}
        component={DeviceAndTracking}
      />
      <Route
        path={ROUTES_CONSTANTS.editAdminProfile}
        exact={true}
        component={EditAdminProfile}
      />
      <Route
        path={ROUTES_CONSTANTS.reviewAndSign}
        exact={true}
        component={AddReviewAndSign}
      />
      <Route
        path={ROUTES_CONSTANTS.invoicesAndBills}
        exact={true}
        component={InvoicesAndBills}
      />
      <Route
        path={ROUTES_CONSTANTS.shippingDetails}
        exact={true}
        component={ShippingDetails}
      />
      <Route
        path={ROUTES_CONSTANTS.accountHolder}
        exact={true}
        component={AccountHolder}
      />
      <Route
        path={ROUTES_CONSTANTS.editSubscriberDetails}
        exact={true}
        component={EditSubscriberDetails}
      />
      <Route
        path={ROUTES_CONSTANTS.orderHistory}
        exact={true}
        component={OrderHistory}
      />
      <Route
        path={ROUTES_CONSTANTS.billingDetails}
        exact={true}
        component={BillingDetails}
      />
      <Route
        path={ROUTES_CONSTANTS.emergencyPlan}
        exact={true}
        component={EmergencyPlan}
      />
      <Route
        path={ROUTES_CONSTANTS.orderDetails}
        exact={true}
        component={OrderDetails}
      />
      <Route
        path={ROUTES_CONSTANTS.location}
        exact={true}
        component={LocationHistory}
      />
    </Switch>
  );
};

export default MainRoutes;
