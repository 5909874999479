import React, { useEffect } from "react";
import "./ChangesSavedModal.css";
import { Modal } from "react-bootstrap";
import { titles } from "constants/titles";
import { ChangesSavedIcon } from "assets/SVGs/SVGs";

const ChangesSavedModal = ({ show, setShow, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onClose) {
        onClose();
      } else {
        setShow(false);
      }
    }, [2000]);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="cm_container"
      show={show}
    >
      <Modal.Body className="cm_body">
        <div className="cm_container">
          <div className="cm_svg_container mb10">
            <ChangesSavedIcon />
          </div>
          <p className="display_h5 cm_text">{titles?.changes_saved}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangesSavedModal;
