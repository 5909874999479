import { postApi, getApi } from "../../api/commonApi";
import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  ACCESS_TOKEN,
  ACTIVE_SCREEN,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
  CONTACT_US_REQUEST,
  GENERATE_OTP_FAILURE,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_REQUEST,
  SESSION_EXPIRY_POPUP,
  AUTH_LODER_VISIBILITY,
  LOG_OUT_AND_CLEAR,
  FEEDBACK_ISSUE_TYPE_SUCESS,
  FEEDBACK_ISSUE_TYPE_REQUEST,
  FEEDBACK_ISSUE_SUB_TYPE_SUCESS,
} from "./actionTypes";
import apis from "../../api/apiEndPoints";
import {
  clearData,
  deleteData,
  encryptData,
  getData,
  handleResponses,
  handleUnAuthorizedAccess,
  notifyError,
  storeData,
} from "../../utility";
import keys from "../../constants/keys";
import moment from "moment";
import alerts from "../../constants/alerts";
import { BASE_URL } from "constants/routes/api";
import { titles } from "constants/titles";
import { ROUTES_CONSTANTS } from "routes/route-constants";

export const getContactUsApi = async (dispatch, userType) => {
  const API = BASE_URL + apis?.contactUs;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, CONTACT_US_REQUEST);
  getApi(API, accessToken, userType)
    .then((res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, CONTACT_US_SUCCESS, res?.data?.data);
      } else {
        returnToDispatch(dispatch, CONTACT_US_FAILURE);
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, CONTACT_US_FAILURE, error);
    });
};

export const login = (dispatch, payload, navigation, insets) => {
  const currentLoginTime = moment().format("YYYY-MM-DD HH:mm:ss");
  const API = BASE_URL + apis?.login;
  returnToDispatch(dispatch, LOGIN_REQUEST);
  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });
  postApi(API, "", encryptedPayload)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, ACCESS_TOKEN, response?.data?.data);
        storeData(keys?.accessTokenKey, response?.data?.data);
        let storedUser = await getData(keys?.loggnedInUser);
        if (storedUser && JSON.parse(storedUser)) {
          let storedUserObj = JSON.parse(storedUser);
          if (storedUserObj?.username !== payload?.username) {
            storeData(keys?.isFingerPrintEnable, JSON.stringify(false));
          }
        }
        storeData(keys?.loggnedInUser, JSON.stringify(payload));
        storeData(keys?.logInTime, currentLoginTime);

        let firstLoginTime = await getData(keys?.firstLoginTime);

        if (firstLoginTime == null) {
          storeData(keys?.firstLoginTime, currentLoginTime);
        }

        returnToDispatch(dispatch, LOGIN_SUCCESS, {
          isGuestUser: false,
        });
        navigation?.reset({
          index: 0,
          routes: [{ name: "Home" }],
        });
        setActiveScreen(dispatch, "Home");
        // logCustomEvents("login", "engagement", "User Login", payload?.username);
      } else {
        returnToDispatch(dispatch, LOGIN_FAILURE);
        setTimeout(() => {
          notifyError(response?.data?.message, insets?.top);
        }, 500);
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, LOGIN_FAILURE, error);
    });
};

export const logout = async (
  dispatch,
  history,
  setSnackBarMessage,
  isGuestUser
) => {
  const API = isGuestUser
    ? BASE_URL + apis?.guestLogout
    : BASE_URL + apis?.customerLogout;
  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);
  let accessToken = await getData(keys?.accessTokenKey);

  getApi(API, accessToken, isGuestUser ? "guestuser" : "accountowner")
    .then(async (response) => {
      try {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        await deleteData(keys.accessTokenKey);
        history?.replace(ROUTES_CONSTANTS.customerLogin);
        logoutAndClear(dispatch, {});
      } catch (error) {}
    })
    .catch((error) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

export const forgotPassword = async (dispatch, payload, navigation, insets) => {
  const API = BASE_URL + apis?.forgotPassword;
  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);
  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });

  postApi(API, "", encryptedPayload)
    .then((response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        handleResponses(true, response?.data?.message, insets);
        navigation?.goBack();
      } else {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, insets);
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

export const changePassword = async (
  dispatch,
  payload,
  history,
  setSnackBarMessage
) => {
  const API = BASE_URL + apis?.changePassword;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);
  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });
  postApi(API, accessToken, encryptedPayload, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);

        handleResponses(
          true,
          "Password Changed Successfully.",
          setSnackBarMessage
        );
        await clearData();
        await storeData(keys?.isFingerPrintEnable, JSON.stringify(false));
        history?.replace(ROUTES_CONSTANTS.landingScreen);
      } else {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

export const generateOtp = async (dispatch) => {
  const API = BASE_URL + apis?.generateOtp;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, GENERATE_OTP_REQUEST);

  getApi(API, accessToken, "accountowner")
    .then((res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, GENERATE_OTP_SUCCESS, res?.data?.data);
      } else {
        returnToDispatch(dispatch, GENERATE_OTP_FAILURE);
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, GENERATE_OTP_FAILURE, error);
    });
};

export const verifyOtp = async (
  dispatch,
  payload,
  navigation,
  insets,
  onButtonClick
) => {
  const API = BASE_URL + apis?.verifyOtp;
  let accessToken = await getData(keys?.accessTokenKey);

  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);
  let encryptedPayload = JSON.stringify({
    data: encryptData(payload),
  });
  postApi(API, accessToken, encryptedPayload, "accountowner")
    .then(async (response) => {
      if (response?.data?.data) {
        try {
          returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
          returnToDispatch(dispatch, SESSION_EXPIRY_POPUP, false);
          handleResponses(true, alerts?.otp_verified, insets);
          const updatedSessionTime = moment().format("YYYY-MM-DD HH:mm:ss");
          storeData(keys?.logInTime, updatedSessionTime);
          onButtonClick();
        } catch (error) {}
      } else {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        handleResponses(false, alerts?.invalid_otp, insets);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

export const submitFeedback = async (
  dispatch,
  payload,
  userType,
  getResponseOfFeedback,
  history,
  setSnackBarMessage
) => {
  const API = BASE_URL + apis?.submitFeedback;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);
  postApi(API, accessToken, payload, userType, true)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        handleResponses(true, alerts?.feedback_submission, setSnackBarMessage);
        getResponseOfFeedback(titles?.feedback_success);
        setTimeout(() => {
          history?.goBack();
        }, 2000);
      } else {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        getResponseOfFeedback(titles?.feedback_failure);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
      getResponseOfFeedback(titles?.feedback_failure);
    });
};

export const getFeedBackType = async (dispatch, userType) => {
  const API = BASE_URL + apis?.issueType;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);
  returnToDispatch(dispatch, FEEDBACK_ISSUE_TYPE_REQUEST, false);
  getApi(API, accessToken, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        returnToDispatch(
          dispatch,
          FEEDBACK_ISSUE_TYPE_SUCESS,
          response?.data?.data
        );
      } else {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        if (response?.data?.code === 401) {
          // await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};

export const getFeedBackSubType = async (dispatch, userType, param) => {
  const API =
    BASE_URL + apis?.subIssueType + `?issue_type_id=${param?.issueType}`;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, true);
  returnToDispatch(dispatch, FEEDBACK_ISSUE_TYPE_REQUEST, false);
  getApi(API, accessToken, userType)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        returnToDispatch(
          dispatch,
          FEEDBACK_ISSUE_SUB_TYPE_SUCESS,
          response?.data?.data
        );
      } else {
        returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
        if (response?.data?.code === 401) {
          // await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, AUTH_LODER_VISIBILITY, false);
    });
};
export const setAccessToken = (dispatch, email) => {
  dispatch({
    type: ACCESS_TOKEN,
    payload: email,
  });
};

export const setActiveScreen = (dispatch, screenName) => {
  dispatch({
    type: ACTIVE_SCREEN,
    payload: screenName,
  });
};

export const setSessionExpiryModalVisibility = (dispatch, payload) => {
  dispatch({
    type: SESSION_EXPIRY_POPUP,
    payload: payload,
  });
};

export const logoutAndClear = (dispatch, payload) => {
  dispatch({
    type: LOG_OUT_AND_CLEAR,
    payload: payload,
  });
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
