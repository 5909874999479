import React from "react";
import { useEffect, useRef, useState } from "react";
import "./CustomSelectBox.css";
import { IconDropDown, IconDropUp } from "assets/SVGs/SVGs";
import { isEmpty } from "utility";

const CustomSelectBox = ({
  onChange = () => {},
  title = "Select",
  options,
  className,
  required,
  selectedValue,
  disabled,
}) => {
  const data = options?.map((obj, index) => {
    if (obj?.id) {
      return { ...obj };
    } else {
      return { ...obj, id: index };
    }
  });

  const [selectedId, setSelectedId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(
    selectedId ? data?.find((item) => item?.id === selectedId) : {}
  );
  const dropdownRef = useRef(null);

  const handleChange = (item) => {
    setSelectedId(item?.id);
    onChange(item, item?.id);
    setState(item);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (selectedValue) {
      const preSelectedObj = data?.find(
        (item) => item?.value === selectedValue
      );
      if (!isEmpty(preSelectedObj) && Object.keys(preSelectedObj)?.length > 0) {
        setSelectedId(preSelectedObj?.id);
        setState(preSelectedObj);
      }
    } else {
      setSelectedId(null);
      setState("");
    }
  }, [selectedValue]);

  const selectTitle = (
    <p className="csb_placeholder_text">
      {title}
      {required && <span className="error_text">*</span>}
    </p>
  );
  return (
    <div
      disabled={disabled}
      ref={dropdownRef}
      className={`custom_select_box_container  ${className}`}
    >
      <button
        disabled={disabled}
        aria-label="Toggle dropdown"
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        className="cs_box_button body_h4"
      >
        {state?.label || selectTitle}
        {isOpen ? (
          <IconDropUp className={`cs_dropdown_icon_up `} />
        ) : (
          <IconDropDown className={`cs_dropdown_icon_down `} />
        )}

        {isOpen && (
          <div
            aria-label="Dropdown menu"
            className={`dropdown_page dropdown.bottom-right`}
          >
            <ul
              role="menu"
              aria-orientation="vertical"
              className="cs_list_container"
            >
              {data?.map((item, index) => {
                return (
                  <li
                    key={item?.id + index}
                    onClick={() => handleChange(item)}
                    className={`cs_list body_h1 ${
                      state?.id === item?.id ? "selected" : ""
                    }`}
                  >
                    <span>{item?.label}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </button>
    </div>
  );
};

export default CustomSelectBox;
