export const BatteryLevel1 = () => {
  return (
    <div>
      <svg
        width="30"
        height="17"
        viewBox="0 0 30 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.875 5.46432V1.21432C26.875 0.874321 26.6 0.607178 26.25 0.607178H1.25C0.9 0.607178 0.625 0.874321 0.625 1.21432V15.7857C0.625 16.1257 0.9 16.3929 1.25 16.3929H26.25C26.6 16.3929 26.875 16.1257 26.875 15.7857V11.5357H28.75C29.1 11.5357 29.375 11.2686 29.375 10.9286V6.07146C29.375 5.73146 29.1 5.46432 28.75 5.46432H26.875Z"
          stroke="#242A34"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export const BatteryLevel2 = () => {
  return (
    <div>
      <svg
        width="30"
        height="17"
        viewBox="0 0 30 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3_1010)">
          <path
            d="M6.84998 5.46423H4.34998V11.5357H6.84998V5.46423Z"
            fill="#242A34"
          />
          <path
            d="M26.875 5.46426V1.21426C26.875 0.87426 26.6 0.607117 26.25 0.607117H1.25C0.9 0.607117 0.625 0.87426 0.625 1.21426V15.7857C0.625 16.1257 0.9 16.3928 1.25 16.3928H26.25C26.6 16.3928 26.875 16.1257 26.875 15.7857V11.5357H28.75C29.1 11.5357 29.375 11.2685 29.375 10.9285V6.0714C29.375 5.7314 29.1 5.46426 28.75 5.46426H26.875Z"
            stroke="#242A34"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_1010">
            <rect width="30" height="17" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const BatteryLevel3 = () => {
  return (
    <div>
      <svg
        width="30"
        height="17"
        viewBox="0 0 30 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.84998 5.46423H4.34998V11.5357H6.84998V5.46423Z"
          fill="#242A34"
        />
        <path
          d="M11.85 5.46423H9.34998V11.5357H11.85V5.46423Z"
          fill="#242A34"
        />
        <path
          d="M26.875 5.46426V1.21426C26.875 0.87426 26.6 0.607117 26.25 0.607117H1.25C0.9 0.607117 0.625 0.87426 0.625 1.21426V15.7857C0.625 16.1257 0.9 16.3928 1.25 16.3928H26.25C26.6 16.3928 26.875 16.1257 26.875 15.7857V11.5357H28.75C29.1 11.5357 29.375 11.2685 29.375 10.9285V6.0714C29.375 5.7314 29.1 5.46426 28.75 5.46426H26.875Z"
          stroke="#242A34"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export const BatteryLevel4 = () => {
  return (
    <div>
      <svg
        width="30"
        height="17"
        viewBox="0 0 30 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3_960)">
          <path
            d="M6.84998 5.46429H4.34998V11.5357H6.84998V5.46429Z"
            fill="#242A34"
          />
          <path
            d="M11.85 5.46429H9.34998V11.5357H11.85V5.46429Z"
            fill="#242A34"
          />
          <path
            d="M16.85 5.46429H14.35V11.5357H16.85V5.46429Z"
            fill="#242A34"
          />
          <path
            d="M26.875 5.46429V1.21429C26.875 0.87429 26.6 0.607147 26.25 0.607147H1.25C0.9 0.607147 0.625 0.87429 0.625 1.21429V15.7857C0.625 16.1257 0.9 16.3929 1.25 16.3929H26.25C26.6 16.3929 26.875 16.1257 26.875 15.7857V11.5357H28.75C29.1 11.5357 29.375 11.2686 29.375 10.9286V6.07143C29.375 5.73143 29.1 5.46429 28.75 5.46429H26.875Z"
            stroke="#242A34"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_960">
            <rect width="30" height="17" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const BatteryLevel5 = () => {
  return (
    <div>
      <svg
        width="30"
        height="17"
        viewBox="0 0 30 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3_935)">
          <path
            d="M6.84998 5.46429H4.34998V11.5357H6.84998V5.46429Z"
            fill="#242A34"
          />
          <path
            d="M11.85 5.46429H9.34998V11.5357H11.85V5.46429Z"
            fill="#242A34"
          />
          <path
            d="M16.85 5.46429H14.35V11.5357H16.85V5.46429Z"
            fill="#242A34"
          />
          <path
            d="M21.85 5.46429H19.35V11.5357H21.85V5.46429Z"
            fill="#242A34"
          />
          <path
            d="M26.875 5.46429V1.21429C26.875 0.87429 26.6 0.607147 26.25 0.607147H1.25C0.9 0.607147 0.625 0.87429 0.625 1.21429V15.7857C0.625 16.1257 0.9 16.3929 1.25 16.3929H26.25C26.6 16.3929 26.875 16.1257 26.875 15.7857V11.5357H28.75C29.1 11.5357 29.375 11.2686 29.375 10.9286V6.07143C29.375 5.73143 29.1 5.46429 28.75 5.46429H26.875Z"
            stroke="#242A34"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_935">
            <rect width="30" height="17" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const SignalLevel1 = () => {
  return (
    <div>
      <svg
        width="25"
        height="17"
        viewBox="0 0 25 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0423 3.66566C15.5366 3.66582 18.8973 5.03632 21.4297 7.49391C21.6204 7.68365 21.9253 7.68125 22.1131 7.48855L23.936 5.61064C24.0311 5.5129 24.0841 5.3805 24.0833 5.24275C24.0825 5.105 24.028 4.97325 23.9318 4.87665C17.2849 -1.62555 6.79869 -1.62555 0.151814 4.87665C0.0555296 4.97317 0.000898845 5.10489 1.09924e-05 5.24264C-0.000876861 5.38039 0.0520514 5.51283 0.147083 5.61064L1.97053 7.48855C2.15823 7.68154 2.46328 7.68394 2.65386 7.49391C5.18664 5.03616 8.5477 3.66566 12.0423 3.66566Z"
          fill="#BEBEBE"
        />
        <path
          d="M12.0925 9.45258C14.0124 9.45246 15.8638 10.1809 17.2869 11.4963C17.4794 11.683 17.7826 11.6789 17.9703 11.4872L19.7911 9.60925C19.887 9.51075 19.9402 9.37713 19.9388 9.23827C19.9374 9.09942 19.8815 8.96692 19.7837 8.87043C15.45 4.75559 8.73874 4.75559 4.40505 8.87043C4.30716 8.96692 4.25131 9.09948 4.25002 9.23838C4.24874 9.37728 4.30213 9.5109 4.39822 9.60925L6.21851 11.4872C6.40615 11.6789 6.70936 11.683 6.90184 11.4963C8.32403 10.1817 10.1739 9.45339 12.0925 9.45258Z"
          fill="#BEBEBE"
        />
        <path
          d="M15.647 13.6064C15.7444 13.5089 15.798 13.3746 15.7952 13.2354C15.7924 13.0961 15.7335 12.9642 15.6323 12.8708C13.6208 11.1342 10.6748 11.1342 8.66332 12.8708C8.56205 12.9642 8.503 13.096 8.50011 13.2353C8.49723 13.3745 8.55076 13.5088 8.64807 13.6064L11.7982 16.8509C11.8906 16.9463 12.0164 16.9999 12.1478 16.9999C12.2791 16.9999 12.405 16.9463 12.4973 16.8509L15.647 13.6064Z"
          fill="#BEBEBE"
        />
      </svg>
    </div>
  );
};

export const SignalLevel2 = () => {
  return (
    <div>
      <svg
        width="25"
        height="17"
        viewBox="0 0 25 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0423 3.66566C15.5366 3.66582 18.8973 5.03632 21.4297 7.49391C21.6204 7.68365 21.9253 7.68125 22.1131 7.48855L23.936 5.61064C24.0311 5.5129 24.0841 5.3805 24.0833 5.24275C24.0825 5.105 24.028 4.97325 23.9318 4.87665C17.2849 -1.62555 6.79869 -1.62555 0.151814 4.87665C0.0555296 4.97317 0.000898845 5.10489 1.09924e-05 5.24264C-0.000876861 5.38039 0.0520514 5.51283 0.147083 5.61064L1.97053 7.48855C2.15823 7.68154 2.46328 7.68394 2.65386 7.49391C5.18664 5.03616 8.5477 3.66566 12.0423 3.66566Z"
          fill="#BEBEBE"
        />
        <path
          d="M12.0925 9.45258C14.0124 9.45246 15.8638 10.1809 17.2869 11.4963C17.4794 11.683 17.7826 11.6789 17.9703 11.4872L19.7911 9.60925C19.887 9.51075 19.9402 9.37713 19.9388 9.23827C19.9374 9.09942 19.8815 8.96692 19.7837 8.87043C15.45 4.75559 8.73874 4.75559 4.40505 8.87043C4.30716 8.96692 4.25131 9.09948 4.25002 9.23838C4.24874 9.37728 4.30213 9.5109 4.39822 9.60925L6.21851 11.4872C6.40615 11.6789 6.70936 11.683 6.90184 11.4963C8.32403 10.1817 10.1739 9.45339 12.0925 9.45258Z"
          fill="#BEBEBE"
        />
        <path
          d="M15.647 13.6065C15.7444 13.5089 15.798 13.3747 15.7952 13.2354C15.7924 13.0962 15.7335 12.9643 15.6323 12.8709C13.6208 11.1343 10.6748 11.1343 8.66332 12.8709C8.56205 12.9642 8.503 13.0961 8.50011 13.2353C8.49723 13.3746 8.55076 13.5089 8.64807 13.6065L11.7982 16.851C11.8906 16.9463 12.0164 17 12.1478 17C12.2791 17 12.405 16.9463 12.4973 16.851L15.647 13.6065Z"
          fill="#242A34"
        />
      </svg>
    </div>
  );
};

export const SignalLevel3 = () => {
  return (
    <div>
      <svg
        width="25"
        height="17"
        viewBox="0 0 25 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0423 3.66566C15.5366 3.66582 18.8973 5.03632 21.4297 7.49391C21.6204 7.68365 21.9253 7.68125 22.1131 7.48855L23.936 5.61064C24.0311 5.5129 24.0841 5.3805 24.0833 5.24275C24.0825 5.105 24.028 4.97325 23.9318 4.87665C17.2849 -1.62555 6.79869 -1.62555 0.151814 4.87665C0.0555296 4.97317 0.000898845 5.10489 1.09924e-05 5.24264C-0.000876861 5.38039 0.0520514 5.51283 0.147083 5.61064L1.97053 7.48855C2.15823 7.68154 2.46328 7.68394 2.65386 7.49391C5.18664 5.03616 8.5477 3.66566 12.0423 3.66566Z"
          fill="#BEBEBE"
        />
        <path
          d="M12.0925 9.45258C14.0124 9.45246 15.8638 10.1809 17.2869 11.4963C17.4794 11.683 17.7826 11.6789 17.9703 11.4872L19.7911 9.60925C19.887 9.51075 19.9402 9.37713 19.9388 9.23827C19.9374 9.09942 19.8815 8.96692 19.7837 8.87043C15.45 4.75559 8.73874 4.75559 4.40505 8.87043C4.30716 8.96692 4.25131 9.09948 4.25002 9.23838C4.24874 9.37728 4.30213 9.5109 4.39822 9.60925L6.21851 11.4872C6.40615 11.6789 6.70936 11.683 6.90184 11.4963C8.32403 10.1817 10.1739 9.45339 12.0925 9.45258Z"
          fill="#242A34"
        />
        <path
          d="M15.647 13.6065C15.7444 13.5089 15.798 13.3747 15.7952 13.2354C15.7924 13.0962 15.7335 12.9643 15.6323 12.8709C13.6208 11.1343 10.6748 11.1343 8.66332 12.8709C8.56205 12.9642 8.503 13.0961 8.50011 13.2353C8.49723 13.3746 8.55076 13.5089 8.64807 13.6065L11.7982 16.851C11.8906 16.9463 12.0164 17 12.1478 17C12.2791 17 12.405 16.9463 12.4973 16.851L15.647 13.6065Z"
          fill="#242A34"
        />
      </svg>
    </div>
  );
};

export const SignalLevel4 = () => {
  return (
    <div>
      <svg
        width="25"
        height="17"
        viewBox="0 0 25 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.0423 3.66566C15.5366 3.66582 18.8973 5.03632 21.4297 7.49391C21.6204 7.68365 21.9253 7.68125 22.1131 7.48855L23.936 5.61064C24.0311 5.5129 24.0841 5.3805 24.0833 5.24275C24.0825 5.105 24.028 4.97324 23.9318 4.87665C17.2849 -1.62555 6.79869 -1.62555 0.151814 4.87665C0.0555296 4.97317 0.000898845 5.10489 1.09924e-05 5.24264C-0.000876861 5.38039 0.0520514 5.51283 0.147083 5.61064L1.97053 7.48855C2.15823 7.68154 2.46328 7.68394 2.65386 7.49391C5.18664 5.03616 8.5477 3.66566 12.0423 3.66566ZM12.0925 9.45256C14.0124 9.45244 15.8638 10.1809 17.2869 11.4963C17.4794 11.683 17.7826 11.6789 17.9703 11.4871L19.7911 9.60924C19.887 9.51073 19.9402 9.37711 19.9388 9.23825C19.9374 9.0994 19.8815 8.9669 19.7837 8.87041C15.45 4.75557 8.73874 4.75557 4.40505 8.87041C4.30716 8.9669 4.25131 9.09946 4.25002 9.23836C4.24874 9.37726 4.30213 9.51088 4.39822 9.60924L6.21851 11.4871C6.40615 11.6789 6.70936 11.683 6.90184 11.4963C8.32403 10.1817 10.1739 9.45338 12.0925 9.45256ZM15.7952 13.2354C15.798 13.3747 15.7444 13.5089 15.647 13.6065L12.4973 16.851C12.405 16.9463 12.2791 17 12.1478 17C12.0164 17 11.8906 16.9463 11.7982 16.851L8.64807 13.6065C8.55076 13.5089 8.49723 13.3746 8.50011 13.2353C8.503 13.0961 8.56205 12.9642 8.66332 12.8709C10.6748 11.1343 13.6208 11.1343 15.6323 12.8709C15.7335 12.9643 15.7924 13.0962 15.7952 13.2354Z"
          fill="#242A34"
        />
      </svg>
    </div>
  );
};
