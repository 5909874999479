import React, { useEffect, useState } from "react";
import "./EditDeviceNameModal.css";
import { Modal } from "react-bootstrap";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { updateDeviceName } from "redux/Devices/actions";
import { isEmpty, isSessionTimeExpired } from "utility";
import { useHistory } from "react-router-dom";
import alerts from "constants/alerts";
import SnackBar from "components/widgets/snack-bar/SnackBar";
import Loader from "react-spinners/ClipLoader";
import SessionExpiredPopup from "../session-expired-popup/SessionExpiredPopup";

const EditDeviceNameModal = ({ show, setShow, onCancel }) => {
  const [deviceName, setDeviceName] = useState("");
  const [verificationPopUp, setVerificationPopUp] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const { selectedDeviceDetails, loading } = useSelector(
    (store) => store?.devices
  );
  const userData = useSelector((store) => store?.auth?.userData);
  const { selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (show) {
      setDeviceName(selectedDeviceDetails?.device_name);
    } else {
      setTimeout(() => {
        setSnackBarMessage({ message: "" });
      }, 2000);
    }
  }, [show]);

  const onClose = () => {
    setShow(false);
  };

  const onUpdateDeviceName = () => {
    let payload = {
      medical_alert_user_id: selectedDeviceDetails?.medical_alert_user_id,
      user_device_id: selectedDeviceDetails?.user_device_id,
      device_name: deviceName,
    };
    updateDeviceName({
      dispatch,
      payload,
      history,
      setSnackBarMessage,
      onClose,
      medical_alert_user_id: selectedMedicalUser?.medical_alert_user_id,
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
    });
  };

  const onSaveChanges = async () => {
    let timeDifference = await isSessionTimeExpired();
    if (isEmpty(deviceName)) {
      setSnackBarMessage({ message: alerts?.enter_device_name });
    } else if (timeDifference && !userData?.isGuestUser) {
      setVerificationPopUp(true);
      return;
    } else {
      onUpdateDeviceName();
    }
  };

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="edm_container"
      show={show}
    >
      <Modal.Body className="edm_body">
        <SnackBar snackBarMessage={snackBarMessage} />
        {loading ? (
          <div className="layout_loader_container">
            <Loader color={"#248ca7"} />
          </div>
        ) : null}
        <div className="edm_header">
          <p className="display_h5 edm_title">{titles?.set_device_name}</p>
          <p className="body_h1 edm_name_instruction">
            {titles?.set_device_name_instruction}
          </p>
        </div>
        <div className="edm_content_section">
          <p className="body_h1 edm_input_label">{titles?.device_name}</p>
          <p className="body_h2 edm_input_note">
            {16 - deviceName?.length} characters left
          </p>
          <input
            placeholder={titles?.device_name_placeholder}
            className="edm_input_field mb10"
            type={"text"}
            value={deviceName}
            onChange={(e) => {
              setDeviceName(e?.target?.value);
            }}
          />
          <CustomButton
            className={"edm_card_btn edm_save_btn"}
            text={titles?.save_changes}
            width={"100%"}
            onClick={() => {
              onSaveChanges();
            }}
          />
          <CustomButton
            className={"edm_card_btn mb15"}
            text={titles?.cancel}
            width={"100%"}
            onClick={() => {
              onCancel();
              setShow(!show);
            }}
          />
        </div>
      </Modal.Body>
      {verificationPopUp ? (
        <SessionExpiredPopup
          isVisible={verificationPopUp}
          onClosePopup={(parentPopupHide) => {
            setVerificationPopUp(false);
            if (parentPopupHide) {
              onClose();
            }
          }}
          history={history}
        />
      ) : null}
    </Modal>
  );
};

export default EditDeviceNameModal;
