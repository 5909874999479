import { getApi, postApi } from "../../api/commonApi";
import {
  ACCOUNT_HOLDER_DETAILS_REQUEST,
  ACCOUNT_HOLDER_DETAILS_FAILURE,
  ACCOUNT_HOLDER_DETAILS_SUCCESS,
  ACCOUNT_HOLDER_UPDATE_REQUEST,
  ACCOUNT_HOLDER_UPDATE_SUCCESS,
  ACCOUNT_HOLDER_UPDATE_FAILURE,
  ESIGN_HISTORY_FAILURE,
  ESIGN_HISTORY_SUCCESS,
  ESIGN_HISTORY_REQUEST,
} from "./actionTypes";
import apis from "../../api/apiEndPoints";
import keys from "../../constants/keys";
import {
  getData,
  handleResponses,
  handleUnAuthorizedAccess,
} from "../../utility";
import { BASE_URL } from "constants/routes/api";

export const getAccountOwnerDetails = async (dispatch, history) => {
  const API = BASE_URL + apis?.accountOwnerDetails;
  let accessToken = await getData(keys?.accessTokenKey);

  // useful
  console.log("accessToken", accessToken);

  returnToDispatch(dispatch, ACCOUNT_HOLDER_DETAILS_REQUEST);
  getApi(API, accessToken, "accountowner")
    .then(async (res) => {
      if (res?.data?.code === 200) {
        const accountHolderDetail = res?.data?.data;
        localStorage.setItem(
          keys.accountHolderUserdetail,
          JSON.stringify(accountHolderDetail)
        );
        returnToDispatch(
          dispatch,
          ACCOUNT_HOLDER_DETAILS_SUCCESS,
          accountHolderDetail
        );
      } else {
        returnToDispatch(dispatch, ACCOUNT_HOLDER_DETAILS_FAILURE);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, ACCOUNT_HOLDER_DETAILS_FAILURE, error);
    });
};

export const updateAccountOwnerDetails = async (
  dispatch,
  payload,
  history,
  setSnackBarMessage,
  setChangesSaveModal
) => {
  const API = BASE_URL + apis?.accountOwnerDetails;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, ACCOUNT_HOLDER_UPDATE_REQUEST, true);
  postApi(API, accessToken, payload, "accountowner")
    .then(async (response) => {
      if (response?.data?.data) {
        returnToDispatch(dispatch, ACCOUNT_HOLDER_UPDATE_SUCCESS, false);
        setChangesSaveModal(true);
        // logCustomEvents(
        //   "update",
        //   "engagement",
        //   "Account Details Update",
        //   payload?.name
        // );
        getAccountOwnerDetails(dispatch, history);
        setTimeout(() => {
          setChangesSaveModal(false);
          history?.goBack();
        }, 2000);
      } else {
        returnToDispatch(dispatch, ACCOUNT_HOLDER_UPDATE_FAILURE, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, ACCOUNT_HOLDER_UPDATE_FAILURE, false);
    });
};

export const getEsignHistoryList = async (
  dispatch,
  history,
) => {
  const API = BASE_URL + apis?.getSignHistory;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, ESIGN_HISTORY_REQUEST);
  getApi(API, accessToken, "accountowner")
    .then(async (res) => {
      if (res?.data?.code === 200 && res?.data?.data && res?.data?.data?.length > 0) {
        returnToDispatch(
          dispatch,
          ESIGN_HISTORY_SUCCESS,
          res?.data?.data?.[0]
        );
      } else {
        returnToDispatch(dispatch, ESIGN_HISTORY_FAILURE);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, ESIGN_HISTORY_FAILURE, error);
    });
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
