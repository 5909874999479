import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import authReducer from "./auth/reducers";
import adminScreenReducer from "./AdminScreens/reducers";
import commonReducer from "./Common/reducers";
import guestUserReducer from "./ManageGuestUser/reducers";
import userDetailsReducer from "./UserDetails/reducers";
import paymentReducer from "./Payments/reducers";
import notificationReducer from "./Notifications/reducers";
import medicalAlertUserReducer from "./MedicalAlertUsers/reducers";
import deviceReducer from "./Devices/reducers";
import emergencyReducer from "./EmergencyPlan/reducers";

let middleware = [];
middleware = [thunkMiddleware];

export const store = configureStore({
  reducer: {
    auth: authReducer,
    guestUser: guestUserReducer,
    common: commonReducer,
    userDetail: userDetailsReducer,
    notifications: notificationReducer,
    medicalAlertUsers: medicalAlertUserReducer,
    payments: paymentReducer,
    adminUser: adminScreenReducer,
    devices: deviceReducer,
    emergencyData: emergencyReducer,
  },
  middleware,
  enhancers: [applyMiddleware(thunkMiddleware)],
  devTools: true,
});