export const MEDICAL_USER_LIST_REQUEST = "MEDICAL_USER_LIST_REQUEST";
export const MEDICAL_USER_LIST_SUCCESS = "MEDICAL_USER_LIST_SUCCESS";
export const MEDICAL_USER_LIST_FAILURE = "MEDICAL_USER_LIST_FAILURE";

export const MEDICAL_USER_LOADER_VISIBILITY = "MEDICAL_USER_LOADER_VISIBILITY";

export const SELECTED_MEDICAL_USER = "SELECTED_MEDICAL_USER";

export const INVOICE_LIST_REQUEST= "INVOICE_LIST_REQUEST";
export const INVOICE_LIST_SUCCESS = "INVOICE_LIST_SUCCESS";

export const MEDICAL_USER_DETAIL_REQUEST= "MEDICAL_USER_DETAIL_REQUEST";
export const MEDICAL_USER_DETAIL_SUCCESS = "MEDICAL_USER_DETAIL_SUCCESS";
export const MEDICAL_USER_PULL_REFRESH_VISIBILITY= "MEDICAL_USER_PULL_REFRESH_VISIBILITY";

export const ORDER_HISTORY_REQUEST = "ORDER_HISTORY_REQUEST";
export const ORDER_HISTORY_SUCCESS = "ORDER_HISTORY_SUCCESS";
export const ACCOUNT_HOLDER_PULL_REFRESH = "ACCOUNT_HOLDER_PULL_REFRESH";