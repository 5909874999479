import React, { useEffect, useState } from "react";
import "./AddGuestUserModal.css";
import { Modal } from "react-bootstrap";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isSessionTimeExpired, isValidPassword } from "utility";
import alerts from "constants/alerts";
import { useHistory } from "react-router-dom";
import { addGuestUser, updateGuestUser } from "redux/ManageGuestUser/actions";
import SnackBar from "components/widgets/snack-bar/SnackBar";
import Loader from "react-spinners/ClipLoader";
import CustomInput from "components/widgets/custom-input/CustomInput";
import SessionExpiredPopup from "../session-expired-popup/SessionExpiredPopup";

const AddGuestUserModal = ({ mode, show, setShow, guestDetail }) => {
  const [addGuestUserState, setGuestUserState] = useState({
    name: "",
    password: "",
    guestUserID: null,
  });
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const [verificationPopUp, setVerificationPopUp] = useState(false);
  const accountHolderData = useSelector(
    (store) => store?.userDetail?.accountHolderData
  );
  const loading = useSelector((store) => store?.guestUser?.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setGuestUserState({
      guestUserID: mode === "edit" ? guestDetail?.guest_user_id : null,
      name: mode === "edit" ? guestDetail?.guest_user_name : "",
      password: mode === "edit" ? guestDetail?.password : "",
    });
    if (!mode) {
      setTimeout(() => {
        setSnackBarMessage({ message: "" });
      }, 400);
    }
  }, [show]);

  const onClose = () => {
    setShow("");
  };

  const onSaveChanges = async () => {
    let timeDifference = await isSessionTimeExpired();
    if (isEmpty(addGuestUserState?.name)) {
      setSnackBarMessage({ message: alerts?.enter_guest_user_name });
    } else if (isEmpty(addGuestUserState?.password)) {
      setSnackBarMessage({ message: alerts?.enter_guest_user_password });
    } else if (!isValidPassword(addGuestUserState?.password, 6)) {
      setSnackBarMessage({ message: alerts?.password_minimum_message });
    } else if (timeDifference) {
      setVerificationPopUp(true);
      return;
    } else {
      const params = {
        dispatch,
        payload: {
          guest_user_id:
            mode === "edit" ? addGuestUserState?.guestUserID : undefined,
          guest_user_name: addGuestUserState?.name,
          password: addGuestUserState?.password,
        },
        setSnackBarMessage,
        onClose,
        history,
      };

      if (mode === "edit") {
        updateGuestUser(params);
      } else {
        addGuestUser(params);
      }
    }
  };

  const onValueChange = (type, e) => {
    let newText = e?.target?.value;
    if (type === "password") {
      newText = newText?.replace(/\s/g, "");
    }
    setGuestUserState((prev) => {
      return { ...prev, [type]: newText };
    });
  };

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="agu_container"
      show={show}
    >
      <Modal.Body className="agu_body">
        <SnackBar snackBarMessage={snackBarMessage} />
        {loading ? (
          <div className="layout_loader_container">
            <Loader color={"#248ca7"} />
          </div>
        ) : null}
        <div className="agu_header">
          <p className="display_h5 agu_title">{titles?.add_your_guest}</p>
          <p className="body_h1 agu_name_instruction">
            {titles?.passwords_must_characters}
          </p>
        </div>
        <div className="agu_content_section">
          <p className="body_h1 agu_input_label">{titles?.guest_name}</p>
          <CustomInput
            required={true}
            placeholderStyle={"agu_holder_placeholder"}
            placeholder={titles?.guest_name_placeholder_text}
            className="agu_input_field mb10"
            type={"text"}
            value={addGuestUserState?.name}
            onChange={(e) => onValueChange("name", e)}
          />
          <p className="body_h1 agu_input_label">
            {titles?.account_holder_email}
          </p>
          <CustomInput
            required={true}
            placeholderStyle={"agu_holder_placeholder"}
            placeholder={titles?.acc_holder_email_placeholder_text}
            className="agu_input_field mb10"
            type={"email"}
            value={`**${accountHolderData?.email}**`}
            disabled={true}
          />
          <p className="body_h1 agu_input_label">{titles?.guest_password}</p>
          <CustomInput
            required={true}
            placeholderStyle={"agu_holder_placeholder"}
            placeholder={titles?.unique_guest_password}
            className="agu_input_field mb10"
            type={"text"}
            value={addGuestUserState?.password}
            onChange={(e) => onValueChange("password", e)}
          />
          <CustomButton
            className={"agu_card_btn agu_save_btn"}
            text={titles?.save_changes}
            width={"100%"}
            onClick={() => {
              onSaveChanges();
            }}
          />
          <CustomButton
            className={"agu_card_btn"}
            text={titles?.cancel}
            width={"100%"}
            onClick={() => {
              setShow("");
            }}
          />
        </div>
      </Modal.Body>
      {verificationPopUp ? (
        <SessionExpiredPopup
          isVisible={verificationPopUp}
          onClosePopup={(parentPopupHide) => {
            setVerificationPopUp(false);
            if (parentPopupHide) {
              onClose();
            }
          }}
          history={history}
        />
      ) : null}
    </Modal>
  );
};

export default AddGuestUserModal;
