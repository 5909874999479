import keys from "constants/keys";
import {
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
  MEDICAL_USER_DETAIL_REQUEST,
  MEDICAL_USER_DETAIL_SUCCESS,
  MEDICAL_USER_LIST_FAILURE,
  MEDICAL_USER_LIST_REQUEST,
  MEDICAL_USER_LIST_SUCCESS,
  MEDICAL_USER_LOADER_VISIBILITY,
  ORDER_HISTORY_REQUEST,
  ORDER_HISTORY_SUCCESS,
  SELECTED_MEDICAL_USER,
  ACCOUNT_HOLDER_PULL_REFRESH,
  MEDICAL_USER_PULL_REFRESH_VISIBILITY,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  accountRefresh: false,
  subscriberPullRefreshing: false,
  medicalAlertUserList: [],
  selectedMedicalUser: (localStorage.getItem(keys.selectedMedicalUser) !== null && localStorage.getItem(keys.selectedMedicalUser) !== "undefined")
  ? JSON.parse(localStorage.getItem(keys.selectedMedicalUser))
  : null,
  invoiceList: [],
  medicalUserDetail: null,
  orderList: [],
};

const medicalAlertUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MEDICAL_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MEDICAL_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        medicalAlertUserList: action?.payload,
      };
    case MEDICAL_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        medicalAlertUserList: [],
      };
    case MEDICAL_USER_LOADER_VISIBILITY:
      return {
        ...state,
        loading: action?.payload,
      };
    case INVOICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        invoiceList: [],
      };
    case INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceList: action?.payload,
      };
    case SELECTED_MEDICAL_USER:
      return {
        ...state,
        selectedMedicalUser: action?.payload,
      };
    case MEDICAL_USER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        medicalUserDetail: null,
      };
    case MEDICAL_USER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        medicalUserDetail: action?.payload,
      };
    case ORDER_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        orderList: [],
      };
    case ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        orderList: action?.payload,
      };
    case ACCOUNT_HOLDER_PULL_REFRESH:
      return {
        ...state,
        accountRefresh: action?.payload,
      };
    case MEDICAL_USER_PULL_REFRESH_VISIBILITY:
      return {
        ...state,
        subscriberPullRefreshing: action?.payload,
      };
    default:
      return state;
  }
};

export default medicalAlertUserReducer;
