import React, { useState, useEffect } from "react";
import "./SnackBar.css"; // You can create a CSS file for styling

const SnackBar = ({ snackBarMessage, duration = 2000 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (snackBarMessage?.message) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [snackBarMessage, duration]);

  return (
    <div className="snackbar_main_container">
      <div>
        <div
          className={`snackbar ${isVisible ? "show" : ""} ${
            snackBarMessage?.isSuccess ? "snackbar_success" : ""
          }`}
        >
          <div className="new_line_text body_h2 snackbar_text">
            {snackBarMessage?.message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnackBar;
