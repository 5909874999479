export const PAYMENT_LOADER_VISIBILITY = "PAYMENT_LOADER_VISIBILITY";

export const ADD_EDIT_CARD_REQUEST = "ADD_EDIT_CARD_REQUEST";
export const ADD_EDIT_CARD_SUCCESS = "ADD_EDIT_CARD_SUCCESS";

export const GET_CARD_REQUEST = "GET_CARD_REQUEST";
export const GET_CARD_SUCCESS = "GET_CARD_SUCCESS";

export const GET_BANK_REQUEST = "GET_BANK_REQUEST";
export const GET_BANK_SUCCESS = "GET_BANK_SUCCESS";

export const ADD_EDIT_BANK_REQUEST = "ADD_EDIT_BANK_REQUEST";
export const ADD_EDIT_BANK_SUCCESS = "ADD_EDIT_BANK_SUCCESS";

export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";

export const CARD_LIST_SUCCESS = "CARD_LIST_SUCCESS";

export const ACCOUNT_TYPE_SUCCESS = "ACCOUNT_TYPE_SUCCESS";
