import React from "react";
import "./SignalDetailCard.css";
import { AvatarImage } from "assets/SVGs/SVGs";
import { titles } from "constants/titles";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { convertDateFormate } from "utility";
import { useHistory } from "react-router-dom";

const SignalDetailCard = ({ className, signalData, onCardClick }) => {
  const history = useHistory();
  return (
    <div
      className={`${className} sdc_container`}
      onClick={() => onCardClick(signalData)}
    >
      <div className="sdc_img_container">
        {signalData?.image_url ? (
          <img
            src={signalData?.image_url}
            alt="Avatar_User"
            className="sdc_device_image"
          />
        ) : (
          <AvatarImage height="50" width="50" />
        )}
      </div>
      <div className="sdc_right_container">
        <p className="body_h2">{convertDateFormate(signalData?.date)}</p>
        <p className="body_h2">{signalData?.device_name}</p>
        {signalData?.signal ? (
          <p className="body_h2 ">{signalData?.signal}</p>
        ) : null}
        {signalData?.latitude ? (
          <p
            className="sdc_see_map body_h3"
            onClick={() => {
              history.push(ROUTES_CONSTANTS.caregiverTracking, {
                isFromSignalHistory: true,
                item: signalData,
              });
            }}
          >
            {titles?.see_map}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default SignalDetailCard;
