import React, { useEffect, useState } from "react";
import "./NotificationSetting.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "redux/Notifications/actions";
import { isSessionTimeExpired } from "utility";
import { setSessionExpiryModalVisibility } from "redux/auth/actions";
import CustomSelectBox from "components/widgets/custom-select-box/CustomSelectBox";

const NotificationSetting = () => {
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDeviceId, setselectedDeviceIdId] = useState("");
  const [selectedDevice, setSelectedDevice] = useState({});
  const isLoading = useSelector((store) => store?.notifications?.loading);
  const settingsList = useSelector(
    (store) => store?.notifications?.notificationSettings
  );
  const userData = useSelector((store) => store?.auth?.userData);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      let params = {
        userType: userData?.isGuestUser ? "guestuser" : "accountowner",
        history,
        dispatch,
      };
      getNotificationSettings(params);
    }, 600);
  }, []);

  useEffect(() => {
    if (settingsList) {
      const list = settingsList || [];
      const filtedList = list?.filter(
        (item) => item?.notifications_settings !== null
      );
      setNotificationSettings(filtedList);
      setDeviceList(
        filtedList?.map((item, index) => {
          return {
            label:
              item?.device_id +
              " - " +
              (item?.device_type ? item?.device_type?.toLowerCase() : ""),
            id: index + 1,
            value: item?.device_id,
          };
        })
      );
    }
  }, [settingsList]);

  const handleChange = async (item) => {
    let timeDifference = await isSessionTimeExpired();
    if (timeDifference && !userData?.isGuestUser) {
      setSessionExpiryModalVisibility(dispatch, true);
      return;
    } else {
      let params = {
        userType: userData?.isGuestUser ? "guestuser" : "accountowner",
        setSnackBarMessage,
        history,
        dispatch,
        item,
      };
      updateNotificationSettings(params);
    }
  };

  const handleDropdownSelect = (e) => {
    setselectedDeviceIdId(e?.value);
    setSelectedDevice(
      notificationSettings?.filter((item) => item?.device_id === e?.value)
    );
  };

  return (
    <MainLayout
      text={titles?.notifications}
      isBackHide={false}
      isSemiHeader={true}
      white={false}
      isLoading={isLoading}
      snackBarMessage={snackBarMessage}
    >
      {deviceList && deviceList?.length > 0 ? (
        <>
          <p className="feedback_desc_text hc_color body_h1 mb10">
            {titles?.select_device}
          </p>
          <CustomSelectBox
            selectedValue={selectedDeviceId}
            onChange={handleDropdownSelect}
            options={deviceList}
            title={titles?.select_device}
            className={"cs_select_dropdown"}
          />
          {selectedDevice &&
            selectedDevice?.length > 0 &&
            selectedDevice[0]?.notifications_settings?.map((alert, index) => {
              return (
                <>
                  <div
                    key={`alert ${index}`}
                    className="ns_notification_container"
                  >
                    <p className="body_h1 ns_text">
                      {alert?.notification_type}
                    </p>
                    <Form.Check
                      id="custom-switch"
                      checked={alert?.is_enabled}
                      type="switch"
                    />
                  </div>
                  {index === 0 ? <div className=" ns_h_line"></div> : null}
                </>
              );
            })}
        </>
      ) : (
        <p className="feedback_desc_text hc_color body_h1 mb10">
          {"No active device found."}
        </p>
      )}
    </MainLayout>
  );
};

export default NotificationSetting;
