import React, { useState } from "react";
import "./CardLayoutWrapper.css";
import { AvatarImage } from "assets/SVGs/SVGs";

const CardLayoutWrapper = ({ children, image_url, className }) => {
  const [isImgError, setImgError] = useState(false);
  return (
    <div className="card_wrapper_container">
      <div className={`card_wrapper_img_container ${className}`}>
        {image_url && !isImgError ? (
          <img
            src={image_url}
            alt="Avatar_User"
            width={72}
            height={72}
            onError={() => setImgError(true)}
          />
        ) : (
          !isImgError ?  <AvatarImage height="70" width="70" /> : null
        )}
      </div>
      <div className="children_wrapper_div">{children}</div>
    </div>
  );
};

export default CardLayoutWrapper;
