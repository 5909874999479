import { getApi, postApi } from "../../api/commonApi";
import {
  EMERGENCY_CONTACT_LOADER_VISIBILITY,
  GET_MERGENCY_DATA_SUCCESS,
  RELATION_LIST_SUCCESS,
} from "./actionTypes";
import apis from "../../api/apiEndPoints";
import keys from "../../constants/keys";
import {
  decryptData,
  getData,
  handleResponses,
  handleUnAuthorizedAccess,
  storeData,
} from "../../utility";
import { BASE_URL } from "constants/routes/api";

export const getRelationList = async (params) => {
  const { dispatch, history, userType } = params;
  const API = BASE_URL + apis?.emergencyRelationList;
  let accessToken = await getData(keys?.accessTokenKey);

  returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, true);

  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        returnToDispatch(dispatch, RELATION_LIST_SUCCESS, res?.data?.data);
      } else {
        returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, false);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, false);
    });
};

export const getEmergencyData = async (params) => {
  const { dispatch, history, userType, emergency_contract_id } = params;
  const API =
    BASE_URL +
    apis?.getEmergencyPlan +
    `?emergency_contract_id=${emergency_contract_id}`;
  let accessToken = await getData(keys?.accessTokenKey);

  returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, true);

  getApi(API, accessToken, userType)
    .then(async (res) => {
      if (res?.data?.code === 200) {
        const data = decryptData(res?.data?.data);
        returnToDispatch(
          dispatch,
          GET_MERGENCY_DATA_SUCCESS,
          data ? JSON.parse(data) : null
        );
      } else {
        returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, false);
        if (res?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, false);
    });
};

export const updateEmergencyPlan = async (
  dispatch,
  payload,
  setSnackBarMessage,
  history,
  setPopUpMode
) => {
  const API = BASE_URL + apis?.getEmergencyPlan;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, true);
  postApi(API, accessToken, payload, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, false);
        setPopUpMode({ mode: "changesSaved" });
        // logCustomEvents(
        //   "update",
        //   "engagement",
        //   "Update Emergency Plan",
        //   payload?.emergency_contract_id
        // );
        getEmergencyData({
          dispatch,
          setSnackBarMessage,
          history,
          userType: "accountowner",
          emergency_contract_id: payload?.emergency_contract_id,
        });
        storeData(keys?.isDetailUpdate,'true');
        setTimeout(() => {
          setPopUpMode({ mode: "" });
          history?.goBack();
        }, 2000);
      } else {
        returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch(() => {
      returnToDispatch(dispatch, EMERGENCY_CONTACT_LOADER_VISIBILITY, false);
    });
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
