import React, { useEffect, useRef, useState } from "react";
import "./HelpCenter.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { Link, useHistory } from "react-router-dom";
import { IconTrash } from "assets/SVGs/SVGs";
import { FEEDBACK_RESET_VALUES } from "redux/auth/actionTypes";
import {
  getContactUsApi,
  getFeedBackSubType,
  getFeedBackType,
  submitFeedback,
} from "redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "utility";
import alerts from "constants/alerts";
import ChangesSavedModal from "components/layout/changes-saved-modal/ChangesSavedModal";
import CustomSelectBox from "components/widgets/custom-select-box/CustomSelectBox";

const HelpCenter = () => {
  const [file, setFile] = useState(null);
  const [feedbackValue, setFeedbackValue] = useState("");
  const [selectedSubIssue, setSelectedSubIssue] = useState("");
  const [changesSavedModal, setChangesSaveModal] = useState({
    visibility: false,
    content: "",
  });
  const [selectedValue, setSelectedValue] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const data = useSelector((store) => store?.auth?.contactUsDetails);
  const isLoading = useSelector((store) => store?.auth?.loading);
  const userData = useSelector((store) => store?.auth?.userData);
  const feedBackType = useSelector((store) => store?.auth?.feedBackType);
  const feedBackSubType = useSelector((store) => store?.auth?.subIssues);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const history = useHistory();
  const callLinkRef = useRef(null);

  useEffect(() => {
    getContactUsApi(
      dispatch,
      !userData?.isGuestUser ? "accountowner" : "guestuser"
    );
    getFeedBackType(
      dispatch,
      !userData?.isGuestUser ? "accountowner" : "guestuser"
    );

    return () => {
      dispatch({
        type: FEEDBACK_RESET_VALUES,
        payload: [],
      });
      setSelectedValue("");
      setSelectedSubIssue("");
    };
  }, []);

  const getResponseOfFeedback = (value) => {
    setChangesSaveModal({
      visibility: true,
      content: value,
    });
  };

  const handleOnSubmitFeedback = async () => {
    // let timeDifference = await isSessionTimeExpired();
    if (isEmpty(selectedValue)) {
      setSnackBarMessage({ message: alerts?.select_feedback_type });
    } else if (
      feedBackSubType?.length > 0 &&
      !isEmpty(selectedValue) &&
      isEmpty(selectedSubIssue)
    ) {
      setSnackBarMessage({ message: alerts?.select_sub_feedback_type });
    } else if (isEmpty(feedbackValue)) {
      setSnackBarMessage({ message: alerts?.enter_feedback_value });
    } else {
      let data = new FormData();
      data.append("issue_type_id", parseInt(selectedValue));
      data.append("description", feedbackValue);
      //  kept this for reference in case required
      // const lastFile = new Blob([document?.uri], { type: "mimeType" });

      if (file && file?.name?.length > 0) {
        data.append("", file, file?.name);
      }
      if (selectedSubIssue) {
        data.append("issue_id", selectedSubIssue);
      }
      submitFeedback(
        dispatch,
        data,
        !userData?.isGuestUser ? "accountowner" : "guestuser",
        getResponseOfFeedback,
        history,
        setSnackBarMessage
      );
    }
  };

  const handleLinkClick = (event, call) => {
    event.preventDefault();
    if (call) {
      callLinkRef.current.click();
    } else {
      fileInputRef.current.click();
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e?.target;
    if (name === "feedback_desc") {
      setFeedbackValue(value);
    } else {
      setFile(files[0]);
    }
  };

  const getFeedBack = (feedBackType = []) => {
    const updateList = feedBackType?.map((ele) => {
      return {
        label: ele?.issue_type,
        value: ele?.issue_type_id,
        id: ele?.issue_type_id,
      };
    });
    return updateList;
  };

  const handleDropdownSelect = (e) => {
    setSelectedValue(e?.value);
    setSelectedSubIssue("");
    getFeedBackSubType(
      dispatch,
      !userData?.isGuestUser ? "accountowner" : "guestuser",
      { issueType: e?.value }
    );
  };

  const getSubFeedBackType = (feedBacksubType = []) => {
    const updateList = feedBacksubType?.map((ele) => {
      return {
        label: ele?.issue,
        value: ele?.issue_id,
        id: ele?.issue_id,
      };
    });
    return updateList;
  };

  const handleDropdownSelectSubIssue = (e) => {
    setSelectedSubIssue(e?.value);
  };

  return (
    <MainLayout
      text={titles?.help_center}
      isBackHide={false}
      isSemiHeader={true}
      white={false}
      isLoading={isLoading}
      snackBarMessage={snackBarMessage}
    >
      <ChangesSavedModal
        show={changesSavedModal?.visibility}
        setShow={setChangesSaveModal}
      />
      <p className="display_h4 hc_color mb15">
        {titles?.help_center_assist_text}
      </p>
      <p className={"hc_color body_h1 mb15"}>{titles?.email}</p>
      <div className="mb15">
        <p className={"hc_color display_h4"}>
          {titles?.help_center_customer_service}
        </p>
        <p
          className={"hc_color body_h1"}
        >{`Mon to Fri, ${data?.weekday_hours} ${data?.timezone}`}</p>
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = `mailto:${data?.customer_care_email}`;
            e.preventDefault();
          }}
          className={"hc_color body_h1"}
        >
          {data?.customer_care_email}
        </Link>
        {data?.weenend_hours && data?.weenend_hours?.length > 0 ? (
          <p className="hc_color body_h1">
            {`Sat Sun, ${data?.weenend_hours} ${data?.timezone}`}
          </p>
        ) : null}
      </div>
      <div className="mb15">
        <p className={"hc_color display_h4"}>{titles?.help_center_billing}</p>
        <p
          className={"hc_color body_h1"}
        >{`Mon to Fri, ${data?.billing_service_hours_weekdays} ${data?.timezone}`}</p>
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = `mailto:${data?.billing_service_email}`;
            e.preventDefault();
          }}
          className={"hc_color body_h1"}
        >
          {data?.billing_service_email}
        </Link>
        {data?.billing_service_hours_weekends &&
        data?.billing_service_hours_weekends?.length > 0 ? (
          <p className="hc_color body_h1">
            {`Sat Sun, ${data?.billing_service_hours_weekends} ${data?.timezone}`}
          </p>
        ) : null}
      </div>
      <div className="mg_horizontal_line"></div>
      <p className={"hc_color body_h1 mb15"}>{titles?.phone}</p>
      <CustomButton
        width={"250px"}
        height={"50px"}
        className={"blue_button hc_contact_btn mb20 text-white-color"}
        text={data?.customer_care_no}
        onClick={(e) => handleLinkClick(e, true)}
      />
      <p className="feedback_desc_text hc_color body_h1 mb10">
        {titles?.issue_type}
      </p>
      <CustomSelectBox
        selectedValue={selectedValue}
        onChange={handleDropdownSelect}
        options={getFeedBack(feedBackType)}
        title={titles?.select_issue_type}
        className={"cs_select_dropdown"}
      />
      <p className="feedback_desc_text hc_color body_h1 mb10">
        {titles?.issue}
      </p>
      <CustomSelectBox
        disabled={feedBackSubType?.length > 0 ? false : true}
        selectedValue={selectedSubIssue}
        onChange={handleDropdownSelectSubIssue}
        options={getSubFeedBackType(feedBackSubType)}
        title={titles?.select_issue}
        className={"cs_select_dropdown"}
      />
      <div className="hc_desc_container">
        <p className="feedback_desc_text hc_color body_h1 mb10">
          {titles?.description}
        </p>
        <textarea
          onChange={handleChange}
          className="feedback_desc body_h1"
          placeholder={titles?.write_feedback}
          row={8}
          value={feedbackValue}
          name="feedback_desc"
        />
      </div>
      {!file ? (
        <a
          className="web_link cs_upload_doc_link"
          href="#"
          onClick={handleLinkClick}
        >
          {titles?.upload_media_and_upload}
        </a>
      ) : (
        <div className="selected_file_name">
          <p className="body_h1">{file?.name}</p>
          <IconTrash
            onClick={() => setFile(null)}
            className="hc_trash_icon"
            color="#000000"
          />
        </div>
      )}
      <input
        className="hc_file_input"
        type="file"
        onChange={handleChange}
        name="file"
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <CustomButton
        width={"250px"}
        height={"50px"}
        className={"blue_button hc_contact_btn text-white-color"}
        text={titles?.submit}
        onClick={() => handleOnSubmitFeedback()}
      />
      <a
        ref={callLinkRef}
        href={`tel:${data?.customer_care_no} alt="Call 555-555-5555"`}
      />
    </MainLayout>
  );
};

export default HelpCenter;
