import {
  IconCheckMark,
  IconHome,
  IconNavbarUser,
  IconSettings,
} from "assets/SVGs/SVGs";
import React, { useEffect, useState } from "react";
import "./CustomTabBar.css";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { useDispatch, useSelector } from "react-redux";
import { setActiveScreen } from "redux/auth/actions";
import { setSelectedMedicalUser } from "redux/MedicalAlertUsers/actions";
import { getNotificationList } from "redux/Notifications/actions";

const CustomTabBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { dashboardMenuList = [] } = useSelector((store) => store?.common);
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((store) => store?.auth?.userData);
  const { totalUnreadCount } = useSelector((store) => store?.notifications);
  const { medicalUserTimezone } = useSelector((store) => store?.common);

  useEffect(() => {
    switch (history.location.pathname) {
      case ROUTES_CONSTANTS.home:
        setSelectedTab(0);
        break;
      case ROUTES_CONSTANTS.deviceList:
      case ROUTES_CONSTANTS.deviceProfile:
      case ROUTES_CONSTANTS.deviceSignalHistory:
        setSelectedTab(1);
        break;
      case ROUTES_CONSTANTS.alerts:
        setSelectedTab(2);
        break;
      case ROUTES_CONSTANTS.settings:
      case ROUTES_CONSTANTS.notificationSetting:
      case ROUTES_CONSTANTS.manageGuestUsers:
      case ROUTES_CONSTANTS.about:
      case ROUTES_CONSTANTS.helpCenter:
      case ROUTES_CONSTANTS.cancelServices:
        setSelectedTab(3);
        break;
      default:
        setSelectedTab(0);
        break;
    }
    let params = {
      userType: userData?.isGuestUser ? "guestuser" : "accountowner",
      history,
      dispatch,
      fetchMore: false,
      page: 1,
    };
    getNotificationList(params, false, medicalUserTimezone);
    // eslint-disable-next-line
  }, []);

  const handleTabSelect = (index, route) => {
    history?.push(route?.menu_name?.toLowerCase());
    setSelectedTab(index);
    setActiveScreen(dispatch, route?.menu_name?.toLowerCase());
    if (route?.menu_key == "Devices") {
      setSelectedMedicalUser(dispatch, null);
    }
  };

  const tabs = [
    {
      menu_name: "Home",
      svg: <IconHome color={selectedTab === 0 ? "#248CA7" : "#F3F4F6"} />,
      path: ROUTES_CONSTANTS.home,
    },
    {
      menu_name: "Devices",
      svg: <IconNavbarUser color={selectedTab === 1 ? "#248CA7" : "#F3F4F6"} />,
      path: ROUTES_CONSTANTS.deviceList,
    },
    {
      menu_name: "Alerts",
      svg: <IconCheckMark color={selectedTab === 2 ? "#248CA7" : "#F3F4F6"} />,
      path: ROUTES_CONSTANTS.alerts,
    },
    {
      menu_name: "Settings",
      svg: <IconSettings color={selectedTab === 3 ? "#248CA7" : "#F3F4F6"} />,
      path: ROUTES_CONSTANTS.settings,
    },
  ];

  const tabBarList = dashboardMenuList?.length > 0 ? dashboardMenuList : tabs;
  return (
    <div className="smalldevice_navbar_container">
      {tabBarList?.map((tab, index) => {
        return (
          <div
            key={`tab` + index}
            className="nb_small_tab relative"
            onClick={() => handleTabSelect(index, tab)}
          >
            {tab?.svg ? (
              tab?.svg
            ) : (
              <img
                src={
                  selectedTab === index
                    ? tab?.menu_icon_selected
                    : tab?.menu_icon_deselected
                }
                className="tab_image"
                alt="tab_image"
              ></img>
            )}
            <h5
              className="nb_tabname display_h6"
              style={{ color: selectedTab === index ? "#248CA7" : "#F3F4F6" }}
            >
              {tab?.menu_name}
            </h5>
            {tab?.menu_key === "Alerts" && totalUnreadCount ? (
              <div className="tab_notif_count">
                <p className="display_h5 text-white-color">
                  {totalUnreadCount + ""}
                </p>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default CustomTabBar;
