import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { logo_bam_std } from "assets/images/images";
import MainLayout from "components/layout/main-layout/MainLayout.js";
import CustomButton from "components/widgets/custom-button/CustomButton.js";
import { titles } from "constants/titles";
import { getData } from "utility";
import keys from "constants/keys";
import { getDashboardMenuList } from "redux/Common/action";
import "./LandingScreen.css";

export const LandingScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    history.push(history.location.pathname);
    history.replace(history.location.pathname);
  }, [history]);

  const getUserInfo = async () => {
    let accessToken = await getData(keys?.accessTokenKey);
    let adminAccessToken = await getData(keys?.adminAccessTokenKey);
    if (accessToken?.length > 0) {
      getDashboardMenuList(dispatch);
      history?.push(ROUTES_CONSTANTS.home);
    } else if (adminAccessToken?.length > 0) {
      history?.push(ROUTES_CONSTANTS.customerLogin);
    }
  };

  return (
    <MainLayout isNavbar={false}>
      <div className="admin_sign_in_container">
        <div className="logo_bam_std_container">
          <img src={logo_bam_std} alt="logo_bam_std" />
        </div>
        <h2 className="intro_header_text display_h4">
          {titles?.number_one_alert_system} <br />
          {titles?.medical_alert_system}
        </h2>
        <CustomButton
          width={"250px"}
          height={"50px"}
          className={"blue_button admin_sign_in_btn text-white-color"}
          text={titles?.admin_sign_in}
          onClick={() => history.push(ROUTES_CONSTANTS.login)}
        />
        <p className="copyright_text body_h2">{titles?.bay_alarm_medical}</p>
        <h2 className="copyright_text body_h2">
          {titles?.all_rights_reserved}
        </h2>
      </div>
    </MainLayout>
  );
};

export default LandingScreen;
