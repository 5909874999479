export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const ACTIVE_SCREEN = "ACTIVE_SCREEN";

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";

export const GENERATE_OTP_REQUEST = "GENERATE_OTP_REQUEST";
export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS";
export const GENERATE_OTP_FAILURE = "GENERATE_OTP_FAILURE";

export const SESSION_EXPIRY_POPUP = "SESSION_EXPIRY_POPUP";

export const AUTH_LODER_VISIBILITY = "AUTH_LODER_VISIBILITY";

export const LOG_OUT_AND_CLEAR = "LOG_OUT_AND_CLEAR";

export const FEEDBACK_ISSUE_TYPE_REQUEST= "FEEDBACK_ISSUE_TYPE_REQUEST"
export const FEEDBACK_ISSUE_TYPE_SUCESS= "FEEDBACK_ISSUE_TYPE_SUCESS"
export const FEEDBACK_ISSUE_TYPE_FAILURE= "FEEDBACK_ISSUE_TYPE_FAILURE"

export const SUBMIT_FEEDBACK_REQUEST='SUBMIT_FEEDBACK_REQUEST'
export const SUBMIT_FEEDBACK_SUCESS='SUBMIT_FEEDBACK_SUCESS'
export const SUBMIT_FEEDBACK_FAILURE='SUBMIT_FEEDBACK_FAILURE'

export const FEEDBACK_ISSUE_SUB_TYPE_REQUEST= "FEEDBACK_ISSUE_SUB_TYPE_REQUEST"
export const FEEDBACK_ISSUE_SUB_TYPE_SUCESS= "FEEDBACK_ISSUE_SUB_TYPE_SUCESS"
export const FEEDBACK_ISSUE_SUB_TYPE_FAILURE= "FEEDBACK_ISSUE_SUB_TYPE_FAILURE"

export const FEEDBACK_RESET_VALUES="FEEDBACK_RESET_VALUES"