import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";
import { IconCalender } from "assets/SVGs/SVGs";

const CustomDatePicker = ({ className, selected, onChange }) => {
  const dateRef = useRef();
  return (
    <div className={`ci_container date_picker_container ${className}`}>
      <DatePicker
        ref={dateRef}
        selected={selected}
        onChange={onChange}
        dateFormat="MM/dd/yyyy"
        placeholderText="MM/DD/YYYY"
        className="ci_input"
      />
      <IconCalender className={"calender_icon"} />
    </div>
  );
};

export default CustomDatePicker;
