import {
  GUEST_USER_LIST_SUCCESS,
  GUEST_USER_LOGIN_SUCCESS,
  GUEST_USER_LOADER_VISIBILITY,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  guestUserData: {},
  guestUserList: [],
};

const guestUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GUEST_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        guestUserList: action?.payload,
      };
    case GUEST_USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        guestUserData: action?.payload,
      };
    case GUEST_USER_LOADER_VISIBILITY:
        return {
          ...state,
          loading: action?.payload,
        };
    default:
      return state;
  }
};

export default guestUserReducer;