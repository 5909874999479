import keys from "constants/keys";
import {
  DEVICES_LIST_SUCCESS,
  DEVICES_COMMON_LOADER_VISIBILITY,
  UPDATE_STEP_COUNTER_SUCCESS,
  SET_SELECTED_DEVICE_DETAILS,
  DEVICES_SIGNAL_LIST_SUCCESS,
  ANELTO_DEVICE_DETAILS_SUCCESS,
  SET_SELECTED_DEVICE_DETAIL,
  FREEUS_DEVICE_DETAILS_SUCCESS,
  ANELTO_TIME_INTERVAL_SUCCESS,
  FREEUS_TIME_INTERVAL_SUCCESS,
  DEVICES_SIGNAL_LIST_REQUEST,
  RING_DEVICE_SUCCESS,
  ANELTO_DEVICE_INITIAL_LOAD_REQUEST,
  ANELTO_DEVICE_INITIAL_LOAD_SUCCESS,
  ANELTO_DEVICE_SYNC_DETAIL_SUCCESS,
  FREE_US_DEVICE_INITIAL_LOAD_REQUEST,
  FREE_US_DEVICE_INITIAL_LOAD_SUCCESS,
  FREEUS_DEVICE_SYNC_DETAIL_SUCCESS,
  CLAIRVOYANT_DEVICE_DETAIL_SUCESS,
  CLAIRVOYANT_TIME_INTERVAL_SUCCESS,
  EVIEW_DEVICE_DETAIL_SUCESS,
  DEVICE_DETAIL_LOADER,
  EVIEW_DEVICE_TIMELINE_SUCESS,
  BREADCRUM_DEVICE_DETAIL_SUCCESS,
  BREADCRUM_DEVICE_DETAIL_REQUEST,
  BREADCRUM_DEVICE_DETAIL_LOADER,
  DEVICE_REFRESH_VISIBILITY,
  DEVICE_DETAIL_MANUAL_REFRESH_LOADER,
  SET_DEVICE_LAST_REFRESH_TIME,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  deviceList: [],
  deviceSignalHistory: [],
  selectedDeviceData: {},
  aneltoDeviceDetails: localStorage.getItem(keys.aneltoDeviceDetail)
    ? JSON.parse(localStorage.getItem(keys.aneltoDeviceDetail))
    : {},
  freeusDeviceDetails: localStorage.getItem(keys.freeusDeviceDetail)
    ? JSON.parse(localStorage.getItem(keys.freeusDeviceDetail))
    : {},
  clairvoyantDeviceDetails: localStorage.getItem(keys.clairvoyantDeviceDetail)
    ? JSON.parse(localStorage.getItem(keys.clairvoyantDeviceDetail))
    : {},
  selectedDeviceDetails: localStorage.getItem(keys.selectedDeviceDetail)
    ? JSON.parse(localStorage.getItem(keys.selectedDeviceDetail))
    : {},
  aneltoIntervals: [],
  freeusIntervals: [],
  clairvoyantIntervals: [],
  eViewIntervalArray: [],
  ringDeviceSuccess: false,
  lastDate: null,
  deviceDetailLoading: false,
  breadcrumData: [],
  devicePullRefresh:false,
  manualRefreshLoading: false,
  deviceRefreshData: null
};

const deviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DEVICE_LAST_REFRESH_TIME:
      return {
        ...state,
        deviceRefreshData: action?.payload,
      };
    case DEVICES_COMMON_LOADER_VISIBILITY:
      return {
        ...state,
        loading: action?.payload,
      };
      case DEVICE_REFRESH_VISIBILITY:
        return {
          ...state,
          devicePullRefresh: action?.payload,
        };
    case DEVICE_DETAIL_MANUAL_REFRESH_LOADER:
      return {
        ...state,
        manualRefreshLoading: action?.payload,
      };
    case DEVICE_DETAIL_LOADER:
      return {
        ...state,
        deviceDetailLoading: action?.payload,
      };
    case DEVICES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceList: action?.payload,
      };
    case UPDATE_STEP_COUNTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SET_SELECTED_DEVICE_DETAILS:
      return {
        ...state,
        loading: false,
        selectedDeviceData: action?.payload,
      };
    case DEVICES_SIGNAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        deviceSignalHistory: [],
      };
    case DEVICES_SIGNAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceSignalHistory: action?.payload?.data,
        lastDate: action?.payload?.last_signal_date,
      };
    case ANELTO_DEVICE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        deviceDetailLoading: false,
        aneltoDeviceDetails: action?.payload,
      };
    }

    case FREEUS_DEVICE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        deviceDetailLoading: false,
        freeusDeviceDetails: action?.payload,
      };
    }
    case CLAIRVOYANT_DEVICE_DETAIL_SUCESS: {
      return {
        ...state,
        loading: false,
        deviceDetailLoading: false,
        clairvoyantDeviceDetails: action?.payload,
      };
    }
    case EVIEW_DEVICE_DETAIL_SUCESS: {
      return {
        ...state,
        loading: false,
        eViewDeviceDetails: action?.payload,
      };
    }
    case SET_SELECTED_DEVICE_DETAIL: {
      return {
        ...state,
        loading: false,
        deviceDetailLoading: false,
        selectedDeviceDetails: action?.payload,
      };
    }

    case ANELTO_TIME_INTERVAL_SUCCESS: {
      return {
        ...state,
        aneltoIntervals: action?.payload,
      };
    }
    case FREEUS_TIME_INTERVAL_SUCCESS: {
      return {
        ...state,
        freeusIntervals: action?.payload,
      };
    }
    case CLAIRVOYANT_TIME_INTERVAL_SUCCESS: {
      return {
        ...state,
        clairvoyantIntervals: action?.payload,
      };
    }
    case EVIEW_DEVICE_TIMELINE_SUCESS: {
      return {
        ...state,
        eViewIntervalArray: action?.payload,
      };
    }
    case ANELTO_DEVICE_INITIAL_LOAD_REQUEST: {
      return {
        ...state,
        deviceDetailLoading: true,
        aneltoDeviceDetails: {},
        deviceRefreshData: null
      };
    }
    case ANELTO_DEVICE_INITIAL_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        aneltoDeviceDetails: action?.payload,
      };
    }
    case ANELTO_DEVICE_SYNC_DETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        aneltoDeviceDetails: action?.payload,
      };
    }
    case FREE_US_DEVICE_INITIAL_LOAD_REQUEST: {
      return {
        ...state,
        deviceDetailLoading: true,
        freeusDeviceDetails: {},
        deviceRefreshData: null
      };
    }
    case FREE_US_DEVICE_INITIAL_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        deviceDetailLoading: false,
        freeusDeviceDetails: action?.payload,
      };
    }
    case FREEUS_DEVICE_SYNC_DETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        deviceDetailLoading: false,
        manualRefreshLoading: false,
        freeusDeviceDetails: action?.payload,
      };
    }
    case RING_DEVICE_SUCCESS: {
      return {
        ...state,
        ringDeviceSuccess: action?.payload,
      };
    }
    case BREADCRUM_DEVICE_DETAIL_REQUEST:
      return {
        ...state,
        breadCrumloading: true,
      };
    case BREADCRUM_DEVICE_DETAIL_SUCCESS:
      return {
        ...state,
        breadcrumData: action?.payload,
        breadCrumloading: false,
      };
    case BREADCRUM_DEVICE_DETAIL_LOADER:
      return {
        ...state,
        breadCrumloading: action?.payload,
      };
    default:
      return state;
  }
};

export default deviceReducer;
