import React from "react";
import "./DeviceSignalCard.css";
import { titles } from "constants/titles";
import {
  getBatteryIcon,
  getBatteryTextColor,
  getSignalIcon,
  getSignalTextColor,
  getSignalType,
} from "utility/deviceDetailUtils";

const DeviceSignalCard = ({ statusUpdateAt, className, deviceInfo, type }) => {
  return (
    <div className={`${className} para_card_container dsc_width_full`}>
      <div className="pc_section_div mb10 dsc_width_full">
        <div className="pc_left_section_div dsc_width_full">
          <p className="pc_title_text display_h3 dsc_title_margin_bottom">
            {titles?.device_status}
          </p>
          <div className="dsc_signal_container dsc_width_full">
            {getBatteryIcon(deviceInfo?.batteryLife)}
            <p className="display_h4 dsc_margin_left">{titles?.battery}</p>
            <p className={`display_h4 dsc_status_value ${getBatteryTextColor(deviceInfo?.batteryLife)}`}>
            {deviceInfo?.batteryLife + "%"}
            </p>
          </div>
          {type !== "anelto" ? (
            <>
              <div className="ahh_user_item_seperator dsc_seperator"></div>
              <div className="dsc_signal_container dsc_width_full">
                {getSignalIcon(deviceInfo?.signal_strength)}
                <p className="display_h4 dsc_margin_left">{titles?.signal}</p>
                <p className={`display_h4 dsc_status_value ${getSignalTextColor(deviceInfo?.signal_strength)}`}>
                  {getSignalType(deviceInfo?.signal_strength)}
                </p>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <p className="pc_status_text">{statusUpdateAt}</p>
    </div>
  );
};

export default DeviceSignalCard;
