import React, { useEffect, useState } from "react";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import CardLayoutWrapper from "components/layout/card-layout-wrapper/CardLayoutWrapper";
import CustomInput from "components/widgets/custom-input/CustomInput";
import CustomDraggableCards from "components/layout/custom-draggable-cards/CustomDraggableCards";
import AddAccountContactModal from "components/layout/add-account-contact-modal/AddAccountContactModal";
import ConfirmationModal from "components/layout/confirmation-modal/ConfirmationModal";
import { IconQuestion, IconTrash } from "assets/SVGs/SVGs";
import CustomButton from "components/widgets/custom-button/CustomButton";
import CustomCheckbox from "components/widgets/custom-checkbox/CustomCheckbox";
import CustomSelectBox from "components/widgets/custom-select-box/CustomSelectBox";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  getAllRegionListNames,
  getDataFromId,
  isEmpty,
  isFutureDate,
  isSessionTimeExpired,
} from "utility";
import alerts from "constants/alerts";
import { setSessionExpiryModalVisibility } from "redux/auth/actions";
import {
  getPhoneTypeList,
  getRegionList,
  setSelectedPhoneType,
} from "redux/Common/action";
import moment from "moment";
import { updateMedicalAddress } from "redux/MedicalAlertUsers/actions";
import CustomDatePicker from "components/widgets/custom-date-picker/CustomDatePicker";
import { useHistory, useLocation } from "react-router-dom";
import ChangesSavedModal from "components/layout/changes-saved-modal/ChangesSavedModal";
import InformationModal from "components/layout/information-modal/InformationModal";

const EditSubscriberDetails = () => {
  const [isOpenDeleteContactModal, setOpenDeleteContactModal] = useState(false);
  const [subscriberState, setSubscriberState] = useState({
    profileName: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    apt: "",
    dateOfBirth: new Date(),
    isAcceptSignature: false,
  });
  const [popupMode, setPopUpMode] = useState({ mode: "" });
  const [phoneList, setPhoneList] = useState([]);
  const [changesSavedModal, setChangesSaveModal] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const [infoModalPopup, setInfoModalPopup] = useState();

  const location = useLocation();
  const medicalUserDetail = location?.state?.medicalUserDetail;
  const phoneTypeList = location?.state?.phoneTypeList;

  const allRegionList = useSelector((store) => store?.common?.allRegionList);
  const { loading } = useSelector((store) => store?.medicalAlertUsers);
  const { selectedPhoneType } = useSelector((store) => store?.common);
  const { accountHolderData } = useSelector((store) => store?.userDetail);
  const userData = useSelector((store) => store?.auth?.userData);

  const dispatch = useDispatch();
  const history = useHistory();

  const setInitialData = () => {
    const protected_address = medicalUserDetail?.protected_address;
    setSubscriberState({
      ...subscriberState,
      street: protected_address?.street,
      apt: protected_address?.street2,
      city: protected_address?.city ?? "",
      state: getRegionName(protected_address?.region_id),
      zip_code: protected_address?.zip ?? "",
      dateOfBirth: medicalUserDetail?.date_of_birth
        ? formatDate(medicalUserDetail?.date_of_birth)
        : new Date(),
    });

    const regionData = getDataFromId(
      allRegionList,
      "region_id",
      protected_address?.region_id
    );
    handleFieldChange(
      "state",
      regionData && regionData?.length > 0
        ? {
            id: regionData[0]?.region_id,
            value: regionData[0]?.region_code,
          }
        : null
    );

    let cloneData = [];
    const phoneType1 = getDataFromId(
      phoneTypeList,
      "phone_type_id",
      protected_address?.mobile_type_id
    );
    const phoneType2 = getDataFromId(
      phoneTypeList,
      "phone_type_id",
      protected_address?.cellphone_phone_type_id
    );
    if (protected_address?.mobile) {
      cloneData?.push({
        phoneNumber: protected_address?.mobile,
        id: 0,
        type: phoneType1 && phoneType1?.length > 0 ? phoneType1[0] : "",
        auto_notify: protected_address?.is_auto_notify ? false : true,
      });
    }
    if (protected_address?.cellphone) {
      cloneData?.push({
        phoneNumber: protected_address?.cellphone,
        id: 1,
        type: phoneType2 && phoneType2?.length > 0 ? phoneType2[0] : "",
        auto_notify: protected_address?.is_cell_auto_notify ? false : true,
      });
    }
    setPhoneList(cloneData);
  };

  useEffect(() => {
    getRegionList({
      dispatch,
      userType: "accountowner",
    });
    getPhoneTypeList({
      dispatch,
      history,
      userType: "accountowner",
    });
    setInitialData();
  }, []);

  useEffect(() => {
    const protected_address = medicalUserDetail?.protected_address;
    const regionData = getDataFromId(
      allRegionList,
      "region_id",
      protected_address?.region_id
    );
    handleFieldChange(
      "state",
      regionData && regionData?.length > 0
        ? {
            id: regionData[0]?.region_id,
            value: regionData[0]?.region_code,
          }
        : null
    );
  }, [allRegionList]);

  const getRegionName = (id) => {
    let preSelectedRegionName = "";
    allRegionList?.filter((ele) => {
      if (parseInt(ele?.region_id) === parseInt(id)) {
        preSelectedRegionName = ele?.region_code;
      }
    });

    return preSelectedRegionName;
  };

  const handleFieldChange = (type, value, isNumeric = false) => {
    let numericText = value;
    if (isNumeric) {
      numericText = value.replace(/[^0-9]/g, "");
    }
    setSubscriberState((prev) => {
      return { ...prev, [type]: numericText };
    });
  };

  const onRemoveContact = () => {
    const deletedItem = isOpenDeleteContactModal?.item;
    if (deletedItem) {
      const deletedList = phoneList?.filter(
        (item) => item?.id !== isOpenDeleteContactModal?.item?.id
      );
      setPhoneList(deletedList);
      setPopUpMode({ mode: "" });
    }
    setOpenDeleteContactModal(false);
  };

  const onAddContact = () => {
    setSelectedPhoneType(dispatch, null);
    setPopUpMode({ mode: "addContact", item: null });
  };

  const onEditContact = (item) => {
    setSelectedPhoneType(dispatch, item?.type);
    setPopUpMode({ mode: "editContact", item: item, id: item?.id });
  };

  const handleOnSubmit = async () => {
    if (!subscriberState?.isAcceptSignature) return;
    let timeDifference = await isSessionTimeExpired();
    if (isEmpty(subscriberState?.street)) {
      setSnackBarMessage({ message: alerts?.enter_street });
    } else if (isEmpty(subscriberState?.city)) {
      setSnackBarMessage({ message: alerts?.enter_city });
    } else if (isEmpty(subscriberState?.state)) {
      setSnackBarMessage({ message: alerts?.select_state });
    } else if (isEmpty(subscriberState?.zip_code)) {
      setSnackBarMessage({ message: alerts?.enter_zipcode });
    } else if (isFutureDate(subscriberState?.dateOfBirth)) {
      setSnackBarMessage({ message: alerts?.future_birth_date });
    } else if (phoneList?.length <= 0) {
      setSnackBarMessage({
        message: alerts?.minimum_one_medical_contact_required,
      });
    } else if (timeDifference) {
      setSessionExpiryModalVisibility(dispatch, true);
      return;
    } else {
      const selectedRegion = getDataFromId(
        allRegionList,
        "region_id",
        subscriberState?.state?.id
      );

      let payload = {
        address_id: medicalUserDetail?.protected_address?.address_id,
        region_id: subscriberState?.state?.id,
        street: subscriberState?.street,
        street2: subscriberState?.apt,
        postcode: subscriberState?.zip_code,
        city: subscriberState?.city,
        reference_region_id:
          selectedRegion && selectedRegion?.length > 0
            ? selectedRegion[0]?.reference_region_id
            : null,
        date_of_birth: moment(subscriberState?.dateOfBirth)
          ?.format("YYYY-MM-DD")
          ?.toString(),
        mobile: phoneList[0]?.phoneNumber ? phoneList[0]?.phoneNumber : false,
        phone_type_id: phoneList[0]?.type?.phone_type_id
          ? phoneList[0]?.type?.phone_type_id
          : phoneTypeList[0]?.phone_type_id,
        reference_phone_type_id: phoneList[0]?.type?.reference_phone_type_id
          ? phoneList[0]?.type?.reference_phone_type_id
          : phoneTypeList[0]?.reference_phone_type_id,
        phone: phoneList[1]?.phoneNumber ? phoneList[1]?.phoneNumber : false,
        cell_phone_type_id: phoneList[1]?.type?.phone_type_id
          ? phoneList[1]?.type?.phone_type_id
          : phoneTypeList[0]?.phone_type_id,
        reference_cellphone_phone_type_id: phoneList[1]?.type
          ?.reference_phone_type_id
          ? phoneList[1]?.type?.reference_phone_type_id
          : phoneTypeList[0]?.reference_phone_type_id,
        is_auto_notify: phoneList[0]?.auto_notify ? false : true,
        is_cell_auto_notify: phoneList[1]?.auto_notify ? false : true,
      };

      const params = {
        dispatch,
        history,
        setSnackBarMessage,
        setChangesSaveModal,
        payload,
        medical_alert_user_id: medicalUserDetail?.medical_alert_user_id,
        userType: userData?.isGuestUser ? "guestuser" : "accountowner",
        engagementType: "Update Subscriber Detail",
      };
      updateMedicalAddress(params);
    }
  };

  const onPhoneNumberSave = (item) => {
    const phoneId = popupMode?.item?.id;
    if (phoneId || phoneId == 0) {
      const selectedPhoneId = phoneList?.findIndex(
        (item) => item?.id === phoneId
      );
      if (selectedPhoneId > -1) {
        let selectedPhone = phoneList[selectedPhoneId];
        selectedPhone = {
          ...selectedPhone,
          phoneNumber: item?.phoneNumber,
          type: selectedPhoneType,
          auto_notify: item?.isAcceptSignature,
        };
        phoneList[selectedPhoneId] = selectedPhone;
        setPhoneList(phoneList);
      }
    } else {
      let list = JSON.parse(JSON.stringify(phoneList));
      let obj = {
        key: phoneList?.length,
        phoneNumber: item?.phoneNumber,
        type: selectedPhoneType,
        auto_notify: item?.isAcceptSignature,
      };
      list.push(obj);
      setPhoneList(list);
    }
    setPopUpMode({ mode: "" });
  };

  const handleDropdownSelect = (value, id) => {
    handleFieldChange("state", { id: id, value: value });
  };

  const showModal = (description) => {
    setInfoModalPopup({
      description,
      isVisible: true,
    });
  };

  return (
    <MainLayout
      text={titles?.subscriber}
      isBackHide={false}
      isNavbar={true}
      white
      isLoading={loading}
      snackBarMessage={snackBarMessage}
    >
      <AddAccountContactModal
        show={popupMode?.mode !== ""}
        onClose={() => setPopUpMode({ mode: "" })}
        onSave={(item) => {
          onPhoneNumberSave(item);
        }}
        phoneInfo={popupMode?.item}
        onDeleteContact={(item) => {
          setOpenDeleteContactModal({ item: item });
          setPopUpMode({ mode: "" });
        }}
      />
      <ConfirmationModal
        show={isOpenDeleteContactModal?.item ? true : false}
        setShow={setOpenDeleteContactModal}
        svgIcon={<IconTrash color="#000000" />}
        title={titles?.delete_contact}
        description={titles?.are_you_sure_to_remove_number}
        btnText={titles?.remove}
        onClick={() => {
          onRemoveContact();
        }}
      />
      <ChangesSavedModal
        show={changesSavedModal}
        setShow={setChangesSaveModal}
      />
      <InformationModal
        show={infoModalPopup?.isVisible}
        setShow={setInfoModalPopup}
        infoText={infoModalPopup?.description}
      />
      <CardLayoutWrapper>
        <div className="sp_title_container">
          <p className="display_h2 mb5">{`${medicalUserDetail?.firstname}  ${medicalUserDetail?.lastname}`}</p>
        </div>
        <p className="body_h1 acc_status_text">{`${titles?.account} #${medicalUserDetail?.account_no}`}</p>
        <p className="body_h1 acc_status_text">{`${titles?.status}: ${
          medicalUserDetail?.is_subscription_cancelled ? "Inactive" : "Active"
        }`}</p>
      </CardLayoutWrapper>
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb10 mr5">
          {titles?.protected_address}
        </p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() =>
            showModal(titles?.modal_texts?.protected_address_help_tip)
          }
        />
      </div>
      <p className="body_h1 left mb15">{titles?.emergency_info_tooltip}</p>
      <CustomInput
        placeholderStyle={"acc_holder_placeholder"}
        placeholder={titles?.street}
        type="text"
        className="mb10 esd_input_field"
        required
        value={subscriberState?.street}
        onChange={(e) => {
          handleFieldChange("street", e?.target?.value, false);
        }}
      />
      <CustomInput
        placeholderStyle={"acc_holder_placeholder"}
        placeholder={titles?.apt}
        type="text"
        className="mb10 esd_input_field"
        value={subscriberState?.apt ? subscriberState?.apt : ''}
        onChange={(e) => {
          handleFieldChange("apt", e?.target?.value, false);
        }}
      />
      <CustomInput
        placeholderStyle={"acc_holder_placeholder"}
        placeholder={titles?.city}
        type="text"
        className="mb10 esd_input_field"
        required
        value={subscriberState?.city}
        onChange={(e) => {
          handleFieldChange("city", e?.target?.value, false);
        }}
      />
      <div className="sd_fields_container mb10">
        <CustomSelectBox
          required={true}
          options={getAllRegionListNames(allRegionList)}
          onChange={(item, id) => handleDropdownSelect(item?.value, id)}
          title={titles?.state}
          selectedValue={subscriberState?.state?.value}
        />
      </div>
      <CustomInput
          placeholderStyle={"acc_holder_placeholder"}
          placeholder={titles?.zip_code}
          type="number"
          className={"esd_input_field mb20"}
          required
          value={subscriberState?.zip_code}
          onChange={(e) => {
            handleFieldChange("zip_code", e?.target?.value, true);
          }}
        />
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb10 mr5">
          {titles?.date_of_birth}
        </p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() => showModal(titles?.modal_texts?.dob_help_tip)}
        />
      </div>
      <CustomDatePicker
        selected={subscriberState?.dateOfBirth}
        className={"mb20"}
        onChange={(date) => {
          handleFieldChange("dateOfBirth", date, false);
        }}
      />
      <div className="field_title_container">
        <p className="sd_label_text display_h3 mb15 mr5">{titles?.phone}</p>
        <IconQuestion
          className={"sd_notify_icon"}
          onClick={() =>
            showModal(titles?.modal_texts?.phone_subscriber_help_tip)
          }
        />
      </div>
      <p className="body_h1 left mb10">{titles?.number_used_subscriber}</p>
      <p className="body_h1 left mb10">{titles?.hold_drag_item_desc}</p>
      <CustomDraggableCards
        setOpenDeleteContactModal={setOpenDeleteContactModal}
        className={"mb15"}
        initialItems={phoneList}
        onDelete={(item) => setOpenDeleteContactModal({ item: item })}
        onEdit={(item) => onEditContact(item)}
        onDragEnd={(data) => setPhoneList(data)}
      />
      <p
        className="ah_add_contact_text display_h5 mb22"
        onClick={() => onAddContact()}
      >
        {titles?.add_contact}
      </p>
      <div className="horizontal_divider_line mb22"></div>
      <p className="display_h4 mb10">{titles?.digital_signature}</p>
      <p className="body_h1 left mb20">{titles?.please_read_the_following}</p>
      <p className="body_h1 left mb20">
        {titles?.degital_signature_terms_condition}
      </p>
      <div className="consent_note_container mb20">
        <CustomCheckbox
          checked={subscriberState?.isAcceptSignature}
          onChange={() => {
            handleFieldChange(
              "isAcceptSignature",
              !subscriberState?.isAcceptSignature
            );
          }}
          className="acm_checkbox_field"
        />
        <div>
          <p className="body_h1 ml-1 sms_note new_line_text">
            {`**${
              accountHolderData?.firstname + " " + accountHolderData?.lastname
            }\nc/o ${
              medicalUserDetail?.firstname + " " + medicalUserDetail?.lastname
            }**`}
          </p>
        </div>
      </div>
      <CustomButton
        width={"100%"}
        height={"50px"}
        className={"esd_submit_btn"}
        text={titles?.accept}
        onClick={() => handleOnSubmit()}
        disabled={!subscriberState?.isAcceptSignature}
      />
    </MainLayout>
  );
};

export default EditSubscriberDetails;
