import React, { useEffect, useState } from "react";
import { titles } from "constants/titles";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import ConfirmationModal from "../confirmation-modal/ConfirmationModal";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLogout, getMenuList } from "redux/AdminScreens/action";
import { getData } from "utility";
import keys from "constants/keys";
import "./Sidebar.css";

const Sidebar = React.forwardRef(({ show, setShow }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const [adminDetails, setAdminDetails] = useState({
    support_user_name: "",
  });
  const dispatch = useDispatch();
  const { adminMenu = [] } = useSelector((state) => state.adminUser);
  const history = useHistory();

  const onLogout = async () => {
    setShowConfirmationPopUp(true);
  };

  useEffect(() => {
    getMenuList(dispatch);
    getAdminData();
  }, []);

  const getAdminData = async () => {
    let adminData = await getData(keys?.adminDetail);
    if (adminData && JSON.parse(adminData)) {
      setAdminDetails({
        support_user_name: JSON.parse(adminData)?.support_user_name || "",
      });
    }
  };

  const onTabClick = (route) => {
    if (route?.menu_key === "Logout") {
      onLogout();
      return;
    } else if (route?.menu_key === "Customer Login") {
      history?.replace(ROUTES_CONSTANTS.customerLogin);
      setShow(false);
    }
  };

  const handleTabSelect = (index, tab) => {
    setSelectedIndex(index);
    onTabClick(tab);
  };

  return (
    <div ref={ref} className={`sidebar_container ${show ? "visible" : "hide"}`}>
      <ConfirmationModal
        title={titles?.logout}
        description={titles?.sign_out_desc}
        btnText={titles?.logout}
        onClick={() => {
          adminLogout(dispatch, history, setShowConfirmationPopUp);
        }}
        show={showConfirmationPopUp}
        setShow={setShowConfirmationPopUp}
      />
      {show ? (
        <>
          <div className="s_profile_img_container mb15">
            <p className="display_h2 s_profile_text">
              {adminDetails?.support_user_name
                ? adminDetails?.support_user_name?.charAt(0)?.toUpperCase()
                : ""}
            </p>
            {/* hide edit admin functionality */}
            {/* <IconEdit
              onClick={() => history.push(ROUTES_CONSTANTS?.editAdminProfile)}
              color="#6b6b6b"
              className={"s_edit_icon"}
            /> */}
          </div>
          <p className="s_profile_name display_h2 mb10">
            {adminDetails?.support_user_name}
          </p>
          <div className="horizontal_divider_line"></div>
          <div className="s_tabs_container">
            {adminMenu?.map((tab, index) => {
              return (
                <div
                  key={`tab ${index}`}
                  onClick={() => handleTabSelect(index, tab)}
                  className={`s_tab_bg_filler ${
                    selectedIndex === index ? "selected_tab" : ""
                  } `}
                >
                  <div className="s_tab">
                    <img
                      src={tab?.menu_icon}
                      alt=""
                      className="sidebar_menu_icon"
                    />
                    <p className="body_h1">{tab?.menu_key}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
});
export default Sidebar;
