import React, { useEffect, useState } from "react";
import "./ManageGuestUsers.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import { IconEdit, IconSmallQuestion, IconTrash } from "assets/SVGs/SVGs";
import ConfirmationModal from "components/layout/confirmation-modal/ConfirmationModal";
import AddGuestUserModal from "components/layout/add-guest-user-modal/AddGuestUserModal";
import { useDispatch, useSelector } from "react-redux";
import { isSessionTimeExpired } from "utility";
import {
  deleteGuestUser,
  getGuestUserList,
} from "redux/ManageGuestUser/actions";
import { useHistory } from "react-router-dom";
import alerts from "constants/alerts";
import SessionExpiredPopup from "components/layout/session-expired-popup/SessionExpiredPopup";
import InformationModal from "components/layout/information-modal/InformationModal";

const ManageGuestUsers = () => {
  const [guestUserPopupMode, setGuestUserPopupMode] = useState("");
  const [verificationPopUp, setVerificationPopUp] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const [showInformationModal, setShowInformationModal] = useState();
  const guestList = useSelector((store) => store?.guestUser?.guestUserList);
  const loading = useSelector((store) => store?.guestUser?.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getGuestUserList(dispatch, history);
  }, []);

  const onRemoveUser = async (data) => {
    let timeDifference = await isSessionTimeExpired();
    if (timeDifference) {
      setGuestUserPopupMode("");
      setVerificationPopUp(true);
      return;
    } else {
      deleteGuestUser(
        dispatch,
        {
          guest_user_id: data?.guest_user_id,
        },
        setSnackBarMessage,
        setGuestUserPopupMode(""),
        history
      );
    }
  };

  const onAddGuestUser = () => {
    if (guestList && guestList?.length >= 3) {
      setSnackBarMessage({ message: alerts?.guest_user_maximum_allowed });
    } else {
      setGuestUserPopupMode({ mode: "add" });
    }
  };

  return (
    <MainLayout
      text={titles?.manage_guest_users}
      isBackHide={false}
      isSemiHeader={true}
      white={false}
      isLoading={loading}
      snackBarMessage={snackBarMessage}
    >
      <AddGuestUserModal
        show={
          guestUserPopupMode?.mode === "add" ||
          guestUserPopupMode?.mode === "edit"
        }
        mode={guestUserPopupMode?.mode}
        guestDetail={guestUserPopupMode?.detail}
        setShow={setGuestUserPopupMode}
      />
      <InformationModal
        show={showInformationModal}
        setShow={setShowInformationModal}
        infoText={titles?.modal_texts?.guest_user_help_tip}
      />
      <ConfirmationModal
        show={guestUserPopupMode?.mode === "delete"}
        setShow={setGuestUserPopupMode}
        svgIcon={<IconTrash color="#000000" />}
        title={titles?.delete_guest}
        description={titles?.remove_guest_desc}
        btnText={titles?.remove}
        onClick={() => onRemoveUser(guestUserPopupMode?.detail)}
      />
      <p className="body_h1 mg_notify_text mb10">
        {titles?.add_upto_three_guest}
        <IconSmallQuestion
          className={"sd_notify_icon tip_text_icon mg_tip_left"}
          fillColor={'#fff'}
          onClick={() => setShowInformationModal(true)}
        />
      </p>
      <p className="body_h1 mg_notify_text">{titles?.guest_notification}</p>
      <div className="mg_horizontal_line"></div>
      {guestList?.map((guest, index) => {
        return (
          <div className="mg_card_container mb15" key={`guest ${index}`}>
            <div className="mg_tools">
              <IconEdit
                className={"mg_icon"}
                onClick={() =>
                  setGuestUserPopupMode({ mode: "edit", detail: guest })
                }
              />
              <IconTrash
                className={"mg_icon"}
                onClick={() =>
                  setGuestUserPopupMode({ mode: "delete", detail: guest })
                }
              />
            </div>
            <p className="body_h1 mg_notify_text">
              {`${titles?.guest} :  ${guest?.guest_user_name}`}
            </p>
            <p className="body_h1 mg_notify_text">
              {`${titles?.password} : ${guest?.Password ?? "*******"}`}
            </p>
          </div>
        );
      })}
      {guestList?.length < 3 ? (
        <p
          onClick={() => onAddGuestUser()}
          className="display_h5 add_guest_user_text"
        >
          + Add a Guest User
        </p>
      ) : null}
      {verificationPopUp ? (
        <SessionExpiredPopup
          isVisible={verificationPopUp}
          onClosePopup={() => {
            setVerificationPopUp(false);
          }}
          history={history}
        />
      ) : null}
    </MainLayout>
  );
};

export default ManageGuestUsers;
