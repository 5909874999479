import React from "react";
import "./DeviceDetailCard.css";
import CustomButton from "components/widgets/custom-button/CustomButton";

const ParameterCard = ({
  title,
  value,
  icon,
  statusUpdateAt,
  className,
  isHideStatus = false,
  isShowButton,
  text,
  onClick,
}) => {
  return (
    <div className={`${className} para_card_container`}>
      <div className="pc_section_div mb10">
        <div className="pc_left_section_div">
          <p className="pc_title_text display_h3">{title}</p>
          <p className="pc_value_text display_h4">{value}</p>
        </div>
        {icon}
      </div>
      {!isHideStatus ? (
        <p className="pc_status_text">{statusUpdateAt}</p>
      ) : null}
      {isShowButton ? (
        <CustomButton
          className={"pc_card_btn"}
          text={text}
          width={"250px"}
          onClick={onClick}
        />
      ) : null}
    </div>
  );
};

export default ParameterCard;
