import React from "react";
import "./EditAdminProfile.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import CustomInput from "components/widgets/custom-input/CustomInput";
import { titles } from "constants/titles";
import CustomButton from "components/widgets/custom-button/CustomButton";

const EditAdminProfile = () => {
  return (
    <MainLayout
      text={titles?.edit_admin_profile}
      isBackHide={false}
      isNavbar={false}
    >
      <div className="admin_sign_in_fields_container">
        <CustomInput label={titles?.first_name} type="text" className="mb15" />
        <CustomInput label={titles?.last_name} type="text" className="mb15" />
        <CustomInput label={titles?.email} type="email" className="mb15" />

        <CustomButton
          height={"50px"}
          className={"mb20 blue_button"}
          text={titles?.save_changes}
        />
      </div>
    </MainLayout>
  );
};

export default EditAdminProfile;
