import {
  ADMIN_LIST_REQUEST,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAILURE,
  ADMIN_PROFILE_UPDATE_REQUEST,
  ADMIN_PROFILE_UPDATE_SUCCESS,
  ADMIN_PROFILE_UPDATE_FAILURE,
  ADMIN_SHOW_LOADER,
  ADMIN_HIDE_LOADER,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILURE,
  ADMIN_ADD_REQUEST,
  ADMIN_ADD_SUCCESS,
  ADMIN_ADD_FAILURE,
  ADMIN_MENU_LIST_REQUEST,
  ADMIN_MENU_LIST_SUCESS,
  ADMIN_MENU_LIST_FAILURE,
  ADMIN_GENRATE_OTP_REQUEST,
  ADMIN_GENRATE_OTP_SUCESS,
  ADMIN_GENRATE_OTP_FAILURE,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  adminList: [],
  adminProfileData: {},
  selectedAdminData: {},
  userData: null,
  accessToken: "",
  adminData: null,
  adminMenu: [],
  customerOTP: null,
};

const adminScreenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        adminList: action?.payload,
      };
    case ADMIN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        adminList: [],
      };
    case ADMIN_PROFILE_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_PROFILE_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        adminProfileData: action?.payload,
      };
    }
    case ADMIN_PROFILE_UPDATE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADMIN_MENU_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_MENU_LIST_SUCESS: {
      return {
        ...state,
        loading: false,
        adminMenu: action?.payload,
      };
    }

    case ADMIN_MENU_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADMIN_LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        adminData: action?.payload,
      };
    }
    case ADMIN_LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADMIN_SHOW_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_HIDE_LOADER: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADMIN_LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        adminData: action?.payload,
      };
    }
    case ADMIN_LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADMIN_ADD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADMIN_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADMIN_GENRATE_OTP_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_GENRATE_OTP_SUCESS: {
      return {
        ...state,
        loading: false,
        customerOTP: action?.payload,
      };
    }
    case ADMIN_GENRATE_OTP_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default adminScreenReducer;