import React, { useEffect, useState } from "react";
import "./InvoicesAndBills.css";
import MainLayout from "components/layout/main-layout/MainLayout";
import { titles } from "constants/titles";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceList } from "redux/MedicalAlertUsers/actions";
import { useHistory, useLocation } from "react-router-dom";
import { getRegionList } from "redux/Common/action";
import { formatDate } from "utility";
import { icon_pdf } from "assets/images/images";
import CustomCheckbox from "components/widgets/custom-checkbox/CustomCheckbox";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { ROUTES_CONSTANTS } from "routes/route-constants";

const InvoicesAndBills = () => {
  const [invoiceDownloading, setInvoiceDownloading] = useState(false);
  const [inoviceLocalList, setInvoiceList] = useState([]);
  const [counter, setCounter] = useState(0);

  const { invoiceList, loading, selectedMedicalUser } = useSelector(
    (store) => store?.medicalAlertUsers
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getInvoiceList({
      dispatch,
      history,
      medical_alert_user_id: selectedMedicalUser?.medical_alert_user_id,
    });
  }, []);

  useEffect(() => {
    if (invoiceList) {
      try {
        let list = JSON.parse(JSON.stringify(invoiceList));
        list?.sort(compare);
        setInvoiceList(list);
      } catch (e) {}
    }
  }, [invoiceList]);

  function compare(a, b) {
    if (a?.is_paid < b?.is_paid) {
      return -1;
    }
    if (a?.is_paid > b?.is_paid) {
      return 1;
    }
    return 0;
  }

  const onPayClick = (item) => {
    const selectedInvoiceList = inoviceLocalList?.filter(
      (item) => item?.isSelected === true && !item?.is_paid
    );
    getRegionList({
      dispatch,
      userType: "accountowner",
    });
    history?.push(ROUTES_CONSTANTS.billingDetails, {
      isFromPayment: true,
      invoices: selectedInvoiceList,
      ...location.state,
    });
  };

  const onInvoiceSelect = (item, index) => {
    if (item?.is_paid) return;
    const list = inoviceLocalList;
    list[index] = {
      ...list[index],
      isSelected: !list[index]?.isSelected,
    };
    setInvoiceList(list);
    setCounter(counter + 1);
  };

  const isSelectedUnpaidInvoices = () => {
    const list = inoviceLocalList?.filter((item) => item?.isSelected);
    return list && list?.length > 0;
  };

  const isUnPaidInvoice = () => {
    const list = inoviceLocalList?.filter((item) => !item?.is_paid);
    return list && list?.length > 0;
  };

  const downLoadInvoice = (item) => {
    let randomNumber = Math.floor(Math.random() * 100);
    setInvoiceDownloading(true);
    fetch(item?.invoice_doc_url)
      .then((response) => response?.blob())
      .then((blob) => {
        setInvoiceDownloading(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link?.setAttribute(
          "download",
          `${item?.sub_invoice_id + "_" + randomNumber}.pdf`
        ); // Customize the downloaded file name
        document.body.appendChild(link);
        link?.click();
        link?.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        setInvoiceDownloading(false);
        console.error("Error downloading PDF:", error);
      });
  };

  return (
    <MainLayout
      text={titles?.invoices_bills}
      isBackHide={false}
      isNavbar={true}
      white={true}
      isLoading={loading || invoiceDownloading}
    >
      <div
        className={`invoice_list_container ${
          isUnPaidInvoice() ? "with_button" : "without_button"
        } mb22`}
      >
        {inoviceLocalList?.map((item, index) => {
          return (
            <div className="consent_note_container mb20">
              <CustomCheckbox
                checked={item?.isSelected ? true : false}
                onChange={() => {
                  onInvoiceSelect(item, index);
                }}
                className={`acm_checkbox_field ${
                  item?.is_paid ? "checkbox_hide" : "checkbox_visible"
                }`}
              />

              <div className="invoice_card_container">
                <div className="card_details_container mb5">
                  <div>
                    <p className="display_h4 mb5">{titles?.invoice}</p>
                    <p className="display_h5 left invoice_title">
                      {item?.sub_invoice_id}
                    </p>
                  </div>
                  {item?.is_paid ? (
                    <div
                      className="sh_backIcon"
                      onClick={() => downLoadInvoice(item)}
                    >
                      <img src={icon_pdf} alt="" width={46} height={46} />
                    </div>
                  ) : null}
                </div>

                <div className="card_details_container">
                  <div>
                    <p className="display_h4 ">{titles?.date}</p>
                    <p className="body_h4 left card_values">
                      {formatDate(item?.invoice_date)}
                    </p>
                  </div>
                  <div>
                    <p className="display_h4 ">{titles?.total_invoice}</p>
                    <p className="body_h4 left card_values">{`$${item?.invoice_amount}`}</p>
                  </div>
                  <div>
                    <p className="display_h4 ">{titles?.status}</p>
                    <p
                      className={`body_h4 left ${
                        item?.is_paid ? "paid" : "unpaid"
                      }`}
                    >
                      {item?.is_paid ? titles?.paid : titles?.unpaid}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {isUnPaidInvoice() ? (
        <CustomButton
          width={"100%"}
          height={"50px"}
          className={"esd_submit_btn"}
          text={titles?.pay}
          disabled={!isSelectedUnpaidInvoices()}
          onClick={() => {
            onPayClick();
          }}
        />
      ) : null}
    </MainLayout>
  );
};

export default InvoicesAndBills;
