import keys from "constants/keys";
import {
  GET_LANGUAGE_SUCCESS,
  COMMON_API_LOADER_VISIBILITY,
  PHONE_TYPE_LIST_SUCCESS,
  SET_SELECTED_PHONE_TYPE,
  DASHBOARD_MENU_LIST_SUCCESS,
  SIDE_MENU_LIST_SUCCESS,
  GET_REGIONS_SUCCESS,
  STATIC_URL_SUCCESS,
  CUSTOMER_TIMEZONE_SUCCESS,
  CURRENT_TIMEZONE,
} from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  languageList: [],
  phoneTypeList: [],
  allRegionList: [],
  selectedPhoneType: null,
  dashboardMenuList: [],
  sideMenuList: [],
  staticUrls: [],
  medicalUserTimezone: localStorage.getItem(keys.medicalUserTimezone)
  ? JSON.parse(localStorage.getItem(keys.medicalUserTimezone))
  : [],
  currentTimeZone: null,
};

const commonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        languageList: action?.payload,
      };

    case COMMON_API_LOADER_VISIBILITY:
      return {
        ...state,
        loading: action?.payload,
      };
    case PHONE_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        phoneTypeList: action?.payload,
      };
    case SET_SELECTED_PHONE_TYPE:
      return {
        ...state,
        loading: false,
        selectedPhoneType: action?.payload,
      };
    case DASHBOARD_MENU_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardMenuList: action?.payload,
      };
    case SIDE_MENU_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sideMenuList: action?.payload,
      };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allRegionList: action?.payload,
      };
    case STATIC_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        staticUrls: action?.payload,
      };
    case CUSTOMER_TIMEZONE_SUCCESS:
      return {
        ...state,
        loading: false,
        medicalUserTimezone: action?.payload,
      };
    case CURRENT_TIMEZONE:
      return {
        ...state,
        loading: false,
        currentTimeZone: action?.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
