export const ROUTES_CONSTANTS = {
  landingScreen: "/",
  login: "/login",
  customerLogin: "/customer-login",
  forgotPassword: "/forgot-password",
  changePassword: "/change-password",
  home: "/home",
  deviceProfile: "/device-profile",
  deviceList: "/devices",
  deviceSignalHistory: "/signal-history",
  caregiverTracking: "/caregiver-tracking",
  alerts: "/alerts",
  settings: "/settings",
  notificationSetting: "/notification-setting",
  manageGuestUsers: "/manage-guest-users",
  about: "/about",
  helpCenter: "/help-center",
  cancelServices: "/cancel-services",
  subscriberProfile: "/subscriber-profile",
  deviceAndTracking: "/device-and-tracking",
  editAdminProfile: "/edit-admin-profile",
  reviewAndSign: '/review-and-sign',
  invoicesAndBills: "/invoices-and-bills",
  shippingDetails: "/shipping-details",
  accountHolder: "/edit-account-holder",
  editSubscriberDetails: "/edit-subscriber-details",
  orderHistory: "/order-history",
  billingDetails: "/billing-details",
  emergencyPlan: "/emergency-plan",
  orderDetails: "/order-details",
  location:"/location-history",
  timeZoneSettings: "/time-zone-settings"
};
