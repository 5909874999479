import React, { useEffect, useRef, useState } from "react";
import "./OTPModal.css";
import { Modal } from "react-bootstrap";
import { CrossIconBtn } from "assets/SVGs/SVGs";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { titles } from "constants/titles";
import { isEmpty, isValidPassword } from "utility";
import { adminVerifyOtp } from "redux/AdminScreens/action";
import { useDispatch, useSelector } from "react-redux";
import alerts from "constants/alerts";
import { useHistory } from "react-router-dom";
import { generateOtp } from "redux/auth/actions";
import SnackBar from "components/widgets/snack-bar/SnackBar";

const OTPModal = ({ show, setShow, onResendOtp }) => {
  const [resendCode, setResendCode] = useState(60);
  const [codeExpiration, setCodeExpiration] = useState(600);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const otpUuid = useSelector((store) => store?.adminUser?.customerOTP);
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (show) {
      setSnackBarMessage({ message: "" });
      setResendCode(60);
      setCodeExpiration(600);
      [0, 1, 2, 3, 4, 5].map((_, index) => {
        if (inputRefs.current[index + 1]) {
          inputRefs.current[index + 1] = "";
        }
      });
      generateOtp(dispatch);
    }
  }, [show]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (resendCode > 0) {
        setResendCode(resendCode - 1);
      }
      if (codeExpiration > 0) {
        setCodeExpiration(codeExpiration - 1);
      }
    }, 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [resendCode, codeExpiration]);

  const handleChange = (e, index) => {
    if (e.target.value.length === 1 && index < 5) {
      inputRefs.current[index + 1].focus();
    } else if (index === 5) {
      inputRefs.current[index].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (e.target.value === "") {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        e.target.value = "";
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 6);
    pasteData.split("").forEach((char, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = char;
      }
    });
    inputRefs.current[Math.min(pasteData.length - 1, 5)].focus();
  };

  const handleOnVerify = async () => {
    if (inputRefs) {
      const value = inputRefs?.current?.map((ref) => ref?.value).join("");
      if (isEmpty(value)) {
        setSnackBarMessage({ message: alerts?.enter_otp });
      } else if (!isValidPassword(value, 6)) {
        setSnackBarMessage({ message: alerts?.otp_minimum_required });
      } else {
        adminVerifyOtp(
          dispatch,
          {
            uuid: otpUuid?.uuid,
            otp: parseInt(value, 10),
            customer_id: otpUuid?.customer_id,
          },
          history,
          setSnackBarMessage,
          setShow
        );
      }
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const onResendCode = () => {
    if (resendCode < 1) {
      setResendCode(60);
      setCodeExpiration(600);
      onResendOtp();
    }
  };

  return (
    <Modal
      backdrop="true"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="otp_container"
      show={show}
    >
      <Modal.Body className="otp_body">
        <SnackBar snackBarMessage={snackBarMessage} />
        <CrossIconBtn
          className="otp_cross_icon"
          onClick={() => setShow(!show)}
        />
        <div className="otp_container">
          <p className="display_h5 otp_text mb15 otp_expire_text">
            {`Code Expires in ` + formatTime(codeExpiration)}
          </p>
          <div className="otp_container_div mb15">
            {[0, 1, 2, 3, 4, 5].map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="otp_input"
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          <p
            className="display_h5 otp_text mb15 resend_code_text"
            onClick={() => onResendCode()}
          >
            {resendCode < 1
              ? `Resend Code`
              : `Resend Code in ` + " " + formatTime(resendCode)}
          </p>
          <CustomButton
            text={titles?.verify}
            width={"80%"}
            className={"otp_confirm_btn"}
            onClick={() => {
              handleOnVerify();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OTPModal;
