import { getApi, postApi } from "../../api/commonApi";
import apis from "../../api/apiEndPoints";
import {
  getData,
  handleResponses,
  handleUnAuthorizedAccess,
  storeData,
} from "../../utility";
import keys from "../../constants/keys";
import {
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
  MEDICAL_USER_DETAIL_REQUEST,
  MEDICAL_USER_DETAIL_SUCCESS,
  MEDICAL_USER_LIST_FAILURE,
  MEDICAL_USER_LIST_REQUEST,
  MEDICAL_USER_LIST_SUCCESS,
  MEDICAL_USER_LOADER_VISIBILITY,
  ORDER_HISTORY_REQUEST,
  ORDER_HISTORY_SUCCESS,
  SELECTED_MEDICAL_USER,
  ACCOUNT_HOLDER_PULL_REFRESH,
  MEDICAL_USER_PULL_REFRESH_VISIBILITY,
} from "./actionTypes";
import { BASE_URL } from "constants/routes/api";

export const getMedicalAlertUsers = async (params, pullRefresh) => {
  const { dispatch, userType, history, setSnackBarMessage } = params;

  const API = BASE_URL + apis?.medicalAlertUserList;
  let accessToken = await getData(keys?.accessTokenKey);

  if (!params?.disableReset && !pullRefresh) {
    returnToDispatch(dispatch, MEDICAL_USER_LIST_REQUEST);
  }
  if (pullRefresh == true) {
    returnToDispatch(dispatch, ACCOUNT_HOLDER_PULL_REFRESH, pullRefresh);
  }

  getApi(API, accessToken, userType)
    .then(async (response) => {
      returnToDispatch(dispatch, ACCOUNT_HOLDER_PULL_REFRESH, false);
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          MEDICAL_USER_LIST_SUCCESS,
          response?.data?.data
        );
      } else {
        returnToDispatch(dispatch, MEDICAL_USER_LIST_FAILURE);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, MEDICAL_USER_LIST_FAILURE, false);
      returnToDispatch(dispatch, ACCOUNT_HOLDER_PULL_REFRESH, false);
    });
};

export const getMedicalAlertUserDetail = async (params, pullRefresh) => {
  const { dispatch, userType, history, medical_alert_user_id } = params;

  const API =
    BASE_URL +
    apis?.medicalAlertUserDetail +
    `?medical_alert_user_id=${medical_alert_user_id}`;
  let accessToken = await getData(keys?.accessTokenKey);

  if (!params?.disableReset && !pullRefresh) {
    returnToDispatch(dispatch, MEDICAL_USER_DETAIL_REQUEST);
  }
  if (pullRefresh == true) {
    returnToDispatch(dispatch, MEDICAL_USER_PULL_REFRESH_VISIBILITY, pullRefresh);
  }

  getApi(API, accessToken, userType)
    .then(async (response) => {
      returnToDispatch(dispatch, MEDICAL_USER_PULL_REFRESH_VISIBILITY, false);
      if (response?.data?.code === 200) {
        returnToDispatch(
          dispatch,
          MEDICAL_USER_DETAIL_SUCCESS,
          response?.data?.data
        );
      } else {
        returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
      returnToDispatch(dispatch, MEDICAL_USER_PULL_REFRESH_VISIBILITY, false);
    });
};

export const getInvoiceList = async (params) => {
  const { dispatch, history, medical_alert_user_id } = params;

  const API =
    BASE_URL +
    apis?.getInvoiceList +
    `?medical_alert_user_id=${medical_alert_user_id}`;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, INVOICE_LIST_REQUEST);
  getApi(API, accessToken, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, INVOICE_LIST_SUCCESS, response?.data?.data);
      } else {
        returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
    });
};

export const getOrderList = async (params) => {
  const { dispatch, navigation, medical_alert_user_id } = params;

  const API =
    BASE_URL +
    apis?.getOrderList +
    `?medical_alert_user_id=${medical_alert_user_id}`;
  let accessToken = await getData(keys?.accessTokenKey);
  returnToDispatch(dispatch, ORDER_HISTORY_REQUEST);
  getApi(API, accessToken, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, ORDER_HISTORY_SUCCESS, response?.data?.data);
      } else {
        returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(navigation);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
    });
};

export const cancelSubscription = async (params) => {
  const { dispatch, setSnackBarMessage, history, payload } = params;

  const API = BASE_URL + apis?.cancelsubscription;
  let accessToken = await getData(keys?.accessTokenKey);

  returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, true);

  postApi(API, accessToken, payload, "accountowner", true)
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
        history?.goBack();
        // logCustomEvents(
        //   "update",
        //   "engagement",
        //   "Cancellation Request",
        //   medical_alert_user_id
        // );
        handleResponses(
          true,
          "Cancel subscription request has been submitted",
          setSnackBarMessage
        );
      } else {
        returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
    });
};

export const updateMedicalAddress = async (params) => {
  const {
    dispatch,
    setSnackBarMessage,
    history,
    payload,
    userType,
    setChangesSaveModal,
    engagementType,
  } = params;

  const API = BASE_URL + apis?.updateMedicalAddress;
  let accessToken = await getData(keys?.accessTokenKey);

  returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, true);
  postApi(API, accessToken, payload, "accountowner")
    .then(async (response) => {
      if (response?.data?.code === 200) {
        returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
        getMedicalAlertUserDetail({
          ...params,
          disableReset: true,
        });
        // logCustomEvents(
        //   "update",
        //   "engagement",
        //   engagementType,
        //   payload?.medical_alert_user_id
        // );
        storeData(keys?.isDetailUpdate, "true");
        if (engagementType !== "Update CC" || params?.isRefreshRequired) {
          setChangesSaveModal(true);
          setTimeout(() => {
            setChangesSaveModal(false);
            history?.goBack();
          }, 2000);
        } else {
          if (params?.isPaymentRequired && params?.onCardUpdateSuccess) {
            params?.onCardUpdateSuccess(payload?.medical_alert_user_id);
          }
        }
      } else {
        returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
        handleResponses(false, response?.data?.message, setSnackBarMessage);
        if (response?.data?.code === 401) {
          await handleUnAuthorizedAccess(history);
        }
      }
    })
    .catch((error) => {
      returnToDispatch(dispatch, MEDICAL_USER_LOADER_VISIBILITY, false);
    });
};

export const setSelectedMedicalUser = (dispatch, detail) => {
  localStorage.setItem(keys.selectedMedicalUser, JSON.stringify(detail));
  returnToDispatch(dispatch, SELECTED_MEDICAL_USER, detail);
};

const returnToDispatch = (dispatch, type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
