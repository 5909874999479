import React, { useEffect, useRef, useState } from "react";
import "./SessionExpiredPopup.css";
import { useDispatch, useSelector } from "react-redux";
import {
  generateOtp,
  logout,
  setSessionExpiryModalVisibility,
  verifyOtp,
} from "redux/auth/actions";
import { handleUnAuthorizedAccess, isEmpty, isValidPassword } from "utility";
import alerts from "constants/alerts";
import { Modal } from "react-bootstrap";
import Loader from "react-spinners/ClipLoader";
import { titles } from "constants/titles";
import ConfirmationModal from "../confirmation-modal/ConfirmationModal";
import CustomButton from "components/widgets/custom-button/CustomButton";
import { CrossIconBtn } from "assets/SVGs/SVGs";
import SnackBar from "components/widgets/snack-bar/SnackBar";
import { useHistory } from "react-router-dom";

const SessionExpiredPopup = (parentProps) => {
  const [resendCode, setResendCode] = useState(60);
  const [codeExpiration, setCodeExpiration] = useState(600);
  const [popUpMode, setPopUpMode] = useState("timeOut");
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: "",
    type: "",
  });
  const otpUuid = useSelector((store) => store?.auth?.otpData);
  const loading = useSelector((store) => store?.auth?.loading);
  const userData = useSelector((store) => store?.auth?.userData);
  const dispatch = useDispatch();
  const history = useHistory();

  const inputRefs = useRef([]);

  useEffect(() => {
    if (parentProps?.isVisible) {
      logout(dispatch, history, setSnackBarMessage, userData?.isGuestUser);

      // setPopUpMode("timeOut");
      // setResendCode(60);
      // setCodeExpiration(600);
    }
  }, [parentProps?.isVisible]);

  useEffect(() => {
    if (popUpMode === "timeOut") return;
    const timer = setInterval(() => {
      if (resendCode > 0) {
        setResendCode(resendCode - 1);
      }
      if (codeExpiration > 0) {
        setCodeExpiration(codeExpiration - 1);
      }
      if (popUpMode !== "otpVerify") {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [popUpMode, resendCode, codeExpiration]);

  const onClose = (parentPopupHide = false) => {
    setSessionExpiryModalVisibility(dispatch, false);
    setPopUpMode("");
    // it's for local
    if (parentProps?.onClosePopup) {
      parentProps?.onClosePopup(parentPopupHide);
      setPopUpMode("");
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleOnVerify = () => {
    if (inputRefs) {
      const value = inputRefs?.current?.map((ref) => ref?.value).join("");
      if (isEmpty(value)) {
        setSnackBarMessage({ message: alerts?.enter_otp });
      } else if (!isValidPassword(value, 6)) {
        setSnackBarMessage({ message: alerts?.otp_minimum_required });
      } else {
        verifyOtp(
          dispatch,
          {
            uuid: otpUuid,
            otp: parseInt(value, 10),
          },
          parentProps?.history,
          setSnackBarMessage,
          parentProps?.onClosePopup
        );
      }
    }
  };

  const handleOnEmailAccess = () => {
    setPopUpMode("reLogin");
  };

  const handleOnConfirmYes = () => {
    setPopUpMode("otpVerify");
    generateOtp(dispatch);
  };

  const onResendCode = () => {
    if (resendCode < 1) {
      setResendCode(60);
      setCodeExpiration(600);
      generateOtp(dispatch);
    }
  };

  const handleChange = (e, index) => {
    if (e.target.value.length === 1 && index < 5) {
      inputRefs.current[index + 1].focus();
    } else if (index === 5) {
      inputRefs.current[index].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (e.target.value === "") {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        e.target.value = "";
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 6);
    pasteData.split("").forEach((char, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = char;
      }
    });
    inputRefs.current[Math.min(pasteData.length - 1, 5)].focus();
  };

  return (
    <div>
      {/* {popUpMode === "otpVerify" ? (
        <Modal show={parentProps?.isVisible || false}>
          <Modal.Body className="otp_body">
            <SnackBar snackBarMessage={snackBarMessage} />
            <CrossIconBtn
              className="otp_cross_icon"
              onClick={() => {
                onClose();
              }}
            />
            <div className="otp_container">
              <p className="display_h5 mb15 center sep_title_text">
                {titles?.for_account_security}
              </p>
              <p className="display_h5 otp_text mb15 otp_expire_text">
                {`Code Expires in ` + formatTime(codeExpiration)}
              </p>
              <div className="otp_container_div mb15">
                {[0, 1, 2, 3, 4, 5].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="otp_input"
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              <p
                className="display_h5 otp_text mb15 resend_code_text"
                onClick={() => onResendCode()}
              >
                {resendCode < 1
                  ? `Resend Code`
                  : `Resend Code in ` + " " + formatTime(resendCode)}
              </p>
              <CustomButton
                text={titles?.verify}
                width={"80%"}
                className={"otp_confirm_btn mb15"}
                onClick={() => {
                  handleOnVerify();
                }}
              />
              <p
                className="display_h5 otp_text mb15 resend_code_text"
                onClick={() => handleOnEmailAccess()}
              >
                {titles?.cannot_access_email}
              </p>
            </div>
          </Modal.Body>
        </Modal>
      ) : popUpMode === "timeOut" ? (
        <ConfirmationModal
          show={popUpMode === "timeOut"}
          title={" "}
          description={titles?.session_time_out}
          btnText={titles?.yes}
          onClose={onClose}
          onClick={() => handleOnConfirmYes()}
        />
      ) : popUpMode === "reLogin" ? (
        <ConfirmationModal
          show={popUpMode === "reLogin"}
          title={titles?.cannot_access_email}
          description={titles?.cannot_access_relogin}
          btnText={titles?.yes}
          onClose={() => setPopUpMode("otpVerify")}
          onClick={() => {
            onClose(true);
            handleUnAuthorizedAccess(parentProps?.history);
          }}
        />
      ) : null}

      {parentProps?.isVisible ? (
        loading || false
      ) : false ? (
        <div className="layout_loader_container">
          <Loader color={"#248ca7"} />
        </div>
      ) : null} */}
    </div>
  );
};

export default SessionExpiredPopup;
